import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
    useDispatch, useSelector
} from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { ReactComponent as ChevronNextIcon } from "../assets/img/ico-chevron--next.svg";
import PlanningModal from "../components/modals/PlanningModal";
import { AuthModals } from "../components/modals/singUpModals/AuthProvider";
import FAQ from "../components/parts/FAQ";
import HowPlatformWork from "../components/parts/HowPlatformWork";
import SeminarsSliderSection from "../components/parts/SeminarsSliderSection";
import TopInstructors from "../components/parts/TopInstructors";
import WhyChoseUs from "../components/parts/WhyChoseUs";
import PageLoader from "../components/ui/PageLoader";
import { USER_ROLE } from "../constants/enums";
import findValue from "../helpers/findValue";
import { getImageSrc } from "../helpers/utils";
import {
    getAuthorized, getUser, setAuthModalOpen
} from "../redux/slices/applicationSlice";


const HomePage = () => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'home' });
    const [pageData, setPageData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [planModalOpen, setPlanModalOpen] = useState(false);
    const user = useSelector(getUser);
    const isAuthorized = useSelector(getAuthorized);

    const getPageData = async (lang) => {
        const res = await api.pages.getPagesByPath("/", lang);
        setPageData(res.data);
        setIsLoading(false);
    };

    useEffect(() => {
        getPageData(i18n.resolvedLanguage);
    }, [i18n.resolvedLanguage]);
    const getValue = (key) => findValue(pageData?.blocks, key);

    const headText = () => {
        if (!isAuthorized) {
            return (
                <Trans
                    i18nKey="home.title"
                    components={{
                        1: <span style={{ color: '#00F4F1' }} />,
                    }}
                />
            );
        }

        if (user && user.role !== USER_ROLE.student) {
            return (
                <Trans
                    i18nKey="home.title"
                    components={{
                        1: <span style={{ color: '#00F4F1' }} />,
                    }}
                />
            )
        } else {
            return <Trans
                i18nKey="home.check_out"
                components={{
                    1: <span style={{ color: '#00F4F1' }} />,
                }}
            />
        }
    };

    const heroButtonData = (() => {
        if (!isAuthorized) {
            return {
                action: () => dispatch(setAuthModalOpen(AuthModals.singUp)),
                text: t('call_to_action_button_not_logged_in')
            }
        }
        if (user && user.role !== USER_ROLE.student) {
            return {
                action: () => setPlanModalOpen(true),
                text: t('plan_seminar')
            };
        } else {
            return {
                action: () => navigate("/my-seminars"),
                text: t('call_to_action_button_student')
            };
        }
    })();

    if (isLoading) {
        return <PageLoader />
    }

    if (!pageData) {
        throw new Error('Failed to fetch page data');
    }

    return (
        <>
            <section className="first-screen first-screen-home">
                <div className="firts-screen-bg">
                    <img
                        alt="hero"
                        src={getImageSrc(getValue('header_background_image'), require("../assets/img/first-screen-bg-1.jpg"))}
                    />

                    <div className="home-bg-gradient" />

                    <div className="firts-screen-bg-effect" />
                </div>

                <div className="container">
                    <div className="first-screen-body mw-600">
                        <h1
                            className="heading mb-42 max-sm-text-center"
                        >
                            {headText()}
                        </h1>
                        <p
                            className="first-screen-body__description mb-60"
                        >
                            {t('description')}
                        </p>
                        <button
                            className="btn btn--primary btn--lg max-md-w-full fs-20"
                            onClick={heroButtonData.action}
                        >
                            {heroButtonData.text}

                            <span className="ico ico-14">
                                <ChevronNextIcon />
                            </span>
                        </button>
                    </div>
                </div>
            </section>

            <HowPlatformWork pageData={pageData} />

            <SeminarsSliderSection sectionTitle={t('upcoming_seminars')} />

            <TopInstructors pageData={pageData} />

            <WhyChoseUs pageData={pageData} />

            <FAQ pageData={pageData} />

            {planModalOpen && (
                <PlanningModal
                    handleClose={() => setPlanModalOpen(false)}
                    open={planModalOpen}
                />
            )}
        </>
    );
};

export default HomePage;
