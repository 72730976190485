import { useWindowSize } from "@uidotdev/usehooks";
import classNames from "classnames";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as CheckIcon } from "../../assets/img/ico-check-editor.svg";
import Template from "../../pages/editor/templates/Template";
import { templates } from "../../pages/editor/templates/variants";
import { setCurrentTemplate } from "../../redux/slices/editorSlice";
import ModalTemplate from "./ModalTemplate";

const SelectTemplateModal = ({ open, onClose, onSubmit }) => {
    const dispatch = useDispatch();
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const { width } = useWindowSize()
    const isMobile = width < 993;

    const handleSubmit = () => {
        if (!selectedTemplate) return;
        dispatch(setCurrentTemplate(selectedTemplate));
        onSubmit && onSubmit();
    };

    return (
        <ModalTemplate
            modalSize={isMobile ? "modal__dialog--xs pb-0" : "modal__dialog--xl"}
            modalContentClassName={isMobile ? "mt-auto modal-h-screen" : ""}
            onClick={onClose}
            open={open}
        >
            <div className="modal__body p-0 h-full overflow-hidden">
                <form className="form overflow-hidden">
                    <div className="form-header modal__header">
                        <div className="heading-block">
                            <h3 className="heading">
                                Select Cover Template
                            </h3>

                            <p className="text--md desk-heading">
                                Select one of ready cover templates to use
                            </p>
                        </div>
                        {!isMobile && <SubmitButton onClick={handleSubmit} />}
                    </div>

                    <div className="form-body cards-templates-container modal__body scroll-custom">
                        <div className="row g-16 gutters-form-6">
                            {templates.map((template) => (
                                <div
                                    className="col-md-4 col-sm-6 col-12 flex justify-center"
                                    key={template.templateId}
                                    style={{
                                        aspectRatio: "1.32244897959",
                                        overflow: "hidden"
                                    }}
                                >
                                    <label
                                        className="editor-checkbox"
                                        style={{ transform: "scale(0.46)" }}
                                    >
                                        <input
                                            type="radio"
                                            hidden
                                            className="hidden"
                                            name="template"
                                            checked={selectedTemplate?.templateId === template.templateId}
                                            onChange={() => setSelectedTemplate(template)}
                                        />

                                        <span className="ico ico-checked">
                                            <CheckIcon />
                                        </span>

                                        <div className="editor-checkbox--overlay" />

                                        <Template template={template} />
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </form>
            </div>
            {isMobile && <SubmitButton style={{ width: "100%", position: "absolute", bottom: 0 }} onClick={handleSubmit} />}
        </ModalTemplate >
    );
};


function SubmitButton({ onClick, ...props }) {
    return (
        <button
            className={classNames('btn btn--primary btn--md gap-10', { disabled: props.disabled }, props.className)}
            onClick={onClick}
            type="button"
        >
            <span className="info">
                Submit Template
            </span>

            <span className="ico ico-18">
                <svg
                    fill="none"
                    height="18"
                    viewBox="0 0 19 18"
                    width="19"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g>
                        <path
                            d="M7.25 3.375L12.875 9L7.25 14.625"
                            stroke="currentColor"
                            strokeLinecap="square"
                            strokeWidth="2"
                        />
                    </g>

                    <defs>
                        <clipPath>
                            <rect
                                fill="white"
                                height="18"
                                transform="translate(0.5)"
                                width="18"
                            />
                        </clipPath>
                    </defs>
                </svg>
            </span>
        </button>
    )
}

export default SelectTemplateModal;
