import classNames from "classnames";
import { useState } from "react";
import LineIcon from "../../../../assets/img/ico-minus.svg";
import PlusIcon from "../../../../assets/img/ico-plus-icon.svg";

export const Step = ({
    label,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div
                className="chat-sidebar__accordion"
                onClick={() => setIsOpen(!isOpen)}
            >
                <h4 dangerouslySetInnerHTML={{ __html: label }} />

                <span className="ico">
                    <img src={isOpen ? LineIcon : PlusIcon} alt="PlusIcon" />
                </span>
            </div>

            {isOpen && (
                <div
                    className={classNames("col-group", "gap-24", {
                        "pb-24 border-bottom": isOpen
                    })}
                >
                    {children}
                </div>
            )}
        </>
    );
};
