import { addDays } from 'date-fns'
import { Controller } from 'react-hook-form'
import TooltipInfo from '../../../../components/parts/TooltipInfo'
import DatePicker from '../../../../components/ui/formElements/DatePicker'
import SelectMulty from '../../../../components/ui/formElements/SelectMulty'
import SelectOne from '../../../../components/ui/formElements/SelectOne'
import { explanations } from '../../../../constants/explanations'
import useLocationOptions from '../../hooks/useLocationOptions'

const TourDateTab = ({
    useFormReturn,
    generalInfo
}) => {
    const { register, setValue, watch, formState: { errors }, control } = useFormReturn
    const continent = watch("continent");
    const country = watch("country");
    const startAt = watch("startAt");

    const { continentOptions, countryOptions } = useLocationOptions({
        continent,
        country,
    }, {
        includeAllCountries: false,
    });


    return (
        <>
            <div className="row-group mb-36">
                <h6 className="heading">
                    SET DATE INTERVAL
                </h6>
                <TooltipInfo content={explanations.dateInterval} />
            </div>

            <div className='schedule-group'>
                <div className="input-date-time-group">
                    <div className="flex-1">
                        <Controller
                            name="startAt"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <DatePicker
                                    label="Start Date"
                                    minDate={new Date()}
                                    placeholderText="Select Start Date"
                                    selected={value}
                                    onChange={onChange}
                                    error={errors.startAt?.message}
                                />
                            )}
                        />
                    </div>

                    <span className="input-delimiter" style={{ marginTop: 26 }} />

                    <div className="flex-1">
                        <Controller
                            name="endAt"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <DatePicker
                                    label="End Date"
                                    minDate={startAt ? addDays(new Date(startAt), 7) : new Date()}
                                    placeholderText="Select End Date"
                                    selected={value}
                                    onChange={onChange}
                                    error={errors.endAt?.message}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>

            <hr className="hr mb-42 mt-42" />

            <div className="row-group mb-36">
                <h6 className="heading">
                    SET YOUR LOCATION AVAILABILITY
                </h6>
            </div>
            <div className="row gx-16 gy-16 py-24 mb-10">
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-12">
                            <Controller
                                name="continent"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <SelectOne
                                        error={errors?.continent?.message}
                                        inputClasses="input--solid"
                                        label="Continent"
                                        onChange={(option) => onChange(option?.value)}
                                        options={continentOptions}
                                        placeholder="Enter location here..."
                                        value={value}
                                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group input--lg w-full">
                        <div className="label-row">
                            <label className="label">COUNTRY/STATE (OPTIONAL)</label>
                        </div>
                        <p className="text-14 color-text">You'll only receive requests from academies in the selected countries</p>
                        <div className="row">
                            <div className="col-12">
                                <Controller
                                    name="countries"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <SelectMulty
                                            disabled={!continent}
                                            error={errors?.countries?.message}
                                            inputClasses="input--solid"
                                            onChange={(options) => onChange(options.map(item => item.value))}
                                            options={countryOptions}
                                            placeholder="Enter location here..."
                                            value={value}
                                            wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="hr mb-42 mt-42" />

        </>
    )
}

export default TourDateTab