import { useWindowSize } from "@uidotdev/usehooks";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as BackIcon } from "../../../assets/img/caret-right.svg";
import { EVENT_STATUS, EVENT_TYPE } from "../../../constants/enums";
import { getUser } from "../../../redux/slices/applicationSlice";
import CampDiscussionPanel from "../discussion/camp/CampDiscussionPanel";
import LessonDiscussionPanel from "../discussion/lesson/LessonDiscussionPanel";
import SeminarDiscussionPanel from "../discussion/seminar/SeminarDiscussionPanel";
import { ChatHeader } from "./ChatHeader";
import { ChatMessages } from "./ChatMessages";

export const CurrentChat = () => {
    const user = useSelector(getUser);
    const { width } = useWindowSize()
    const isMobile = width <= 992
    const {
        currentChatData,
        isCurrentChatDataLoading,
    } = useSelector((state) => state.chats);
    const [isSidebarView, setIsSidebarView] = useState(false);

    const toggleSidebarView = () => {
        setIsSidebarView(!isSidebarView);
    }

    const showMessages = !isMobile || (isMobile && !isSidebarView)
    const showSidebar = !isMobile || (isMobile && isSidebarView)

    return (
        <>
            {showMessages && currentChatData && user && !isCurrentChatDataLoading && (
                <div className="messages">
                    <ChatHeader toggleSidebarView={toggleSidebarView} />
                    <ChatMessages />
                </div>
            )}
            {showSidebar && currentChatData?.status !== EVENT_STATUS.canceled && !isCurrentChatDataLoading && (
                <>
                    {isMobile && (
                        <button className="btn btn--outline" onClick={toggleSidebarView}>
                            <BackIcon />
                        </button>
                    )}
                    <ChatSidebarEvent data={currentChatData} />
                </>
            )}

        </>
    );
};


function ChatSidebarEvent({ data }) {
    if (!data || (!data?.academyAccept && !data?.eventType === "private-lessons")) return
    if (data.status === EVENT_STATUS.canceled) return

    if (data?.seminar && data?.eventType === EVENT_TYPE.seminar) {
        return <SeminarDiscussionPanel data={data} />
    }

    if (data?.plancamp && data?.eventType === EVENT_TYPE.camp) {
        return <CampDiscussionPanel data={data} />
    }

    if (data?.privateLesson && data?.eventType === "private-lessons") {
        return <LessonDiscussionPanel chatData={data} academyAccept={data?.academyAccept} />
    }
}
