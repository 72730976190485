import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TicketsModal from "../../../components/modals/TicketsModal";
import CardSeminar from "../../../components/parts/cards/CardSeminar";
import PageLoader from "../../../components/ui/PageLoader";
import { getAllMyTicketsFetch } from "../../../redux/thunks/ticketsThunks";

export const MySeminarsStudent = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [ticketsModalOpen, setTicketsModalOpen] = useState(false)
    const [activeTab, setActiveTab] = useState("upcoming");
    const [isLoading, setIsLoading] = useState(false);

    // const { tickets, isLoading } = useSelector(state => state.tickets)
    const [sortedSeminars, setSortedSeminars] = useState({
        upcoming: [],
        previous: []
    });

    const getTickets = async () => {
        const [upcoming, previous] = await Promise.all([
            await dispatch(getAllMyTicketsFetch(`statuses=active,in_progress`)).unwrap(),
            await dispatch(getAllMyTicketsFetch(`statuses=archived,cancelled`)).unwrap()
        ])

        setSortedSeminars({
            upcoming: upcoming?.data || [],
            previous: previous?.data || []
        })
    }

    useEffect(() => {
        getTickets()
    }, [])


    const handleClose = () => {
        dispatch(getAllMyTicketsFetch())
        setTicketsModalOpen(null)
    }

    if (isLoading) return <PageLoader />;

    return (
        <div className="tab-seminars tabs">
            <div className="tabs__header flex items-center border-bottom gap-24 pb-24 pt-24 mb-24">
                <div className="tabs__nav row-group gap-24 flex-auto">
                    <button
                        className={classNames("tab-link", { "active-tab": activeTab === "upcoming" })}
                        onClick={() => {
                            setActiveTab("upcoming");
                        }}
                    >
                        <span className="tab-link__text">
                            upcoming seminars
                        </span>

                        <span className="tab-link__number">
                            {sortedSeminars.upcoming?.length}
                        </span>
                    </button>

                    <button
                        className={classNames("tab-link", { "active-tab": activeTab === "previous" })}
                        onClick={() => {
                            setActiveTab("previous");
                        }}
                    >
                        <span className="tab-link__text">
                            Previous Seminars
                        </span>

                        <span className="tab-link__number">
                            {sortedSeminars.previous?.length}
                        </span>
                    </button>
                </div>
            </div>

            <div>
                {activeTab === "upcoming" && (
                    <div className="tab active-tab">
                        {sortedSeminars.upcoming?.length
                            ? (
                                <div className="row gx-24 gy-70">
                                    {sortedSeminars.upcoming.map((seminar) => (
                                        <div
                                            className={"col-lg-4"}
                                            key={seminar?.id}
                                        >
                                            <CardSeminar
                                                data={seminar}
                                                isBooked={true}
                                                actions={[
                                                    ...(seminar?.tickets?.length ? [{
                                                        label: "Show tickets",
                                                        action: () => setTicketsModalOpen(seminar)
                                                    }] : []),
                                                    {
                                                        label: "Seminar Details",
                                                        action: () => navigate(`/seminars/${seminar?.id}`)
                                                    },
                                                ]}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )
                            : (
                                <h2>
                                    There is no seminars
                                </h2>
                            )
                        }

                    </div>
                )}

                {activeTab === "previous" && (
                    <div className="tab active-tab">
                        {sortedSeminars.previous?.length
                            ? (
                                <div className="row gx-24 gy-70 opacity-card-50">
                                    {sortedSeminars.previous.map((seminar) => (
                                        <div
                                            className={"col-lg-4"}
                                            key={seminar?.id}
                                        >
                                            <CardSeminar
                                                data={seminar}
                                                actions={
                                                    [
                                                        ...(seminar?.tickets?.length ? [{
                                                            label: "Show tickets",
                                                            action: () => setTicketsModalOpen(seminar)
                                                        }] : []),
                                                        {
                                                            label: "Seminar Details",
                                                            action: () => navigate(`/seminars/${seminar?.id}`)
                                                        },
                                                    ]
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                            )
                            : (
                                <h2>
                                    There is no seminars
                                </h2>
                            )}
                    </div>
                )}
            </div>

            {ticketsModalOpen && (
                <TicketsModal
                    data={ticketsModalOpen}
                    open={ticketsModalOpen}
                    handleClose={handleClose}
                />
            )}
        </div>
    );
};

