import React from "react";
import { ReactComponent as BackIcon } from "../../../assets/img/caret-right.svg";
import StarIcon from "../../../assets/img/star.svg";
import StarEmpty from "../../../assets/img/starEmpty.svg";
import BurgerIcon from "../../../assets/img/ico-full.svg";
import { getImageSrc } from "../../../helpers/utils";
import profilePlaceholder from "../../../assets/img/user-profile.svg";
import { clearCurrentChatData, getAllChats } from "../../../redux/slices/chatsSlice";
import { EVENT_STATUS } from "../../../constants/enums";
import { useDispatch, useSelector } from "react-redux";
import { getChatOnlineUsers } from "../../../redux/slices/socketChatSlice";
import { favoriteChatFetch } from "../../../redux/thunks/chatsThunks";
import { useNavigate, useParams } from "react-router-dom";
import { getUser } from "../../../redux/slices/applicationSlice";
import { useWindowSize } from "@uidotdev/usehooks";

export const ChatHeader = ({ toggleSidebarView }) => {
    const { chatId } = useParams();
    const {
        currentChatData,
        isCurrentChatDataLoading,
    } = useSelector((state) => state.chats);
    const { width } = useWindowSize()
    const isMobile = width <= 992
    const isFavorite = !!useSelector(getAllChats)?.find((el) => el?.id + "" === chatId)?.isFavorite;
    const onlineArr = useSelector(getChatOnlineUsers);
    const navigate = useNavigate();
    const user = useSelector(getUser);
    const getRecipient = (chat) => {
        return chat?.users?.find((recipient) => recipient?.id !== user?.id);
    };
    const isOnline = onlineArr.includes(getRecipient(currentChatData)?.id);
    const clickFavorite = () => {
        dispatch(favoriteChatFetch({ chatId: chatId, isFavorite: !isFavorite }));
    };
    const isPrivateLesson = currentChatData?.privateLesson && currentChatData?.eventType === "private-lessons"

    const dispatch = useDispatch();

    return (
        <div className="messages__header">
            <div className="current-chat">
                <BackIcon
                    className="messages__header-back"
                    onClick={() => {
                        dispatch(clearCurrentChatData());
                        navigate("/chat");
                    }}
                />

                <div className="current-chat__image">
                    <img
                        alt={getRecipient(currentChatData)?.fullName}
                        className="ico ico--sm"
                        src={getImageSrc(getRecipient(currentChatData)?.photo, profilePlaceholder, 64)}
                    />
                </div>

                <div className="current-chat__details">
                    <p className="current-chat__name">
                        {getRecipient(currentChatData)?.fullName}
                    </p>

                    <p className="current-chat__status">
                        {isOnline
                            ? "Online"
                            : "Offline"}
                    </p>
                </div>
            </div>
            <div className="row-group gap--xxl">
                <button className="btn btn--sm btn--square">
                    <div className="ico" onClick={clickFavorite}>
                        <img className="ico ico--xxs" src={isFavorite ? StarIcon : StarEmpty} alt="star" />
                    </div>
                </button>
                {isMobile && isPrivateLesson &&
                    currentChatData?.status !== EVENT_STATUS.canceled && !isCurrentChatDataLoading && (
                        <button onClick={toggleSidebarView} className="btn btn--sm btn--square">
                            <span className="ico">
                                <img src={BurgerIcon} alt="burger"
                                />
                            </span>
                        </button>
                    )
                }
            </div>
        </div>
    );
};
