export async function getCoordinates(address) {
    // const address = '1600 Pennsylvania Ave NW, Washington, DC 20500';
    const mapBoxToken = "pk.eyJ1IjoibWF4aW1va29semluIiwiYSI6ImNsdGU5OW1lMzBlOTQyaXF1OHQ3a3FyZzQifQ.iHTE8f0GCRZQyZiAX8rfBw";
    const geocodingUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${mapBoxToken}&limit=1`;

    try {
        const response = await fetch(geocodingUrl);

        if (response.ok) {
            const data = await response.json();
            if (data.features && data.features.length > 0) {
                const coordinates = data.features[0].geometry.coordinates;

                return {
                    coordinates: {
                        lat: coordinates[1],
                        lon: coordinates[0]
                    }
                };
            } else {
                console.log("Not found");
            }
        } else {
            console.error(response.status, response.statusText);
        }
    } catch (error) {
        console.error(error);
    }
}

export async function getAddress(coordinates) {
    const mapBoxToken = "pk.eyJ1IjoibWF4aW1va29semluIiwiYSI6ImNsdGU5OW1lMzBlOTQyaXF1OHQ3a3FyZzQifQ.iHTE8f0GCRZQyZiAX8rfBw";
    const reverseGeocodingUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates.lon},${coordinates.lat}.json?access_token=${mapBoxToken}&limit=1`;

    try {
        const response = await fetch(reverseGeocodingUrl);

        if (response.ok) {
            const data = await response.json();

            if (data.features && data.features.length > 0) {
                const { address, context } = data.features[0];

                const addressLine = address;

                const city = context.find(item => item.id.includes('place'))?.text;
                const stateCode = context.find(item => item.id.includes('region'))?.short_code;
                const country = stateCode?.split('-')?.[0];
                const state = stateCode?.split('-')?.[1] || context.find(item => item.id.includes('country'))?.short_code?.toUpperCase();
                const zip = context.find(item => item.id.includes('postcode'))?.text;
                const latitude = coordinates.lat;
                const longitude = coordinates.lon;

                return {
                    addressLine,
                    country,
                    city,
                    state,
                    zip,
                    latitude,
                    longitude
                };
            } else {
                console.log("Not found");
            }
        } else {
            console.error(response.status, response.statusText);
        }
    } catch (error) {
        console.error(error);
    }
}

export const addClusteredPointsLayer = (map, points) => {
    // Add GeoJSON source with clustering enabled
    map.addSource("points", {
        type: "geojson",
        data: {
            type: "FeatureCollection",
            features: points.map((point) => ({
                type: "Feature",
                geometry: { type: "Point", coordinates: point.coordinates },
                properties: { title: point.title },
            })),
        },
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points
        clusterRadius: 50,  // Cluster radius in pixels
    });

    // Add cluster circles
    map.addLayer({
        id: "clusters",
        type: "circle",
        source: "points",
        filter: ["has", "point_count"],
        paint: {
            "circle-color": [
                "step",
                ["get", "point_count"],
                "#51bbd6",
                100,
                "#f28cb1",
                750,
                "#f1f075",
            ],
            "circle-radius": [
                "step",
                ["get", "point_count"],
                20,
                100,
                30,
                750,
                40,
            ],
        },
    });

    // Add cluster labels
    map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "points",
        filter: ["has", "point_count"],
        layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
        },
    });

    // Add unclustered points
    map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "points",
        filter: ["!", ["has", "point_count"]],
        paint: {
            "circle-color": "#11b4da",
            "circle-radius": 8,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#fff",
        },
    });

    // Click event for clusters
    map.on("click", "clusters", (e) => {
        const features = map.queryRenderedFeatures(e.point, {
            layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        map.getSource("points").getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;
            map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom,
            });
        });
    });

    // Add click event for unclustered points
    map.on("click", "unclustered-point", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const { title } = e.features[0].properties;

        // new mapboxgl.Popup()
        //     .setLngLat(coordinates)
        //     .setHTML(`<strong>${title}</strong>`)
        //     .addTo(map);
    });

    // Cursor styling for clusters
    map.on("mouseenter", "clusters", () => {
        map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", "clusters", () => {
        map.getCanvas().style.cursor = "";
    });
};
