import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../api';
import { convertObjectToFormData } from '../../../helpers/utils';
import { useTabs } from '../../../hooks/useTabs';
import { setToken } from '../../../redux/slices/applicationSlice';
import { getUserFetch } from '../../../redux/thunks/applicationThunks';
import PasswordTab from '../common/PasswordTab';
import { Steps } from '../components/Steps';
import StepsActions from '../components/StepsActions';
import { useDataFromSocial } from '../hooks/useDataFromSocial';
import { academySchema, additionalDetailsSchema, commonDetailsSchema, locationSchema, selectionSchema } from '../validation/academySchema';
import { passwordSchema } from '../validation/passwordSchema';
import AcademySelection from './tabs/AcademySelection';
import AdditionalDetailsTab from './tabs/AdditionalDetailsTab';
import CommonDetailsTab from './tabs/CommonDetailsTab';
import LocationTab from './tabs/LocationTab';


const tabsMap = {
    SELECT: 'select',
    COMMON: 'common',
    ADDITIONAL: 'additional',
    LOCATION: 'location',
    PASSWORD: 'password',
}

const AcademySignUpPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { isLoading: isAcademyLoading } = useSelector((state) => state.academyGeneralInfoApp);
    const [isLoading, setIsLoading] = useState(false);


    const tabs = [
        {
            label: "Step 1",
            id: tabsMap.SELECT,
            schema: selectionSchema,
        },
        {
            label: "Step 2",
            id: tabsMap.COMMON,
            schema: commonDetailsSchema,
        },
        {
            label: "Step 3",
            id: tabsMap.ADDITIONAL,
            schema: additionalDetailsSchema,
        },
        {
            label: "Step 4",
            id: tabsMap.LOCATION,
            schema: locationSchema,
        },
        {
            label: "Step 5",
            id: tabsMap.PASSWORD,
            schema: passwordSchema,
        },
    ];

    const { activeTab, setActiveTab, activeTabIndex, activeTabObject } = useTabs(tabs, 't');
    const currentSchema = activeTabIndex === tabs.length - 1 ?
        academySchema : activeTabObject?.schema;

    const resolver = currentSchema && yupResolver(currentSchema);
    const useFormReturn = useForm({
        resolver,
    })
    const { handleSubmit, watch, setValue, formState: { errors, touchedFields, dirtyFields } } = useFormReturn

    useDataFromSocial(({ email, photo }) => {
        email && setValue('email', email);
        photo && setValue('photo', photo);
    });

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            console.log(errors, 'errors');

            const errorMessages = Object.keys(errors).map(key => errors[key].message)
            errorMessages.map(message => toast.warn(message))
        }
    }, [errors])

    useEffect(() => {
        setActiveTab(tabsMap.SELECT);
    }, [setActiveTab])


    const tabComponents = {
        [tabsMap.SELECT]: (
            <AcademySelection
                useFormReturn={useFormReturn}
            />
        ),
        [tabsMap.COMMON]: (
            <CommonDetailsTab
                useFormReturn={useFormReturn}
            />
        ),
        [tabsMap.ADDITIONAL]: (
            <AdditionalDetailsTab
                useFormReturn={useFormReturn}
            />
        ),
        [tabsMap.LOCATION]: (
            <LocationTab
                useFormReturn={useFormReturn}
            />
        ),
        [tabsMap.PASSWORD]: (
            <PasswordTab
                useFormReturn={useFormReturn}
            />
        ),
    }


    const renderTab = (activeTab) => {
        return tabComponents[activeTab]
    }


    const signUp = async (data) => {
        const formData = convertObjectToFormData(data);
        const response = await api.auth.signupAcademy(formData);
        
        if (response?.success) {
            if (response?.token) {
                localStorage.setItem("authToken", response.token);
                dispatch(setToken(response.token));
                await dispatch(getUserFetch(response.token));
                navigate("/settings/my-profile");
            }
        } else {
            toast.error(response.message || "Something went wrong");
        }
    }



    const onSubmit = async (data) => {
        try {
            console.log("Form data:", data);
            if (activeTabIndex < tabs.length - 1) {
                setActiveTab(tabs[activeTabIndex + 1].id);
            } else {
                setIsLoading(true);
                console.log("Final Submit:", data);
                await signUp(data);
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);

    };

    const handleCancel = () => {
        if (activeTabIndex === 0) {
            navigate("/");
        } else {
            if (activeTabIndex > 0) {
                const previousStep = tabs[activeTabIndex - 1];
                setActiveTab(previousStep.id);
            }
        }
    };

    return (
        <div className="container">
            {activeTabIndex !== 0 && <Steps steps={tabs.filter(item => item.id !== tabsMap.SELECT)} currentStep={activeTab} setCurrentStep={setActiveTab} />}
            <form onSubmit={handleSubmit(onSubmit)} className="form form--md">
                {renderTab(activeTab)}
                <StepsActions
                    canSkip={activeTabIndex === 0}
                    onSkip={() => setActiveTab(tabs[activeTabIndex + 1].id)}
                    canGoBack={activeTabIndex > 0}
                    isFinalStep={activeTabIndex === tabs.length - 1}
                    onBack={handleCancel}
                    loading={isLoading || isAcademyLoading}
                />
            </form>
        </div>
    )
}

export default AcademySignUpPage
