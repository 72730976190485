import { PAYMENT_STATUS, TICKET_TYPE } from "./enums"

export const ticketTypeMap = {
    [TICKET_TYPE.seminarTicket]: "Seminar Ticket",
    [TICKET_TYPE.plancampTicket]: "Camp Ticket",
    [TICKET_TYPE.privateLessons]: "Private Lesson Ticket"
}

export const paymentStatusMap = {
    [PAYMENT_STATUS.pending]: "Pending",
    [PAYMENT_STATUS.failed]: "Failed",
    [PAYMENT_STATUS.canceled]: "Canceled",
    [PAYMENT_STATUS.refused]: "Refused",
    [PAYMENT_STATUS.waiting_payout_method]: "Waiting Payout Method",
    [PAYMENT_STATUS.completed]: "Completed",
    [PAYMENT_STATUS.verified]: "Verified",
    [PAYMENT_STATUS.open]: "Open",
}