import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthModals } from '../components/modals/singUpModals/AuthProvider';
import PageLoader from '../components/ui/PageLoader';
import { getUser, setAuthModalOpen } from '../redux/slices/applicationSlice';

const ProtectedRoute = () => {
    const token = localStorage.getItem('authToken');
    const user = useSelector(getUser)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!user && !token) {
            dispatch(setAuthModalOpen(AuthModals.login));
        }
    }, [dispatch, token, user])

    if (!user && token) {
        return <PageLoader />
    }
    if (!user) {
        return (
            <main className='content'>

            </main>
        );
    }

    return <Outlet />;
};

export default ProtectedRoute