import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageLoader from '../../components/ui/PageLoader';
import { EVENT_STATUS, EVENT_TYPE } from '../../constants/enums';
import { getSeminarsById } from '../../redux/thunks/seminarsThunks';
import BackButton from './components/BackButton';
import BoughtsSetion from './components/BoughtsSetion';
import DetailsSection from './components/DetailsSection';
import OverviewHeader from './components/OverviewHeader';
import PricesSection from './components/PricesSection';
import RequestsSection from './components/RequestsSection';

const SeminarOverviewPage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { seminarDetails, isLoading } = useSelector((state) => state.seminars);

    useEffect(() => {
        dispatch(getSeminarsById(id));
    }, [dispatch, id]);

    if (isLoading) {
        return <PageLoader />
    }

    if (!seminarDetails) {
        return (
            <section className="section-settings profile-page">
                <div className="container container--full">
                    <h2>Seminar not found</h2>
                </div>
            </section>
        )
    }

    return (
        <section className="section-settings profile-page">
            <div className="container container--full">
                <BackButton to="/my-seminars" />
                <div className="settings-row">
                    <div className="settings-col">
                        <div className="settings-body settings-gap">
                            <OverviewHeader data={seminarDetails} eventType={EVENT_TYPE.seminar} />
                            <div className="form form-settings">
                                {seminarDetails.status === EVENT_STATUS.active && <PricesSection data={seminarDetails} />}
                                <div className="form-body">
                                    <div className="form-items-list">
                                        <DetailsSection data={seminarDetails} />
                                        <hr className="hr-horisontal my-42" />
                                        {seminarDetails.status === EVENT_STATUS.pending && (
                                            <RequestsSection event={seminarDetails} eventId={seminarDetails.id} eventType={EVENT_TYPE.seminar} />
                                        )}
                                        {seminarDetails.status !== EVENT_STATUS.pending && (
                                            <BoughtsSetion data={seminarDetails} eventId={seminarDetails.id} eventType={EVENT_TYPE.seminar} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}



export default SeminarOverviewPage