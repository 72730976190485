import { useNavigate } from "react-router-dom";

function ErrorPage({ message = 'Something went wrong', statusCode = 500, cleanup }) {
    const navigate = useNavigate();
    const handleBack = () => {
        cleanup()
        navigate('/')
    }
    return (
        <div className="wrapper">
            <main className="content col-group">
                <div className="container flex-auto flex items-center justify-center">
                    <div className="col-group text-center gap-24">
                        <h1 className="heading">{statusCode}</h1>
                        <p className="color-text">{message}</p>
                        <button className="btn btn--primary btn--lg" onClick={handleBack}>
                            Go Back Home
                        </button>
                    </div>
                </div>
            </main>
        </div>

    );
}


export default ErrorPage;
