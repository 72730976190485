import classNames from "classnames";
import moment from "moment";
import { useMemo } from "react";
import {
    calcTotalHours,
    convertDatesToTimeSlot, getTrainingStyle
} from "../../../../../helpers/utils";
import { Step } from "../../components/Step";

const Body = ({ useStep, data }) => {
    const totalHours = useMemo(() => calcTotalHours(data.dates), [data.dates])
    const totalPrice = data?.priceInHour * totalHours
    return (
        <div
            className={classNames("col-group gap-24", { "pt-24 pb-24": useStep })}
        >
            <div className="form-group input--lg">
                <label className="label row-group justify-between gap--xs">
                    <div className="row-group gap--xs">
                        Location
                    </div>
                </label>

                <div className="card-details-big col-group">
                    <p className="color-text">
                        {data?.seminar?.address || data?.plancamp?.address || data?.address}
                    </p>
                </div>
            </div>

            <div className="form-group input--lg">
                <label className="label row-group justify-between gap--xs">
                    <div className="row-group gap--xs">
                        Private lessons Time
                    </div>
                </label>

                <div className="card-details col-group ">
                    {data?.dates?.length &&
                        convertDatesToTimeSlot(data?.dates).map((item, index) => (
                            <div
                                className="flex justify-between"
                                key={index}
                            >
                                <p className="fw-500">
                                    {moment(item?.date).format("DD MMM, YYYY")}
                                </p>

                                <div className="col-group gap--md">
                                    {item?.timeSlots?.length && item.timeSlots.map(({ startTime, endTime }, index) => (
                                        <p
                                            key={index}
                                            className={"color-text"}
                                        >
                                            {`${moment(startTime).format("hh:mm")} - ${moment(endTime).format("hh:mm")}`}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        ))}
                </div>
            </div>

            <div className="form-group input--lg">
                <label className="label row-group justify-between gap--xs">
                    <div className="row-group gap--xs">
                        Lesson Information
                    </div>
                </label>

                <div className="card-details-big col-group gap-24">
                    <div className="flex justify-between">
                        <p className="fw-500">
                            Gi or NoGi
                        </p>

                        <div className="col-group gap--md">
                            <p className="color-text">
                                {getTrainingStyle(data?.style)?.[0]?.title}
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-between">
                        <p className="fw-500">
                            Attending
                        </p>

                        <div className="col-group gap--md">
                            <p className="color-text">
                                {data?.peopleAttending}
                            </p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="form-group input--lg">
                <label className="label row-group justify-between gap--xs">
                    <div className="row-group gap--xs">
                        Price Information
                    </div>
                </label>

                <div className="card-details-big col-group gap-24">
                    <div className="flex justify-between">
                        <p className="fw-500">
                            Session
                        </p>

                        <div className="col-group gap--md">
                            <p className="color-text">
                                $

                                {data?.priceInHour}
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-between ">
                        <p className="fw-500">
                            Ticket Fee
                        </p>

                        <div className="col-group gap--md">
                            <p className="color-text">
                                $

                                {0}
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-between border-bottom" />

                    <div className="flex justify-between ">
                        <div className="col-group gap--md">
                            <p className="color-text">
                                Total
                            </p>
                        </div>

                        <p className="fw-500">
                            $

                            {totalPrice}
                        </p>
                    </div>
                </div>

            </div>

        </div>
    );
};

export const PLStepOne = ({
    useStep, data
}) => {
    return (
        <>
            {useStep
                ? (
                    <Step label="Lesson Details">
                        <Body
                            data={data}
                            useStep={useStep}
                        />
                    </Step>
                ) : (
                    <Body
                        useStep={useStep}
                        data={data}
                    />
                )}
        </>
    );
};
