import Input from "../../../../components/ui/formElements/Input";
import Textarea from "../../../../components/ui/formElements/Textarea";
import HeadingBlock from "../../components/HeadingBlock";

const AdditionalDetailsTab = ({ useFormReturn }) => {
    const { register, formState: { errors }, control } = useFormReturn;
    return (
        <>
            <HeadingBlock
                title="ACADEMY INFO"
                description="Tell us a bit about your academy"
            />

            <Input
                inputClasses="input--solid"
                label="PERSON IN CHARGE"
                placeholder="e.g. Jane Smith, Wade Warren..."
                wrapperClasses="input--lg"
                {...register("personInCharge")}
                error={errors.personInCharge?.message}
            />
            <Input
                inputClasses="input--solid"
                label="ACADEMY OWNER"
                placeholder="e.g. Jane Smith, Wade Warren..."
                wrapperClasses="input--lg"
                {...register("generalAcademyInfo.academyOwner")}
                error={errors.generalAcademyInfo?.academyOwner?.message}
            />

            <Textarea
                label="Academy Description"
                {...register("about")}
                placeholder="Describe your academy..."
                rows="4"
                textareaClasses="input--solid"
                wrapperClasses="input--lg"
                error={errors.about?.message}
            />
            <Input
                inputClasses="input--solid"
                label="Website (optional)"
                {...register("website")}
                placeholder="https://example.com"
                wrapperClasses="input--lg"
                error={errors.website?.message}
            />

            <Input
                inputClasses="input--solid"
                label="Instagram (optional)"
                {...register("instagram")}
                placeholder="https://instagram.com/example"
                wrapperClasses="input--lg"
                error={errors.instagram?.message}
            />

            <Input
                inputClasses="input--solid"
                label="Facebook (optional)"
                {...register("facebook")}
                placeholder="https://facebook.com/example"
                wrapperClasses="input--lg"
                error={errors.facebook?.message}
            />
        </>
    )
}

export default AdditionalDetailsTab