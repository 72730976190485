import ButtonBack from "../../../components/parts/ButtonBack";

import classNames from 'classnames';
import { ReactComponent as BookingIcon } from "../../../assets/img/booking-icon.svg";
import { ReactComponent as DesignIcon } from "../../../assets/img/design-icon.svg";
import { ReactComponent as InfoIcon } from "../../../assets/img/event-info-icon.svg";
import { ReactComponent as DateIcon } from "../../../assets/img/location-date-icon.svg";
import Button from '../../../components/ui/buttons/Button';
import { EVENT_STEP, STEPS_ORDER } from '../../../constants/enums';

const icons = {
    [EVENT_STEP.date]: <DateIcon />,
    [EVENT_STEP.details]: <InfoIcon />,
    [EVENT_STEP.design]: <DesignIcon />,
    [EVENT_STEP.terms]: <BookingIcon />
};

const PLANNING_STATUS = {
    draft: "draft",
    negotiation: "negotiation",
    active: "active"
}

const TabsNavigation = ({
    planningStatus = PLANNING_STATUS.draft,
    currentStep,
    activeTab,
    setActiveTab,
    tabItems
}) => {

    const currentStepIndex = STEPS_ORDER.indexOf(currentStep);

    return (
        <section className="page-menu-section">
            <div className="container relative">
                <ButtonBack />
                <div className="container overflow-auto page-menu-border">
                    <ul className="page-menu-list">
                        {tabItems.map((tabItem) => (
                            <li key={tabItem.id} className="page-menu-list__item">
                                <Button
                                    className={classNames("page-menu-list__link not-italic", {
                                        active: activeTab === tabItem.id,
                                    })}
                                    onClick={() => setActiveTab(tabItem.id)}
                                    // style={planningStatus !== PLANNING_STATUS.draft && currentStepIndex < STEPS_ORDER.indexOf(tabItem.id) ?
                                    //     {
                                    //         pointerEvents: "none",
                                    //         opacity: 0.5
                                    //     } : {}}
                                >
                                    <span className="ico">
                                        {icons[tabItem.id]}
                                    </span>

                                    <span className="page-menu-list__text">
                                        {tabItem.label}
                                    </span>
                                </Button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default TabsNavigation