import React from 'react'
import { USER_ROLE } from '../../../../constants/enums'
import { getImageSrc } from '../../../../helpers/utils'

const ParticipantsBlock = ({ users, accepts }) => {

    return (
        <div className="row-group gap--sm pb-24">
            <span className="seminar__status">
                Participants:
            </span>

            {!!users?.length && (
                <div className="row-group gap--xs">
                    {users?.map((user) => (
                        <div
                            key={user.id}
                            className={"participant"}
                        >
                            {((user?.role === USER_ROLE.academy && accepts?.isAcademyConfirm) ||
                                (user?.role === USER_ROLE.instructor && accepts?.isInstructorConfirm))
                                && <span className={"sign"} />}
                            <img
                                src={getImageSrc(user.photo, null, 64)}
                                alt={"user"}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default ParticipantsBlock