import { useDispatch } from "react-redux";
import Button from "../../../../../components/ui/buttons/Button";
import DropDown from "../../../../../components/ui/DropDown";
import SelectOne from "../../../../../components/ui/formElements/SelectOne";
import { CardDetails } from "../../components/CardDetails";
import { Step } from "../../components/Step";

import { format, isValid } from "date-fns";
import { useState } from "react";
import { ReactComponent as DoneIcon } from "../../../../../assets/img/done.svg";
import { ReactComponent as AnimIcon } from "../../../../../assets/img/ico-anim.svg";
import { ReactComponent as PencilIcon } from "../../../../../assets/img/ico-pencil.svg";

import { NOT_SPECIFIED, sharedProfits } from "../../../../../constants";
import { EventHelper } from "../../../../../helpers/EventHelper";
import { getValueOrDefault } from "../../../../../helpers/utils";
import { updateCampFetch } from "../../../../../redux/thunks/planCampThuncks";
import { checkIsEdited } from "../../utils";

export const DetailsStep = ({ data, isEditable }) => {
    const dispatch = useDispatch()
    const [isSharedProfitEditing, setIsSharedProfitEditing] = useState(false)
    const sharedProfitOptions = sharedProfits.map(({ key, title }) => ({
        label: title,
        value: key
    }))

    const [sharedProfit, setSharedProfit] = useState(data?.sharedProfit)


    const handleCheck = async () => {
        setIsSharedProfitEditing(true)
        await dispatch(updateCampFetch({
            seminarId: data.id,
            body: { sharedProfit }
        })).unwrap();

        setIsSharedProfitEditing(false)
    };

    return (
        <Step label="Seminar Details">
            {data?.sharedProfit ? (
                <CardDetails
                    isEdited={checkIsEdited([
                        'sharedProfit',
                        'instructorPrice',
                        'minimalPriceTicket',
                        'maxGymCapacity'
                    ])}
                    title="Price Information"
                >
                    <div className="row-group justify-between mb-12 pb-24 border-bottom">
                        <p className="fw-500">
                            Shared Profit
                        </p>

                        <p className="color-text row-group">
                            {!isSharedProfitEditing ?
                                getValueOrDefault(data?.sharedProfit, (v) => v + "%")
                                : <AnimIcon />}
                            {!isSharedProfitEditing && isEditable && (
                                <DropDown
                                    dropDownListClasses="time-input-menu"
                                    labelButton={
                                        <button
                                            className="btn btn--xs btn--square"
                                        >
                                            <PencilIcon />
                                        </button>
                                    }
                                    position="left"
                                >
                                    <div className="dropdown-menu">
                                        <div className="row-group gap--sm">
                                            <SelectOne
                                                onChange={(option) => setSharedProfit(option.value)}
                                                options={sharedProfitOptions}
                                                placeholder="Specific Shared Profit"
                                                value={sharedProfit}
                                                wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                            />

                                            <Button
                                                isLoading={isSharedProfitEditing}
                                                className="done-button drop-close"
                                                onClick={handleCheck}
                                            >
                                                <DoneIcon />
                                            </Button>
                                        </div>
                                    </div>
                                </DropDown>
                            )}

                        </p>
                    </div>

                    <div className="row-group justify-between">
                        <p className="color-text fw-500">
                            Ticket Price
                        </p>

                        <p className="color-text">
                            {getValueOrDefault(EventHelper.totalTicketPrice(data, (v) => "$" + v))}
                        </p>
                    </div>
                    <div className="row-group justify-between">
                        <p className="color-text fw-500">
                            Min Revenue Instructor
                        </p>

                        <p className="color-text">
                            {getValueOrDefault(EventHelper.instructorCut(data), (v) => "$" + v)}
                        </p>
                    </div>
                    <div className="row-group justify-between">
                        <p className="color-text fw-500">
                            Min Revenue Academy
                        </p>

                        <p className="color-text">
                            {getValueOrDefault(EventHelper.academyCut(data), (v) => "$" + v)}
                        </p>
                    </div>

                    <div className="row-group justify-between">
                        <p className="color-text fw-500">
                            Min Attendance
                        </p>

                        <p className="color-text">
                            {getValueOrDefault(data?.minimumAttendance)}
                        </p>
                    </div>

                </CardDetails>

            ) : (
                <CardDetails
                    isEdited={checkIsEdited(['seminarMinPrice',
                        'seminarMaxPrice',
                        'instructorPrice',
                        'minimalPriceTicket',
                        'maxGymCapacity'
                    ])}
                    title="Price Informationa"
                >
                    <div className="row-group justify-between mb-12 pb-24 border-bottom">
                        <p className="fw-500">
                            Price Range
                        </p>

                        <p className="color-text">
                            {EventHelper.priceRange(data) || NOT_SPECIFIED}
                        </p>
                    </div>
                    <div className="row-group justify-between">
                        <p className="color-text fw-500">
                            Instructor Price
                        </p>

                        <p className="color-text">
                            {EventHelper.instructorCut(data) || NOT_SPECIFIED}
                        </p>
                    </div>
                    <div className="row-group justify-between">
                        <p className="color-text fw-500">
                            Ticket Price
                        </p>

                        <p className="color-text">
                            {EventHelper.totalTicketPrice(data) || NOT_SPECIFIED}
                        </p>
                    </div>
                    <div className="row-group justify-between">
                        <p className="color-text fw-500">
                            Academy Potential Revenue
                        </p>

                        <p className="color-text">
                            {EventHelper.academyCut(data) || NOT_SPECIFIED}
                        </p>
                    </div>

                </CardDetails>
            )}
            <CardDetails
                isEdited={checkIsEdited(["teachingStyles"])}
                title="Teaching Styles"
            >
                {EventHelper.teaching(data)}
            </CardDetails>
            <CardDetails
                isEdited={
                    checkIsEdited([
                        "languages",
                        "traningStyle",
                        "targetAudience",
                        "maxGymCapacity",
                        "allowUnder18",
                        "minAttendance",
                        "minAttendanceDeadline",
                    ])
                }
                title="Seminar Information"
            >
                <div className="row-group justify-between">
                    <p className="fw-500">
                        Language
                    </p>

                    <p className="color-text">
                        {EventHelper.languages(data) || NOT_SPECIFIED}
                    </p>
                </div>

                <div className="row-group justify-between">
                    <p className="fw-500">
                        Gi or NoGi
                    </p>

                    <p className="color-text">
                        {EventHelper.style(data).title || NOT_SPECIFIED}
                    </p>
                </div>

                <div className="row-group justify-between">
                    <p className="fw-500">
                        Open to
                    </p>

                    <p className="color-text">
                        {EventHelper.targetAudience(data) || NOT_SPECIFIED}
                    </p>
                </div>

                <div className="row-group justify-between">
                    <p className="fw-500">
                        Max Gym Capacity
                    </p>

                    <p className="color-text">
                        {data?.maxGymCapacity || NOT_SPECIFIED}
                    </p>
                </div>

                <div className="row-group justify-between">
                    <p className="fw-500">
                        Under the Age of 18
                    </p>

                    <p className="color-text">
                        {data?.allowUnder18
                            ? "Allowed"
                            : "Not  Allowed"
                        }
                    </p>
                </div>
                {!data?.sharedProfit && (
                    <div className="row-group justify-between">
                        <p className="fw-500">
                            Minimum Attendance
                        </p>

                        <p className="color-text">
                            {data?.minimumAttendance || NOT_SPECIFIED}
                        </p>
                    </div>
                )}
                <div className="row-group justify-between">
                    <p className="fw-500">
                        Min Attendance Deadline
                    </p>

                    <p className="color-text">
                        {data?.minAttendanceDeadline && isValid(data?.minAttendanceDeadline)
                            ? format(new Date(data.minAttendanceDeadline), "dd MMM, yyyy")
                            : NOT_SPECIFIED
                        }
                    </p>
                </div>
            </CardDetails>
        </Step>
    );
};
