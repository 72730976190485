import classNames from "classnames";
import React, { forwardRef } from "react";
const Input = forwardRef(({
    label, placeholder, afterLabel, defaultValue, type = "text", inputClasses, wrapperClasses, value, error, ...props
}, ref) => {
    return (
        <div className={`form-group ${wrapperClasses}`}>
            {label && (
                <div className="row-group gap--sm">
                    <label className="label">
                        {label}
                    </label>
                    {afterLabel}
                </div>
            )}

            <div className="input-wrapper">
                <input
                    className={classNames("input", {
                        "input--error": error
                    }, inputClasses)}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    ref={ref}
                    type={type}
                    value={value}
                    {...props}
                />
            </div>

            {error && typeof error === "string" && (
                <p className="error-input">
                    {error}
                </p>
            )}
        </div>
    );
});

export default Input;
