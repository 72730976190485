import { Component } from 'react';
import ErrorPage from '../pages/ErrorPage';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            message: ''
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, message: error.message };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ message: error.message });
        console.error('Error caught by ErrorBoundary:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage message={this.state.message || 'Something went wrong!'} cleanup={() => this.setState({ hasError: false })} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

