import { isValid } from "date-fns";
import { array, boolean, number, object, string } from "yup";
import { belts, trainingStyle } from "../../../../constants";
import { EVENT_STATUS } from "../../../../constants/enums";
import { transformToNumber } from "../../../../helpers/utils";


const sharedOrFixed = object().shape({
    instructorPrice: string()
        .transform(transformToNumber)
        .nullable()
        .test(
            'one-or-other',
            'Either instructor price or shared profit must be defined.',
            function (value) {
                return value !== null || this.parent.sharedProfit !== null;
            }
        ),
    sharedProfit: string()
        .transform(transformToNumber)
        .nullable()
        .test(
            'one-or-other',
            'Either shared profit or instructor price must be defined.',
            function (value) {
                return value !== null || this.parent.instructorPrice !== null;
            }
        ),
});

export const activeCampSchema = object().shape({
    isAvailablePrivateLesson: boolean().default(false),
    allowUnder18: boolean().default(false),
    isPrivate: boolean().default(false),
    name: string().required(),
    id: number().defined(),
    continent: string().optional().nullable(),
    country: string().required('Country is required'),
    city: string().optional().nullable(),
    lastEdited: array().of(string()).default([]),
    address: string().optional().nullable(),
    languages: array().of(string()).default([]),
    ...sharedOrFixed.fields,
    seminarMinPrice: string().transform(transformToNumber).optional().nullable(),
    seminarMaxPrice: string().transform(transformToNumber).optional().nullable(),
    targetAudience: string().oneOf(["male", "female", "any"]),
    teachingStyles: array().of(string()).default([]),
    maxGymCapacity: string().transform(transformToNumber).required('Gym capacity is required').min(1, 'Gym capacity must be at more than 0'),
    minimumAttendance: string().transform(transformToNumber).required('Minimum attendance is required'),
    minAttendanceDeadline: string().transform((value) => (isValid(new Date(value)) ? value : null)).nullable().optional(),
    minimalPriceTicket: string().transform(transformToNumber).required('Ticket price is required').min(1, 'Ticket price must be at more than 0'),
    traningStyle: string().oneOf(trainingStyle.map(({ key }) => key)),
    specificBelt: string().oneOf([...belts.map(({ key }) => key), ""]).optional(),
    summary: string(),
    status: string().oneOf(Object.values(EVENT_STATUS)),
    faq: array().default([]),
    coverId: number().nullable(),
    userId: number().defined(),
    academyId: number().nullable(),
    startAt: string().nullable().transform((value) => (isValid(new Date(value)) ? value : null)),
    endAt: string().nullable().transform((value) => (isValid(new Date(value)) ? value : null)),
    schedules: array()
        .of(
            array()
                .of(
                    object().shape({
                        date: string()
                            .transform((value) => (isValid(new Date(value)) ? value : null))
                            .required("The date is required.")
                            .test(
                                "isValidDate",
                                "Invalid date format provided.",
                                (value) => value && isValid(new Date(value))
                            ),
                        start: string()
                            .required('The schedule start time is required.'),
                        // .matches(
                        //     /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
                        //     "Start time must be in the format HH:mm."
                        // ),
                        end: string()
                            .required('The schedule end time is required.'),
                        // .matches(
                        //     /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
                        //     "End time must be in the format HH:mm."
                        // )
                        // .test(
                        //     "isAfterStartTime",
                        //     "End time must be after the start time.",
                        //     function (end) {
                        //         const { start } = this.parent;
                        //         return end && start ? new Date(`1970-01-01T${end}`) > new Date(`1970-01-01T${start}`) : true;
                        //     }
                        // ),
                        name: string().required("The schedule name is required."),
                    })
                )
                .required("Each schedule entry must have a valid date, start time, end time, and name."),
        )
        .min(3, "At least 3 schedules are required.")
        .default([]),

    privateSessionDates: array().default([]),
    photos: array().default([]),
});
