
import { ReactComponent as UploadFileIcon } from '../../assets/img/upload-file-icon.svg'

const UploadPhotoAndCover = ({ cover, photo, onCoverChange, onPhotoChange, error, }) => {
    return (
        <div>
            <div className="cover-block">
                <div className="cover-load">
                    <img
                        src={cover}
                        alt='user-cover'
                    />
                    <label className="photo-load-control">
                        <>
                            <input
                                accept="image/*"
                                hidden={true}
                                onChange={(e) => onCoverChange(e.target.files[0])}
                                type="file"
                            />

                            <div className="photo-load-btn btn">
                                <span className="ico">
                                    <UploadFileIcon />
                                </span>
                            </div>
                        </>
                    </label>
                </div>

                <div className="photo-load">
                    <img
                        src={photo}
                        alt='user-photo'
                    />
                    <label className="photo-load-control">


                        <input
                            accept="image/*"
                            hidden={true}
                            onChange={(e) => onPhotoChange(e.target.files[0])}
                            type="file"
                        />

                        <div className="photo-load-btn btn">
                            <span className="ico">
                                <UploadFileIcon />
                            </span>
                        </div>

                    </label>
                </div>
            </div>

            {(error && typeof error === 'string') && <span className="error-input">{error}</span>}
        </div>
    )
}

export default UploadPhotoAndCover