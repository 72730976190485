import classNames from 'classnames'
import React from 'react'
import Button from '../../../components/ui/buttons/Button'
import { EVENT_TYPE } from '../../../constants/enums'
import { useTabs } from '../../../hooks/useTabs'

const EventTypeSwitcher = () => {
    const tabs = [
        {
            label: "Seminars",
            id: EVENT_TYPE.seminar,
        },
        {
            label: "Camps",
            id: EVENT_TYPE.camp,
        },
    ]
    const { activeTab, setActiveTab } = useTabs(tabs, "eventType")

    return (
        <div className="flex items-center gap-24 mb-24 flex-auto flex-wrap select-checkers-wrapper">
            <div className="select-checkers">
                {tabs.map((item, index) => (
                    <Button
                        onClick={() => setActiveTab(item.id)}
                        key={index}
                        className={
                            classNames('btn btn--md radius',
                                { 'btn--default': item.id === activeTab })
                        }
                    >
                        <span>{item.label}</span>
                    </Button>
                ))}

            </div>
        </div>
    )
}

export default EventTypeSwitcher