
import classNames from "classnames";
import Fancybox from "../../../components/ui/Fancybox";
import { getImageSrc } from "../../../helpers/utils";

const GalleryImages = function ({ gallery = [], coverId, wrapperClasses }) {
    const getSortedGallery = () => {
        if (coverId && gallery.length) {
            const copy = [...gallery];
            const coverImage = copy.find((image) => image.id === coverId);
            const index = copy.findIndex((image) => image.id === coverId);

            copy.splice(index, 1);
            copy.unshift(coverImage);

            return copy;
        }

        return gallery;
    };

    const sorted = getSortedGallery();

    return (
        <div className="relative w-full">
            <Fancybox wrapperClasses={classNames("grid-imgs", wrapperClasses)}>
                {sorted.map((item, index) => (
                    <a
                        data-fancybox="gallery"
                        className={classNames({ "hidden": index > 4 })}
                        href={getImageSrc(item?.url || item?.file)}
                        key={item?.id}
                    >
                        <img
                            src={getImageSrc(item?.url || item?.file)}
                            alt={item?.url || item?.file?.name}
                        />
                    </a>
                ))}

                {sorted.length > 5 && (
                    <div className="grid-imgs__show-all">
                        <button
                            className="btn btn--default btn--sm radius"
                            data-fancybox-index="0"
                            data-fancybox-trigger="gallery"
                            type="button"
                        >
                            Show All
                        </button>
                    </div>
                )}
            </Fancybox>
        </div>
    );
};

export default GalleryImages;
