import { Country } from "country-state-city";
import { Controller } from "react-hook-form";
import SelectOne from "../../../../components/ui/formElements/SelectOne";
import Textarea from "../../../../components/ui/formElements/Textarea";
import { belts, trainingStyle } from "../../../../constants";
import HeadingBlock from "../../components/HeadingBlock";

const PersonalDetailsTab = ({ useFormReturn }) => {
    const { register, formState: { errors }, control } = useFormReturn;
    const beltsOptions = belts.map((belt) => ({ label: belt.title, value: belt.key }));
    const trainingStyleOptions = trainingStyle.map((style) => ({ label: style.title, value: style.key }));
    return (
        <>
            <HeadingBlock
                title="Tell us a bit more about yourself"
                description="Please fill out all the fields below"
            />
            <Controller
                name="belt"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <SelectOne
                        error={errors.belt?.message}
                        label="Belt"
                        onChange={(option) => onChange(option.value)}
                        options={beltsOptions}
                        placeholder="Choose your belt"
                        value={value}
                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                    />
                )}
            />
            <Controller
                name="traningStyle"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <SelectOne
                        error={errors.traningStyle?.message}
                        label="Training Style"
                        onChange={(option) => onChange(option.value)}
                        options={trainingStyleOptions}
                        placeholder="Choose your training style"
                        value={value}
                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                    />
                )}
            />
            <Controller
                name="gender"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <SelectOne
                        error={errors.gender?.message}
                        label="Gender"
                        onChange={(option) => onChange(option.value)}
                        options={[
                            { label: 'Male', value: 'Male' },
                            { label: 'Female', value: 'Female' }
                        ]}
                        placeholder="Choose your gender"
                        value={value}
                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                    />
                )}
            />
            <Controller
                name="country"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <SelectOne
                        error={errors.country?.message}
                        label="Country"
                        onChange={(option) => onChange(option.value)}
                        options={Country.getAllCountries().map(country => ({
                            label: country.name,
                            value: country.name
                        }))}
                        placeholder="Choose your country"
                        value={value}
                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                    />
                )}
            />
            <Textarea
                style={{ minHeight: 140 }}
                textareaClasses="input--solid"
                wrapperClasses="input--lg"
                label="Type in your description (optional)"
                placeholder="Enter your description..."
                error={errors.bio?.message}
                {...register('bio')}
            />
        </>
    )
}

export default PersonalDetailsTab