import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../api';
import PageLoader from '../../components/ui/PageLoader';
import { USER_ROLE } from '../../constants/enums';
import { useTabs } from '../../hooks/useTabs';
import { getUser } from '../../redux/slices/applicationSlice';
import InvitationsTab from './tabs/InvitationsTab';
import LessonsTab from './tabs/LessonsTab';
import RequestsTab from './tabs/RequestsTab';

const tabsMap = {
    REQUESTS: 'requests',
    INVITATIONS: 'invitations',
    PRIVATE: 'private'
}

const RequestsPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const user = useSelector(getUser);
    const userRole = user?.role;
    const [counts, setCounts] = useState({
        "upcomingSeminarsCount": 0,
        "upcomingPlancampsCount": 0,
        "plannedSeminars": 0,
        "plannedPlancamps": 0,
        "inProgressSeminars": 0,
        "inProgressPlancamps": 0,
        "previousSeminars": 0,
        "previousPlancamps": 0,
        "newRequestsSeminarCount": 0,
        "newRequestsPlancampCount": 0,
        "seminarRequestsCount": 0,
        "plancampRequestsCount": 0,
        "countMyOwnEvents": 0,
        countMySeminarEvents: 0,
        countMyPlancampEvents: 0,
        countPrivateLessons: 0
    });

    const getCounts = async () => {
        setIsLoading(true)
        const res = await api.requests.getCounts()
        if (res?.success) {
            setCounts(res.data)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getCounts()
    }, [])

    const newRequests = counts.newRequestsPlancampCount + counts.newRequestsSeminarCount;


    const tabs = useMemo(() => [
        {
            label: "Requests",
            id: tabsMap.REQUESTS,
            count: counts.seminarRequestsCount + counts.plancampRequestsCount + counts.countMySeminarEvents + counts.countMyPlancampEvents +
                counts.academyHostRequestPlancampCount + counts.academyHostRequestSeminarCount,
            roles: Object.values(USER_ROLE)
        },
        {
            label: "Invitations",
            id: tabsMap.INVITATIONS,
            count: counts.countMySeminarEvents + counts.countMyPlancampEvents,
            roles: Object.values(USER_ROLE)
        },
        {
            label: "Private Lesson Requests",
            id: tabsMap.PRIVATE,
            count: counts.countPrivateLessons,
            roles: USER_ROLE.instructor

        },
    ], [counts])

    const { activeTab, setActiveTab } = useTabs(tabs, "tab")


    const handleChangeTab = (tab) => {
        setActiveTab(tab);
    };

    const tabComponents = {
        [tabsMap.REQUESTS]: <RequestsTab />,
        [tabsMap.INVITATIONS]: <InvitationsTab />,
        [tabsMap.PRIVATE]: <LessonsTab />,
    }

    const renderTab = (activeTab) => {
        return tabComponents[activeTab]
    }

    if (isLoading) {
        return <PageLoader />
    }

    if (!user) {
        return null
    }

    return (
        <section>
            <div className="container">
                <div className="my-seminars-page__header">
                    <div className="heading relative">
                        <h3 className="heading">Requests and Invitations</h3>
                        {!!newRequests && (
                            <div className="notification-count">{newRequests}</div>
                        )}
                    </div>
                </div>
                <div className="tab-seminars tabs">
                    <div className="tabs__header flex items-center border-bottom gap-24 pb-24 pt-24 mb-24">
                        <div className="tabs__nav row-group gap-24 flex-auto">
                            {tabs.filter(({ roles }) => roles?.includes(userRole)).map(tab => (
                                <button
                                    key={tab.id}
                                    className={classNames("tab-link", { "active-tab": activeTab === tab.id })}
                                    onClick={() => handleChangeTab(tab.id)}
                                >
                                    <span className="tab-link__text">
                                        {tab.label}
                                    </span>

                                    <span className="tab-link__number">
                                        {tab.count}
                                    </span>
                                </button>
                            ))}
                        </div>
                    </div>
                    <div>
                        {renderTab(activeTab)}
                    </div>
                </div>
            </div>
        </section>
    )
}


export default RequestsPage


