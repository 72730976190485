import { Controller } from 'react-hook-form';
import CheckboxSwitch from '../../../../components/ui/formElements/CheckboxSwitch';
import Input from '../../../../components/ui/formElements/Input';
import Textarea from '../../../../components/ui/formElements/Textarea';
import UploadVideos from '../../../../components/ui/formElements/UploadVideos';
import { EVENT_TYPE } from '../../../../constants/enums';
import HotelInfoSection from '../../components/HotelInfoSection';
import IncludedSection from '../../components/IncludedSection';
import PopularQuestions from '../../components/PopularQuestions';
import ScheduleDates from '../../components/ScheduleDates';
import SeminarPhotosUpload from '../../components/SeminarPhotosUpload';
import { PLANNING_STATUS } from '../../constants/emuns';

const CampDesignTab = ({
    planningStatus = PLANNING_STATUS.draft,
    useFormReturn,
    role,
    generalInfo,
    setPreview,
}) => {
    const { register, setValue, watch, resetField, getValues, formState: { errors, defaultValues }, control } = useFormReturn

    const startAt = watch("startAt");
    const endAt = watch("endAt");


    const handleDeletePhoto = (id, cb) => {
        const deletePhotos = getValues("delete_photos");
        setValue("delete_photos", deletePhotos ? [...deletePhotos, id] : [id]);
        cb()
    };

    const handleChangeCoverImage = (file) => {
        setPreview(prev => ({
            ...prev,
            cover: file
        }));
        if (file?.id) {
            setValue("coverImageId", file?.id, { shouldDirty: true });
            return;
        }
        const coverFilename = file?.name || file?.originalname;
        setValue("coverImageName", coverFilename);
    };


    return (
        <>
            <Controller
                name="photos"
                control={control}
                render={({ field: { onChange, value, } }) => (
                    <SeminarPhotosUpload
                        error={errors.photos?.message}
                        onDelete={(id) => handleDeletePhoto(id, () => onChange(photos))}
                        setCoverImage={(file) => handleChangeCoverImage(file)}
                        setImages={onChange}
                        images={value}
                        eventName={"Camp"}
                    />
                )}
            />

            <hr className="hr mb-42" />

            <h6 className="heading mb-32">
                Camp Info
            </h6>

            <div className="row gx-16 py-24 mb-42">
                <div className="col-12">
                    <Input
                        inputClasses="input--solid"
                        wrapperClasses="input--lg"
                        error={errors.name?.message}
                        label="Camp Name"
                        {...register("name")}
                        placeholder="Add a name here..."
                    />
                </div>

                <div className="col-12">
                    <Textarea
                        wrapperClasses="input--lg"
                        textareaClasses="input--solid"
                        error={errors.summary?.message}
                        label="Summary"
                        {...register("summary")}
                        placeholder="Please write summary here..."
                        style={{ minHeight: 140 }}
                    />
                </div>

                {planningStatus !== PLANNING_STATUS.draft && (
                    <>
                        <hr className="hr" />
                        <div className="col-12">
                            <Controller
                                name="videos"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <UploadVideos
                                        error={errors.videos?.message}
                                        setVideos={onChange}
                                        videos={value}
                                    />
                                )}
                            />
                        </div>

                        <div className="col-12">
                            <Controller
                                name="hotelLink"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <HotelInfoSection
                                        data={value}
                                        errors={errors.hotelLink?.message}
                                        handleChange={onChange}
                                    />
                                )}
                            />
                        </div>

                        <div className="col-12">
                            <Controller
                                name="what_included"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <IncludedSection
                                        data={value}
                                        error={errors?.what_included}
                                        handleChange={onChange}
                                    />
                                )}
                            />
                        </div>

                        <div className="col-12">
                            <div className="border-bottom mb-42 pb-42">
                                <label className="label mb-12">
                                    Camp Schedule
                                </label>
                                <Controller
                                    name="schedule"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <ScheduleDates
                                            data={value}
                                            startAt={startAt}
                                            endAt={endAt}
                                            errors={errors.schedule?.message}
                                            handleChange={onChange}
                                            handleInit={onChange}
                                            isErrorsShown={!!errors.schedule}
                                        />
                                    )}
                                />
                            </div>

                            <Controller
                                name="faq"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <PopularQuestions
                                        data={value}
                                        errors={errors.faq}
                                        handleChange={onChange}
                                        isErrorsShown={errors.faq}
                                        eventType={EVENT_TYPE.camp}
                                    />
                                )}
                            />

                        </div>

                    </>
                )}

                <Controller
                    name="isPrivate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <CheckboxSwitch
                            checked={value}
                            onChange={onChange}
                            text="Set this camp as private."
                        />
                    )}
                />
            </div>
        </>
    )
}


export default CampDesignTab