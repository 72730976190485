import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as AjustIcon } from "../../../../assets/img/icon-ajust.svg";
import Button from '../../../../components/ui/buttons/Button';
import { EVENT_STATUS, EVENT_STEP, USER_ROLE } from '../../../../constants/enums';
import { getImageSrc } from '../../../../helpers/utils';
import SemianrActionsDropdown from '../../../events/parts/SemianrActionsDropdown';
import TermsStep from '../common/TermsStep';
import EventPreview from '../components/EventPreview';
import ParticipantsBlock from '../components/ParticipantsBlock';
import StatusBlock from '../components/StatusBlock';
import { activeSeminarSchema } from '../validation/activeSeminarSchema';
import { DesignStep } from './steps/DesignStep';
import { DetailsStep } from './steps/DetailsStep';
import { LocationStep } from './steps/LocationStep';


const SeminarDiscussionPanel = ({ data }) => {
    const [isAccepted, setIsAccepted] = useState(false)
    const seminar = data.seminar
    const isNegotiation = seminar?.status === EVENT_STATUS.pending

    const handleAccept = async () => {
        try {
            await activeSeminarSchema.validate(seminar);
            setIsAccepted(true);
        } catch (error) {
            console.error("Validation failed:", error);
            toast.warn(error.message);
        }
    }
    return (
        <aside className="chat-sidebar">
            <div className="chat-sidebar__header">
                <h6 className="heading">
                    Seminar Information
                </h6>
                <SemianrActionsDropdown event={seminar} showCompletionConfirmationAction />
            </div>
            <div className="chat-sidebar__body">
                <EventPreview
                    link={seminar?.status === EVENT_STATUS.active
                        ? `/seminars/${seminar?.id}`
                        : `/${seminar?.user?.role === USER_ROLE.academy ? 'planed-by-academy' : 'planed-by-insctructor'}/${seminar?.id}`}
                    name={seminar?.seminarName}
                    cover={getImageSrc(seminar?.photos?.find((photo) => photo.id === seminar.coverId)?.url, null, 256)}
                />
                <StatusBlock status={seminar?.status} />
                <ParticipantsBlock users={data?.users} accepts={data?.academyAccept} />

                <LocationStep data={seminar} isEditable={isNegotiation} />
                <DetailsStep data={seminar} isEditable={isNegotiation} />
                <DesignStep data={seminar} />

                <TermsStep
                    data={data?.academyAccept?.terms}
                    link={`/edit-seminar/${data?.id}/${seminar?.id}/${data?.academyAccept?.id}?edit=${EVENT_STEP.terms}`}
                    isEditable={isNegotiation}
                />
                {isNegotiation && (
                    <div className="chat-sidebar__footer gap--xs">
                        {!isAccepted && (
                            <div className="row-group gap--xs">
                                <Link
                                    className="btn btn--default btn--sm text-14 w-full"
                                    to={`/edit-seminar/${data.id}/${data?.seminar?.id}?edit=${EVENT_STEP.date}`}
                                >
                                    <span className="ico">
                                        <AjustIcon />
                                    </span>

                                    <span className="info">
                                        Adjust
                                    </span>
                                </Link>
                                <Button
                                    className="btn btn--primary btn--sm text-14 w-full"
                                    onClick={handleAccept}
                                >
                                    <span className="info">
                                        Accept
                                    </span>
                                </Button>
                            </div>
                        )}
                        {isAccepted && (
                            <div className="row-group gap--xs">
                                <Link
                                    className="btn btn--primary btn--sm text-14 w-full"
                                    to={`/edit-seminar/${data?.id}/${seminar?.id}/${data?.academyAccept?.id}/summary`}
                                >
                                    <span className="ico">
                                        <AjustIcon />
                                    </span>

                                    <span className="info">
                                        Proceed to Summary
                                    </span>
                                </Link>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </aside>
    )
}

export default SeminarDiscussionPanel