
import {
    useCallback, useEffect, useState
} from "react";
import { createPortal } from "react-dom";
import { ReactComponent as GridsIcon } from "../../../assets/img/ico-grids.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/ico-trash.svg";
import { ReactComponent as AddIcon } from "../../../assets/img/ico-circle-add.svg";
import SelectTemplateModal from "../../../components/modals/SelectTemplateModal";
import { getImageSrc } from "../../../helpers/utils";
import TemplateEditor from "../../editor/TemplateEditor";

const SeminarPhotosUpload = ({
    images = [],
    setImages,
    setCoverImage,
    coverImage,
    eventName = "Seminar",
    error,
    onDelete,
}) => {
    const [isEditorOpen, setIsEditorOpen] = useState(false)
    const [coverImageIndex, setCoverImageIndex] = useState(null);
    const [selectTemplateModalOpen, setSelectTemplateModalOpen] = useState(false);

    const handleUpload = (e) => {
        const files = [...e.target.files];

        if (!files?.length) return;

        const updatedImages = [...images, ...files];

        setImages(updatedImages);
        setCoverImage(files[0]);
        setCoverImageIndex(images.length);
    };

    const handleRemove = useCallback((e, index) => {
        e.stopPropagation();
        const updatedImages = [...images];

        if (onDelete && updatedImages[index].id) {
            onDelete(updatedImages[index].id);
        }

        updatedImages.splice(index, 1);
        setImages(updatedImages);

        if (index === coverImageIndex) {
            setCoverImageIndex(null);
            setCoverImage(null);
        }


    }, [images, onDelete, setImages, coverImageIndex, setCoverImage]);

    const handleSelect = (index) => {
        setCoverImageIndex(index);
        setCoverImage(images[index]);
    };

    useEffect(() => {
        const index = images.indexOf(coverImage);
        if (index > -1) {
            setCoverImageIndex(index)
        }
    }, [coverImage, images])


    return (
        <>
            <div className="flex items-center flex-wrap justify-between">
                <h6 className="heading mb-32">{eventName} Photo(S)</h6>
                <button
                    className="btn mb-32 gap--md color-secondary"
                    type="button"
                    onClick={() => setSelectTemplateModalOpen(true)}
                >
                    <span className="ico">
                        <GridsIcon />
                    </span>
                    <span>Cover Templates</span>
                </button>
            </div>
            <div className="row g-8 mb-42">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className="col-md-3 col-sm-4 col-6"
                    >
                        <div
                            className="seminar-gallery-item"
                            onClick={() => handleSelect(index)}
                            style={{
                                background:
                                    `linear-gradient(0deg, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0) 100%),
                                    url(${image?.url
                                        ? getImageSrc(image.url)
                                        : getImageSrc(image)}) center/cover no-repeat`,
                                cursor: "pointer"
                            }}
                        >
                            <div className="seminar-gallery-item__actions">
                                <button
                                    onClick={(e) => handleRemove(e, index)}
                                    type="button"
                                    className="btn btn--secondary-2 btn--square btn--xxs rounded-full"
                                >
                                    <span className="ico">
                                        <DeleteIcon />
                                    </span>
                                </button>
                            </div>

                            <div className="seminar-gallery-item__title">
                                {coverImageIndex === index
                                    ? (
                                        <span className="color-primary">
                                            Seminar Cover
                                        </span>
                                    )
                                    : "Set as Cover"}
                            </div>
                        </div>
                    </div>
                ))}

                <div className="col-lg-3">
                    <label
                        className={`btn add-photo-btn ${error
                            ? "input--error"
                            : ""}`}
                        style={{ minHeight: 120 }}
                    >
                        <input
                            accept={"image/*"}
                            className="hidden"
                            hidden
                            multiple
                            onChange={handleUpload}
                            type="file"
                        />

                        <span className="ico ico-24">
                            <AddIcon />
                        </span>
                        <span>
                            Add Photo
                        </span>
                    </label>
                </div>
                {error && typeof error === "string" && (
                    <p className="error-input">
                        {error}
                    </p>
                )}
            </div>

            {selectTemplateModalOpen && (
                <SelectTemplateModal
                    open={selectTemplateModalOpen}
                    onSubmit={(file) => {
                        setIsEditorOpen(true)
                        setSelectTemplateModalOpen(false)
                    }}
                    onClose={() => {
                        setSelectTemplateModalOpen(false)
                    }}
                />
            )}
            {isEditorOpen && (
                <Portal>
                    <TemplateEditor
                        onSubmit={(file) => {
                            setImages([...images, file]);
                            setCoverImageIndex(images.length);
                            setCoverImage(file);
                            setIsEditorOpen(false)
                        }}
                        onClose={() => setIsEditorOpen(false)}
                    />
                </Portal>
            )}
        </>
    );
};

function Portal({ children }) {
    const portalRoot = document.getElementById('portal-root') || createPortalRoot();
    return createPortal(
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1000,
            background: 'white'
        }}>
            {children}
        </div>,
        portalRoot
    );
};

function createPortalRoot() {
    const div = document.createElement('div');
    div.id = 'portal-root';
    document.body.appendChild(div);
    return div;
};

export default SeminarPhotosUpload;
