import classNames from 'classnames'
import React, { useState } from 'react'
import ModalTemplate from './ModalTemplate'
import { ReactComponent as DeleteIcon } from "../../assets/img/ico-trash.svg";
import { getImageSrc } from '../../helpers/utils';
import Textarea from '../ui/formElements/Textarea';

const VerifyPassportModal = ({ onClose, open, onSubmit }) => {
    const [files, setFiles] = useState([]);
    const [message, setMessage] = useState('');
    const addFiles = (files) => {
        setFiles(prev => ([...prev, ...files]));
    }
    const handleFileChange = (e) => {
        addFiles(e.target.files)
    };

    const handleDrop = (e) => {
        e.preventDefault();
        addFiles(e.dataTransfer.files)
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleRemove = (e, index) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (files?.length) {
            onSubmit && onSubmit({
                files,
                message,
            });
        }
    }
    return (
        <ModalTemplate
            onClick={onClose}
            open={open}
            modalSize='modal__dialog--lg'
        >
            <div className="modal__body">
                <form
                    className="form form--lg"
                    method="post"
                >
                    <div className="form-header">
                        <div className="heading-block text-center">
                            <h3 className="heading">
                                Add Additional Proof of Ownership
                            </h3>

                            <p className="text--md desk-heading">
                                Please upload a documents that proves your ownership
                            </p>
                        </div>
                    </div>

                    <div className="form-body">
                        <label
                            className="drop-area"
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                        >
                            <input
                                type="file"
                                className='hidden'
                                onChange={handleFileChange}
                                multiple
                            />
                            <p>Drag & Drop here or click to select a file</p>
                        </label>
                        <div className="row g-8 mb-42">
                            {files.map((image, index) => (
                                <div
                                    key={index}
                                    className="col-md-4 col-12"
                                >
                                    <div
                                        className="seminar-gallery-item"
                                        style={{
                                            position: "relative",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {image?.type?.startsWith('image') ? (
                                            <img
                                                className='w-full h-full' style={{ position: 'absolute', inset: 0 }}
                                                src={getImageSrc(image)}
                                                alt={'document'}
                                            />
                                        ) : (
                                            <div
                                                className='col-group items-center justify-center'
                                                style={{ position: 'absolute', inset: 0 }}
                                            >
                                                <span className='ico ico--lg color-text'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                        <g opacity='0.4'>
                                                            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z" fill='currentColor' />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </div>
                                        )}
                                        <div className="seminar-gallery-item__actions">
                                            <button
                                                onClick={(e) => handleRemove(e, index)}
                                                type="button"
                                                className="btn btn--secondary-2 btn--square btn--xxs rounded-full"
                                            >
                                                <span className="ico">
                                                    <DeleteIcon />
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <p className="color-text text--xs text-center mt-1">{image?.name}</p>
                                </div>
                            ))}
                        </div>
                        <div className="form-item">
                            <Textarea
                                style={{ minHeight: 140 }}
                                textareaClasses="input--solid"
                                wrapperClasses="input--lg"
                                label='Additional Information'
                                rows="4"
                                placeholder="Enter any additional information here"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            ></Textarea>
                        </div>

                        <div className='row-group justify-between gap--sm'>
                            <button
                                className='btn btn--default btn--md gap-10'
                                onClick={onClose}
                                type="button"
                            >
                                <span className="info">
                                    Cancel
                                </span>
                            </button>
                            <button
                                className={classNames('btn btn--primary btn--md gap-10', { disabled: !files?.length })}
                                onClick={handleSubmit}
                                type="button"
                            >
                                <span className="info">
                                    Submit
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalTemplate>
    )
}

export default VerifyPassportModal


