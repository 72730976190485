import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import api from "../../api";
import { PaginationTemplate } from "../../components/parts/PaginationTemplate";
import Button from "../../components/ui/buttons/Button";
import Loader from "../../components/ui/Loader";
import PageLoader from "../../components/ui/PageLoader";
import { TICKET_TYPE, PAYMENT_STATUS } from "../../constants/enums";
import { paymentStatusMap, ticketTypeMap } from "../../constants/mappings";
import { addPayoutMethodsFetch, createStripeAccountConnectionLinkFetch, generateStripeAccountLinkFetch, getMyPayoutMethodsFetch } from "../../redux/thunks/payoutMethodsThunks";



const PayuotMethods = () => {
    const dispatch = useDispatch()
    const { payoutMethods, isLoading } = useSelector(state => state.payoutMethods)

    useEffect(() => {
        dispatch(getMyPayoutMethodsFetch())
    }, [])

    if (isLoading) {
        return <PageLoader />
    }

    const stripeData = payoutMethods?.find(item => item.method === 'stripe')

    return (
        <div className="settings-body settings-gap">
            <div className="sidebar-header heading-row">
                <h3 className="heading">
                    Payout Methods
                </h3>
            </div>

            <form
                className="form form-settings"
                method="post"
            >
                <div className="form-body">
                    <div className="form-items-list">
                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <ul className="settings-list">
                                        <li className="settings-list--item">
                                            <PayoutMethodCard method="stripe" data={stripeData} />
                                        </li>

                                        <li
                                            className="settings-list--item"
                                            style={{
                                                opacity: 0.4,
                                                pointerEvents: "none"
                                            }}
                                        >
                                            <PayoutMethodCard method="paypal" />
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <PayoutHistory />
        </div>
    );
};

function PayoutMethodCard({ method, data }) {
    const methods = {
        stripe: {
            logo: require("../../assets/img/ico-payout-stripe.svg").default,
            name: "Stripe"
        },
        paypal: {
            logo: require("../../assets/img/ico-payout-paypal.svg").default,
            name: "PayPAl"
        }
    };

    const stepsMap = {
        continue_write_details: "continue",
        account_completed: "to account"
    }
    const step = data?.step
    const error = data?.stripe?.requirements?.errors?.[0]?.reason
    const isPending = step === "continue_write_details"

    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const handleSetup = async () => {
        setIsLoading(true)
        let res;

        if (data && data.step === "continue_write_details") {
            res = await dispatch(createStripeAccountConnectionLinkFetch(data.id)).unwrap()
        } else if (data) {
            res = await dispatch(generateStripeAccountLinkFetch(data.id)).unwrap()
        } else {
            res = await dispatch(addPayoutMethodsFetch({
                method
            })).unwrap()
        }

        if (res?.success && res?.data) {
            window.open(res.data.url)
            setTimeout(() => setIsLoading(false), 200)
        } else {
            setIsLoading(false)
        }
    }

    return (
        <div className="card-bordered card-row payout-method-card">
            <div className="card-row--item justify-between flex-auto overflow--x-hidden">
                <div className="card-row-group overflow--x-hidden">
                    <div className="flex flex-col justify-center">
                        <div className="payout-logo">
                            <img
                                alt={methods[method.name]}
                                src={methods[method].logo}
                            />
                        </div>
                    </div>

                    <div className="heading-block heading-nowrap flex-auto justify-center">
                        <h6 className="heading">
                            {methods[method].name}
                        </h6>

                        {error
                            ? (
                                <p className="desk-heading --sm-heading" style={{ whiteSpace: "normal" }}>{error}</p>
                            )
                            : (isPending || !data)
                                ? (
                                    <p className="desk-heading --sm-heading" style={{ whiteSpace: "normal" }}>
                                        Please, connect your
                                        {" "}

                                        {method}

                                        {" "}

                                        account to accept payments.
                                    </p>
                                ) : null
                        }
                    </div>
                </div>
            </div>

            <div className="flex-group justify-end flex-group--xs flex-nowrap">
                <Button
                    isLoading={isLoading}
                    onClick={handleSetup}
                    className="btn btn--primary btn--sm radius"
                >
                    <span>
                        {data ? stepsMap[step] : "Setup"}
                    </span>
                </Button>
            </div>
        </div >
    );
};

function PayoutHistory() {
    const [history, setHistory] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [pagination, setPagination] = useState(null)
    const [searchParams] = useSearchParams()
    const page = searchParams.get("page")
    const getPayoutHistory = async ({ page }) => {
        setIsLoading(true)
        const res = await api.payouts.getMyPayouts({ page })
        if (res?.success && res?.data) {
            setHistory(res.data)
            setPagination(res?.pagination)
        }
        setIsLoading(false)
    }
    useEffect(() => {
        getPayoutHistory({ page })
    }, [page])

    return (
        <div className="form-body">
            <div className="form-items-list">
                <div className="form-item">
                    <div className="row gutters-form">
                        <div className="col-12">
                            <div className="sidebar-header heading-row">
                                <h3 className="heading">
                                    Payment History
                                </h3>
                            </div>
                        </div>
                        <div className="col-12">
                            {isLoading ? (
                                <div className="row-group justify-center items-center" style={{minHeight: "300px"}}>
                                    <Loader />
                                </div>
                            )
                                : history.length ? (
                                    <div className="table-wrapper mb-16">
                                        <table className="table">
                                            <tbody>
                                                {history.map((item, index) => (
                                                    <tr key={index}>
                                                        <td style={{ width: "0px" }}>
                                                            <p className="font-500">
                                                                {format(new Date(item.createdAt), 'dd.MM.yyyy hh:mm')}
                                                            </p>
                                                        </td>

                                                        <td>
                                                            <p className="color-text">
                                                                {`#${item.id}`}
                                                            </p>
                                                        </td>

                                                        <td>
                                                            <p className="font-500">
                                                                ${item.amount}
                                                            </p>
                                                        </td>

                                                        <td>
                                                            <p className="color-text">
                                                                {ticketTypeMap[item.productType]}
                                                            </p>
                                                        </td>

                                                        <td>
                                                            <p className="font-500">
                                                                {format(new Date(item.deferredPayment), 'dd.MM.yyyy')}
                                                            </p>
                                                        </td>

                                                        <td>
                                                            <p className="color-text">
                                                                {paymentStatusMap[item.status]}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <p className="desk-heading --sm-heading">
                                        You have no payment history
                                    </p>
                                )}
                            <PaginationTemplate {...pagination} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PayuotMethods;
