import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../api";

export const instructorPassportVerificationFetch = createAsyncThunk(
    "/instructorPassportVerificationFetch",
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.verifications.instructorPassportVerification(payload);
            toast[res?.success ? 'success' : 'error'](res?.message)
            return res;
        } catch (err) {
            console.log(err);

            return rejectWithValue(err?.response?.data);
        }
    }
);

export const academyPassportVerificationFetch = createAsyncThunk(
    "/academyPassportVerificationFetch", async (payload, { rejectWithValue }) => {
        try {
            const res = await api.verifications.academyPassportVerification(payload);
            toast[res?.success ? 'success' : 'error'](res?.message)
            return res;
        } catch (err) {
            console.log(err);

            return rejectWithValue(err?.response?.data);
        }
    }
)

export const academyCodeVerificationFetch = createAsyncThunk(
    "/academyCodeVerificationFetch", async (payload, { rejectWithValue }) => {
        try {
            const res = await api.verifications.academyCodeVerification(payload);
            toast[res?.success ? 'success' : 'error'](res?.message)
            return res;
        } catch (err) {
            console.log(err);

            return rejectWithValue(err?.response?.data);
        }
    }
)
export const academyFilesVerificationFetch = createAsyncThunk(
    "/academyFilesVerificationFetch", async (payload, { rejectWithValue }) => {
        try {
            const res = await api.verifications.academyFilesVerification(payload);
            if (res?.success) {
                toast.success("Files sent for verification successfully");
            } else {
                toast.error(res?.message);
            }
            return res;
        } catch (err) {
            console.log(err);

            return rejectWithValue(err?.response?.data);
        }
    }
)

export const studentEmailVerificationFetch = createAsyncThunk(
    "/studentEmailVerificationFetch", async (payload, { rejectWithValue }) => {
        try {
            const res = await api.verifications.studentCodeVerification(payload);
            toast[res?.success ? 'success' : 'error'](res?.message)
            return res;
        } catch (err) {
            console.log(err);

            return rejectWithValue(err?.response?.data);
        }
    }
)

export const studentSendVerificationCodeFetch = createAsyncThunk(
    "/studentSendVerificationCodeFetch", async (payload, { rejectWithValue }) => {
        try {
            const res = await api.verifications.studentSendVerificationCode(payload);
            toast[res?.success ? 'success' : 'error'](res?.message)
            return res;
        } catch (err) {
            console.log(err);

            return rejectWithValue(err?.response?.data);
        }
    }
)

export const getMyVerificationFetch = createAsyncThunk("/getMyVerificationFetch", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.verifications.getMyVerification(payload);
        return res;
    } catch (err) {
        console.log(err);

        return rejectWithValue(err?.response?.data);
    }
});