import React from "react";
import { getUser } from "../../redux/slices/applicationSlice";
import { useSelector } from "react-redux";
import { MySeminarsInstructorsAndAcademies } from "./MySeminarsInstructorsAndAcademies";
import { MySeminarsStudent } from "./studentProfilePages/MySeminarsStudent";
import { USER_ROLE } from "../../constants/enums";

export const MySeminars = () => {
    const user = useSelector(getUser);

    if(!user) {
        return null
    }

    return (
        <div className="my-seminars-page__body">
            {user && user.role === USER_ROLE.student
                ? <MySeminarsStudent />
                : <MySeminarsInstructorsAndAcademies />}
        </div>
    );
};
