import React, {useState} from "react";
import classNames from "classnames";
import FileIcon from "../../../assets/img/ico-file.svg";
import {sendChatMessageFetch} from "../../../redux/thunks/chatsThunks";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getChatSocket} from "../../../redux/slices/socketChatSlice";
import {Tooltip} from "react-tooltip";

export const ChatFooter = ({canSendMessages, chatMessages, currentChatData}) => {
    const dispatch = useDispatch();
    const {chatId} = useParams();
    const [inputValue, setInputValue] = useState("");
    const [file, setFile] = useState(null);
    const socket = useSelector(getChatSocket);
    const handleKeyDown = async (event) => {
        if (event.key === "Enter") {
            if (!canSendMessages) return;

            if (file) {
                const body = new FormData();

                body.append("message", inputValue);
                body.append("attachments", file, file.name);
                dispatch(sendChatMessageFetch({
                    chatId,
                    body
                }));
                setInputValue("");
                setFile(null);

                return;
            } else if (inputValue) {
                socket.emit("chat:sendMessage", {
                    chatId: chatId,
                    message: inputValue
                });
                setInputValue("");
            }
        }
    };
    const handleFileRemove = () => {
        setFile(null);
    };
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        setFile(selectedFile);
    };
    return (
        <div>
            {file && (
                <div className="chat-files-part">
                    <div className="chat-selected-file">
                        <p>
                            {file.name}
                        </p>

                        <button onClick={handleFileRemove}>
                            Delete
                        </button>
                    </div>
                </div>
            )}

            <div
                className={classNames("messages__footer", {"block-disabled": currentChatData?.status === "archived" || !canSendMessages})}
                data-tooltip-id={!canSendMessages
                    ? `my-tooltip-${chatId}`
                    : ""}
            >

                <label className="btn btn--square messages__button">
                    <input
                        className="hidden"
                        disabled={!canSendMessages}
                        onChange={handleFileChange}
                        type="file"
                    />

                    <span className="ico">
                                        <img src={FileIcon} alt="file"/>
                                    </span>
                </label>

                <div className="form-group messages__input">
                    <input
                        disabled={!canSendMessages}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder={chatMessages?.length
                            ? "Type your message here..."
                            : "Type a message here and start the seminar negotiation"}
                        style={{resize: "none"}}
                        value={inputValue}
                    />
                </div>
            </div>
            {!canSendMessages && (
                <Tooltip
                    border="1px solid var(--border-color)"
                    id={`my-tooltip-${chatId}`}
                    style={{
                        background: "var(--white)",
                        color: "var(--defaultTextColor)",
                        padding: 10
                    }}
                >
                    <p>
                        You will be able to send messages after the other participant has responded
                    </p>
                </Tooltip>
            )}
        </div>
    );
};
