import React from 'react'
import { Controller } from 'react-hook-form';
import DatePicker from '../../../components/ui/formElements/DatePicker';
import HeadingBlock from '../components/HeadingBlock';
import { useTranslation } from 'react-i18next';
import { useBirthday } from '../../../hooks/useBirthday';

const BirthdayTab = ({ useFormReturn }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'birthdayTab' });
    const { control, setValue, watch } = useFormReturn;

    useBirthday(setValue, watch);

    return (
        <>
            <HeadingBlock
                title={t('title')}
                description={t('subtitle')}
            />

            <div className="row">
                <div className="col-md-4 mt-4">

                    <div className="form-group form-group input--lg">
                        <label className="label">
                            {t('dayLabel')}
                        </label>

                        <div className="day-datepicker">
                            <Controller
                                name="dateDay"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <DatePicker
                                        className="input input--solid"
                                        dateFormat="dd"
                                        onChange={onChange}
                                        selected={value}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-4 mt-4">
                    <div className="form-group form-group input--lg">
                        <label className="label">
                            {t('monthLabel')}
                        </label>

                        <div className="month-datepicker">
                            <Controller
                                name="dateMonth"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <DatePicker
                                        className="input input--solid"
                                        dateFormat="MM"
                                        onChange={(event) => {console.log(event); onChange(event)}}
                                        selected={value}
                                        showMonthYearPicker
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-4 mt-4">
                    <div className="form-group form-group input--lg">
                        <label className="label">
                            {t('yearLabel')}
                        </label>

                        <div className="month-datepicker">
                            <Controller
                                name="dateYear"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <DatePicker
                                        className="input input--solid"
                                        dateFormat="yyyy"
                                        onChange={onChange}
                                        selected={value}
                                        showYearPicker
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BirthdayTab