import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {
    useEffect, useRef, useState
} from "react";
import "../../../assets/css/mapBox.css";
import secondaryMarker from "../../../assets/img/map/secondary-map-point.png";

const mapBoxToken = "pk.eyJ1IjoibWF4aW1va29semluIiwiYSI6ImNsdGU5OW1lMzBlOTQyaXF1OHQ3a3FyZzQifQ.iHTE8f0GCRZQyZiAX8rfBw";

mapboxgl.accessToken = mapBoxToken;

const MapBox = ({
    mainPoints,
    secondaryPoints,
    newCenter,
    getCoordinatesOnclick,
    noEvents = false,
    onMarkerClick,
    onMapClick
}) => {

    const mapContainer = useRef(null);
    const [map, setMap] = useState(null);
    const [mainMarkers, setMainMarkers] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (map) return;

        const currentMap = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/maximokolzin/clxd74ktr02dc01pnb0ra8sd0",
            center: newCenter
                ? newCenter
                : [12.554729, 55.70651],
            zoom: 10
        });

        currentMap.on("load", () => {
            setLoaded(true);
        })
        if (!noEvents) {
            currentMap.on("click", function (e) {
                const features = currentMap.queryRenderedFeatures(e.point);
                if (!features.length) {
                    const coordinates = e.lngLat;
                    onMapClick && onMapClick({
                        lon: coordinates.lng,
                        lat: coordinates.lat
                    });
                }
            });
        }

        if (!noEvents) {
            currentMap.on("click", function (e) {
                const coordinates = e.lngLat;
                getCoordinatesOnclick && getCoordinatesOnclick({
                    lon: coordinates.lng,
                    lat: coordinates.lat
                });
            });
        }

        setMap(currentMap);
    }, []);

    useEffect(() => {
        if (map && newCenter) {
            // map.setCenter(newCenter);
            map.easeTo({
                center: newCenter,
                zoom: 16,
            });
        }
    }, [newCenter]);

    const clickHandler = (e, data) => {
        if (onMarkerClick && data) {
            onMarkerClick(data);
        }
    };

    useEffect(() => {
        if ((!map || !mainPoints) && !loaded) return;

        mainMarkers?.forEach((markerObj) => {
            const element = markerObj.marker.getElement();

            element.removeEventListener("click", markerObj.clickHandler);
            markerObj.marker.remove();
        });

        const newMainMarkers = mainPoints?.filter(point => point.coordinates?.length).map((point) => {
            const a = document.createElement("div");

            a.classList.add("map-main-point");
            const marker = new mapboxgl.Marker({ element: a })
                .setLngLat(point.coordinates)
                .addTo(map);

            if (!noEvents) {
                marker.getElement().addEventListener("click", clickHandler);
            }

            return {
                marker,
                point,
                clickHandler
            };
        });

        setMainMarkers(newMainMarkers);
    }, [map, mainPoints, loaded]);

    useEffect(() => {
        if (!map || !loaded || !secondaryPoints) return;

        const geojsonData = {
            type: "FeatureCollection",
            features: secondaryPoints.map((point) => ({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: point.coordinates,
                },
                properties: point.data,
            })),
        };

        if (map.getSource("secondaryPoints")) {
            map.removeSource("secondaryPoints");
        }

        map.addSource("secondaryPoints", {
            type: "geojson",
            data: geojsonData,
            cluster: true,
            clusterMaxZoom: 14,  // Max zoom to cluster points
            clusterRadius: 50,   // Cluster radius in pixels
        });

        map.addLayer({
            id: "secondary-clusters",
            type: "circle",
            source: "secondaryPoints",
            filter: ["has", "point_count"],
            paint: {
                "circle-color": [
                    "step",
                    ["get", "point_count"],
                    "#9747FF",
                    100,
                    "#9747FF",
                    750,
                    "#9747FF",
                ],
                "circle-radius": [
                    "step",
                    ["get", "point_count"],
                    20,
                    100,
                    30,
                    750,
                    40,
                ],
            },
        });

        map.addLayer({
            id: "secondary-cluster-count",
            type: "symbol",
            source: "secondaryPoints",
            filter: ["has", "point_count"],
            layout: {
                "text-field": "{point_count_abbreviated}",
                "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                "text-size": 12,
            },
            paint: {
                'text-color': '#ffffff'
            }
        });

        const base64Image = secondaryMarker;

        const image = new Image();
        image.onload = () => {
            map.addImage('custom-marker', image);

            map.addLayer({
                id: "unclustered-secondary-point",
                type: 'symbol',
                source: 'secondaryPoints',
                filter: ["!", ["has", "point_count"]],
                layout: {
                    'icon-image': 'custom-marker',
                    'icon-size': 0.8,
                },
            });
        };

        image.onerror = (e) => {
            console.error('Failed to load internal image:', e);
        };

        image.src = base64Image;

        map.on("click", "secondary-clusters", (e) => {
            const features = map.queryRenderedFeatures(e.point, {
                layers: ["secondary-clusters"],
            });
            const clusterId = features[0].properties.cluster_id;
            map.getSource("secondaryPoints").getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) return;
                map.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom: zoom,
                });
            });
        });

        // Handle click event for unclustered points
        map.on("click", "unclustered-secondary-point", (e) => {
            const properties = e.features[0].properties;
            const data = {
                ...properties,
                coordinates: typeof properties?.coordinates === 'string' ? JSON.parse(properties.coordinates) : null
            };

            clickHandler(e, data);
        });

        // Set cursor style for clusters
        map.on("mouseenter", "secondary-clusters", () => {
            map.getCanvas().style.cursor = "pointer";
        });
        map.on("mouseleave", "secondary-clusters", () => {
            map.getCanvas().style.cursor = "";
        });

        // Update the secondary markers state with the new data (optional)

        // Cleanup when the component unmounts or map updates
        return () => {
            // Remove the layers and source
            if (map.getLayer("secondary-clusters")) {
                map.removeLayer("secondary-clusters");
            }
            if (map.getLayer("secondary-cluster-count")) {
                map.removeLayer("secondary-cluster-count");
            }
            if (map.getLayer("unclustered-secondary-point")) {
                map.removeLayer("unclustered-secondary-point");
            }
            if (map.getSource("secondaryPoints")) {
                map.removeSource("secondaryPoints");
            }
        };
    }, [map, secondaryPoints, loaded]);


    return (
        <div
            className="map-container"
            ref={mapContainer}
        />
    );
};

export default MapBox;
