import { mixed, object, string } from "yup";
import { birthdaySchema } from "./birthdaySchema";
import { passwordSchema } from "./passwordSchema";
import { phoneSchema } from "./phoneSchema";

export const commonDetailsSchema = object().shape({
    photo: mixed().nullable(),
    fullName: string().required("Full Name is required").matches(/^[A-Za-z]+(\s[A-Za-z]+)+$/, "Full Name must contain Name and Surname").trim(""),
    email: string().email("Incorrect email format").required("Email is required"),
    ...phoneSchema.fields,
});

export const personalDetailsSchema = object().shape({
    belt: string().required("Belt is required"),
    traningStyle: string().required("Training Style is required"),
    gender: string().required("Gender is required"),
    country: string().required("Country is required"),
    bio: string().nullable(),
});




export const instructorSchema = object().shape({
    ...commonDetailsSchema.fields,
    ...personalDetailsSchema.fields,
    ...birthdaySchema.fields,
    ...passwordSchema.fields
});


