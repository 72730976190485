import classNames from "classnames";
import { format } from "date-fns";
import moment from "moment";
import React, {
    useEffect, useState
} from "react";
import { ReactComponent as PlusIcon } from "../../../assets/img/ico-plus.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/ico-trash.svg";
import DatePicker from "../../../components/ui/formElements/DatePicker";

const nextDay = (date) => {
    if (date) {
        return moment(date).day(moment(date).day() + 1)
            .toDate().toISOString();
    } else {
        return null;
    }
};

const SpecificDatesSelect = ({ handleChange, disabled, errors, isErrorsShown, maxLength = 2, minLength = 1 }) => {
    const [dates, setDates] = useState([
        {
            date: null,
            startTime: null,
            endTime: null
        }
    ]);

    useEffect(() => {
        if (dates?.length && !!dates[0].date) {
            handleChange(dates);
        }
    }, [dates]);

    const handleAddTimeSlot = () => {
        if (!dates?.length) return;
        setDates([
            ...dates, {
                date: nextDay(dates[dates.length - 1]?.date),
                startTime: null,
                endTime: null
            }
        ]);
    };

    const handleChangeDate = (date, index) => {
        const nextDate = nextDay(date)
        setDates(dates.map((oldDate, oldIndex) => oldIndex === index
            ? {
                date: new Date(date).toISOString(),
                startTime: null,
                endTime: null,
            }
            : oldDate));
        if (index === 0 && dates.length >= maxLength) {
            setDates((prev) => {
                prev[1] = {
                    date: format(nextDate, 'yyyy-MM-dd'),
                    startTime: null,
                    endTime: null
                };

                return prev;
            });
        }
    };

    const handleRemoveTimeSlot = (index) => {
        setDates(dates.filter((_, oldIndex) => index !== oldIndex));
    };

    return (
        <div className="col-group gap--md">
            <div className={classNames("specific-dates-wrapper", { "block-disabled": disabled })}>
                {dates?.map((date, index) => (
                    <React.Fragment key={index}>
                        <div className="schedule-group-item__date">
                            <DatePicker
                                error={!disabled && isErrorsShown && errors && !date.date}
                                excludeDates={dates.filter((item) => item.date).map((item) => item.date)}
                                label={`Day #${index + 1}`}
                                minDate={moment().toDate()}
                                onChange={(date) => handleChangeDate(date, index)}
                                placeholderText="Select Event Date"
                                selected={date.date}
                            />
                        </div>

                        {dates.length > 1 && (
                            <button
                                onClick={() => handleRemoveTimeSlot(index)}
                                style={{ marginTop: 26 }}
                                className="btn btn--outline-default btn--square btn--md rounded-full"
                                type="button"
                            >
                                <DeleteIcon />
                            </button>
                        )}
                    </React.Fragment>
                )



                )}

                {dates.length < maxLength && (
                    <button
                        className="btn btn--outline-default btn--square btn--md rounded-full"
                        onClick={handleAddTimeSlot}
                        style={{ marginTop: 26 }}
                        type="button"
                    >
                        <PlusIcon />
                    </button>
                )}

            </div>
            {isErrorsShown && typeof errors === "string" && <p className="error-input">{errors}</p>}
        </div>
    );
};

export default SpecificDatesSelect;
