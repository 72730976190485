import { NOT_SPECIFIED } from '../../../../../constants';
import { CardDetails } from '../../components/CardDetails';
import Photos from '../../components/Photos';
import { Step } from '../../components/Step';
import { checkIsEdited } from '../../utils';

export const DesignStep = ({ data }) => {
    return (
        <Step label="Design & Text">
            <Photos data={data?.photos} />

            <CardDetails
                isEdited={checkIsEdited(data?.lastEdited, ['seminarName'])}
                title="Seminar Name"
            >
                <p className="color-text">
                    {data?.seminarName || NOT_SPECIFIED}
                </p>
            </CardDetails>

            <CardDetails
                isEdited={checkIsEdited(data?.lastEdited, ['summary'])}
                title="Summary"
            >
                <p className="color-text">
                    {data?.summary || NOT_SPECIFIED}
                </p>
            </CardDetails>
        </Step >
    );
};