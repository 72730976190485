import classNames from "classnames";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Button from "../../components/ui/buttons/Button";
import Loader from "../../components/ui/Loader";
import {getImageSrc} from "../../helpers/utils";
import {
    deleteNotificationFetch, getAllNotificationsFetch, readNotificationsFetch, reviewNotificationsFetch
} from "../../redux/thunks/notificationsThunks";
import {ReactComponent as ChevronNextIcon} from "../../assets/img/ico-chevron--next.svg";
import {ReactComponent as DeleteIcon} from "../../assets/img/ico-trash.svg";
import {PaginationTemplate} from "../../components/parts/PaginationTemplate";
import {formatDistanceToNow} from "date-fns";
import {setUnreviewedNotificationsCount} from "../../redux/slices/notificationsSlice";
import {notificationEventGroups, notificationEvents} from "../../constants/notificationEvents";
import {toast} from "react-toastify";
import {ReactComponent as BellIcon} from "../../assets/img/bell-icon.svg";
import {getUser} from "../../redux/slices/applicationSlice";
import {USER_ROLE} from "../../constants/enums";

export const NotificationItem = ({data, isAllReading}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isReading, setIsReading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const user = useSelector(getUser);


    const handleRead = () => {
        setIsReading(true);
        dispatch(readNotificationsFetch({body: {ids: [data.id]}})).finally(() => setIsReading(false));
    };

    const handleDelete = () => {
        setIsDeleting(true);
        dispatch(deleteNotificationFetch(data.id)).finally(() => setIsDeleting(false));
    };

    const handleClick = () => {
        const item = {...data};

        if (Object.keys(item?.payload).length === 0 || !item) return;

        const redirectId =
            item?.payload?.instructorId ||
            item?.payload?.academyId ||
            item?.payload?.seminarId ||
            item?.payload?.plancampId;

        if (item?.payload?.chatId) {
            navigate(`/chat/${item?.payload?.chatId}`);
            handleRead(item?.id);
            return;
        }

        switch (true) {
            case notificationEventGroups.navigateToRequests.includes(item?.type):
                navigate('/requests');
                break;

            case notificationEventGroups.navigateToInstructor.includes(item?.type) && !!redirectId:
                navigate(`/instructors/${redirectId}`);
                break;

            case notificationEventGroups.navigateToAcademy.includes(item?.type) && !!redirectId:
                navigate(`/academies/${redirectId}`);
                break;

            case notificationEventGroups.navigateSeminarByAcademy.includes(item?.type) && !!redirectId:
                navigate(`/planed-by-academy/${redirectId}`);
                break;

            case notificationEventGroups.navigateSeminarByInstructor.includes(item?.type) && !!redirectId:
                navigate(`/planed-by-insctructor/${redirectId}`);
                break;

            default:
                break;
        }

        handleRead(item.id);

        return null;
    };

    const photo =
        data?.payload?.photo ||
        data?.payload?.user?.photo ||
        data?.payload?.student?.photo ||
        data?.payload?.instructor?.photo ||
        data?.privateLesson?.[user?.role === USER_ROLE.student ? "instructor" : "student"]?.photo


    return (
        <div onClick={handleClick}
             className={classNames("notification-item border-bottom", {"--readed": data.isReaded})}
             style={{cursor: "pointer"}}>
            <div className="notification-item__body">
                <div className="col-group gap--sm flex-auto">
                    <div className="row-group gap--md">
                        <div className="image-wrapper avatar flex items-center justify-center">
                            {photo && <img
                                alt="instructor"
                                src={getImageSrc(photo)}
                            />}
                            {!photo && <BellIcon path={"red"}/>}
                        </div>
                        <div className="col-group gap--xs">
                            <h6>{data?.payload?.fullName || data?.payload?.user?.fullName}</h6>
                            <p className="color-text">
                                {data.message}
                            </p>
                        </div>
                    </div>
                </div>
                {formatDistanceToNow(data?.createdAt ? new Date(data.createdAt) : new Date(), {addSuffix: true})}
            </div>

            <div className="notification-item__actions "
            onClick={e => e.stopPropagation()}
            >
                <Button
                    className="underline color-danger btn--md btn--square"
                    isLoading={isDeleting}
                    onClick={handleDelete}
                >
                    <DeleteIcon/>
                </Button>
            </div>
            <span className="blue-indicator" style={{opacity: data.isReaded ? "0" : "1"}}></span>
        </div>
    );
};

const NotificationsPage = () => {
    const dispatch = useDispatch();
    const [isAllReading, setIsAllReading] = useState(false);
    const {notifications, isLoading, pagination} = useSelector((state) => state.notifications);
    const [params, setParams] = useSearchParams({
        sort_by: "isReaded",
        sort_as: "asc"
    })

    const handleReadAll = () => {
        setIsAllReading(true);
        dispatch(readNotificationsFetch({readUnreadedAll: true})).finally(() => setIsAllReading(false));
    };

    useEffect(() => {
        dispatch(getAllNotificationsFetch(params.toString()));
    }, [params]);

    const handlePageChange = (page) => {
        setParams(prev => {
            prev.set("page", page);

            return prev
        })
    }

    const handleReviewAll = () => {
        dispatch(reviewNotificationsFetch({
            reviewAll: true
        }))
        dispatch(setUnreviewedNotificationsCount(0))
    }
    useEffect(() => {
        handleReviewAll()
    }, [])

    return (
        <section className="col-group flex-auto py-5">
            <div className="container col-group flex-auto">
                <div className="row-group justify-between gap--md mb-24">
                    <h3 className="heading">
                        Notifications
                    </h3>

                    {
                        !!notifications?.length && (
                            <Button
                                className="btn--primary btn--md"
                                isLoading={isAllReading}
                                onClick={handleReadAll}
                            >
                                Read all
                            </Button>
                        )}
                </div>

                {
                    notifications?.length && !isLoading
                        ? (
                            <div className="col-group">
                                {
                                    notifications.map((item) => (
                                            <NotificationItem
                                                key={item.id}
                                                data={item}
                                                isAllReading={isAllReading}
                                            />
                                        )
                                    )
                                }
                            </div>
                        )


                        : isLoading
                            ? (
                                <div className="m-auto">
                                    <Loader/>
                                </div>
                            )


                            : (
                                <div className="m-auto">
                                    <h5 className="heading">
                                        There is no notifications
                                    </h5>
                                </div>
                            )


                }
                <PaginationTemplate
                    {...pagination}
                    onPageChange={handlePageChange}
                    className={"mt-auto pt-24"}
                />
            </div>
        </section>
    );
};

export default NotificationsPage;
