import React from 'react'
import { Link } from 'react-router-dom';
import { ReactComponent as ChevronPrevIcon } from "../../../assets/img/ico-chevron--prev.svg";

const BackButton = ({ to = "/my-seminars" }) => {
    return (
        <div className="section-heading-row">
            <Link
                className="btn btn-back"
                to={to}
            >
                <span className="ico">
                    <ChevronPrevIcon />
                </span>

                <p className="text-sm">
                    Back
                </p>
            </Link>
        </div>
    )
}
export default BackButton