import { useEffect } from 'react'
import { isDayInMonth } from '../helpers/utils.js';

export const useBirthday = (setValue, watch) => {
    const setBirthDate = (day, month, year) => {
        if (!day || !month || !year) {
            return;
        }

        let dayToSave = day.getDate();
        let monthToSave = month.getMonth();
        let yearToSave = year.getFullYear();

        const isValidDay = isDayInMonth(yearToSave, monthToSave, dayToSave);

        if (!isValidDay) {
            dayToSave = 1;

            setValue('dateDay', new Date(yearToSave, monthToSave, dayToSave));
        }

        const updatedBirthday = Date.UTC(yearToSave, monthToSave, dayToSave);

        const dateToSave = new Date(updatedBirthday);

        setValue('birthday', dateToSave, { shouldDirty: true });
    }

    const dayDate = watch('dateDay');
    const monthDate = watch('dateMonth');
    const yearDate = watch('dateYear');

    useEffect(() => {
        setBirthDate(dayDate, monthDate, yearDate);
    }, [dayDate, monthDate, yearDate]);
}