import React from 'react'

import { ReactComponent as ChevronNextIcon } from "../../../assets/img/ico-chevron--next.svg";
import { ReactComponent as ChevronPrevIcon } from "../../../assets/img/ico-chevron--prev.svg";
import Button from '../../../components/ui/buttons/Button';

const StepsActions = ({
    onBack,
    isFinalStep,
    canGoBack,
    onSubmit,
    loading,
    canSkip,
    onSkip
}) => {
    return (
        <div className="row-group gap--xs">
            {canGoBack && (
                <Button
                    className="btn btn--default btn--lg w-full fs-20 mt-12"
                    onClick={onBack}
                    type="button"
                >
                    <span className="ico ico-18">
                        <ChevronPrevIcon />
                    </span>
                    <span>
                        Back
                    </span>
                </Button>
            )}
            {canSkip && (
                <Button
                    className="btn btn--default btn--lg w-full fs-20 mt-12"
                    onClick={onSkip}
                    type="button"
                >
                    <span>
                        Skip
                    </span>
                </Button>
            )}

            <Button
                className="btn btn--primary btn--lg w-full fs-20 mt-12"
                onClick={onSubmit && onSubmit}
                isLoading={loading}
                type="submit"
            >
                {isFinalStep ? (
                    <span className="info">
                        Create Account
                    </span>
                ) : (
                    <>
                        <span className="info">
                            Continue
                        </span>
                        <span className="ico">
                            <ChevronNextIcon />
                        </span>
                    </>
                )}
            </Button>
        </div>
    );
}

export default StepsActions