import classNames from "classnames";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import api from "../../../api";
import VerifyPassportModal from "../../../components/modals/VerifyPassportModal";
import TooltipInfo from "../../../components/parts/TooltipInfo";
import Button from "../../../components/ui/buttons/Button";
import Input from "../../../components/ui/formElements/Input";
import SelectOne from "../../../components/ui/formElements/SelectOne";
import Textarea from "../../../components/ui/formElements/Textarea";
import UploadPhotoAndCover from "../../../components/ui/UploadPhotoAndCover";
import { explanations } from "../../../constants/explanations";
import { convertObjectToFormData, getImageSrc } from "../../../helpers/utils";
import { instructorPassportVerificationFetch } from "../../../redux/thunks/verificationsThunk";

import { ReactComponent as NextIcon } from '../../../assets/img/ico-chevron--next.svg';
import { ReactComponent as VerifyIcon } from '../../../assets/img/verify-icon.svg';
import BirthdayFormFields from "../../../components/parts/BirthdayFormFields";

const MyProfileInstructor = ({ userData, isVerifyModalInitialyOpen }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation('translation', { keyPrefix: 'profile' });
    const [verifyModalOpen, setVerifyModalOpen] = useState(isVerifyModalInitialyOpen);

    const birthday = userData?.profile?.birthday;

    console.log("isVerifyModalInitialyOpen", isVerifyModalInitialyOpen)

    const {
        control,
        register,
        setValue,
        handleSubmit,
        formState: { dirtyFields, errors },
        reset,
        watch
    } = useForm({
        defaultValues: {
            email: userData?.email || '',
            phone: userData?.phone || '',
            ...userData?.profile,
            dateDay: birthday && new Date(birthday.split('T')[0]),
            dateMonth: birthday && new Date(birthday.split('T')[0]),
            dateYear: birthday && new Date(birthday.split('T')[0]),
        }
    });

    const onSubmit = async (data) => {
        const editedFieldsNames = Object.keys(dirtyFields);

        const changedFieldNames = editedFieldsNames.filter(
            (fieldName) => dirtyFields[fieldName]
        );

        const updateData = {};

        changedFieldNames.forEach((fieldName) => {
            updateData[fieldName] = data[fieldName];
        });

        try {
            if (updateData?.email || updateData?.phone) {
                const updatePayload = {};

                ["email", "phone"].forEach((item) => {
                    if (updateData[item]) {
                        updatePayload[item] = updateData[item];
                    }
                });
                const updateUserRes = await api.users.updateUser(updatePayload);

                if (updateUserRes.success) {
                    toast.success(updateUserRes.message || "Updated");
                } else {
                    toast.error(updateUserRes?.message || "Error");
                }
            }

            if (Object.values(dirtyFields).length > 0) {
                const formData = new FormData();

                if (updateData?.birthday) {
                    formData.append("birthday", new Date(updateData?.birthday).toISOString());
                }

                [
                    "cover",
                    "photo",
                    "fullName",
                    "nickname",
                    "bio",
                    "gender"
                ].forEach((item) => {
                    if (updateData[item]) {
                        formData.append(item, updateData[item]);
                    }
                });

                const updateInstructorRes = await api.profiles.updateInstructor(formData);

                if (updateInstructorRes.success) {
                    toast.success(updateInstructorRes.message || "Updated");
                    reset();
                } else {
                    toast.error(updateInstructorRes?.message || "Error");
                }
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleSumbitVerification = async (data) => {
        await dispatch(instructorPassportVerificationFetch(convertObjectToFormData({ source: data })))
        setVerifyModalOpen(false);
    };

    return (
        <div className="settings-body settings-gap">
            <div className="sidebar-header heading-row">
                <h3 className="heading">
                    {t('title')}
                </h3>
            </div>

            <form
                className="form form-settings"
                method="post"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="form-header">
                    <div className="row gutters-form">
                        <div className="col-12">
                            <UploadPhotoAndCover
                                cover={getImageSrc(watch('cover'))}
                                photo={getImageSrc(watch('photo'))}
                                onCoverChange={(file) => {
                                    setValue('cover', file, { shouldDirty: true });
                                }}
                                onPhotoChange={(file) => {
                                    setValue('photo', file, { shouldDirty: true });
                                }}
                                error={errors?.photo?.message && t('photoRequiredErrorText')}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-body">
                    <div className="form-items-list">
                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <Input
                                        inputClasses="input--solid"
                                        label={t('fullName')}
                                        wrapperClasses="input--lg"
                                        {...register('fullName', {
                                            required: 'fullNameRequiredErrorText'
                                        })}
                                        error={errors.fullName?.message && t(errors.fullName?.message)}
                                    />
                                </div>

                                <div className="col-12">
                                    <Input
                                        inputClasses="input--solid"
                                        label={t('nickname')}
                                        {...register('nickname')}
                                        wrapperClasses="input--lg"
                                    />
                                </div>
                                <div className="col-12">
                                    <Controller
                                        name={`gender`}
                                        control={control}
                                        rules={{
                                            required: 'genderRequiredErrorText'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <SelectOne
                                                label={t('gender')}
                                                onChange={(event) => onChange(event.value)}
                                                options={["Male", "Female"].map(el => ({
                                                    label: el,
                                                    value: el.toLowerCase()
                                                }))}
                                                value={value}
                                                wrapperClasses="select--outline  select--outline-bg input--lg"
                                                error={errors.gender?.message && t(errors.gender?.message)}
                                            />
                                        )}
                                    />

                                </div>
                                <div className="col-12">
                                    <div className="form-group input--lg">
                                        <label className="label">
                                            {t('dateOfBirth')}
                                        </label>

                                        {/* <div className="row gutters-form-2">
                                            <div className="col-md-4 col-sm-4 col-12">
                                                <div className="day-datepicker">
                                                    <Controller
                                                        name={`birthday`}
                                                        control={control}
                                                        rules={{
                                                            required: 'birthdayRequiredErrorText'
                                                        }}
                                                        render={({ field: { value, onChange } }) => (
                                                            <DatePicker
                                                                className={classNames("input input--solid", {
                                                                    "error-input": !!errors.birthday?.message
                                                                })}
                                                                dateFormat="dd"
                                                                onChange={onChange}
                                                                selected={value}
                                                                error={errors.birthday?.message && t(errors.birthday?.message)}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-sm-4 col-12">
                                                <div className="month-datepicker">
                                                    <Controller
                                                        name={`birthday`}
                                                        control={control}
                                                        rules={{
                                                            required: 'birthdayRequiredErrorText'
                                                        }}
                                                        render={({ field: { value, onChange } }) => (
                                                            <DatePicker
                                                                className={classNames("input input--solid", {
                                                                    "error-input": !!errors.birthday?.message
                                                                })}
                                                                dateFormat="MM"
                                                                onChange={onChange}
                                                                selected={value}
                                                                error={t(errors.birthday?.message)}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-sm-4 col-12">
                                                <div className="month-datepicker">
                                                    <Controller
                                                        name={`birthday`}
                                                        control={control}
                                                        rules={{
                                                            required: 'birthdayRequiredErrorText'
                                                        }}
                                                        render={({ field: { value, onChange } }) => (
                                                            <DatePicker
                                                                className={classNames("input input--solid", {
                                                                    "error-input": !!errors.birthday?.message
                                                                })}
                                                                dateFormat="yyyy"
                                                                onChange={onChange}
                                                                selected={value}
                                                                error={t(errors.birthday?.message)}
                                                                showYearPicker
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div> */}

                                        <BirthdayFormFields
                                            control={control}
                                            setValue={setValue}
                                            watch={watch}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        inputClasses="input--solid"
                                        label={t('email')}
                                        {...register('email', { required: 'emailRequiredErrorText' })}
                                        wrapperClasses="input--lg"
                                        error={errors.email?.message && t(errors.email?.message)}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        inputClasses="input--solid"
                                        label={t('phone')}
                                        {...register('phone', { required: 'phoneRequiredErrorText' })}
                                        wrapperClasses="input--lg"
                                        error={errors.phone?.message && t(errors.phone?.message)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <Textarea
                                        label={t('bio')}
                                        textareaClasses={"input--solid"}
                                        wrapperClasses={"input--lg"}
                                        rows={"6"}
                                        {...register('bio', {
                                            required: 'bioRequiredErrorText'
                                        })}
                                        error={errors.bio?.message && t(errors.bio?.message)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <div className="heading-row">
                                        <div className="flex-ico-ss-text">
                                            <span className="ico ico--sm rounded-full border-ico">
                                                <VerifyIcon />
                                            </span>
                                            <div className="row-group">
                                                <h4 className="heading">
                                                    {t('verifyAccountText')}
                                                </h4>

                                                <TooltipInfo content={explanations.verifyFighterID} />
                                            </div>
                                        </div>

                                        <div className="flex-group">
                                            <Button onClick={() => setVerifyModalOpen(true)} className="btn btn--primary btn--sm">
                                                <span className="info">
                                                    {t('verifyIdButtonText')}
                                                </span>

                                                <span className="ico ico-14">
                                                    <NextIcon />
                                                </span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-footer --bordered">
                    <div className="flex-group justify-end">
                        <button
                            className={classNames("btn btn--primary btn--sm", {
                                disabled: !Object.values(dirtyFields).length,
                            })}
                            type="submit"
                        >
                            <span>
                                {t('saveButtonText')}
                            </span>
                        </button>
                    </div>
                </div>
            </form>
            {verifyModalOpen && (
                <VerifyPassportModal
                    open={verifyModalOpen}
                    onClose={() => setVerifyModalOpen(false)}
                    onSubmit={handleSumbitVerification}
                />
            )}
        </div>
    );
};

export default MyProfileInstructor;
