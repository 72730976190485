import {
    useEffect, useState
} from "react";
import { useTranslation } from "react-i18next";
import api from "../api";
import FAQ from "../components/parts/FAQ";
import PlatformBenefits from "../components/parts/PlatformBenefits";
import PageLoader from "../components/ui/PageLoader";
import findValue from "../helpers/findValue";
import { getImageSrc } from "../helpers/utils";

const AboutUs = () => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'about_us' });
    const [pageData, setPageData] = useState(null);

    const getPageData = async (lang) => {
        const res = await api.pages.getPagesByPath("/aboutUs", lang);

        setPageData(res.data);
    };

    useEffect(() => {
        getPageData(i18n.resolvedLanguage);
    }, [i18n.resolvedLanguage]);
    const getValue = (key) => findValue(pageData?.blocks, key);

    const firstSection = getValue('content_list')?.data?.[0]
    const secondSection = getValue('content_list')?.data?.[1]

    return (
        pageData
            ? (
                <>
                    <section className="first-screen">
                        <div className="firts-screen-bg">
                            <img
                                alt=""
                                src={getValue('header_background_image')
                                    ? process.env.REACT_APP_API_URL + getValue('header_background_image')
                                    : require("../assets/img/hero_about_us.png")}
                            />

                            <div className="firts-screen-bg-effect" />
                        </div>

                        <div className="container">
                            <div className="first-screen-body first-screen-body--center">
                                <h1
                                    className="heading"
                                // dangerouslySetInnerHTML={{ __html: pageData?.blocks?.[0]?.blockPresets?.[0]?.value || "About Us" }}
                                >{t('title')}</h1>
                            </div>
                        </div>
                    </section>

                    <section className="au-second-screen">
                        <div className="container mobile-adapt">

                            <div className="au-desc-block">
                                <div className="row gx-60 au-gap-80">
                                    <div className="col-lg-6 au-desc-block-item">
                                        {/* <span dangerouslySetInnerHTML={{ __html: getValue('header_left_topic') || "Welcome to Plan Seminars, the go-to platform for easily booking and planning Brazilian Jiu-Jitsu seminars. We're here to take the hassle out of organizing and attending BJJ events, making the entire process straightforward and efficient. Our goal is to make life easier for instructors, academies, and students, ensuring a smooth experience for everyone." }} /> */}
                                        {t('left_topic')}
                                    </div>

                                    <div className="col-lg-6 au-desc-block-item">
                                        {/* <span
                                            dangerouslySetInnerHTML={{
                                                __html: getValue('header_right_topic')
                                            }}
                                        /> */}
                                        {t('right_topic')}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="au-second-screen-bg-effect" />

                        <div className="au-second-screen-bg-effect-mobile" />
                    </section>

                    {/* {getValue('content_list')?.data?.map((item, index) => (
                        <section key={index} className="article section">
                            <div className="container">
                                <div className="row gx-60 gy-5 au-image-desc-section">
                                    {index % 2 === 0 && <div className="col-lg-6 au-block-one">
                                        <div className="au-image-big img-wrapper">
                                            <img
                                                alt=""
                                                src={
                                                    item?.image_preview
                                                        ? process.env.REACT_APP_API_URL + item.image_preview
                                                        : require("../assets/img/about-us-first.png")
                                                }
                                            />
                                        </div>
                                    </div>}

                                    <div className="col-lg-6 au-block-two">
                                        <h2
                                            className="heading heading-section"
                                            dangerouslySetInnerHTML={{ __html: item.title || "WHO ARE WE ?" }}
                                        />

                                        <br />

                                        <br />

                                        <p
                                            className="description-section"
                                            dangerouslySetInnerHTML={{ __html: item.content || "We are a team of dedicated jiu-jitsu athletes who saw a problem with the current process of arranging seminars, including issues with payment, scheduling, communication with athletes, and more. That's why we created Plan Seminars. Our platform simplifies the process for instructors, students, and academics worldwide, making it easier to teach, host, and attend seminars." }}
                                        />
                                    </div>
                                    {index % 2 != 0 && <div className="col-lg-6 au-block-one">
                                        <div className="au-image-big img-wrapper">
                                            <img
                                                alt=""
                                                src={
                                                    item?.image_preview
                                                        ? process.env.REACT_APP_API_URL + item.image_preview
                                                        : require("../assets/img/about-us-first.png")
                                                }
                                            />
                                        </div>
                                    </div>}
                                </div>

                            </div>
                        </section>
                    ))} */}
                    <section className="article section">
                        <div className="container">
                            <div className="row gx-60 gy-5 au-image-desc-section">
                                <div className="col-lg-6 au-block-one">
                                    <div className="au-image-big img-wrapper">
                                        <img
                                            src={getImageSrc(firstSection.image_preview)}
                                            alt={t('who_are_we_title')}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6 au-block-two">
                                    <h2
                                        className="heading heading-section"
                                        dangerouslySetInnerHTML={{ __html: t('who_are_we_title') }}
                                    />

                                    <br />

                                    <br />

                                    <p
                                        className="description-section"
                                        dangerouslySetInnerHTML={{ __html: t('who_are_we_desc') }}
                                    />
                                </div>
                            </div>
                            </div>
                    </section>
                    <section className="article section">
                        <div className="container">
                            <div className="row gx-60 gy-5 au-image-desc-section">
                                <div className="col-lg-6 au-block-two">
                                    <h2
                                        className="heading heading-section"
                                        dangerouslySetInnerHTML={{ __html: t('our_mission_title') }}
                                    />

                                    <br />

                                    <br />

                                    <p
                                        className="description-section"
                                        dangerouslySetInnerHTML={{ __html: t('our_mission_desc') }}
                                    />
                                </div>

                                <div className="col-lg-6 au-block-one">
                                    <div className="au-image-big img-wrapper">
                                    <img
                                            src={getImageSrc(secondSection.image_preview)}
                                            alt={t('our_mission_title')}
                                        />
                                    </div>
                                </div>

                            </div>
                            </div>
                    </section>

                    <section className="section">
                        <PlatformBenefits pageData={pageData} />

                    </section>

                    <FAQ pageData={pageData} />
                </>
            )


            : <PageLoader />
    );
};

export default AboutUs;
