import React from 'react'
import { Link } from 'react-router-dom';

const EventPreview = ({ name, link, cover, isActive }) => (
    <div className="row-group gap--xxl pb-24 border-bottom mb-24">
        <div className="image-wrapper --small-thumb">
            <img
                alt={name}
                src={cover}
            />
        </div>

        <div className="col-group gap--xxl justify-between">
            <div className="seminar-label-info">
                <h6 className="heading">
                    {name}
                </h6>
            </div>

            <Link
                className="color-secondary ttu italic fw-600 text--xs underline"
                to={link}
            >
                {isActive
                    ? "Show Seminar"
                    : "Preview"}
            </Link>
        </div>
    </div>
);

export default EventPreview