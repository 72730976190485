import React from "react";
import GeneralInfoAcademy from "./academyProfilePages/GeneralInfoAcademy";
import GeneralInfoInstructor from "./instructorProfilePages/GeneralInfoInstructor";
import { useSelector } from "react-redux";
import { getUser } from "../../redux/slices/applicationSlice";
import { USER_ROLE } from "../../constants/enums";

const GeneralInfo = () => {
    const user = useSelector(getUser);

    return (
        <>
            {user && user.role === USER_ROLE.academy && <GeneralInfoAcademy />}

            {user && user.role === USER_ROLE.instructor && <GeneralInfoInstructor />}
        </>
    );
};

export default GeneralInfo;
