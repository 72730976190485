import React from 'react'
import { Controller } from 'react-hook-form';
import Input from '../../../../components/ui/formElements/Input';
import PhoneInput from '../../../../components/ui/formElements/PhoneInput';
import HeadingBlock from '../../components/HeadingBlock';
import UploadPhotoInput from '../../components/UploadPhotoInput';

const CommonDetailsTab = ({ useFormReturn }) => {
    const { register, control, formState: { errors } } = useFormReturn

    return (
        <>
            <HeadingBlock
                title="WELCOME TO Plan Seminars"
                description="Let’s start, tell us a bit about yourself"
            />
            <Controller
                name="photo"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <UploadPhotoInput
                        onChange={onChange}
                        value={value}
                    />
                )}
            />

            <Input
                inputClasses="input--solid"
                label="Academy Name"
                {...register('name')}
                placeholder="e.g. Jiu-Jitsu Academy..."
                wrapperClasses="form-group input--lg"
                error={errors.name?.message}
            />

            <Input
                inputClasses="input--solid"
                label="Email"
                placeholder="Enter your email..."
                wrapperClasses="form-group input--lg"
                {...register('email')}
                error={errors.email?.message}
            />

            <Controller
                name="phone"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <PhoneInput
                        inputClasses="input--solid"
                        label="Phone"
                        value={value}
                        onChange={onChange}
                        placeholder="Enter your phone..."
                        wrapperClasses="form-group input--lg"
                        error={errors.phone?.message}
                    />
                )}
            />
        </>
    )
}

export default CommonDetailsTab
