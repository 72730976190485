import React from 'react'
import { getImageSrc } from '../../../../helpers/utils'
import Fancybox from '../../../../components/ui/Fancybox'

const Videos = ({ data }) => {
    if (!data?.videos?.length) {
        return <p className="color-text mx-auto">No video</p>
    }
    return (
        <div className="col-group gap--xs">
            {data?.videos.map((item) => (
                <Fancybox>
                    <a
                        data-fancybox="gallery"
                        href={getImageSrc(item?.url, null)}
                    >
                        <video className="w-full" src={getImageSrc(item?.url, null, 256)} />
                    </a>
                </Fancybox>
            ))}
        </div>
    )
}

export default Videos

