import { Route } from "react-router-dom";
import SecondaryLayout from "../layouts/SecondaryLayout";
import SignUpLayout from "../layouts/SingUpLayout";
import AcademySignUpPage from "../pages/singUpPages/academy/AcademySignUpPage";
import InstructorSignUpPage from "../pages/singUpPages/instructor/InstructorSignUpPage";
import StudentSignUpPage from "../pages/singUpPages/student/StudentSignUpPage";

export const signUpRoutes = (
    <Route element={<SecondaryLayout />}>
        <Route element={<SignUpLayout headerTitle="INSTRUCTOR ACCOUNT" />}>
            <Route
                element={<InstructorSignUpPage />}
                path="/instructor-sing-up"
            />
        </Route>
        <Route element={<SignUpLayout headerTitle="ACADEMY ACCOUNT" />}>
            <Route
                element={<AcademySignUpPage />}
                path="/academy-sing-up"
            />
        </Route>

        <Route element={<SignUpLayout headerTitle="STUDENT ACCOUNT" />}>
            <Route
                element={<StudentSignUpPage />}
                path="/student-sing-up"
            />
        </Route>
    </Route>
);