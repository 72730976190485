import { useState } from 'react'
import { Controller } from 'react-hook-form'
import TooltipInfo from '../../../../components/parts/TooltipInfo'
import CheckboxSwitch from '../../../../components/ui/formElements/CheckboxSwitch'
import Input from '../../../../components/ui/formElements/Input'
import SelectMulty from '../../../../components/ui/formElements/SelectMulty'
import SelectOne from '../../../../components/ui/formElements/SelectOne'
import { USER_ROLE } from '../../../../constants/enums'
import { explanations } from '../../../../constants/explanations'
import { sharedProfitOptions, targetAudienceOptions, teachingStyleOptions, traningStyleOptions } from '../../constants/options'

const TourInformationTab = ({
	useFormReturn,
	role,
	generalInfo,
	setPreview,
	preview,
}) => {
	const { register, setValue, watch, resetField, reset, getValues, formState: { errors }, control } = useFormReturn
	const [isSharedProfit, setIsSharedProfit] = useState(getValues("sharedProfit") || false);


	const handlePriceTypeChange = () => {
		setIsSharedProfit((prevIsSharedProfit) => {
			const resetFields = prevIsSharedProfit
				? ["sharedProfit"]
				: ["seminarMinPrice", "seminarMaxPrice"]

			resetFields.forEach((field) => {
				resetField(field)
				setValue(field, null);
			});

			return !prevIsSharedProfit;
		});
	}


	return (
		<div className='row gx-16 py-24 mb-42'>
			<div className="col-12">
				<div className="form-group input--lg mb-16">
					<div className="row-group gap--sm">
						<label className="label">
							Price range for seminar
						</label>
						<TooltipInfo
							content={role === USER_ROLE.academy
								? explanations.priceRangeAcademy
								: explanations.priceRangeInstructor}
						/>
					</div>
					<div className="row-group w-full gap-10 mobile-range">
						<div className="form-group flex-auto input--lg">
							<Input
								wrapperClasses="input--currency"
								inputClasses="input--solid"
								type="number"
								min={0}
								max={99999900}
								placeholder="3000"
								disabled={isSharedProfit}
								{...register("seminarMinPrice")}
								error={errors.seminarMinPrice?.message}
							/>
						</div>

						<span className="input-delimiter" />

						<div className="form-group flex-auto input--lg">
							<Input
								wrapperClasses="input--currency"
								inputClasses="input--solid"
								type="number"
								min={0}
								max={99999900}
								placeholder="4000"
								disabled={isSharedProfit}
								error={errors.seminarMaxPrice?.message}
								{...register("seminarMaxPrice")}

							/>
						</div>
					</div>
				</div>

				{!isSharedProfit && (
					<p className="color-text text-14 mb-16">
						Seminar pricing varies based on duration. Please adjust parameters based on your seminar duration.
					</p>
				)}

				<CheckboxSwitch
					checked={isSharedProfit}
					onChange={handlePriceTypeChange}
					text={`Set “Shared Profit” for seminar`}
				/>
			</div>

			<div className="col-md-6 col-sm-6 col-12">
				<Controller
					control={control}
					disabled={!isSharedProfit}
					name="sharedProfit"
					render={({ field: { onChange, value }, }) => (
						<SelectOne
							error={errors.sharedProfit?.message}
							label="Shared Profit (Instructor / Academy)"
							labelRowSecondItem={(<TooltipInfo content={explanations.sharedProfit} />)}
							onChange={(option) => onChange(option.value)}
							options={sharedProfitOptions}
							placeholder="Specific Shared Profit"
							value={value}
							disabled={!isSharedProfit}
							wrapperClasses="select--outline select--outline-bg input--lg w-full"
						/>
					)}
				/>
			</div>

			<div className="col-12">
				<hr className="hr" />
			</div>

			<div className="col-md-6 col-sm-6 col-12">
				<Controller
					control={control}
					name="traningStyle"
					render={({ field: { onChange, value } }) => (
						<SelectOne
							error={errors.traningStyle?.message}
							label="Gi or Nogi"
							options={traningStyleOptions}
							onChange={(option) => onChange(option.value)}
							placeholder="Gi or Nogi"
							value={value}
							wrapperClasses="select--outline select--outline-bg input--lg w-full"
						/>
					)}
				/>

			</div>

			<div className="col-md-6 col-sm-6 col-12">
				<Controller
					control={control}
					name="targetAudience"
					render={({ field: { onChange, value } }) => (
						<SelectOne
							error={errors.targetAudience?.message}
							label="Open to"
							onChange={(option) => onChange(option.value)}
							options={targetAudienceOptions}
							placeholder="Select target audience"
							value={value}
							wrapperClasses="select--outline select--outline-bg input--lg w-full"
						/>
					)}
				/>
			</div>

			<Controller
				control={control}
				name="allowUnder18"
				render={({ field: { onChange, value } }) => (
					<CheckboxSwitch
						checked={value}
						onChange={onChange}
						text="Do you allow students under the age of 18 to attend your seminar/camp?"
					/>
				)}
			/>

			<div className="col-12">
				<hr className="hr" />
			</div>

			<div className="col-12">
				<Controller
					control={control}
					name="teachingStyles"
					render={({ field: { onChange, value } }) => (
						<SelectMulty
							error={errors.teachingStyles?.message}
							label={role === USER_ROLE.instructor
								? "What do you want to teach?"
								: "Teaching Style"}
							onChange={(options) => onChange(options.map((option) => option.value))}
							options={teachingStyleOptions}
							placeholder="Add teaching style..."
							value={value}
							wrapperClasses="select--outline select--outline-bg input--lg w-full my-18"
						/>
					)}
				/>
			</div>
			{/* <InviteInstructors
				onChange={(field, data) => setValue("instructors", data)}
				preview={preview}
				onPreviewChange={(field, data) => setPreview((prev) => ({ ...prev, [field]: data }))}
			/> */}
		</div>
	)
}

export default TourInformationTab