const base = '/api/payouts'

export const payouts = (instance) => ({
    async getMyPayouts({
        page = 1,
    }) {
        const { data } = await instance.get(`${base}/my?sort_by=id&sort_as=desc&page=${page}`);

        return data;
    },
});