import React, { useState } from "react";
import Accordeon from "../ui/accordeons/Accordeon";
import Button from "../ui/buttons/Button";
import { useTranslation } from "react-i18next";

const FAQ = ({ pageData }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'faq' });

    const [visibleCount, setVisibleCount] = useState(7);  // Start with 7 questions visible

    const data = new Array(19).fill(undefined)
        .map((_, index) => {
            const title = t(`faq_${index + 1}_title`, { defaultValue: '' }); // Set empty string as fallback
            const description = t(`faq_${index + 1}_descr`, { defaultValue: '' }); // Set empty string as fallback
            return { title, description };
        })
        .filter(faq => faq.title && faq.description);  // Ensure both title and description exist


    const handleLoadMore = () => {
        if (visibleCount >= data.length) {
            setVisibleCount(7); // Reset to the default view (7 items)
        } else {
            setVisibleCount(visibleCount + 5); // Load 5 more items
        }
    };

    const filteredData = data.slice(0, visibleCount);
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <section className="section faq">
            <div className="container">
                <h2 className="heading text-center mb-44">
                    {t('title')}
                </h2>

                {filteredData.map((item, index) => (
                    <Accordeon
                        className="w-full"
                        index={index}
                        isActive={activeIndex === index}
                        key={index}
                        onClick={() => toggleAccordion(index)}
                        onlyOneIsOpen={true}
                        title={item.title}
                    >
                        {item?.content || item?.description}
                    </Accordeon>
                ))}

                <Button
                    className="btn btn--primary btn--md show-more__button"
                    onClick={handleLoadMore}
                >
                    <span className="info">
                        {visibleCount >= data.length ? "Show less" : "Load more"}
                    </span>
                </Button>
            </div>
        </section>
    );
};

export default FAQ;

