import React, { useState, forwardRef } from "react";
import classNames from "classnames";
import Button from "../buttons/Button";

const InputPass = forwardRef(({
    label,
    placeholder,
    forgotPass = true,
    forgotPassClick,
    inputClass,
    formGroupClass,
    value,
    error,
    ...props
}, ref) => {
    const [inputType, setInputType] = useState("password");

    const showPassHandler = () => {
        setInputType((prev) => {
            if (prev === "password") return "text";

            if (prev === "text") return "password";
        });
    };

    return (
        <div className={classNames("form-group", formGroupClass)}>
            {(forgotPass || label) && (
                <div className="label-row">
                    {label && (
                        <label className="label">
                            {label}
                        </label>
                    )}

                    {forgotPass && (
                        <Button
                            className="color-secondary btn-link"
                            onClick={forgotPassClick}
                        >
                            <span className="text--xs">
                                Forgot Password?
                            </span>
                        </Button>
                    )}
                </div>
            )}

            <div className="input-wrapper">
                <Button
                    className="ico"
                    onClick={showPassHandler}
                    type="button"
                >
                    {inputType === "text" && (
                        <span className="btn-ico">
                            <img src={require("../../../assets/img/eye-false.svg").default} />
                        </span>
                    )}

                    {inputType === "password" && (
                        <span className="btn-ico">
                            <img src={require("../../../assets/img/eye-true.svg").default} />
                        </span>
                    )}
                </Button>

                <input
                    className={classNames("input", inputClass, {
                        "input--error": error
                    })}
                    placeholder={placeholder}
                    type={inputType}
                    value={value}
                    ref={ref}
                    {...props}
                />
            </div>

            {error && typeof error === "string" && (
                <p className="error-input">
                    {error}
                </p>
            )}
        </div>
    );
});

export default InputPass;

