import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

function decodeJWT(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(atob(base64).split("")
        .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""));

    return JSON.parse(jsonPayload);
}

export const getUserFetch = createAsyncThunk("/get-user/", async (payload = null, { rejectWithValue }) => {
    const token = localStorage.getItem("authToken");
    const session = decodeJWT(token);

    try {
        let res;

        if (session?.role === "guest") {
            res = await api.guest.getGuestUser(payload);
        } else {
            res = await api.users.getUser(payload);
        }

        if (res.success) {
            return res.data;
        }
    } catch (err) {
        console.log(err);

        return rejectWithValue(err?.response.data);
    }
});
export const getGuestUserFetch = createAsyncThunk("/get-guest-user/", async (payload = null, { rejectWithValue }) => {
    try {
        const res = await api.guest.getGuestUser(payload);

        if (res.success) {
            return res.data;
        }
    } catch (err) {
        console.log(err);

        return rejectWithValue(err?.response.data);
    }
});
export const getInstructorGeneralInfoFetch = createAsyncThunk("/getInstructorGeneralInfoFetch/", async (payload = null, { rejectWithValue }) => {
    try {
        const res = await api.profiles.getInstructorGeneralInfo(payload);

        if (res.success) {
            return res.data;
        }
    } catch (err) {
        console.log(err);

        return rejectWithValue(err?.response.data);
    }
});
export const updateUserFetch = createAsyncThunk("/update-user/", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.users.updateUser(payload);

        if (res.success) {
            return res.data;
        }
    } catch (err) {
        console.log(err);

        return rejectWithValue(err?.response.data);
    }
});


export const logoutFetch = createAsyncThunk("/logoutFetch/", async (payload, { rejectWithValue }) => {
    try {
        const res = await api.auth.logout(payload);

        if (res.success) {
            return res.data;
        }
    } catch (err) {
        console.log(err);

        return rejectWithValue(err?.response.data);
    }
});