import React from 'react';
import { Link } from 'react-router-dom';
import { USER_ROLE } from '../../constants/enums';

const VerifyAlert = ({ role }) => {

    return (
        <div className='verification-alert'>
            <p style={{ margin: 0 }}>
                {role.role === USER_ROLE.student ?
                    'To book private lessons, please ' :
                    'To start planning and applying for seminars, please '}
                <Link to="/settings/my-profile" className='underline' style={{ color: 'white' }}>verify your account</Link> and complete your profile.
            </p>
        </div>
    );
}

export default VerifyAlert;

