import { mixed, object, string } from "yup";
import { birthdaySchema } from "./birthdaySchema";
import { passwordSchema } from "./passwordSchema";
import { phoneSchema } from "./phoneSchema";

export const commonDetailsSchema = object().shape({
    photo: mixed().nullable(),
    fullName: string().required("Full Name is required").matches(/^[A-Za-z]+(\s[A-Za-z]+)+$/, "Full Name must contain Name and Surname").trim(""),
    email: string().email("Incorrect email format").required("Email is required"),
    ...phoneSchema.fields,
    country: string().required("Country is required"),
    belt: string().required("Belt is required"),
});

export const studentSchema = object().shape({
    ...commonDetailsSchema.fields,
    ...birthdaySchema.fields,
    ...passwordSchema.fields
});
