import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PageLoader from '../../../components/ui/PageLoader'
import { getSingleCampFetch } from '../../../redux/thunks/planCampThuncks'
import PlanCampPage from './PlanCampPage'

const EditCampPage = () => {
    const dispatch = useDispatch()
    const { campDetails, isLoading: isSeminarLoading } = useSelector((state) => state.planCamps);

    const { campId } = useParams()

    useEffect(() => {
        dispatch(getSingleCampFetch(campId))
    }, [campId])

    if (isSeminarLoading) {
        return <PageLoader />
    }

    if (!campDetails) {
        return null
    }

    const prepareInitialData = (campDetails) => {
        return {
            seminarMinPrice: campDetails?.seminarMinPrice || null,
            seminarMaxPrice: campDetails?.seminarMaxPrice || null,
            sharedProfit: campDetails?.sharedProfit,
            address: campDetails?.address,
            startAt: campDetails?.startAt,
            endAt: campDetails?.endAt,
            country: campDetails?.country,
            continent: campDetails?.continent,
            specificBelt: campDetails?.specificBelt,
            allowPrivateLessons: campDetails?.allowPrivateLessons || 0,
            traningStyle: campDetails?.traningStyle,
            targetAudience: campDetails?.targetAudience,
            allowUnder18: campDetails?.allowUnder18,
            schedule: campDetails.schedules || null,
            seminarName: campDetails?.seminarName,
            teachingStyles: campDetails?.teachingStyles,
            maxGymCapacity: campDetails?.maxGymCapacity,
            summary: campDetails?.summary,
            languages: campDetails?.languages,
            isPrivate: campDetails?.isPrivate,
            minAttendance: campDetails?.minAttendance,
            minAttendanceRequirement: campDetails?.minAttendanceRequirement,
            minAttendanceDeadline: campDetails?.minAttendanceDeadline,
            minimalPriceTicket: campDetails.minimalPriceTicket,
            instructorPrice: campDetails.instructorPrice || null,
            coverImageName: campDetails?.photos?.find((photo) => photo?.isCover)?.originalname,
            faq: campDetails?.faq,
            name: campDetails?.name,
            photos: campDetails?.photos.map((photo) => ({
                id: photo?.id,
                url: photo?.url
            })),
            videos: campDetails?.videos,
            what_included: campDetails?.what_included?.length
                ? campDetails?.what_included?.map((item) => item.id)
                : [],
            invited_instructors: campDetails?.instructors,
            hotelLink: campDetails?.hotelLink,
            coverImageId: campDetails?.photos?.find((photo) => photo?.isCover)?.id,
        };
    }
    return (
        <PlanCampPage campDetails={campDetails} defaultValues={prepareInitialData(campDetails)} />
    )
}

export default EditCampPage