import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import PageLoader from '../../../components/ui/PageLoader'
import { getisUserLoading, getToken, getUser } from '../../../redux/slices/applicationSlice'
import { studentEmailVerificationFetch } from '../../../redux/thunks/verificationsThunk'

const StudentVerifyEmailPage = () => {
    const user = useSelector(getUser)
    const isUserLoading = useSelector(getisUserLoading)
    const token = useSelector(getToken)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const code = searchParams.get('code')

    const verifyEmail = async () => {
        await dispatch(studentEmailVerificationFetch({
            code
        }))
        navigate('/settings/my-profile')
    }

    useEffect(() => {
        console.log(code, user,);

        if (code && user) {
            verifyEmail()
        }
    }, [code, dispatch, user])

    if (!user && !isUserLoading && !token) {
        <Navigate to={"/"} />
    }

    return (
        <PageLoader />
    )
}

export default StudentVerifyEmailPage