import { format, isValid } from "date-fns";
import { Link } from "react-router-dom";
import Timer from "../../../components/parts/Timer";
import { EVENT_STATUS, USER_ROLE } from "../../../constants/enums";
import { getImageSrc } from "../../../helpers/utils";

const DetailsSection = ({ data }) => {

    const getStartDateTime = () => {
        const dates = data?.seminarDates || data?.schedules?.flat();
        if (!dates?.length) return
        const copy = [...dates]
        copy.sort((a, b) => new Date(a.date) - new Date(b.date));
        return copy[0].date
    }

    const expiryTimestamp = getStartDateTime();

    return (
        <div className="form-item">
            <div className="other-data-columns">
                {data?.status !== EVENT_STATUS.pending && (
                    <div className="other-data-max other-data-col">
                        <div className="ticker-deadline">
                            <div className="deadline-title">
                                <p className="color-text text-sm">
                                    Min. attendance:
                                </p>

                                <div className="amount-stud-block">
                                    {data?.ticketsPurchased >= data.minimumAttendance && (
                                        <span className="ico color-secondary">
                                            <svg
                                                fill="none"
                                                height="19"
                                                viewBox="0 0 18 19"
                                                width="18"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M9 2C4.8645 2 1.5 5.3645 1.5 9.5C1.5 13.6355 4.8645 17 9 17C13.1355 17 16.5 13.6355 16.5 9.5C16.5 5.3645 13.1355 2 9 2ZM9 15.5C5.69175 15.5 3 12.8082 3 9.5C3 6.19175 5.69175 3.5 9 3.5C12.3082 3.5 15 6.19175 15 9.5C15 12.8082 12.3082 15.5 9 15.5Z"
                                                    fill="currentColor"
                                                />

                                                <path
                                                    d="M7.50005 10.6902L5.7758 8.96898L4.7168 10.031L7.50155 12.8097L12.531 7.78023L11.4705 6.71973L7.50005 10.6902Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </span>
                                    )}

                                    <p className="text-sm">
                                        <span>{data?.minimumAttendance || 0}</span>

                                        {" "}

                                        students
                                    </p>
                                </div>
                            </div>
                            {expiryTimestamp && isValid(new Date(expiryTimestamp)) && (
                                <Timer expiryTimestamp={expiryTimestamp} />
                            )}

                            <div className="deadline-footer">
                                <p className="text-sm">
                                    Min. attendance deadline
                                    {" "}
                                    {(!!data?.minAttendanceDeadline && format(data?.minAttendanceDeadline, "d MMM, yyyy"))
                                        ||
                                        (!!getStartDateTime() && format(getStartDateTime(), "d MMM, yyyy"))
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                <div className="other-data-full other-data-col">
                    <div className="other-data--columns">
                        {(data?.status !== EVENT_STATUS.pending || data?.user?.role === USER_ROLE.academy) && (
                            <div className="other-data--col">
                                <h6 className="heading">
                                    Academy:
                                </h6>

                                <div className="seminar-row">
                                    <Link
                                        to={`/academies/${data?.userAcademy.id}`}
                                        className="card-user-block card-user-block--sm"
                                    >
                                        <div className="ico ico--xs">
                                            <img
                                                src={getImageSrc(data?.userAcademy?.academyProfile?.photo, require("../../../assets/img/avatar-placeholder.svg").default, 64)}
                                                alt={data?.userAcademy?.academyProfile?.name}
                                            />
                                        </div>
                                    </Link>

                                    <div className="seminar-row--content">
                                        <p className="seminar-title text-sm">
                                            {data?.userAcademy?.academyProfile?.name}
                                        </p>

                                        <p className="seminar-desk text-sm color-text">
                                            {data?.userAcademy?.generalAcademyInfo?.addressLine}
                                        </p>

                                        <div className="deadline-title">
                                            <p className="color-text text-sm">
                                                Max. capacity:
                                            </p>
                                            <div className="amount-stud-block">
                                                <p className="text-sm">
                                                    <span>
                                                        {data?.maxGymCapacity}
                                                    </span>
                                                    {" "}
                                                    students
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(data?.status !== EVENT_STATUS.pending || (data?.user?.role === USER_ROLE.instructor && data?.instructors?.length)) && (
                            <div className="other-data--col">
                                <h6 className="heading">
                                    Instructors:
                                </h6>
                                <ul className="row-list">
                                    {data?.instructors?.map(item => {
                                        const instructor = item?.user;
                                        if (!instructor?.instructorProfile) {
                                            return null
                                        }
                                        const { instructorProfile } = instructor

                                        return (
                                            <li key={item.id}>
                                                <Link
                                                    to={`/instructors/${instructor.id}`}
                                                    className="card-user-block card-user-block--sm"
                                                >
                                                    <div className="card-user-block__icon">
                                                        <img src={getImageSrc(instructorProfile?.photo)} alt={instructorProfile?.fullName} />
                                                    </div>
                                                    <div className="card-user-block__name">{instructorProfile?.fullName}</div>
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailsSection;
