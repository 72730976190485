import React from 'react'
import { getImageSrc } from '../../../../helpers/utils';
import Fancybox from '../../../../components/ui/Fancybox';

const Photos = ({ data }) => {
    if (!data?.length) return <p>No data</p>
    return (
        <div className="row gutters-form-7">
            {data.map((photo) => (
                <div
                    className="col-6"
                    key={photo?.id}
                >
                    <Fancybox>
                        <a
                            data-fancybox="gallery"
                            href={getImageSrc(photo?.url, null)}
                        >
                            <img
                                className="w-full"
                                src={getImageSrc(photo?.url, null, 256)}
                                style={{ height: 96 }}
                                alt={photo?.originalname}
                            />
                        </a>
                    </Fancybox>
                </div>
            ))}
        </div>
    );
}

export default Photos
