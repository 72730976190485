import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageLoader from '../../components/ui/PageLoader';
import { EVENT_STATUS, EVENT_TYPE } from '../../constants/enums';
import { getSingleCampFetch } from '../../redux/thunks/planCampThuncks';
import BackButton from './components/BackButton';
import BoughtsSetion from './components/BoughtsSetion';
import DetailsSection from './components/DetailsSection';
import OverviewHeader from './components/OverviewHeader';
import PricesSection from './components/PricesSection';
import RequestsSection from './components/RequestsSection';

const CampOverviewPage = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const { campDetails, isLoading } = useSelector((state) => state.planCamps);

	useEffect(() => {
		dispatch(getSingleCampFetch(id));
	}, [dispatch, id]);

	if (isLoading) {
		return <PageLoader />
	}

	if (!campDetails) {
		return (
			<section className="section-settings profile-page">
				<div className="container container--full">
					<h2>Camp not found</h2>
				</div>
			</section>
		)
	}

	return (
		<section className="section-settings profile-page">
			<div className="container container--full">
				<BackButton to="/my-seminars" />
				<div className="settings-row">
					<div className="settings-col">
						<div className="settings-body settings-gap">
							<OverviewHeader data={campDetails} eventType={EVENT_TYPE.camp} />
							<div className="form form-settings">
								{campDetails.status === EVENT_STATUS.active && <PricesSection data={campDetails} />}
								<div className="form-body">
									<div className="form-items-list">
										<DetailsSection data={campDetails} />
										<hr className="hr-horisontal my-42" />
										{campDetails.status === EVENT_STATUS.pending && (
											<RequestsSection event={campDetails} eventId={campDetails.id} eventType={EVENT_TYPE.camp} />
										)}
										{campDetails.status !== EVENT_STATUS.pending && (
											<BoughtsSetion data={campDetails} eventId={campDetails.id} eventType={EVENT_TYPE.seminar} />
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}



export default CampOverviewPage