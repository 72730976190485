import { useState } from "react";
import { useDispatch } from "react-redux";
import DropDown from "../../../../../components/ui/DropDown";
import DatePicker from "../../../../../components/ui/formElements/DatePicker";
import { CardDetails } from "../../components/CardDetails";
import { Step } from "../../components/Step";

import { format } from "date-fns";
import { ReactComponent as DoneIcon } from "../../../../../assets/img/done.svg";
import { EventHelper } from "../../../../../helpers/EventHelper";
import { updateCampFetch } from "../../../../../redux/thunks/planCampThuncks";
import { PLANNING_STATUS } from "../../../../planning/constants/emuns";
import { commonCampDatesSchema } from "../../../../planning/validation/campSchema";
import { checkIsEdited } from "../../utils";



export const LocationStep = ({ data, isEditable }) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState(data)

    const handleSubmit = async (name, value) => {
        setIsLoading(true)
        const schema = commonCampDatesSchema(PLANNING_STATUS.negotiation);
        try {
            await schema[name].validate(value);
            await dispatch(updateCampFetch({
                campId: data.id,
                body: {
                    [name]: value
                }
            })).unwrap();
        } catch (e) {
            console.log(e);
        }
        setIsLoading(false)
    }

    const handleChange = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <>
            <Step
                label="Location & Date"
            >
                <CardDetails
                    isEdited={checkIsEdited(data?.lastEdited, ["address", "location"])}
                    title="Location"
                >
                    <p className="color-text capitalize">
                        {EventHelper.location(data)}
                    </p>
                </CardDetails>

                <CardDetails
                    isEdited={checkIsEdited(data?.lastEdited, ["startAt"])}
                    title="Start Date"
                >
                    <div className="row-group justify-between">
                        <p className="fw-500">
                            {format(new Date(data.startAt), "dd MMM, yyyy")}
                        </p>
                        {!isLoading && isEditable && (
                            <DropDown
                                dropDownListClasses="time-input-menu"
                                labelButton={<button
                                    className="fw-500 text-14 text-normal style-normal color-secondary"
                                >
                                    Change Date
                                </button>}
                                position="left"
                                overflow="visible"
                            >
                                <div
                                    className="schedule-group-item__time-group"
                                >
                                    <div className="time-input-box-wrapper">
                                        <DatePicker
                                            onChange={(date) => handleChange("startAt", date)}
                                            placeholderText="0:00pm"
                                            selected={formData.startAt ? new Date(formData.startAt) : null}

                                        />

                                        <button
                                            className="done-button"
                                            onClick={() => handleSubmit("startAt", format(new Date(formData.startAt), "yyyy-MM-dd"))}
                                        >
                                            <DoneIcon />
                                        </button>
                                    </div>
                                </div>
                            </DropDown>
                        )}
                    </div>
                </CardDetails>

                <CardDetails
                    isEdited={checkIsEdited(data?.lastEdited, ["startAt"])}
                    title="End Date"
                >
                    <div className="row-group justify-between">
                        <p className="fw-500">
                            {format(new Date(data.endAt), "dd MMM, yyyy")}
                        </p>
                        {!isLoading && isEditable && (
                            <DropDown
                                dropDownListClasses="time-input-menu"
                                labelButton={<button
                                    className="fw-500 text-14 text-normal style-normal color-secondary"
                                >
                                    Change Date
                                </button>}
                                position="left"
                                overflow="visible"
                            >
                                <div
                                    className="schedule-group-item__time-group"
                                >
                                    <div className="time-input-box-wrapper">
                                        <DatePicker
                                            onChange={(date) => handleChange("endAt", date)}
                                            placeholderText="0:00pm"
                                            selected={formData.endAt ? new Date(formData.endAt) : null}

                                        />

                                        <button
                                            className="done-button"
                                            onClick={() => handleSubmit("endAt", format(new Date(formData.endAt), "yyyy-MM-dd"))}
                                        >
                                            <DoneIcon />
                                        </button>
                                    </div>
                                </div>
                            </DropDown>
                        )}
                    </div>
                </CardDetails>

            </Step>
        </>
    );
};
