import React from "react";
import { ReactComponent as CheckIco } from "../../../../../assets/img/ico-check.svg";
import { getImageSrc } from "../../../../../helpers/utils";

export const PLConfirmUsers = ({ users, isConfirmedInstructor, isConfirmedStudent }) => {
    if (!users?.length) return

    return (
        <div className="flex gap-8 flex-col">
            <div className="border-bottom w-full" />

            <div className="pt-24 flex flex-col gap-20 fs-14">
                {
                    users.map(user => (
                        <div key={user.id} className="flex justify-between pb-24">
                            <div className="flex gap-10 items-center">
                                <div className="private-lesson-user">
                                    <img
                                        alt={user.fullName}
                                        className="ico ico--sm"
                                        src={getImageSrc(user.photo)}
                                    />
                                </div>

                                <p className="fw-500">
                                    {user.fullName}
                                </p>
                            </div>

                            {((user.role === "student" && isConfirmedStudent) || (user.role === "instructor" && isConfirmedInstructor))
                                ? (
                                    <div className="fw-500 flex gap-10 items-center private-lesson-confirmed">
                                        <div className="ico">
                                            <CheckIco />
                                        </div>
                                        Confirmed
                                    </div>
                                )
                                : (
                                    <div className="fw-500 flex gap-10 items-center ">
                                        Not Confirmed
                                    </div>
                                )
                            }


                        </div>
                    ))
                }
            </div>

        </div>
    );
};
