import React from 'react'
import { Outlet } from 'react-router-dom'
import ErrorBoundary from '../components/ErrorBoundary'
import AuthProvider from '../components/modals/singUpModals/AuthProvider'

const AppLayout = () => {
    return (
        <ErrorBoundary>
            <Outlet />
            <AuthProvider />
        </ErrorBoundary>
    )
}

export default AppLayout