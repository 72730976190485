import { mixed, object, string } from "yup";
import { passwordSchema } from "./passwordSchema";
import { phoneSchema } from "./phoneSchema";


export const selectionSchema = object().shape({
    name: string().required("Academy name is required"),
});

export const commonDetailsSchema = object().shape({
    name: string().required("Academy name is required"),
    email: string().email("Incorrect email format").required("Email is required"),
    ...phoneSchema.fields,
    photo: mixed().nullable(),
});

export const additionalDetailsSchema = object().shape({
    personInCharge: string().required("Person in charge is required"),
    generalAcademyInfo: object().shape({
        academyOwner: string().required("Academy owner is required"),
    }),
    about: string().nullable(),
    website: string().nullable().url("Incorrect website format"),
    instagram: string().nullable().url("Incorrect instagram format"),
    facebook: string().nullable().url("Incorrect facebook format"),
});

export const locationSchema = object().shape({
    generalAcademyInfo: object().shape({
        addressLine: string().required("Address is required"),
        country: string().required("Country is required"),
        city: string().required("City is required"),
        state: string().required("State is required"),
        zip: string().required("Zip is required"),
        latitude: string().required("Latitude is required"),
        longitude: string().required("Longitude is required"),
    }).required(),
});

export const academySchema = object().shape({
    ...commonDetailsSchema.fields,
    ...additionalDetailsSchema.fields,
    ...locationSchema.fields,
    ...passwordSchema.fields
});

