import classNames from "classnames";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import { useSearchParams } from "react-router-dom";
import api from "../../api";
import ConfirmComplateModal from "../../components/modals/ConfirmComplateModal";
import CardSeminar from "../../components/parts/cards/CardSeminar";
import { PaginationTemplate } from "../../components/parts/PaginationTemplate";
import Button from "../../components/ui/buttons/Button";
import PageLoader from "../../components/ui/PageLoader";
import { USER_ROLE } from "../../constants/enums";
import { useTabs } from "../../hooks/useTabs";
import { getUser } from "../../redux/slices/applicationSlice";
import { getOnlyMyCampsFetch } from "../../redux/thunks/planCampThuncks";
import { getSeminarsMy } from "../../redux/thunks/seminarsThunks";
import { getAllToursFetch, getMyToursFetch } from "../../redux/thunks/toursThunks";


export const MySeminarsInstructorsAndAcademies = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams({ event_type: "seminars" });
    const { seminars, isLoading, pagination } = useSelector((state) => state.seminars);
    const { camps, isLoading: isCampsLoading, pagination: paginationCamps } = useSelector((state) => state.planCamps);
    const { tours, isLoading: isToursLoading, pagination: paginationTours } = useSelector((state) => state.tours);
    const [confirmComplateModal, setConfirmComplateModal] = useState(false)
    const [counts, setCounts] = useState({
        "upcomingSeminarsCount": 0,
        "upcomingPlancampsCount": 0,
        "plannedSeminars": 0,
        "plannedPlancamps": 0,
        "inProgressPlancampsCount": 0,
        "inProgressSeminarsCount": 0,
        "previousSeminars": 0,
        "previousPlancamps": 0,
        "newRequestsSeminarCount": 0,
        "newRequestsPlancampCount": 0,
        "seminarRequestsCount": 0,
        "plancampRequestsCount": 0,
        "countMyOwnEvents": 0,
        "upcomingToursCount": 0,
        "inProgressToursCount": 0,
        "plannedTours": 0,
        "previousTours": 0
    });

    const getCounts = async () => {
        const res = await api.requests.getCounts()
        if (res?.success) {
            setCounts(res.data)
        }
    }

    const tabs = [
        {
            label: "Upcoming Seminars / camps",
            id: "active",
            count: counts.upcomingPlancampsCount + counts.upcomingSeminarsCount + counts.upcomingToursCount + counts.inProgressToursCount
        },
        // {
        //     label: "Seminars / camps in progress",
        //     id: "in_progress",
        //     count: counts.inProgressPlancampsCount + counts.inProgressSeminarsCount
        // },
        {
            label: "Pending Seminars / camps",
            id: "pending",
            count: counts.plannedSeminars + counts.plannedPlancamps + counts.plannedTours
        },
        {
            label: "Previous Seminars / camps",
            id: "finished,archived,inactive,cancelled",
            count: counts.previousSeminars + counts.previousPlancamps + counts.previousTours,
            // classNames: "opacity-card-50"
        },
    ]

    const { activeTab, setActiveTab } = useTabs(tabs, "statuses")

    const handleChangeTab = (id) => {
        setActiveTab(id)
        setSearchParams(prev => {
            prev.set("page", 1)
            return prev
        })
    }

    const eventType = searchParams.get("event_type");

    useEffect(() => {
        // const tabsMap = {}
        getCounts()

        const query = new URLSearchParams({
            statuses: searchParams.get("statuses"),
            page: searchParams.get("page") || 1,
            ...(eventType === "tours" ? { my: true } : {})
        }).toString()

        if (!searchParams.get("statuses")) return;

        if (eventType === "seminars") {
            dispatch(getSeminarsMy({ query }));
        }
        if (eventType === "camps") {
            dispatch(getOnlyMyCampsFetch({ query }));
        }
        if (eventType === "tours") {
            dispatch(getAllToursFetch(query));
        }
    }, [searchParams]);


    if (isLoading || isCampsLoading) return <PageLoader />;


    const activeTabClasses = tabs.find(tab => tab.id === activeTab)?.classNames || ""

    return (
        <div className="tab-seminars tabs">
            <div className="tabs__header flex items-center border-bottom gap-24 pb-24 pt-24 mb-24">
                <div className="tabs__nav row-group gap-24 flex-auto">
                    {
                        tabs.map(tab => (
                            <button
                                key={tab.id}
                                className={classNames("tab-link", { "active-tab": activeTab === tab.id })}
                                onClick={() => handleChangeTab(tab.id)}
                            >
                                <span className="tab-link__text">
                                    {tab.label}
                                </span>

                                <span className="tab-link__number">
                                    {tab.count}
                                </span>
                            </button>
                        ))
                    }
                </div>
            </div>
            <div className="tab active-tab">
                <EventTypeSwitcher activeTab={activeTab} />
                {
                    eventType === "seminars" && (
                        <>
                            {
                                seminars?.length
                                    ? (
                                        <div className={classNames("row gx-24 gy-70", activeTabClasses)}>
                                            {
                                                seminars.map((seminar) => (
                                                    <div
                                                        className={"col-lg-4"}
                                                        key={seminar?.id}
                                                    >
                                                        <CardSeminar
                                                            data={seminar}
                                                            linkPrefix="/my-seminars"
                                                        />
                                                    </div>
                                                ))
                                            }
                                            <PaginationTemplate {...pagination} />
                                        </div>
                                    )
                                    : (
                                        <h2>
                                            There is no seminars
                                        </h2>
                                    )
                            }
                        </>
                    )
                }
                {
                    eventType === "camps" && (
                        <>
                            {
                                camps?.length
                                    ? (
                                        <div className={classNames("row gx-24 gy-70", activeTabClasses)}>
                                            {
                                                camps.map((seminar) => (
                                                    <div
                                                        className={"col-lg-4"}
                                                        key={seminar?.id}
                                                    >
                                                        <CardSeminar
                                                            data={seminar}
                                                            linkPrefix="/my-seminars/camps"
                                                        // actions={seminar.status === "finished" && [
                                                        //     {
                                                        //         label: "Completion Confirmation",
                                                        //         action: () => setConfirmComplateModal(seminar)
                                                        //     }
                                                        // ]}
                                                        />
                                                    </div>
                                                ))
                                            }
                                            <PaginationTemplate {...paginationCamps} />
                                        </div>
                                    )
                                    : (
                                        <h2>
                                            There is no camps
                                        </h2>
                                    )
                            }
                        </>
                    )
                }
                {
                    eventType === "tours" && (
                        <>
                            {
                                tours?.length
                                    ? (
                                        <div className={classNames("row gx-24 gy-70", activeTabClasses)}>
                                            {
                                                tours.map((tour) => (
                                                    <div
                                                        className={"col-lg-4"}
                                                        key={tour?.id}
                                                    >
                                                        <CardSeminar
                                                            data={tour}
                                                            linkPrefix={`/my-seminars/tour-overview`}
                                                        // actions={seminar.status === "finished" && [
                                                        //     {
                                                        //         label: "Completion Confirmation",
                                                        //         action: () => setConfirmComplateModal(seminar)
                                                        //     }
                                                        // ]}
                                                        />
                                                    </div>
                                                ))
                                            }
                                            <PaginationTemplate {...paginationCamps} />
                                        </div>
                                    )
                                    : (
                                        <h2>
                                            There is no camps
                                        </h2>
                                    )
                            }
                        </>
                    )
                }

            </div>
            {/* {
                confirmComplateModal && (
                    <ConfirmComplateModal open={confirmComplateModal} data={confirmComplateModal} handleClose={() => setConfirmComplateModal(null)} />
                )
            } */}
        </div>
    );
};


function EventTypeSwitcher({ activeTab }) {
    const [searchParams, setSearchParams] = useSearchParams({ event_type: "seminars" })
    const user = useSelector(getUser)
    const types = [
        {
            label: "Seminars",
            value: "seminars",
        },
        {
            label: "Camps",
            value: "camps",
        },
        {
            label: "Tours",
            value: "tours",
        },
    ]
    const handleChangeTab = (type) => {
        setSearchParams(prev => {
            prev.set("event_type", type)
            prev.set("page", 1)
            return prev
        })
    }

    return (
        <div className="flex items-center gap-24 mb-24 flex-auto flex-wrap select-checkers-wrapper">
            <div className="select-checkers">
                {types.map((item, index) => {
                    if ((user?.role !== USER_ROLE.instructor || activeTab !== 'pending') && item.value === "tours") {
                        return null
                    }
                    return (
                        <Button
                            onClick={() => handleChangeTab(item.value)}
                            key={index}
                            className={
                                classNames('btn btn--md radius',
                                    { 'btn--default': item.value === searchParams.get("event_type") })
                            }
                        >
                            <span>{item.label}</span>
                        </Button>
                    )
                })}

            </div>
        </div>
    )
}