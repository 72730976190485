import classNames from 'classnames';
import { format, isValid } from 'date-fns';
import { ReactComponent as CheckIcon } from "../../../../../assets/img/ico-check.svg";
import { NOT_SPECIFIED, what_includes } from '../../../../../constants';
import { getValueOrDefault, normalizeTime } from '../../../../../helpers/utils';
import { CardDetails } from '../../components/CardDetails';
import Photos from '../../components/Photos';
import { Step } from '../../components/Step';
import Videos from '../../components/Videos';
import { checkIsEdited } from '../../utils';


export const DesignStep = ({ data }) => {
    return (
        <Step label="Design & Text">
            <Photos data={data?.photos} />

            <CardDetails
                isEdited={checkIsEdited(data?.lastEdited, ["name"])}
                title="Camp Name"
            >
                <p className="color-text">
                    {data?.name || NOT_SPECIFIED}
                </p>
            </CardDetails>

            <CardDetails
                isEdited={data?.lastEdited?.includes("summary")}
                title="Summary"
            >
                <p className="color-text">
                    {data?.summary || NOT_SPECIFIED}
                </p>
            </CardDetails>

            <CardDetails
                isEdited={checkIsEdited(data?.lastEdited, ["videos"])}
                title="Camp Video"
            >
                <Videos data={data} />
            </CardDetails>

            <CardDetails
                isEdited={data?.lastEdited?.includes("hotelLink")}
                title="Hotel Link"
            >
                {data.hotelLink?.length && data.hotelLink.map((item, index) => (
                    <p
                        key={index}
                        className="color-text"
                    >
                        {item}
                    </p>
                ))}
            </CardDetails>

            <CardDetails
                isEdited={data?.lastEdited?.includes("schedules")}
                title="Camp Schedules"
            >
                {data?.schedules?.length && (
                    <div className="col-group gap-24">
                        {data?.schedules.map((item, index, arr) => (
                            <div
                                className={classNames("col-group gap-16")}
                                key={index}
                            >
                                <div className="row-group gap--xs">
                                    <span className="camp-schedule__number-day camp-schedule__number-day--small">
                                        Day{index + 1}
                                    </span>

                                    <p className="fw-500">
                                        {item?.date?.[0] && isValid(new Date(item?.date?.[0])) && format(item?.date?.[0], "MM dd")}
                                    </p>
                                </div>

                                {item?.length && item.map((it, index) => (
                                    <div key={index} className="col-group gap-16">
                                        <div className="row-group justify-between gap--xs">
                                            <p className="color-text fw-500 flex-auto">
                                                {getValueOrDefault(it?.name)}
                                            </p>
                                            <p className="color-text">
                                                Start:
                                                {" "}
                                                <b>
                                                    {getValueOrDefault(it?.start, v => normalizeTime(v))}
                                                </b>
                                            </p>
                                            <p className="color-text">
                                                End:
                                                {" "}
                                                <b>
                                                    {getValueOrDefault(it?.end, v => normalizeTime(v))}
                                                </b>
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}
            </CardDetails>

            <CardDetails
                isEdited={data?.lastEdited?.includes("whatIncludes")}
                title="Whats Included"
            >
                {data?.whatIncludes?.length && (
                    <div className="col-group gap-16">
                        {what_includes.map((item, index) => (
                            <div
                                key={index}
                                className="row-group justify-between"
                            >
                                <p className="row-group gap--xs">
                                    <img
                                        className="ico ico-16"
                                        src={item.imgSrc}
                                        alt="ico"
                                    />

                                    <span className="fw-500">
                                        {item.label}
                                    </span>
                                </p>

                                {
                                    data.whatIncludes.find((inc) => inc.id === item.id) && (
                                        <span className="ico color-secondary">
                                            <CheckIcon />
                                        </span>
                                    )}
                            </div>
                        ))}
                    </div>
                )}
            </CardDetails>

            <h4 className="text-14 italic">
                Camp FAQ
            </h4>

            {data?.faq?.length
                ? data?.faq.map((item, index, arr) => (
                    <CardDetails
                        key={index}
                        title={item.question}
                    >
                        <p className="color-text">
                            {item.answer}
                        </p>
                    </CardDetails>
                ))
                : (
                    <CardDetails>
                        <p className="color-text mx-auto">
                            No camp FAQ
                        </p>
                    </CardDetails >
                )}
        </Step>
    )
};
