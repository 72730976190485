import React from 'react';

import { ReactComponent as AddPhotoIcon } from "../../../assets/img/add-photo-icon.svg";
import { getImageSrc } from '../../../helpers/utils';

const UploadPhotoInput = ({ onChange, value }) => {
    return (
        <label className="form-group input--file">
            <input
                accept=".jpg, .jpeg, .png, .gif, .bmp, .tif, .tiff"
                id="fileInput"
                type="file"
                onChange={(e) => onChange(e.target.files[0])}
            />
            <div className="preview overflow-hidden">
                {value ? (
                    <img src={getImageSrc(value)} alt="Uploaded" />
                ) : (
                    <span className="ico ico--sm">
                        <AddPhotoIcon />
                    </span>
                )}
            </div>
            <p className="label">Add a photo</p>
        </label>
    );
};

export default UploadPhotoInput;
