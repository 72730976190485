import { format } from 'date-fns';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../api';
import TooltipInfo from '../../../components/parts/TooltipInfo';
import Button from '../../../components/ui/buttons/Button';
import { EVENT_TYPE } from '../../../constants/enums';
import { EventHelper } from '../../../helpers/EventHelper';
import { getImageSrc, getValueOrDefault } from '../../../helpers/utils';
import { getUser } from '../../../redux/slices/applicationSlice';
import {acceptInstructorCampRequestFetch, rejectCampRequestFetch} from '../../../redux/thunks/planCampThuncks';
import {acceptInstructorRequestFetch, cancelSeminarRequestFetch} from '../../../redux/thunks/seminarsThunks';

const RequestItem = ({ data, update, isHosting }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const me = useSelector(getUser);
    const isSeminar = data.object_type === EVENT_TYPE.seminar;
    const isCamp = data.object_type === EVENT_TYPE.camp;

    const handleApprove = async () => {
        const requestId = data.id;
        if (!requestId) return;


        if (isHosting) {
            const res = await api.requests.acceptHostingRequests({ requestId });
            const chatId = res?.data?.chatId || res?.data?.chat?.id || res?.chat?.id;

            if (chatId) {
                navigate(`/chat/${chatId}`);
            }
        } else {
            const res = isSeminar
                ? await dispatch(acceptInstructorRequestFetch({ requestInstructorId: requestId })).unwrap()
                : await dispatch(acceptInstructorCampRequestFetch({ instructorId: requestId })).unwrap();

            const chatId = res?.data?.chatId || res?.data?.chat?.id || res?.chat?.id;

            if (chatId) {
                navigate(`/chat/${chatId}`);
            }

        }
        update && update();
    };

    const handleCancelRequest = async () => {
        const requestId = data?.id;
        if (!requestId) return;

        if (isHosting) {
            const res = await api.requests.declineHostingRequests({ requestId });
            if (res?.success) {
                toast.success(res?.message)
            }
        } else {
            if (isSeminar) {
                await dispatch(cancelSeminarRequestFetch({ requestId })).unwrap();
            } else if (isCamp) {
                await dispatch(rejectCampRequestFetch({ requestId })).unwrap();
            }
        }
        update && update();
    };
    const user = data.owner;
    const inviteMessage = data.inviteMessage;
    const isMy = data?.owner?.id === me?.id;
    const event = data.seminar || data.plancamp;
    const hrefPrefix = event?.userAcademy
        ? "planed-by-academy"
        : "planed-by-insctructor";
    if (!event) return null
    return (
        <div className="request-item">
            <div className="request-item__user">
                <div className="ico ico--md rounded-full img-wrapper">
                    <img
                        alt={user?.fullName || user?.name}
                        src={getImageSrc(user?.photo)}
                    />
                </div>

                <div
                    className="col-group gap-4"
                    style={{ width: 224 }}
                >
                    <h6 className="heading truncate flex-auto">
                        {event?.name || event?.seminarName}
                    </h6>
                    <TooltipInfo content={inviteMessage}>
                        <p className="color-text line-clamp-2 flex-auto">
                            {isMy ? event?.summary : inviteMessage}
                        </p>
                    </TooltipInfo>
                </div>
                {!data?.isReaded && !isMy && <span className="badge badge--primary">New</span>}
            </div>

            <div className="request-item__details">
                <div className="col-group gap--xs">
                    <span className="label">
                        Price Range
                    </span>

                    <p className="fw-500 text--sm">
                        {EventHelper.priceRange(event)}
                    </p>
                </div>

                <div className="col-group gap--xs">
                    <span className="label">
                        teaching
                    </span>

                    <p className="fw-500 text--sm">
                        {EventHelper.style(event)?.title}
                    </p>
                </div>

                <div className="col-group gap--xs">
                    <span className="label">
                        Date
                    </span>

                    <p className="fw-500 text--sm">
                        {getValueOrDefault(EventHelper?.startDate(event), v => format(v, "dd MMM, yyyy"))}
                    </p>
                </div>
            </div>

            <div className="request-item__action">
                <Link
                    className="btn btn--sm"
                    to={`/${hrefPrefix}/${event?.id}`}
                >
                    Show Details
                </Link>

                {!isMy && (
                    <Button
                        className="btn btn--sm btn--primary"
                        onClick={handleApprove}
                    >
                        Start Discussion
                    </Button>
                )}

                <Button
                    className="btn btn--sm btn--default"
                    onClick={handleCancelRequest}
                >
                    {isMy ? "Cancel" : "Decline"}
                </Button>
            </div>
        </div>
    );
}

export default RequestItem
