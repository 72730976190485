import { format } from "date-fns";
import { useEffect, useState } from "react";
import api from "../../api";
import { PAYMENT_STATUS } from "../../constants/enums";
import { paymentStatusMap, ticketTypeMap } from "../../constants/mappings";
import { getImageSrc, getTotlaInvoicePrice } from "../../helpers/utils";
import Loader from "../ui/Loader";
import ModalTemplate from "./ModalTemplate";

const InvoiceModal = ({ open, handleClose, invoiceId }) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getMyInvoice = async () => {
        const res = await api.invoices.getMyInvoice(invoiceId);
        const mock = {
            "id": 75,
            "productType": "seminar_ticket",
            "productId": 174,
            "price": 10,
            "fee": 0.5,
            "status": "verified",
            "payload": {},
            "createdAt": "2024-12-30T08:47:25.000Z",
            "invoice": "in_1QbeeNLbpvHSYVhLmgpqjYO2",
            "vendor": {
                "id": 1,
                "photo": "/uploads/6710dab6-e0c8-4cf7-b878-0f48cad74b39_1721813594969_.png",
                "fullName": "Dosekor Academy"
            }
        }
        setData(mock);
        if (res?.data) {
            setData(res.data);
        }
        setIsLoading(false);
    }
    useEffect(() => {
        if (!invoiceId) return
        getMyInvoice()
    }, [invoiceId])


    if (isLoading) {
        return (
            <ModalTemplate
                modalSize="modal__dialog--xl"
                onClick={handleClose}
                open={open}
            >
                <div className="modal__body">
                    <Loader />
                </div>
            </ModalTemplate>
        )
    }

    if (!data) {
        return (
            <ModalTemplate
                modalSize="modal__dialog--xl"
                onClick={handleClose}
                open={open}
            >
                <div className="modal__body">
                    <h4 className="heading">Invoice not found</h4>
                </div>
            </ModalTemplate>
        );
    }

    return (
        <ModalTemplate
            modalSize="modal__dialog--xl"
            onClick={handleClose}
            open={open}
        >
            <div className="modal__body">
                <h4 className="heading pb-24 border-bottom">Invoice #{data.id}</h4>
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-left fw-500">Date</th>
                            <th className="text-left fw-500">ID</th>
                            <th className="text-left fw-500">Amount</th>
                            <th className="text-left fw-500">Vendor</th>
                            <th className="text-left fw-500">Type</th>
                            <th className="text-left fw-500">Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ width: "0px" }}>
                                <p className="font-500">
                                    {format(data.createdAt, 'dd.MM.yyyy')}
                                </p>
                            </td>
                            <td>
                                <p className="color-text">
                                    Invoice #{data.id}
                                </p>
                            </td>
                            <td>
                                <p className="font-500">
                                    ${getTotlaInvoicePrice(data.price, data.fee, data.quantity)}
                                </p>
                            </td>
                            <td>
                                <div className="request-item__user row-group">
                                    <div className="ico ico--sm rounded-full img-wrapper">
                                        <img
                                            alt={data.vendor?.fullName || data.vendor?.name}
                                            src={getImageSrc(data.vendor?.photo)}
                                        />
                                    </div>

                                    <div
                                        className="col-group gap-4 truncate"
                                        style={{ width: 124 }}
                                    >
                                        {data.vendor.fullName}
                                    </div>
                                </div>
                            </td>
                            <td>
                                {ticketTypeMap[data.productType]}
                            </td>
                            <td>
                                {paymentStatusMap[data.status]}
                            </td>
                            <td className="--sticky-end-table">
                                <div className="table-actions justify-end">
                                    {data.status === PAYMENT_STATUS.verified && (
                                        <button
                                            onClick={async () => {
                                                const response = await api.invoices.getDownloadLink(data.id);
                                                if (!response.success) return

                                                const link = document.createElement('a');

                                                link.href = response.data.invoice_pdf;
                                                link.download = 'invoice.pdf';
                                                link.click();
                                            }}
                                            type="button"
                                            className="btn--square btn--xs"
                                        >
                                            <span className="ico">
                                                <img src={require("../../assets/img/ico-download.svg").default} alt="Download Icon" />
                                            </span>
                                        </button>
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ModalTemplate>
    );
};



export default InvoiceModal;
