import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CancelEventModal from "../../../components/modals/CancelEventModal";
import ConfirmComplateModal from "../../../components/modals/ConfirmComplateModal";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import Button from "../../../components/ui/buttons/Button";
import { EVENT_STATUS, EVENT_STEP, EVENT_TYPE } from "../../../constants/enums";
import { EventHelper } from "../../../helpers/EventHelper";
import { getUser } from "../../../redux/slices/applicationSlice";
import SemianrActionsDropdown from "../../events/parts/SemianrActionsDropdown";

const OverviewHeader = ({ data, eventType }) => {
    const [cancelEventModalOpen, setCancelEventModalOpen] = useState(false)
    const [modalConfirmOpen, setModalConfirmOpen] = useState(null)
    const [confirmComplateModal, setConfirmComplateModal] = useState(false)
    const user = useSelector(getUser)
    const trainingStyle = EventHelper.style(data);
    const isActive = data?.status === EVENT_STATUS.active

    const editLink = `/edit-${eventType === EVENT_TYPE.camp
        ? "camp" : "seminar"}/null/${data.id}/null?${isActive ? `edit=${EVENT_STEP.design}` : ""}`


    if (!data) {
        return null
    }

    const isDeadline = data.eventStartStatus === "minimum_attendance_not_reached";
    const isWaitProfit = data.eventStartStatus === "waiting_confirm_by_profit"

    const releases = data?.releases || []
    const isReleased = data?.status === "finished" && releases.find(item => item?.userId === user?.id);

    return (
        <>
            <div className="sidebar-headers">
                <div className="sidebar-header heading-row">
                    <div className="row-group gap--md">
                        <h3 className="heading">
                            {data?.seminarName || data?.name}
                        </h3>
                        {(isDeadline || isWaitProfit) && (
                            <span className="color-white px-3 py-2 ttu italic bg-danger radius-10 fw-500">Minimum attendance is not met</span>
                        )}
                        {isReleased && (
                            <span className="color-white px-3 py-2 ttu italic bg-secondary radius-10 fw-500">Accepted</span>
                        )}
                    </div>

                    <div className="row-group gap--md">
                        {data.status === "finished" && !isReleased && (
                            <Button
                                className="btn btn--sm btn--primary"
                                onClick={() => setConfirmComplateModal(data)}
                            >
                                Completion Confirmation
                            </Button>
                        )}
                        <Link
                            to={editLink}
                            className="btn  btn--md radius"
                        >
                            <span>
                                Edit
                            </span>
                        </Link>
                        <SemianrActionsDropdown event={data} eventType={eventType} />

                    </div>
                </div>

                <div className="sidebar-header heading-row">
                    <div className="options">
                        {data?.status === EVENT_STATUS.pending
                            ? (
                                <>
                                    {(data?.seminarMinPrice) && (
                                        <div className="option">
                                            <div className="price-group">
                                                <span className="color-grey mr-8 text-sm">
                                                    from

                                                    {" "}
                                                </span>

                                                <span className="price-group__price">
                                                    ${data?.seminarMinPrice}
                                                </span>
                                            </div>
                                        </div>
                                    )}

                                    <div className="option">
                                        <p className="text-sm font-500">
                                            Planned Seminar
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <div className="option">
                                    <div className="price-group">
                                        <span className="price-group__price">
                                            ${data.minimalPriceTicket}

                                            {" "}
                                        </span>

                                        <span className="color-grey mr-8 text-sm">
                                            / ticket
                                        </span>

                                        <span className="price-group__amount">
                                            {data?.maxGymCapacity - data?.ticketsPurchased} left
                                        </span>
                                    </div>
                                </div>
                            )}

                        <div className="option">
                            <span className="card-seminar__gi">
                                <span className="ico">
                                    {trainingStyle?.icon}
                                </span>

                                <span>
                                    {trainingStyle?.title}
                                </span>
                            </span>

                            <ul className="list-point-row">
                                {data?.seminarDates && (
                                    <li>
                                        <p className="text-sm">
                                            {EventHelper.dateRange(data)}
                                        </p>
                                    </li>
                                )}

                                {data?.teachingStyles && (
                                    <li>
                                        <p className="text-sm">
                                            {EventHelper.teaching(data)}
                                        </p>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                {cancelEventModalOpen && (
                    <CancelEventModal
                        open={cancelEventModalOpen}
                        handleClose={() => setCancelEventModalOpen(false)}
                        data={data}
                    />
                )}
            </div>
            {modalConfirmOpen && (
                <ConfirmModal
                    open={modalConfirmOpen}
                    onClose={() => setModalConfirmOpen(null)}
                    {...modalConfirmOpen}
                />
            )}
            {confirmComplateModal && (
                <ConfirmComplateModal
                    open={confirmComplateModal}
                    data={confirmComplateModal}
                    handleClose={() => setConfirmComplateModal(null)}
                />
            )}
        </>
    );
};

export default OverviewHeader;
