import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    useNavigate, useParams
} from "react-router-dom";
import DiscussionHelperModal from "../../components/modals/DiscussionHelperModal";
import PageLoader from "../../components/ui/PageLoader";
import { EVENT_STATUS } from "../../constants/enums";
import { clearCurrentChatData } from "../../redux/slices/chatsSlice";
import {
    getAllChatsFetch, getChatByIdFetch, getChatMessagesFetch
} from "../../redux/thunks/chatsThunks";
import { AllChats } from "./parts/AllChats";
import { CurrentChat } from "./parts/CurrentChat";

export const Chat = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isDiscussionHelperModalOpen, setIsDiscussionHelperModalOpen] = useState(true);

    const { chatId } = useParams();
    const {
        isLoading: isChatsLoading,
        isMessagesLoading,
        currentChatData,
        isCurrentChatDataLoading,
        messages
    } = useSelector((state) => state.chats);

    useEffect(() => {
        if (!chatId) return;

        dispatch(getChatMessagesFetch({ chatId }));
        dispatch(getChatByIdFetch({ chatId }));
    }, [chatId, dispatch, navigate]);

    useEffect(() => {
        dispatch(getAllChatsFetch());
        return () => {
            dispatch(clearCurrentChatData());
        };
    }, [dispatch]);

    if (isChatsLoading) return <PageLoader />;

    const hasUserMesssage = messages?.some((msg) => !msg.isSystemMessage);

    const showDiscussionHelperModal = chatId &&
        currentChatData &&
        isDiscussionHelperModalOpen &&
        !isMessagesLoading &&
        messages &&
        !currentChatData?.academyAccept &&
        currentChatData?.seminar?.status === EVENT_STATUS.pending &&
        !isCurrentChatDataLoading &&
        !hasUserMesssage

    return (
        <div className="chat-page">
            <AllChats />

            <CurrentChat />

            {showDiscussionHelperModal && (
                <DiscussionHelperModal
                    handleClose={() => setIsDiscussionHelperModalOpen(false)}
                    open={isDiscussionHelperModalOpen}
                />
            )}
        </div>
    );
};
