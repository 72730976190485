import classNames from 'classnames'
import React from 'react'
import Button from '../../../components/ui/buttons/Button'
import { useTabs } from '../../../hooks/useTabs'

const OwnerTypeSwitcher = () => {
    const tabs = [
        {
            label: "Requests",
            id: 'incoming',
        },
        {
            label: "My Requests",
            id: 'my',
        },
    ]
    const { activeTab, setActiveTab } = useTabs(tabs, "requestType")

    return (
        <div className="flex items-center gap-24 mb-24 flex-auto flex-wrap select-checkers-wrapper">
            <div className="select-checkers">
                {tabs.map((item, index) => (
                    <Button
                        onClick={() => setActiveTab(item.id)}
                        key={index}
                        className={
                            classNames('btn btn--md radius btn--tab', {
                                'active': item.id === activeTab
                            })
                        }
                    >
                        <span>{item.label}</span>
                    </Button>
                ))}

            </div>
        </div>
    )
}

export default OwnerTypeSwitcher