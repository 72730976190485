import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import InstructorSeminarCard from "../../../components/parts/cards/InstructorSeminarCard";

const SinglePageInstructorsBlock = ({ data = [] }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
    const bio = data?.filter((item) => item?.user?.instructorProfile?.bio)
    return (
        <>
            <div
                className={classNames("border-bottom pb-42 mb-42", {
                    "d-none": !data?.length
                })}
            >
                <h4 className="heading mb-32">
                    {t('instructors')}
                </h4>
                {data?.length
                    ? (
                        <ul className="row gx-32 gy-32">
                            {data.map((item, index) => (
                                <li
                                    key={index}
                                    className="col-lg-6"
                                >
                                    <InstructorSeminarCard data={item} />
                                </li>
                            ))}
                        </ul>
                    )
                    : (
                        <h4>
                            {t('no_data')}
                        </h4>
                    )}
            </div>

            {!!bio?.length && bio?.map((item, index) => (
                <React.Fragment key={index}>
                    <h4 className="heading mb-32">
                        About {item?.user?.instructorProfile?.fullName}
                    </h4>

                    <p className="text-description mb-42">
                        {item?.user?.instructorProfile?.bio}
                    </p>
                </React.Fragment>
            ))}

        </>
    );
};

export default SinglePageInstructorsBlock;
