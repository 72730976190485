import { Country } from 'country-state-city';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import api from '../api';
import { ReactComponent as LocationIcon } from "../assets/img/icon-decor-city.svg";
import Loader from '../components/ui/Loader';
import PageLoader from '../components/ui/PageLoader';
import { EVENT_TYPE } from '../constants/enums';
import { getDateRange, getImageSrc, getTrainingStyle } from '../helpers/utils';
import { getSingleCampFetch } from '../redux/thunks/planCampThuncks';
import { getSeminarsById } from '../redux/thunks/seminarsThunks';


const TicketPage = () => {
    const { hash } = useParams()
    const [tickets, setTickets] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const getTickets = async () => {
        try {
            setIsLoading(true)
            const res = await api.tickets.getTicketByPublicHash(hash)

            if (res?.success) {
                setTickets(res.data)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }


    useEffect(() => {
        getTickets()
    }, [])


    if (isLoading) {
        return <PageLoader />
    }

    if (!tickets?.length) {
        return <h3 className="heading pt-28">There is no tickets yet</h3>
    }


    return (
        <div className='section'>
            <div className="container">
                {tickets.map(ticket => (
                    <Ticket key={ticket.id} ticket={ticket} />
                ))}
            </div>
        </div>
    )
}


function Ticket({ ticket }) {
    const dispatch = useDispatch()
    const [event, setEvent] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!ticket) return;

        if (ticket.eventType === EVENT_TYPE.seminar) {
            dispatch(getSeminarsById(ticket.eventId)).unwrap().then((res) => {
                setIsLoading(false)
                setEvent(res)
            })
        }

        if (ticket.eventType === EVENT_TYPE.camp) {
            dispatch(getSingleCampFetch(ticket.eventId)).unwrap().then((res) => {
                setIsLoading(false)
                setEvent(res)
            })
        }
    }, [ticket])



    if (!ticket) {
        return null
    }


    if (!event) {
        return null;
    }

    const handlePay = () => {
        window.open(ticket?.invoice?.session?.url)
    }

    const getLocation = (data) => {
        if (!data?.country) {
            return "Location not specified";
        }

        const location = data?.country
            ? Country.getCountryByCode(data?.country)?.name
            : data?.country;

        return `${location}, ${data?.address} `;
    };


    if (isLoading) {
        return <Loader />
    }
    return (
        <div className="card-shadow px-36 py-32 mb-16">
            <div className='row-group justify-between'>
                <h6 className="heading mb-32">
                    {ticket.eventType === "seminar" ? "Seminar" : "Camp"} {" "} Ticket
                </h6>
                <p className='fw-600 ttu'>{ticket?.invoice?.status.replace(/_/g, ' ')}</p>
            </div>

            <div className="row-group gap--xxl pb-24 border-bottom mb-24 flex-lg-nowrap flex-wrap">
                <div className="image-wrapper --medium-thumb">
                    <img
                        alt={event?.name || event?.seminarName}
                        src={getImageSrc(event.cover || event?.photos?.find((photo) => photo?.isCover || photo.id === ticket.coverId)?.url)}
                    />
                </div>

                <div className="col-group gap--xxl justify-between">
                    {event?.adress}

                    <div className="row-group color-secondary fw-500 text-13 gap-4">
                        <div className="ico">
                            <LocationIcon />
                        </div>

                        <span className="truncate">
                            {getLocation(event)}
                        </span>
                    </div>

                    <div className="seminar-label-info">
                        <h6 className="heading">
                            {event?.name || event?.seminarName}
                        </h6>
                    </div>
                </div>
            </div>

            <div className="ticket-info-body ticket-info-body--no-border mb-32">
                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Full Name:
                    </span>

                    <span className="fw-500">
                        {ticket.fullName}
                    </span>
                </div>
                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Email:
                    </span>

                    <span className="fw-500">
                        {ticket.email}
                    </span>
                </div>

                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Date:
                    </span>

                    <span className="fw-500">
                        {getDateRange(event.seminarDates || [event?.startAt, event?.endAt], event._type === "plancamp")?.split(",") || []}
                    </span>
                </div>

                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Style:
                    </span>

                    <span className="fw-500">
                        {getTrainingStyle(event?.traningStyle)?.[0].title}
                    </span>
                </div>

                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Academy:
                    </span>

                    <span className="fw-500">
                        {event.userAcademy?.academyProfile?.name}
                    </span>
                </div>

                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Instructors:
                    </span>

                    <span className="fw-500">
                        {event.instructors?.map((item) => item?.user?.instructorProfile?.fullName).join(", ")}
                    </span>
                </div>
            </div>

            <hr className="hr-horisontal mb-32" />

            <div className="row-group justify-between gap--md">
                <span className="price-group__price">
                    ${ticket?.invoice?.price + ticket?.invoice?.price * ticket?.invoice?.fee}
                </span>
                {
                    ticket?.invoice?.status === "open" && (
                        <button
                            className={"btn btn--lg btn--primary"}
                            style={{ width: 228 }}
                            onClick={handlePay}
                        >Pay</button>
                    )
                }
            </div>
        </div>
    )
}

export default TicketPage