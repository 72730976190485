import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../api';
import { convertObjectToFormData, getFullName } from '../../../helpers/utils';
import { useTabs } from '../../../hooks/useTabs';
import { setToken } from '../../../redux/slices/applicationSlice';
import { getUserFetch } from '../../../redux/thunks/applicationThunks';
import PasswordTab from '../common/PasswordTab';
import { Steps } from '../components/Steps';
import StepsActions from '../components/StepsActions';
import { useDataFromSocial } from '../hooks/useDataFromSocial';
import { birthdaySchema } from '../validation/birthdaySchema';
import { commonDetailsSchema, instructorSchema, personalDetailsSchema } from '../validation/instructorSchema';
import { passwordSchema } from '../validation/passwordSchema';
import BirthdayTab from '../common/BirthdayTab';
import CommonDetailsTab from './tabs/CommonDetailsTab';
import PersonalDetailsTab from './tabs/PersonalDetailsTab';


const tabsMap = {
    COMMON: 'common',
    PERSONAL: 'personal',
    BIRTHDAY: 'birthday',
    PASSWORD: 'password',
}

const InstructorSignUpPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);

    const tabs = [
        {
            label: "Common",
            id: tabsMap.COMMON,
            schema: commonDetailsSchema,
        },
        {
            label: "Personal",
            id: tabsMap.PERSONAL,
            schema: personalDetailsSchema,
        },
        {
            label: "Birthday",
            id: tabsMap.BIRTHDAY,
            schema: birthdaySchema,
        },
        {
            label: "Password",
            id: tabsMap.PASSWORD,
            schema: passwordSchema,
        },

    ];

    const { activeTab, setActiveTab, activeTabIndex, activeTabObject } = useTabs(tabs, 't');
    const currentSchema = activeTabIndex === tabs.length - 1 ?
        instructorSchema : activeTabObject.schema;

    const resolver = yupResolver(currentSchema);
    const useFormReturn = useForm({
        resolver,
    })
    const { handleSubmit, resetField, setValue, watch, formState: { errors, touchedFields, dirtyFields } } = useFormReturn

    useDataFromSocial(({fullName, email, photo}) => {
        fullName && setValue('fullName', getFullName(data.firstName, data.lastName));
        email && setValue('email', data.email);
        photo && setValue('photo', data.photo);
    });

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const errorMessages = Object.keys(errors).map(key => errors[key].message)
            errorMessages.map(message => toast.warn(message))
        }
    }, [errors])

    useEffect(() => {
        setActiveTab(tabsMap.COMMON)
    }, [setActiveTab])


    const tabComponents = {
        [tabsMap.COMMON]: (
            <CommonDetailsTab
                useFormReturn={useFormReturn}
            />
        ),
        [tabsMap.PERSONAL]: (
            <PersonalDetailsTab
                useFormReturn={useFormReturn}
            />
        ),
        [tabsMap.BIRTHDAY]: (
            <BirthdayTab
                useFormReturn={useFormReturn}
            />
        ),
        [tabsMap.PASSWORD]: (
            <PasswordTab
                useFormReturn={useFormReturn}
            />
        ),
    }

    const renderTab = (activeTab) => {
        return tabComponents[activeTab]
    }


    const signUp = async (data) => {
        const formData = convertObjectToFormData(data);
        const response = await api.auth.signupInstructor(formData);

        if (response?.success) {
            if (response?.token) {
                localStorage.setItem("authToken", response.token);
                dispatch(setToken(response.token));
                await dispatch(getUserFetch(response.token));
                navigate("/settings/my-profile");
            }
        } else {
            toast.error(response.message || "Something went wrong");
        }
    }



    const onSubmit = async (data) => {
        try {
            console.log("Form data:", data);
            if (activeTabIndex < tabs.length - 1) {
                setActiveTab(tabs[activeTabIndex + 1].id);
            } else {
                setIsLoading(true);
                console.log("Final Submit:", data);
                await signUp(data);
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const handleCancel = () => {
        if (activeTabIndex === 0) {
            navigate("/");
        } else {
            if (activeTabIndex > 0) {
                const previousStep = tabs[activeTabIndex - 1];
                setActiveTab(previousStep.id);
            }
        }
    };

    return (
        <div className="container">
            <Steps steps={tabs} currentStep={activeTab} setCurrentStep={setActiveTab} />
            <form onSubmit={handleSubmit(onSubmit)} className="form form--md">
                {renderTab(activeTab)}
                <StepsActions
                    canGoBack={activeTabIndex > 0}
                    isFinalStep={activeTabIndex === tabs.length - 1}
                    onBack={handleCancel}
                    loading={isLoading}
                />
            </form>
        </div>
    )
}

export default InstructorSignUpPage
