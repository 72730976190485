import classNames from "classnames";
import React from "react";

const Button = ({
    type = "button", className, children, isLoading, ...props
}) => {
    return (
        <button
            className={classNames("btn", className, {
                disabled: props.disabled,
                loading: isLoading 
            })}
            disabled={isLoading || props.disabled}
            type={type}
            {...props}
        >
            {isLoading
                && (
                    <span className="btn-loader">
                        <div className="ico">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M12,23a9.63,9.63,0,0,1-8-9.5,9.51,9.51,0,0,1,6.79-9.1A1.66,1.66,0,0,0,12,2.81h0a1.67,1.67,0,0,0-1.94-1.64A11,11,0,0,0,12,23Z"
                                    fill="currentColor"
                                >
                                    <animateTransform
                                        attributeName="transform"
                                        dur="0.75s"
                                        repeatCount="indefinite"
                                        type="rotate"
                                        values="0 12 12;360 12 12"
                                    />
                                </path>
                            </svg>
                        </div>
                    </span>
                )}
            {children}
        </button>
    );
};

export default Button;
