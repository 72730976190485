import { yupResolver } from "@hookform/resolvers/yup";
import { object, ref, string } from "yup";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import api from "../../api";
import InputPass from "../../components/ui/formElements/InputPass";
import { setToken } from "../../redux/slices/applicationSlice.js";

export const updatePasswordSchema = object().shape({
    password:
        string()
            .min(8, "Password must contain at least 8 characters")
            .required("Password is required"),
    newPassword:
        string()
            .min(8, "Password must contain at least 8 characters")
            .required("Password is required"),
    confirmPassword:
        string()
            .min(8, "Password must contain at least 8 characters")
            .oneOf([ref("newPassword")], "Passwords must match")
            .required("Password is required"),
});

const Security = () => {
    const dispatch = useDispatch();
    const { handleSubmit, register, formState: { errors, touchedFields, dirtyFields } } = useForm({
        resolver: yupResolver(updatePasswordSchema),
    })

    const onSubmit = async (data) => {
        const updateUserRes = await api.users.updateUser({
            password: data.password,
            newPassword: data.newPassword
        });

        if (updateUserRes.success) {
            toast.success(updateUserRes.message || "Updated");

            if (updateUserRes?.token) {
                localStorage.setItem("authToken", updateUserRes.token);
                dispatch(setToken(updateUserRes.token));
            }
        } else {
            toast.error(updateUserRes?.message || "Error");
        }
    };

    return (
        <div className="settings-body settings-gap">
            <div className="sidebar-header heading-row">
                <h3 className="heading">
                    Security
                </h3>
            </div>

            <form
                className="form form-settings"
                method="post"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="form-body">
                    <div className="form-items-list">
                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <InputPass
                                        forgotPass={false}
                                        formGroupClass="input--lg input--icon-right"
                                        inputClass="input--solid w-full"
                                        inputClasses="input--solid"
                                        label="Current Password"
                                        placeholder="Current Password"
                                        {...register('password')}
                                        error={errors.password?.message}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <InputPass
                                        forgotPass={false}
                                        formGroupClass="input--lg input--icon-right"
                                        inputClass="input--solid w-full"
                                        inputClasses="input--solid"
                                        label="New Password"
                                        placeholder="New Password"
                                        {...register('newPassword')}
                                        error={errors.password?.message}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <InputPass
                                        forgotPass={false}
                                        formGroupClass="input--lg input--icon-right"
                                        inputClass="input--solid w-full"
                                        inputClasses="input--solid"
                                        label="Confirm Password"
                                        placeholder="Confirm Password"
                                        {...register('confirmPassword')}
                                        error={errors.confirmPassword?.message}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-footer">
                    <div className="flex-group justify-end">
                        <button
                            className="btn btn--primary btn--sm"
                            type="submit"
                        >
                            <span>
                                Save Changes
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Security;
