import { Country } from "country-state-city";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { ReactComponent as ChevronNextIcon } from "../../../assets/img/ico-chevron--next.svg";
import { ReactComponent as LocationIcon } from "../../../assets/img/icon-decor-city.svg";
import { NOT_SPECIFIED, trainingStyle as trainingStyles } from "../../../constants";
import {
    getBelt, getImageSrc
} from "../../../helpers/utils";

const CardInstructor = ({ data }) => {
    const traningStyleOption = trainingStyles.find(({ title }) => title?.toLowerCase() === data?.user?.generalInstructorInfo?.traningStyle?.toLowerCase());
    const belt = getBelt(data?.user?.generalInstructorInfo?.belt);
    const pricesMap = {
        $: "Under $1000",
        $$: "$1000-$2000",
        $$$: "$2000 - $4000",
        $$$$: "$4000 - $8000",
        $$$$$: "$10.000 +"
    };
    const generalInstructorInfo = data?.user?.generalInstructorInfo
    const { country } = generalInstructorInfo
    return (
        <div className="card-instructor">
            <Link to={`${data?.user?.id}`} className="card-instructor__img mb-20">
                <img
                    alt={data?.fullName}
                    src={getImageSrc(data?.photo, null, 512)}
                />

                {generalInstructorInfo?.traningStyle && (
                    <span className="card-seminar__gi card-instructor__img-price">
                        {traningStyleOption?.icon}
                        {generalInstructorInfo?.traningStyle}
                    </span>
                )}
            </Link>

            <div className="card-instructor__body">
                <div className="card-instructor__group-city">
                    <div className="ico">
                        <LocationIcon />
                    </div>
                    <span className="truncate">
                        {Country.getCountryByCode(country)?.name || 'Country not specified'}
                    </span>
                </div>

                <h5 className="heading mb-10">
                    {data?.fullName}
                </h5>

                <div className="card-seminar__group-tags mb-14">
                    {data?.user?.privateSession?.gym ?? "No Gym"}
                </div>

                <div className="flex justify-lg-between items-lg-center flex-col flex-lg-row">
                    {belt
                        ? (
                            <div className="flex items-center gap-9 fs-16 mb-14">
                                <span className="ico">
                                    {belt?.icon}
                                </span>

                                <span>
                                    {belt?.title}
                                </span>
                            </div>
                        )
                        : (
                            <div className="flex items-center gap-9 fs-16 mb-14">
                                <p>
                                    No belt
                                </p>
                            </div>
                        )}
                    <div className="flex items-center gap-6 mb-14">
                        <span className="color-grey fs-16">
                            Price:
                        </span>
                        <div className="dollars-list">
                            {data?.user?.privateSession?.pricePreview && data?.user?.privateSession?.pricePerSession
                                ? (
                                    <>
                                        <div
                                            className="row-group"
                                            data-tooltip-id={`my-tooltip-${data.id}`}
                                        >
                                            {Array.from({ length: data?.user?.privateSession?.pricePreview.length }).map((item, index) => (
                                                <span
                                                    className="ico"
                                                    key={index}
                                                >
                                                    <img
                                                        src={require("../../../assets/img/ico-dollar.svg").default}
                                                        alt="dollar"
                                                    />
                                                </span>
                                            ))}
                                        </div>
                                        <Tooltip
                                            border="1px solid var(--border-color)"
                                            id={`my-tooltip-${data.id}`}
                                            style={{
                                                background: "var(--white)",
                                                color: "var(--defaultTextColor)",
                                                padding: 10,
                                                width: 200
                                            }}
                                        >
                                            <div className="row-group justify-between">
                                                <div className="row-group">
                                                    {Array.from({ length: data?.user?.privateSession?.pricePreview.length }).map((item, index) => (
                                                        <span
                                                            className="ico"
                                                            key={index}
                                                        >
                                                            <img
                                                                src={require("../../../assets/img/ico-dollar.svg").default}
                                                                alt="dollar"
                                                            />
                                                        </span>
                                                    ))}
                                                </div>

                                                <p className="style-normal text-14 color-text">
                                                    {pricesMap[data?.user?.privateSession?.pricePreview]}
                                                </p>
                                            </div>
                                        </Tooltip>
                                    </>
                                ) : NOT_SPECIFIED
                            }
                        </div>
                    </div>
                </div>

            </div>

            <div className="card-seminar__footer flex-nowrap">
                <Link
                    className="btn btn--primary btn--md w-full"
                    to={`${data?.user?.id}`}
                >
                    <span className="info">
                        See Profile
                    </span>

                    <span className="ico ico--20">
                        <ChevronNextIcon />
                    </span>
                </Link>
            </div>
        </div>
    );
};

export default CardInstructor;
