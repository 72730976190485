import classNames from 'classnames'
import React from 'react'
import { EVENT_STATUS } from '../../../../constants/enums'

const StatusBlock = ({ status }) => {
    console.log(status === EVENT_STATUS.active);
    
    return (
        <div className="row-group gap--sm pb-24 border-bottom mb-24">
            <span className="seminar__status">
                Status:
            </span>

            <span
                className={classNames("seminar__dot", {
                    "--done": status === EVENT_STATUS.active,
                    "--rejected": ![EVENT_STATUS.pending, EVENT_STATUS.active].includes(status) ,
                })}
            />

            <span className="chat-item__name" style={{ textTransform: "capitalize" }}>
                {status}
            </span>
        </div>
    )
}

export default StatusBlock