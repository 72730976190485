import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const useDataFromSocial = (callback) => {
    const [params] = useSearchParams();

    const setDataFromSocial = async () => {
        let file = null;
        const photoUrl = params.get("image");

        if (photoUrl) {
            try {
                const response = await fetch(photoUrl);

                if (!response.ok) {
                    throw new Error("Failed to fetch the image.");
                }

                const blob = await response.blob();

                file = new File([blob], (params.get("first_name") || "avatar") + "." + (typeof blob.type === "string" ? blob.type.split("/")?.[1] : "png"), { type: blob.type });
            } catch (error) {
                console.error("Error fetching image:", error);
            }
        }

        const firstName = params.get("first_name") || '';
        const lastName = params.get("last_name") || '';

        callback({
            email: params.get("email"),
            firstName,
            lastName,
            photo: file
        });
    };
    useEffect(() => {
        setDataFromSocial();
    }, [params]);


    return null;
}

