import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { object, string } from "yup";
import api from "../../../api";
import VerifyPassportModal from "../../../components/modals/VerifyPassportModal";
import TooltipInfo from "../../../components/parts/TooltipInfo";
import Button from "../../../components/ui/buttons/Button";
import Input from "../../../components/ui/formElements/Input";
import { explanations } from "../../../constants/explanations";
import { convertObjectToFormData, getImageSrc } from "../../../helpers/utils";
import { getIsVerified, getMyVerification } from "../../../redux/slices/applicationSlice";
import { academyCodeVerificationFetch, academyFilesVerificationFetch, academyPassportVerificationFetch, getMyVerificationFetch } from "../../../redux/thunks/verificationsThunk";

import { ReactComponent as NextIcon } from '../../../assets/img/ico-chevron--next.svg';
import { ReactComponent as VerifyIcon } from '../../../assets/img/verify-icon.svg';
import Textarea from "../../../components/ui/formElements/Textarea";
import UploadPhotoAndCover from "../../../components/ui/UploadPhotoAndCover";
import VerifyCodeModal from "../../../components/modals/VerifyCodeModal";
import AddAdditionalProofModal from "../../../components/modals/AddAdditionalProofModal";

const MyAcademy = ({ userData, isVerifyModalInitialyOpen }) => {
    const dispatch = useDispatch();
    const isVerified = useSelector(getIsVerified);
    const verification = useSelector(getMyVerification);
    const isVerificationPending = verification?.isPassportVerify;
    const { t } = useTranslation('translation', { keyPrefix: 'profile' });
    const [verifyModalOpen, setVerifyModalOpen] = useState(isVerifyModalInitialyOpen);
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { dirtyFields, errors },
    } = useForm({
        defaultValues: {
            email: userData?.email || '',
            phone: userData?.phone || '',
            ...userData?.profile,
        }
    });

    async function onSubmit(data) {
        setIsLoading(true);

        const editedFieldsNames = Object.keys(dirtyFields);

        const changedFieldNames = editedFieldsNames.filter(
            (fieldName) => dirtyFields[fieldName]
        );

        const updateData = {};

        changedFieldNames.forEach((fieldName) => {
            updateData[fieldName] = data[fieldName];
        });

        if (updateData?.email || updateData?.phone) {
            const updatePayload = {};

            ["email", "phone"].forEach((item) => {
                if (updateData[item]) {
                    updatePayload[item] = updateData[item];
                }
            });
            const updateUserRes = await api.users.updateUser(updatePayload);

            if (updateUserRes.success) {
                toast.success(updateUserRes.message || "Updated");
            } else {
                toast.error(updateUserRes?.message || "Error");
            }
        }

        if (updateData?.cover || updateData?.photo || updateData?.name || updateData?.personInCharge || updateData?.about) {
            const formData = new FormData();

            [
                "cover",
                "photo",
                "name",
                "personInCharge",
                "about"
            ].forEach((item) => {
                if (updateData[item]) {
                    formData.append(item, updateData[item]);
                }
            });
            const updateInstructorRes = await api.profiles.updateAcademy(formData);

            if (updateInstructorRes.success) {
                toast.success(updateInstructorRes.message || "Updated");
            } else {
                toast.error(updateInstructorRes?.message || "Error");
            }
        }

        setIsLoading(false);
    }

    useEffect(() => {
        console.log('errors', errors);
    }, [errors])

    useEffect(() => {
        dispatch(getMyVerificationFetch())
    }, [dispatch])

    const handleSumbitVerification = async (data) => {
        await dispatch(academyPassportVerificationFetch(convertObjectToFormData({ source: data })))
        await dispatch(getMyVerificationFetch())
        setVerifyModalOpen(false);

    };

    return (
        <div className="settings-body settings-gap">
            <div className="sidebar-header heading-row">
                <h3 className="heading">{t('title')}</h3>
            </div>

            <form
                className="form form-settings"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="form-header">
                    <div className="row gutters-form">
                        <div className="col-12">
                            <UploadPhotoAndCover
                                cover={getImageSrc(watch('cover'))}
                                photo={getImageSrc(watch('photo'))}
                                onCoverChange={(file) => {
                                    setValue('cover', file, { shouldDirty: true });
                                }}
                                onPhotoChange={(file) => {
                                    setValue('photo', file, { shouldDirty: true });
                                }}
                                error={errors?.photo?.message && t('photoRequiredErrorText')}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-body" >
                    <div className="form-items-list">

                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        inputClasses="input--solid"
                                        label={t('academyName')}
                                        {...register('name', { required: 'academyNameRequiredErrorText' })}
                                        wrapperClasses="input--lg"
                                        error={errors.name?.message && t(errors.name?.message)}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        inputClasses="input--solid"
                                        label={t('personInCharge')}
                                        {...register('personInCharge', {
                                            required: 'personInChargeRequiredErrorText'
                                        })}
                                        wrapperClasses="input--lg"
                                        error={errors.personInCharge?.message &&
                                            t(errors.personInCharge?.message)}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        inputClasses="input--solid"
                                        label={t('email')}
                                        {...register('email', {
                                            required: 'emailRequiredErrorText'
                                        })}
                                        wrapperClasses="input--lg"
                                        error={errors.email?.message && t(errors.email?.message)}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        inputClasses="input--solid"
                                        label={t('phone')}
                                        {...register('phone', {
                                            required: 'phoneRequiredErrorText'
                                        })}
                                        wrapperClasses="input--lg"
                                        error={errors.phone?.message && t(errors.phone?.message)}
                                    />
                                </div>

                            </div>
                        </div>

                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <Textarea
                                        textareaClasses="input--solid"
                                        label={t('bio')}
                                        {...register('about', {
                                            required: 'bioRequiredErrorText'
                                        })}
                                        wrapperClasses={"input--lg"}
                                        rows={"6"}
                                        placeholder='Describe your academy...'
                                        error={errors.about?.message && t(errors.about?.message)}
                                    />
                                </div>
                            </div>
                        </div>
                        {!isVerified && !isVerificationPending && (
                            <div className="form-item">
                                <div className="row gutters-form">
                                    <div className="col-12">
                                        <div className="heading-row">
                                            <div className="flex-ico-ss-text">
                                                <span className="ico ico--sm rounded-full border-ico">
                                                    <VerifyIcon />
                                                </span>

                                                <div className="row-group">
                                                    <h4 className="heading">
                                                        {t('verifyAccountText')}
                                                    </h4>
                                                    <TooltipInfo content={explanations.verifyFighterID} />
                                                </div>
                                            </div>

                                            <div className="flex-group">
                                                <Button onClick={() => setVerifyModalOpen(true)} className="btn btn--primary btn--sm">
                                                    <span className="info">
                                                        {t('verifyIdButtonText')}
                                                    </span>

                                                    <span className="ico ico-14">
                                                        <NextIcon />
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!isVerified && isVerificationPending && <AdditionalProof />}
                        {/* {!isVerified && isVerificationPending && <VerifyCode />} */}
                    </div>
                </div>

                <div className="form-footer --bordered">
                    <div className="flex-group justify-end">
                        <Button
                            className={classNames("btn btn--primary btn--sm", {
                                disabled: !Object.values(dirtyFields).length
                            })}
                            isLoading={isLoading}
                            type="submit"
                        >
                            <span>
                                {t('saveButtonText')}
                            </span>
                        </Button>
                    </div>
                </div>
            </form>

            {verifyModalOpen && (
                <VerifyPassportModal
                    open={verifyModalOpen}
                    onClose={() => setVerifyModalOpen(false)}
                    onSubmit={handleSumbitVerification}
                />
            )}
        </div>
    );
};


function AdditionalProof() {
    const [addAdditionalProofModalOpen, setAddAdditionalProofModalOpen] = useState(false);
    const dispatch = useDispatch()

    const handleSubmit = async (data) => {
        await dispatch(academyFilesVerificationFetch(convertObjectToFormData(data)))
        
        setAddAdditionalProofModalOpen(false);
    }
    return (
        <div className="form-item">
            <div className="row gutters-form">
                <div className="col-12">
                    <div className="heading-row flex-nowrap">
                        <div className="flex-ico-ss-text">
                            <div className="row-group">
                                <h4 className="heading">
                                    Add Additional Proof of Ownership to Speed Up the Verification Process
                                </h4>
                            </div>
                        </div>
                        <div className="flex-group ml-auto">
                            <Button
                                type="button"
                                onClick={() => setAddAdditionalProofModalOpen(true)}
                                className="btn btn--primary btn--sm"
                            >
                                <span className="info">
                                    Upload
                                </span>
                                <span className="ico ico-14">
                                    <NextIcon />
                                </span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {addAdditionalProofModalOpen && (
                <AddAdditionalProofModal
                    open={addAdditionalProofModalOpen}
                    onClose={() => setAddAdditionalProofModalOpen(false)}
                    onSubmit={handleSubmit}
                />
            )}
        </div>
    )
}

const VerifyCode = () => {
    const dispatch = useDispatch()
    const [verifyModalOpen, setVerifyModalOpen] = useState(false);
    const handleSubmit = async (data) => {
        dispatch(academyCodeVerificationFetch(data))
        setVerifyModalOpen(false);
    }

    return (
        <div className="form-item">
            <div className="row gutters-form">
                <div className="col-12">
                    <div className="heading-row">
                        <div className="flex-ico-ss-text">
                            <div className="row-group">
                                <h4 className="heading">
                                    Verification Pending
                                </h4>
                                <TooltipInfo content={explanations.verificationPending} />
                            </div>
                        </div>
                        <div className="flex-group">
                            <Button
                                type="button"
                                onClick={() => setVerifyModalOpen(true)}
                                className="btn btn--primary btn--sm"
                            >
                                <span className="info">
                                    Verify
                                </span>
                                <span className="ico ico-14">
                                    <NextIcon />
                                </span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <VerifyCodeModal
                open={verifyModalOpen}
                onClose={() => setVerifyModalOpen(false)}
                onSubmit={handleSubmit}
            />
        </div>
    )
}

export default MyAcademy;
