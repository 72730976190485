const base = "api/recently-viewed";

export const history = (instance) => ({
    async getHistory ({type = "seminar", status = "pending"}) {
        const { data } = await instance.get(`${base}?type=${type}&status=${status}`);

        return data;
    },
    async clearHistory () {
        const { data } = await instance.delete(`${base}/all`);

        return data;
    }
});
