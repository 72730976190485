import classNames from "classnames";
import { Country } from "country-state-city";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { ReactComponent as ChevronRignt } from "../../../assets/img/ico-chevron--next.svg";
import { ReactComponent as ShareIcon } from "../../../assets/img/icon-share.svg";
import TooltipInfo from "../../../components/parts/TooltipInfo";
import { getLanguagesByKeys, NOT_SPECIFIED } from "../../../constants";
import { EVENT_TYPE, USER_ROLE } from "../../../constants/enums";
import { explanations } from "../../../constants/explanations";
import { convertSeminarDates, getFullLocation, normalizeTime } from "../../../helpers/utils";
import { getIsVerified, getUser } from "../../../redux/slices/applicationSlice";

import { format } from "date-fns";
import { ReactComponent as CheckCircleIcon } from "../../../assets/img/icon-check-circle.svg";
import { EventHelper } from "../../../helpers/EventHelper";

const SeminarSingleDefaultAsside = ({ pageData }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
    const user = useSelector(getUser);
    const isVerified = useSelector(getIsVerified);

    if (!pageData) return;
    const dateRange = EventHelper.dateRange(pageData)
    const dateArray = dateRange.split(',')

    const canBuyTicket = !user || user?.role === USER_ROLE.student || user.role === USER_ROLE.guest
    const ticketsLeft = (pageData?.maxGymCapacity || 0) - (pageData?.ticketsPurchased || 0)
    const bookTicketLink = `/book/${pageData?._type === EVENT_TYPE.camp ? "camp/" : ""}${pageData.id}`
    const bookLessonLink = `/book-lesson/${pageData?._type}/${pageData.id}`

    return (
        <div className="card-shadow px-36 py-32">
            <div className="flex items-center justify-between gap-12 mb-24 pb-24 border-bottom">
                <div className="price-group gap-4">
                    <span className="price-group__price">
                        ${pageData?.minimalPriceTicket}
                    </span>
                    <span className="color-grey">
                        / per ticket
                    </span>
                </div>

                <div className="group-ticket-info">
                    <div className="flex items-center gap-4 color-secondary">
                        <div className="flex">
                            <img src={require("../../../assets/img/icon-ticket.svg").default} alt="tickets" />
                        </div>
                        {pageData?.ticketsPurchased} &nbsp;
                    </div>

                    / {pageData?.maxGymCapacity}
                </div>
            </div>

            <div
                className={classNames(`flex items-center justify-between gap-12 mb-24`, { "pb-24 border-bottom": canBuyTicket })}
            >
                <span className="card-seminar__info-date card-seminar__info-date--lg">
                    {dateArray && dateArray.length > 0 && (
                        <span className="card-seminar__info-date card-seminar__info-date--lg">
                            {dateArray[0]}
                            {", "}
                            &nbsp;
                            <span className="color-secondary">
                                {dateArray[1]}
                            </span>
                        </span>
                    )}
                </span>
            </div>
            <div className="pb-24 mb-24 border-bottom">
                <div data-tooltip-id={"buy-ticket-link"}>
                    <Link
                        to={bookTicketLink}
                        className={classNames("btn btn--primary btn--lg w-full gap-10 mb-8", {
                            "disabled": !ticketsLeft || !canBuyTicket
                        })}
                        onClick={e => (!canBuyTicket || !ticketsLeft) && e.preventDefault()}
                        disabled={!canBuyTicket}
                    >
                        <span className="info fs-20">
                            Buy Ticket
                        </span>
                        <ChevronRignt />
                    </Link>
                </div>
                {!canBuyTicket && (
                    <Tooltip
                        id={"buy-ticket-link"}
                        content={"You can’t buy a ticket as an Instructor/Academy"}
                        style={{ maxWidth: 400 }}
                    />
                )}

                {user && user?.role !== USER_ROLE.guest && !!pageData.isAvailablePrivateLesson && (
                    <div data-tooltip-id={"book-lesson-link"}>
                        <Link
                            to={bookLessonLink}
                            className={classNames("btn btn--default btn--lg w-full gap-10", {
                                "disabled": !ticketsLeft || !canBuyTicket || !isVerified
                            })}
                        >
                            <span className="info fs-20">
                                Book private lesson
                            </span>
                            <ChevronRignt />
                        </Link>
                    </div>
                )}
                {!canBuyTicket && (
                    <Tooltip
                        id={"book-lesson-link"}
                        content={"You can’t book private lesson as an Instructor/Academy"}
                        style={{ maxWidth: 400 }}
                    />
                )}
            </div>

            <button
                className="btn color-secondary gap-10 w-full mb-24 pb-24 border-bottom"
            >
                <ShareIcon />

                <span className="underline fw-500 not-italic tt-none">
                    {t('share')}
                </span>
            </button>

            <div className="ticket-info-body border-bottom mb-24 pb-24">
                <div className="ticket-info-body__item">
                    <span className="color-text">
                        {t('langs')}

                        {" "}
                    </span>

                    <span>
                        {getLanguagesByKeys(pageData?.languages) || NOT_SPECIFIED}
                    </span>
                </div>

                <div className="ticket-info-body__item">
                    <span className="color-text">
                        {t('host')}
                        {" "}
                    </span>

                    <span>
                        {pageData?.userAcademy?.academyProfile?.name}
                    </span>
                </div>

                <div className="ticket-info-body__item">
                    <span className="color-text">
                        {t('address')}
                    </span>

                    <span>
                        {" "}

                        {getFullLocation([Country.getCountryByCode(pageData?.country)?.name, pageData?.city, pageData?.address])}
                    </span>
                </div>

                <div className="ticket-info-body__item">
                    <span className="color-text">
                        Schedule
                    </span>

                    {pageData?.schedules?.length && (
                        <div className="col-group gap-24">
                            {pageData?.schedules.map((item, index, arr) => (
                                <div key={index}>
                                    <span className="fw-500">{format(new Date(item?.[0]?.date), 'dd MMM')}</span>
                                    {item?.length && item?.map(({ start, end }) => (
                                        <p className="mt-2">{`${normalizeTime(start)} - ${normalizeTime(end)}`}</p>
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="col-group gap--md">
                        {pageData?.seminarDates?.length && convertSeminarDates(pageData.seminarDates).map((item, index) => (
                            <div key={index}>
                                <span className="fw-500">{format(new Date(item?.day), 'dd MMM')}</span>
                                {item?.timeSlots?.map(({ start, end }) => (
                                    <p className="mt-2">{`${format(new Date(start), 'hh:mm a')} - ${format(new Date(end), 'hh:mm a')}`}</p>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>

            </div>

            <div className="row-group justify-between gap--sm">
                <span className="block color-text mb-12">
                    {t('attending')}
                </span>
            </div>

            <div className="flex items-center border-bottom mb-24 pb-24">
                <ul className="user-list flex-auto">
                    {Array.from({ length: 3 }).map((_, index) => (
                        <li key={index} className="user-item">
                            <div className="user-item__img">
                                <img src={require("../../../assets/img/ava-students-1.png")} alt="student" />
                            </div>
                        </li>
                    ))}
                </ul>

                <div className="flex flex-col">
                    <span className="fs-14 fw-500">
                        {pageData?.ticketsPurchased}
                        {" / "}
                        {pageData?.maxGymCapacity}
                    </span>

                    <span className="fs-14 color-text">
                        {t('students')}
                    </span>
                </div>
            </div>

            <div className="ticket-info-body__item">
                <span className="color-text">
                    {t('min_attendance')}
                </span>

                <span className="flex items-center justify-between gap-4 flex-auto">
                    <div className="flex items-center gap-4">
                        <div className="ico ico-18">
                            <CheckCircleIcon />
                        </div>

                        <div>
                            {pageData?.minAttendance}

                            {" "}

                            {t('students')}
                        </div>
                    </div>
                    <TooltipInfo content={explanations.minimumAttendance} />
                </span>
            </div>

        </div>
    );
};

export default SeminarSingleDefaultAsside;
