import { useDebounce } from '@uidotdev/usehooks';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SelectOne from '../../../../components/ui/formElements/SelectOne'
import { getImageSrc } from '../../../../helpers/utils';
import { getAcademyByIdFetch, getAllAcademiesFetch } from '../../../../redux/thunks/academyGeneralInfoThunks';
import HeadingBlock from '../../components/HeadingBlock'

const AcademySelection = ({ useFormReturn }) => {
    const { setValue, reset } = useFormReturn
    const [selectedAcademy, setSelectedAcademy] = useState(null)
    const dispatch = useDispatch()
    const { academies, isLoading } = useSelector((state) => state.academyGeneralInfoApp);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 300)

    useEffect(() => {
        dispatch(getAllAcademiesFetch(`templates=true&append_info=true&search=${debouncedSearchTerm}`));
    }, [debouncedSearchTerm, dispatch]);

    useEffect(() => {
        reset();
    }, [reset]);

    const handleAcademyChange = async (id) => {
        const data = await dispatch(getAcademyByIdFetch(id)).unwrap();
        if (data) {
            const { academyProfile, email, phone, generalAcademyInfo } = data

            // setValue('email', email)
            setValue('phone', phone)

            // academyProfile
            setValue('about', academyProfile.about)
            setValue('name', academyProfile.name)
            setValue('personInCharge', academyProfile.personInCharge)


            // generalAcademyInfo
            setValue('generalAcademyInfo.addressLine', generalAcademyInfo.addressLine)
            setValue('generalAcademyInfo.city', generalAcademyInfo.city)
            setValue('generalAcademyInfo.country', generalAcademyInfo.country)
            setValue('generalAcademyInfo.latitude', generalAcademyInfo.latitude)
            setValue('generalAcademyInfo.longitude', generalAcademyInfo.longitude)
            setValue('generalAcademyInfo.state', generalAcademyInfo.state)
            setValue('generalAcademyInfo.zip', generalAcademyInfo.zip)

            setValue('facebook', data.facebookURL || null)
            setValue('website', data.website || null)
            setValue('instagram', data.instagramURL || null)

            const photo = academyProfile.photo
            if (photo) {
                const res = await fetch(getImageSrc(photo));
                if (res.ok) {
                    const file = await res.blob();
                    setValue('photo', new File([file], photo, { type: file.type }));
                }
            }
        }

        setSelectedAcademy(id)
    }

    return (
        <>
            <HeadingBlock
                title='WELCOME TO Plan Seminars'
                description='Search and claim academy in our system or set it manually'
            />

            <div className='flex flex-col gap-5'>
                <div className='flex flex-col gap-2 mb-24'>
                    <SelectOne
                        options={academies?.map(item => ({ label: item.name, value: item.id }))}
                        label="Academy"
                        onChange={(option) => handleAcademyChange(option.value)}
                        placeholder="Find your academy..."
                        value={selectedAcademy}
                        wrapperClasses="select--outline  select--outline-bg input--lg"
                        onInputChange={(value) => setSearchTerm(value)}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </>
    )
}

export default AcademySelection