
import { FirstScreenSearchSection } from "../../../components/parts/FirstScreenSearchSection";
import { PaginationTemplate } from "../../../components/parts/PaginationTemplate";
import CardSeminar from "../../../components/parts/cards/CardSeminar";
import {
    useEffect, useState
} from "react";
import SeminarFilters from "./SeminarFilters";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../../hooks/useDebaunce";
import { useDispatch } from "react-redux";
import Loader from "../../../components/ui/Loader";

export const SeminarTemplate = ({
    firsScreenOptions,
    baseQuery = "",
    pageType = "default",
    filterLinks,
    filters,
    filtersData,
    sotrBy,
    fethFuntion,
    seminars,
    pagination,
    isLoading,
    name
}) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState("");
    const [filtersState, setFiltersState] = useState(filters);
    const debouncedSearchTerm = useDebounce(searchTerm, 500);


    const handleFiltersChange = (key, value) => {
        if (typeof value === "string" && value.includes("&")) {
            const [sortByValue, sortOrder] = value.split("&");
            setFiltersState((prevFilters) => ({
                ...prevFilters,
                [key]: sortByValue,
                sort_as: sortOrder,
            }));
        } else {
            setFiltersState((prevFilters) => ({
                ...prevFilters,
                [key]: value,
            }));
        }
    };

    const searchHandler = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const cleanedFilters = Object.fromEntries(Object.entries(filtersState).filter(([key, value]) => value !== "" && value !== false));

        setSearchParams(cleanedFilters);
    }, [filtersState, debouncedSearchTerm]);

    useEffect(() => {
        handleFiltersChange("search", debouncedSearchTerm);
    }, [debouncedSearchTerm]);

    useEffect(() => {
        const queries = searchParams.toString().replaceAll("%24", "$");
        dispatch(fethFuntion(`${baseQuery}&${queries}`));
    }, [dispatch, searchParams, baseQuery, fethFuntion]);

    return (
        <>
            <FirstScreenSearchSection
                description={firsScreenOptions?.description}
                imageBg={firsScreenOptions?.imageBg}
                onChange={searchHandler}
                placeholder={firsScreenOptions?.placeholder}
                searchValue={searchTerm}
                title={firsScreenOptions?.title}
            />

            <section className="pt-42 pb-160 mt-12">
                <div className="container">

                    <SeminarFilters
                        filters={filtersState}
                        filtersData={filtersData}
                        links={filterLinks}
                        onChange={handleFiltersChange}
                        sortBy={sotrBy}
                        totalCards={pagination?.total}
                        viewStatus={pageType}
                        name={name}
                    />

                    {seminars && seminars?.length > 0
                        ? (
                            <>
                                <div className="row gx-24 gy-70 mb-70">
                                    {seminars.map((el, idx) => (
                                        <div
                                            key={el.id}
                                            className="col-lg-4 col-md-6"
                                        >
                                            <CardSeminar data={el} name={name} />
                                        </div>
                                    ))}
                                </div>
                                <PaginationTemplate
                                    {...pagination}
                                    onPageChange={(page) => handleFiltersChange("page", page)}
                                />
                            </>
                        ) : isLoading
                            ? <Loader />
                            : (
                                <h3 className="text-center">
                                    There is no items now
                                </h3>
                            )}
                </div>
            </section>
        </>
    );
};
