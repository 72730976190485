import classNames from 'classnames'
import { addDays } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import TooltipInfo from '../../../../components/parts/TooltipInfo'
import CheckboxSwitch from '../../../../components/ui/formElements/CheckboxSwitch'
import DatePicker from '../../../../components/ui/formElements/DatePicker'
import Input from '../../../../components/ui/formElements/Input'
import SelectOne from '../../../../components/ui/formElements/SelectOne'
import { USER_ROLE } from '../../../../constants/enums'
import { explanations } from '../../../../constants/explanations'
import EventDatesSelect from '../../components/EventDatesSelect'
import SpecificDatesSelect from '../../components/SpecificDatesSelect'
import { PLANNING_STATUS } from '../../constants/emuns'
import useLocationOptions from '../../hooks/useLocationOptions'

const CampDateTab = ({
    planningStatus = PLANNING_STATUS.draft,
    useFormReturn,
    role,
    generalInfo
}) => {
    const [isInterval, setIsInterval] = useState(true);
    const { register, setValue, watch, formState: { errors, defaultValues }, control, resetField } = useFormReturn
    const continent = watch("continent");
    const country = watch("country");
    const address = watch("address");
    const startAt = watch("startAt");
    const allowPrivateLessons = watch("allowPrivateLessons");

    const { continentOptions, countryOptions, cityOptions, allCountriesOptions, getContinentByCountryCode } = useLocationOptions({
        continent,
        country,
    });

    const isAcademyLocation = useMemo(
        () => role === USER_ROLE.academy
            && address === generalInfo?.addressLine
            && country === generalInfo?.country,
        [role, generalInfo, country, address]
    )
    const handleSetIsAcademyLocation = () => {
        if (isAcademyLocation) {
            resetField("address");
            resetField("country");
        } else {
            setValue("address", generalInfo?.addressLine);
            setValue("country", generalInfo?.country);
        }
    }

    useEffect(() => {
        if (!defaultValues?.address && !defaultValues?.country && generalInfo) {
            setValue("address", generalInfo?.addressLine, { shouldDirty: true });
            setValue("country", generalInfo?.country, { shouldDirty: true });
        }
    }, [defaultValues, generalInfo, setValue])

    return (
        <>
            <div className="mb-42">
                <div className="row gx-16 py-24 mb-10 ">
                    {/* Academy's location */}
                    {role === USER_ROLE.academy && (
                        <>
                            <div className="col-md-6 col-sm-6 col-12">
                                <Controller
                                    name={'country'}
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <SelectOne
                                            disabled={isAcademyLocation}
                                            error={errors.country?.message}
                                            label="Country"
                                            value={value}
                                            onChange={(option) => onChange(option.value)}
                                            options={allCountriesOptions}
                                            placeholder="Select country"
                                            wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                        />
                                    )}
                                />

                            </div>
                            <div className="col-md-6 col-sm-6 col-12">
                                <Input
                                    disabled={isAcademyLocation}
                                    inputClasses="input--solid"
                                    label="Address (academy by deafult)"
                                    placeholder="Enter location here..."
                                    wrapperClasses="input--lg"
                                    {...register("address")}
                                    error={errors.address?.message}
                                />
                            </div>
                            <div className="option-group ">
                                <div className="row-group">
                                    <CheckboxSwitch
                                        checked={!isAcademyLocation}
                                        onChange={handleSetIsAcademyLocation}
                                        text="The camp will take place at a third party location."
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {/* Instructor's location */}
                    {role === USER_ROLE.instructor && (
                        <>
                            <div className="col-12">
                                <div className="row-group ">
                                    <h4 className="row-group">
                                        Set your location Availability
                                    </h4>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-6 col-12">
                                <Controller
                                    name={'continent'}
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <SelectOne
                                            isSearchable={false}
                                            error={errors.continent?.message}
                                            label="Continent"
                                            value={value}
                                            onChange={(option) => onChange(option.value)}
                                            options={continentOptions}
                                            placeholder="Select continent"
                                            wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                        />
                                    )}
                                />
                            </div>

                            <div className="col-md-6 col-sm-6 col-12">
                                <Controller
                                    name={'country'}
                                    control={control}
                                    render={({ field: { value, onChange } }) => (

                                        <SelectOne
                                            error={errors.country?.message}
                                            disabled={!continent}
                                            label="Country"
                                            value={value}
                                            onChange={(option) => onChange(option.value)}
                                            options={countryOptions}
                                            placeholder="Select country"
                                            wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                        />
                                    )}
                                />
                            </div>

                            <div className="col-md-6 col-sm-6 col-12">
                                <Controller
                                    name={'city'}
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <SelectOne
                                            error={errors.city?.message}
                                            disabled={!country}
                                            label="City / State"
                                            value={value}
                                            onChange={(option) => onChange(option.value)}
                                            options={cityOptions}
                                            placeholder="Select city"
                                            wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                        />
                                    )}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>

            <hr className="hr mb-42" />

            <>
                <div className="row-group mb-36">
                    <CheckboxSwitch
                        checked={isInterval}
                        onChange={() => setIsInterval(!isInterval)}
                        text={(<h6 className="heading" style={{ marginLeft: 16 }}>Set Date Interval</h6>)}
                    />
                    <TooltipInfo content={explanations.dateIntervalCamp} />
                </div>
                <div className={classNames("schedule-group", { "block-disabled": !isInterval })}>
                    <div className="input-date-time-group">
                        <div className="flex-1">
                            <Controller
                                name="startAt"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <DatePicker
                                        label="Start Date"
                                        minDate={new Date()}
                                        placeholderText="Select Start Date"
                                        selected={value}
                                        onChange={onChange}
                                        error={errors.startAt?.message}
                                    />
                                )}
                            />
                        </div>

                        <span className="input-delimiter" style={{ marginTop: 26 }} />

                        <div className="flex-1">
                            <Controller
                                name="endAt"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <DatePicker
                                        label="End Date"
                                        minDate={startAt ? addDays(new Date(startAt), 3) : new Date()}
                                        placeholderText="Select End Date"
                                        selected={value}
                                        onChange={onChange}
                                        error={errors.endAt?.message}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className="row-group mb-36 mt-42">
                    <CheckboxSwitch
                        checked={!isInterval}
                        onChange={() => setIsInterval(!isInterval)}
                    />

                    <h6
                        className="heading"
                        style={{ marginLeft: 16 }}
                    >
                        OR Set Specific Dates
                    </h6>
                    <TooltipInfo content={explanations.specificDatesCamp} />
                </div>
                <Controller
                    name="schedule"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <SpecificDatesSelect
                            dates={value}
                            disabled={isInterval}
                            errors={errors.schedule?.message}
                            handleChange={(dates) => {
                                onChange(dates.map(date => ([{ date: date.date }])))
                            }}
                            maxLength={10}
                            isErrorsShown={!!errors.schedule}
                        />
                    )}
                />
            </>

            <hr className="hr mb-42 mt-42" />

            {role === USER_ROLE.academy && (
                <div className="option-group mb-36">
                    <Controller
                        name="allowPrivateLessons"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <CheckboxSwitch
                                checked={!!value}
                                onChange={() => onChange(value ? 0 : 1)}
                                text={"Is instructor allowed to do private sessions before/after the camp?"}
                            />
                        )}
                    />
                </div>
            )}
            {planningStatus !== PLANNING_STATUS.draft && !!allowPrivateLessons && (
                <>
                    <div className="row-group mb-36">
                        <h6 className="heading">Private lessons available Dates</h6>
                    </div>
                    <Controller
                        name="privateSessionDates"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <EventDatesSelect
                                dates={value}
                                handleChange={onChange}
                                errors={errors.privateSessionDates}
                                isErrorsShown={!!errors.privateSessionDates}
                                isMultySlot
                            />
                        )}
                    />
                </>
            )}
        </>
    )
}

export default CampDateTab