export const USER_ROLE = {
    academy: 'academy',
    instructor: 'instructor',
    student: 'student',
    guest: 'guest',
};

export const EVENT_TYPE = {
    seminar: "seminar",
    camp: "plancamp",
    tour: "tour",
    privateLesson: "privateLesson"
}

export const EVENT_STATUS = {
    pending: "pending",
    active: "active",
    canceled: "canceled"
}

export const EVENT_STEP = {
    date: "location_and_date",
    details: "details",
    design: "design",
    terms: "terms",
    done: "done",
}

export const STEPS_ORDER = [
    EVENT_STEP.date,
    EVENT_STEP.details,
    EVENT_STEP.design,
    EVENT_STEP.terms,
]

export const TICKET_TYPE = {
    privateLessons: 'private_lessons',
    seminarTicket: 'seminar_ticket',
    plancampTicket: 'plancamp_ticket'
}

export const PAYMENT_STATUS = {
    pending: "pending",
    refused: "refused",
    completed: "completed",
    failure: "failure",
    canceled: "canceled",
    verified: 'verified',
    open: 'open',
    waiting_payout_method: "waiting_payout_method"
}
