import { useState } from 'react'
import { Controller } from 'react-hook-form'
import TooltipInfo from '../../../../components/parts/TooltipInfo'
import CheckboxSwitch from '../../../../components/ui/formElements/CheckboxSwitch'
import DatePicker from '../../../../components/ui/formElements/DatePicker'
import Input from '../../../../components/ui/formElements/Input'
import SelectMulty from '../../../../components/ui/formElements/SelectMulty'
import SelectOne from '../../../../components/ui/formElements/SelectOne'
import { USER_ROLE } from '../../../../constants/enums'
import { explanations } from '../../../../constants/explanations'
import { calculateMinAttendance } from '../../../../helpers/utils'
import { PLANNING_STATUS } from '../../constants/emuns'
import { languageOptions, sharedProfitOptions, specificBeltOptions, targetAudienceOptions, teachingStyleOptions, traningStyleOptions } from '../../constants/options'
import { calculateMinAttendanceDeadline, getStartDate } from '../../utils/index'

const CampInformationTab = ({
	planningStatus = PLANNING_STATUS.draft,
	useFormReturn,
	role,
	generalInfo,
	setPreview,
	preview,
	isInCountry
}) => {
	const { register, setValue, watch, resetField, reset, getValues, formState: { errors }, control } = useFormReturn
	const [isSharedProfit, setIsSharedProfit] = useState(getValues("sharedProfit") || false);

	const [minimalPriceTicket, insructorPrice, availableDates] = watch(["minimalPriceTicket", "instructorPrice", 'availableDates'])
	const minAttendance = calculateMinAttendance(minimalPriceTicket, insructorPrice)
	const minAttendanceDeadline = calculateMinAttendanceDeadline(getStartDate(availableDates), isInCountry)

	const handlePriceTypeChange = () => {
		setIsSharedProfit((prevIsSharedProfit) => {
			const resetFields = prevIsSharedProfit
				? ["sharedProfit", 'minAttendance']
				: planningStatus === PLANNING_STATUS.draft
					? ["seminarMinPrice", "seminarMaxPrice"]
					: ["instructorPrice", 'minAttendance'];

			resetFields.forEach((field) => {
				resetField(field)
				setValue(field, null, { shouldDirty: true });
			});

			return !prevIsSharedProfit;
		});
	}


	return (
		<div className='row gx-16 py-24 mb-42'>
			{planningStatus === PLANNING_STATUS.draft && (
				<div className="col-12">
					<div className="form-group input--lg mb-16">
						<div className="row-group gap--sm">
							<label className="label">
								Price range for camp
							</label>
							<TooltipInfo
								content={role === USER_ROLE.academy
									? explanations.priceRangeAcademy
									: explanations.priceRangeInstructor}
							/>
						</div>
						<div className="row-group w-full gap-10 mobile-range">
							<div className="form-group flex-auto input--lg">
								<Input
									wrapperClasses="input--currency"
									inputClasses="input--solid"
									type="number"
									min={0}
									max={99999900}
									placeholder="3000"
									disabled={isSharedProfit}
									{...register("seminarMinPrice")}
									error={errors.seminarMinPrice?.message}
								/>
							</div>

							<span className="input-delimiter" />

							<div className="form-group flex-auto input--lg">
								<Input
									wrapperClasses="input--currency"
									inputClasses="input--solid"
									type="number"
									min={0}
									max={99999900}
									placeholder="4000"
									disabled={isSharedProfit}
									error={errors.seminarMaxPrice?.message}
									{...register("seminarMaxPrice")}

								/>
							</div>
						</div>
					</div>

					{!isSharedProfit && (
						<p className="color-text text-14 mb-16">
							Seminar pricing varies based on duration. Please adjust parameters based on your camp duration.
						</p>
					)}

					<CheckboxSwitch
						checked={isSharedProfit}
						onChange={handlePriceTypeChange}
						text={`Set “Shared Profit” for camp`}
					/>
				</div>
			)}
			{planningStatus !== PLANNING_STATUS.draft && (
				<div className="col-12">
					<div className="row gx-16 mb-16">
						<div className="col-md-6 col-sm-6 col-12">
							<div className="form-group flex-auto input--lg">
								<label className="label">
									Ticket Price
								</label>

								<Input
									error={errors.minimalPriceTicket?.message}
									inputClasses="input--solid"
									{...register("minimalPriceTicket")}
									placeholder="100.00"
									type="number"
									wrapperClasses="form-group flex-auto input--lg input--currency"
								/>
							</div>
						</div>
						<div className="col-md-6 col-sm-6 col-12">
							<Input
								error={errors.instructorPrice?.message}
								wrapperClasses="form-group flex-auto input--lg input--currency"
								inputClasses="input--solid"
								label="Instructor Price"
								{...register("instructorPrice")}
								placeholder="100"
								type="number"
								disabled={isSharedProfit}
							/>
						</div>
					</div>
					<CheckboxSwitch
						checked={isSharedProfit}
						onChange={handlePriceTypeChange}
						text={`Set “Shared Profit” for camp`}
					/>
				</div>
			)}
			<div className="col-md-6 col-sm-6 col-12">
				<Controller
					control={control}
					disabled={!isSharedProfit}
					name="sharedProfit"
					render={({ field: { onChange, value }, }) => (
						<SelectOne
							error={errors.sharedProfit?.message}
							label="Shared Profit (Instructor / Academy)"
							labelRowSecondItem={(<TooltipInfo content={explanations.sharedProfit} />)}
							onChange={(option) => onChange(option.value)}
							options={sharedProfitOptions}
							placeholder="Specific Shared Profit"
							value={value}
							disabled={!isSharedProfit}
							wrapperClasses="select--outline select--outline-bg input--lg w-full"
						/>
					)}
				/>
			</div>

			<div className="col-12">
				<hr className="hr" />
			</div>

			<div className="col-md-6 col-sm-6 col-12">
				<Controller
					control={control}
					name="traningStyle"
					render={({ field: { onChange, value } }) => (
						<SelectOne
							error={errors.traningStyle?.message}
							label="Gi or Nogi"
							options={traningStyleOptions}
							onChange={(option) => onChange(option.value)}
							placeholder="Gi or Nogi"
							value={value}
							wrapperClasses="select--outline select--outline-bg input--lg w-full"
						/>
					)}
				/>

			</div>

			{role === USER_ROLE.academy && planningStatus === PLANNING_STATUS.draft && (
				<>
					<div className="col-md-6 col-sm-6 col-12">
						<Controller
							control={control}
							name="specificBelt"
							render={({ field: { onChange, value }, }) => (
								<SelectOne
									error={errors.specificBelt?.message}
									label="Specific Belt for instructor"
									onChange={(option) => onChange(option.value)}
									options={specificBeltOptions}
									placeholder="Specific Belt for instructor"
									value={value}
									wrapperClasses="select--outline select--outline-bg input--lg w-full"
								/>
							)}
						/>
					</div>

					<div className="col-12">
						<Controller
							control={control}
							name="languages"
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<SelectMulty
									error={errors.languages?.message}
									label="Preferred Language"
									onChange={(options) => onChange(options.map((option) => option.value))}
									options={languageOptions}
									placeholder="Select preferred languages"
									value={value}
									wrapperClasses="select--outline select--outline-bg input--lg w-full"
								/>
							)}
						/>
					</div>
				</>
			)}

			<div className="col-md-6 col-sm-6 col-12">
				<Controller
					control={control}
					name="targetAudience"
					render={({ field: { onChange, value } }) => (
						<SelectOne
							error={errors.targetAudience?.message}
							label="Open to"
							onChange={(option) => onChange(option.value)}
							options={targetAudienceOptions}
							placeholder="Select target audience"
							value={value}
							wrapperClasses="select--outline select--outline-bg input--lg w-full"
						/>
					)}
				/>
			</div>


			{role === USER_ROLE.academy && (
				<div className="col-md-6 col-sm-6 col-12">
					<Input
						wrapperClasses="input--lg"
						inputClasses="input--solid"
						afterLabel={(<TooltipInfo content={explanations.maxGymCapacity} />)}
						label="Max Gym capacity"
						placeholder="0"
						type="text"
						error={errors.maxGymCapacity?.message}
						{...register("maxGymCapacity")}
					/>
				</div>
			)}

			{planningStatus !== PLANNING_STATUS.draft && (
				<>
					<div className="col-md-6 col-sm-6 col-12">
						<Controller
							control={control}
							name="minAttendance"
							render={({ field: { onChange, value, ref }, formState: { errors } }) => (
								<Input
									inputClasses={"input--solid"}
									label={"Minimum Attendance Requirement"}
									afterLabel={(<TooltipInfo content={explanations.minimumAttendance} />)}
									readOnly={!isSharedProfit}
									type="number"
									wrapperClasses={"input--lg"}
									error={isSharedProfit && errors.minAttendance?.message}
									onChange={onChange}
									placeholder={"0"}
									value={isSharedProfit ? value : minAttendance}
								/>
							)}
						/>
					</div>

					<div className="col-md-6 col-sm-6 col-12">
						<DatePicker
							dateFormat={"dd.MM.yyyy"}
							afterLabel={(<TooltipInfo content={explanations.minAttendanceDeadline} />)}
							label={"Minimum Attendance Deadline"}
							placeholderText={"Select Event Date"}
							readOnly
							selected={minAttendanceDeadline}
						/>
					</div>
				</>
			)}

			<Controller
				control={control}
				name="allowUnder18"
				render={({ field: { onChange, value } }) => (
					<CheckboxSwitch
						checked={!!value}
						onChange={() => onChange(value ? 0 : 1)}
						text="Do you allow students under the age of 18 to attend your seminar/camp?"
					/>
				)}
			/>

			<div className="col-12">
				<hr className="hr" />
			</div>

			<div className="col-12">
				<Controller
					control={control}
					name="teachingStyles"
					render={({ field: { onChange, value } }) => (
						<SelectMulty
							error={errors.teachingStyles?.message}
							label={role === USER_ROLE.instructor
								? "What do you want to teach?"
								: "Teaching Style"}
							onChange={(options) => onChange(options.map((option) => option.value))}
							options={teachingStyleOptions}
							placeholder="Add teaching style..."
							value={value}
							wrapperClasses="select--outline select--outline-bg input--lg w-full my-18"
						/>
					)}
				/>
			</div>
			{/* {role === USER_ROLE.instructor && (
				<InviteInstructors
					onChange={(field, data) => setValue("invited_instructors", data)}
					preview={preview}
					onPreviewChange={(field, data) => setPreview((prev) => ({ ...prev, [field]: data }))}
				/>
			)} */}
		</div>
	)
}

export default CampInformationTab