export const notificationEvents = {
    INSTRUCTOR_FREE: 'instructor_as_free',
    REQUEST_SEMINAR: 'request_seminar',
    REQUEST_PLANCAMP: 'request_plancamp',
    REQUEST_PRIVATE_LESSON: 'request_private_lesson',

    // Instructor notificationsPage
    IN_INSTRUCTOR_SEMINAR_REQUEST_APPROVED: 'in_instructor_seminar_request_approved',
    IN_SEMINAR_CAMP_PUBLISHED: 'in_seminar_camp_published',
    IN_INSTRUCTOR_ACCOUNT_VERIFIED: 'in_instructor_account_verified',
    IN_MINIMUM_ATTENDANCE_DEADLINE_REMINDER: 'in_minimum_attendance_deadline_reminder',
    IN_MINIMUM_ATTENDANCE_DEADLINE_STATUS: 'in_minimum_attendance_deadline_status',
    IN_MINIMUM_ATTENDANCE_MET_BEFORE_DEADLINE: 'in_minimum_attendance_met_before_deadline',
    IN_SEMINAR_CANCELLED_DUE_TO_LOW_ATTENDANCE: 'in_seminar_cancelled_due_to_low_attendance',
    IN_SEMINAR_PAYMENT_PROCESSED: 'in_seminar_payment_processed',
    IN_PRIVATE_LESSON_REQUEST_RECEIVED: 'in_private_lesson_request_received',
    IN_INSTRUCTOR_INVITED_TO_COLLABORATE: 'in_instructor_invited_to_collaborate',
    IN_CONFIRM_SEMINAR_COMPLETION: 'in_confirm_seminar_completion',
    IN_INSTRUCTOR_VERIFICATION_DENIED: 'in_instructor_verification_denied',
    IN_NEW_SEMINAR_REQUEST_RECEIVED: 'in_new_seminar_request_received',

    // Academy notificationsPage
    AN_ACADEMY_SEMINAR_REQUEST_APPROVED: 'an_academy_seminar_request_approved',
    AN_SEMINAR_CAMP_PUBLISHED: 'an_seminar_camp_published',
    AN_ACADEMY_ACCOUNT_VERIFIED: 'an_academy_account_verified',
    AN_MINIMUM_ATTENDANCE_DEADLINE_REMINDER: 'an_minimum_attendance_deadline_reminder',
    AN_MINIMUM_ATTENDANCE_DEADLINE_STATUS: 'an_minimum_attendance_deadline_status',
    AN_MINIMUM_ATTENDANCE_MET_BEFORE_DEADLINE: 'an_minimum_attendance_met_before_deadline',
    AN_SEMINAR_CANCELLED_DUE_TO_LOW_ATTENDANCE: 'an_seminar_cancelled_due_to_low_attendance',
    AN_SEMINAR_PAYMENT_PROCESSED: 'an_seminar_payment_processed',
    AN_CONFIRM_SEMINAR_COMPLETION_BY_ACADEMY: 'an_confirm_seminar_completion_by_academy',
    AN_ACADEMY_VERIFICATION_DENIED: 'an_academy_verification_denied',

    // Student notificationsPage
    SN_SEMINAR_FULLY_CONFIRMED: 'sn_seminar_fully_confirmed',
    SN_SEMINAR_CANCELLED_DUE_TO_LOW_ATTENDANCE: 'sn_seminar_cancelled_due_to_low_attendance',
    SN_SEMINAR_CANCELLED: 'sn_seminar_cancelled'
}

export const notificationEventGroups = {
    navigateToRequests: [
        'request_seminar',
        'request_plancamp',
        'request_private_lesson',
        'in_instructor_invited_to_collaborate',
        'in_private_lesson_request_received',
        'in_new_seminar_request_received',
    ],

    navigateToInstructor: [
        'instructor_as_free',
        'in_instructor_account_verified',
        'in_instructor_verification_denied',
    ],

    navigateToAcademy: [
        'an_academy_seminar_request_approved',
        'an_academy_account_verified',
        'an_academy_verification_denied',
    ],

    navigateSeminarByAcademy: [
        'an_seminar_camp_published',
        'an_minimum_attendance_deadline_reminder',
        'an_minimum_attendance_deadline_status',
        'an_minimum_attendance_met_before_deadline',
        'an_seminar_payment_processed',
        'an_confirm_seminar_completion_by_academy',
        'an_seminar_cancelled_due_to_low_attendance',
    ],

    navigateSeminarByInstructor: [
        'in_instructor_seminar_request_approved',
        'in_seminar_camp_published',
        'in_seminar_payment_processed',
        'in_confirm_seminar_completion',
    ],

    other: [
        'in_minimum_attendance_deadline_reminder',
        'in_minimum_attendance_deadline_status',
        'in_minimum_attendance_met_before_deadline',
        'in_seminar_cancelled_due_to_low_attendance',
        'sn_seminar_fully_confirmed',
        'sn_seminar_cancelled_due_to_low_attendance',
        'sn_seminar_cancelled',
    ],
};

