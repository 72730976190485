import { Country } from "country-state-city";
import { format, isValid } from "date-fns";
import moment from "moment";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useParams
} from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api";
import CheckboxSwitch from "../../../components/ui/formElements/CheckboxSwitch";
import PageLoader from "../../../components/ui/PageLoader";
import { getLanguagesByKeys, NOT_SPECIFIED } from "../../../constants";
import { EVENT_STEP, USER_ROLE } from "../../../constants/enums";
import { EventHelper } from "../../../helpers/EventHelper";
import {
    getDateRange, getImageSrc
} from "../../../helpers/utils";
import { getAcademyGeneralInfo } from "../../../redux/slices/academyGeneralInfoSlice";
import { getUser } from "../../../redux/slices/applicationSlice";
import { getAcademyGeneralInfoFetch } from "../../../redux/thunks/academyGeneralInfoThunks";
import { getChatByIdFetch } from "../../../redux/thunks/chatsThunks";
import {
    acceptSeminarRequestFetch
} from "../../../redux/thunks/seminarsThunks";
import { AccordionStep } from "../components/AccordionStep";
import { CardDetails } from "../components/CardDetails";


const SeminarSummaryPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [agree, setAgree] = useState({
        booking: false,
        terms: false
    });
    const { seminarId, chatId, requestId } = useParams();
    const user = useSelector(getUser);
    const { currentChatData, isLoading } = useSelector((state) => state.chats);

    const seminarDetails = currentChatData?.seminar

    const academyInfo = useSelector(getAcademyGeneralInfo);
    const [instructorInfo, setInstructorInfo] = useState(null);

    const getInstructorGeneralInfo = async () => {
        const res = await api.profiles.getInstructorGeneralInfo();
        if (res.success) {
            setInstructorInfo(res.data);
        }
    };

    const location = () => {
        const info = user?.role === USER_ROLE.academy ? academyInfo : instructorInfo;
        if (!info) return NOT_SPECIFIED
        const country = Country.getCountryByCode(info.country)?.name
        const location = [country, info.city].filter(Boolean).join(", ");
        return location || NOT_SPECIFIED
    }

    useEffect(() => {
        if (user?.role === USER_ROLE.academy) {
            dispatch(getAcademyGeneralInfoFetch());
        }

        if (user?.role === USER_ROLE.instructor) {
            getInstructorGeneralInfo();
        }
    }, [user]);

    useEffect(() => {
        if (!chatId) return;

        dispatch(getChatByIdFetch({ chatId }));
    }, [chatId]);

    const changeAgree = (type) => {
        setAgree((prev) => ({
            ...prev,
            [type]: !prev[type]
        }));
    };

    const handleSubmit = async () => {
        if (Object.values(agree).some((value) => !value)) {
            toast.warn("You must agree to the terms");
            return;
        }

        const res = await dispatch(acceptSeminarRequestFetch({
            seminarId: seminarDetails.id,
            requestId,
            body: {
                step: "terms"
            }
        })).unwrap();

        if (res) {
            navigate(`/chat/${chatId}`);
        }
    };

    const preview = seminarDetails?.user?.role === USER_ROLE.academy
        ? `/planed-by-academy/${seminarId}`
        : `/planed-by-insctructor/${seminarId}`;

    if (isLoading) {
        return <PageLoader />
    }
    return (
        <section className="section-42">
            <div className="container">
                {
                    seminarDetails && (
                        <div className="double-section flex gap-80">
                            <div className="double-section__content">
                                <h3 className="heading">
                                    Seminar Summary
                                </h3>

                                <div className="row-group gap--xxl">
                                    <div className="image-wrapper --small-thumb">
                                        <img
                                            alt={seminarDetails?.seminarName}
                                            src={getImageSrc(seminarDetails?.photos?.find((photo) => photo.id === seminarDetails.coverId)?.url)}
                                        />
                                    </div>

                                    <div className="col-group flex-auto gap--lg justify-between">
                                        <p className="text--xs color-text">
                                            {getDateRange(seminarDetails?.seminarDates)}
                                        </p>

                                        <div className="seminar-label-info">
                                            <h6 className="heading">
                                                {seminarDetails?.seminarName}
                                            </h6>
                                        </div>
                                    </div>

                                    <Link
                                        className="btn btn--default btn--sm"
                                        to={preview}
                                    >
                                        Preview
                                    </Link>
                                </div>

                                <div className="col-group">
                                    <AccordionStep
                                        href={`./..?edit=${EVENT_STEP.date}`}
                                        title="Location & Date"
                                    >
                                        <div className="row gx-16 py-24 mb-10">
                                            <div className="col-lg-6">
                                                <CardDetails label="Country">
                                                    {Country.getCountryByCode(seminarDetails.country)?.name}
                                                </CardDetails>
                                            </div>

                                            <div className="col-lg-6">
                                                <CardDetails label="Address">
                                                    {seminarDetails.address}
                                                </CardDetails>
                                            </div>

                                            <div className="col-lg-6">
                                                <CardDetails label="Dates & time">
                                                    {seminarDetails?.seminarDates?.map((date) => (
                                                        <p key={date.date}>
                                                            {`${moment(date.date).format("DD MMM, YYYY")}, ${[date.startTime, date.endTime].filter(Boolean).join(" - ")}`}
                                                        </p>
                                                    ))}
                                                </CardDetails>
                                            </div>

                                            <div className="col-lg-6">
                                                <CardDetails label="Private lessons Time">
                                                    {seminarDetails?.privateSessionDates?.map((date) => (
                                                        <p key={date.date}>
                                                            {`${moment(date.date).format("DD MMM, YYYY")}, ${[date.startTime, date.endTime].filter(Boolean).join(" - ")}`}
                                                        </p>
                                                    ))}
                                                </CardDetails>
                                            </div>
                                        </div>
                                    </AccordionStep>

                                    <AccordionStep
                                        href={`./../seminar-info?edit=${EVENT_STEP.details}`}
                                        title="Seminar Details"
                                    >
                                        <div className="row gx-16 py-24 mb-10">
                                            <div className="col-12">
                                                <CardDetails label="Teaching Styles">
                                                    {EventHelper.teaching(seminarDetails)}
                                                </CardDetails>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <CardDetails label="Language">
                                                    {getLanguagesByKeys(seminarDetails.languages)}
                                                </CardDetails>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <CardDetails label="Gi or NoGi">
                                                    {EventHelper.style(seminarDetails).title}
                                                </CardDetails>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <CardDetails label="Max Gym Capacity">
                                                    {seminarDetails.maxGymCapacity}
                                                </CardDetails>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <CardDetails label="Open to">
                                                    {seminarDetails.targetAudience === "any" ? "Everyone" : seminarDetails.targetAudience}
                                                </CardDetails>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <CardDetails label="Under the Age of 18">
                                                    {seminarDetails.allowUnder18 ? "Allowed" : "Not Allowed"}
                                                </CardDetails>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <CardDetails label="Min Attendance">
                                                    {seminarDetails.minAttendance}
                                                </CardDetails>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <CardDetails label="Min Attendance Deadline">
                                                    {isValid(new Date(seminarDetails.minAttendanceDeadline)) && format(new Date(seminarDetails.minAttendanceDeadline), "dd-MM-yyyy")}
                                                </CardDetails>
                                            </div>
                                        </div>
                                    </AccordionStep>

                                    <AccordionStep
                                        href={`./../design-text?edit=${EVENT_STEP.design}`}
                                        title="Design & Text"
                                    >
                                        {seminarDetails?.photos?.length && (
                                            <div className="row gutters-form-7">
                                                {seminarDetails?.photos.map((photo) => (
                                                    <div
                                                        className="col-2"
                                                        key={photo?.id}
                                                    >
                                                        <img
                                                            className="w-full"
                                                            src={getImageSrc(photo?.url)}
                                                            style={{ height: 96 }}
                                                            alt={photo?.originalname}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        <CardDetails label="Seminar Name">
                                            <p>{seminarDetails?.seminarName}</p>
                                        </CardDetails>

                                        <CardDetails label="Summary">
                                            <p>{seminarDetails?.summary}</p>
                                        </CardDetails>
                                    </AccordionStep>

                                    <AccordionStep
                                        href={`./../booking?edit=${EVENT_STEP.terms}`}
                                        title="Booking Specific Terms"
                                    >
                                        <div className="terms-doc text--sm">
                                            {currentChatData?.academyAccept?.terms}
                                        </div>
                                    </AccordionStep>
                                </div>

                                <button
                                    className="btn btn--primary btn--lg"
                                    onClick={handleSubmit}
                                >
                                    <span className="info">
                                        Confirm
                                    </span>
                                </button>

                                <div className="option-group mb-36">
                                    <CheckboxSwitch
                                        checked={agree.terms}

                                        onChange={() => changeAgree("terms")}
                                        text={(
                                            <span>
                                                I have read and agreed to the{" "}
                                                <Link
                                                    className="color-secondary"
                                                    to="terms"
                                                >
                                                    Terms and Conditions
                                                </Link>{" "}
                                                and{" "}
                                                <Link
                                                    className="color-secondary"
                                                    to="privacy"
                                                >
                                                    Privacy Policy
                                                </Link>
                                            </span>
                                        )}
                                    />

                                    <CheckboxSwitch
                                        checked={agree.booking}
                                        onChange={() => changeAgree("booking")}
                                        text="I accept the Booking Specific Terms (if included)"
                                    />
                                </div>
                            </div>

                            <div className="double-section__aside">
                                <div className="card-shadow px-36 py-32 mb-16">
                                    <div className="instructor-profile__group">
                                        <div className="instructor-profile__image-logo">
                                            <img
                                                alt={user?.profile?.name || user?.profile?.fullName}
                                                className="ico ico--md rounded-full"
                                                src={getImageSrc(user?.profile?.photo)}
                                            />
                                        </div>

                                        <div className="instructor-profile__group-info">
                                            <h6 className="instructor-profile__name heading ttu mb-1">
                                                {user?.profile?.name || user?.profile?.fullName}
                                            </h6>

                                            <p className="instructor-profile__location">
                                                {location()}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-shadow px-36 py-32">
                                    <h6 className="heading mb-28">
                                        Price Information
                                    </h6>

                                    <div className="ticket-info-body ticket-info-body--no-border">
                                        <div className="ticket-info-body__item justify-between border-bottom pb-28 mb-28">
                                            {seminarDetails?.sharedProfit ? (
                                                <>
                                                    <span className="color-text">
                                                        Shared Profit:
                                                    </span>
                                                    <span className="fw-500">
                                                        {seminarDetails?.sharedProfit}

                                                        {" "}

                                                        /

                                                        {100 - seminarDetails?.sharedProfit}

                                                        %
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <span className="color-text">
                                                        Ticket price:
                                                    </span>
                                                    <span className="fw-500">
                                                        ${seminarDetails?.minimalPriceTicket}
                                                    </span>
                                                </>
                                            )}
                                        </div>

                                        <div className="ticket-info-body__item justify-between">
                                            <span className="color-text">
                                                Instructor Cut:
                                            </span>

                                            <span className="fw-500">
                                                $ {EventHelper.instructorCut(seminarDetails)}
                                            </span>
                                        </div>

                                        <div className="ticket-info-body__item justify-between">
                                            <span className="color-text">
                                                Academy Potential Revenue:
                                            </span>

                                            <span className="fw-500">
                                                $ {EventHelper.academyCut(seminarDetails)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </section>
    );
};

export default SeminarSummaryPage;
