import {
    createBrowserRouter, createRoutesFromElements, Route
} from "react-router-dom";
import DefaultLayout from "../layouts/DefaultLayout";
import { MySeminarsLayout } from "../layouts/MySeminarsLayout";
import ProfileLayout from "../layouts/ProfileLayout";
import SecondaryLayout from "../layouts/SecondaryLayout";
import SocketProvider from "../layouts/SocketProvider";
import AcademiesPage from "../pages/academies/AcademiesPage";
import AcademySingle from "../pages/academies/AcademySingle";
import BuySeminarPage from "../pages/booking/BuySeminarPage";
import { Chat } from "../pages/chat/Chat";
import ContactUs from "../pages/ContactUs";
import CookiesSettings from "../pages/CookiesSettings";
import HomePage from "../pages/HomePage";
import { Instructors } from "../pages/instructors/Instructors";
import { InstructorSingle } from "../pages/instructors/InstructorSingle";
import InviteToSeminar from "../pages/instructors/InviteToSeminar";
import SuccessInvite from "../pages/instructors/SuccessInvite";
import NotFoundPage from "../pages/NotFoundPage";
import BillingInfo from "../pages/profiles/studentProfilePages/BillingInfo";

import { EVENT_TYPE } from "../constants/enums";
import AppLayout from "../layouts/AppLayout";
import BreadcrumbsProvider from "../layouts/BreadcrumbsProvider";
import BuyPrivateLessonPage from "../pages/booking/BuyPrivateLessonPage";
import { CampsMain } from "../pages/events/capms/CampsMain";
import { CampsPendingByAcademy } from "../pages/events/capms/CampsPendingByAcademy";
import { CampsPendingByInstructor } from "../pages/events/capms/CampsPendingByInstructor";
import { CampsSingle } from "../pages/events/capms/CampsSingle";
import SeminarSingle from "../pages/events/seminars/SeminarSingle";
import { SeminarMain } from "../pages/events/seminars/SeminarsMain";
import SeminarsPendingByAcademy from "../pages/events/seminars/SeminarsPendingByAcademy";
import SeminarsPendingByInstructor from "../pages/events/seminars/SeminarsPendingByInstructor";
import TourSingle from "../pages/events/tours/TourSingle";
import { ToursMain } from "../pages/events/tours/ToursMain";
import ToursPendingByInstructor from "../pages/events/tours/ToursPendingByInstructor";
import { RequestPrivateSession } from "../pages/instructors/requestPrivateSession";
import CampOverviewPage from "../pages/overview/CampOverviewPage";
import SeminarOverviewPage from "../pages/overview/SeminarOverviewPage";
import TourOverviewPage from "../pages/overview/TourOverviewPage";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import GeneralInfo from "../pages/profiles/GeneralInfo";
import LocationInstructr from "../pages/profiles/instructorProfilePages/LocationInstructr";
import PrivateSessionInstructor from "../pages/profiles/instructorProfilePages/PrivateSessionInstructor";
import { RequestedPtivateLessons } from "../pages/profiles/instructorProfilePages/RequestedPtivateLessons";
import MyProfile from "../pages/profiles/MyProfile";
import { MySeminars } from "../pages/profiles/MySeminars";
import PayuotMethods from "../pages/profiles/PayuotMethods";
import Security from "../pages/profiles/Security";
import MyTickets from "../pages/profiles/studentProfilePages/MyTickets";
import RefundPolicy from "../pages/RefundPolicy";
import RequestsPage from "../pages/requests/RequestsPage";
import OAuthSignUp from "../pages/singUpPages/OAuthSignUp";
import TermsAndConditions from "../pages/TermsAndConditions";
import TermsOfUse from "../pages/TermsOfUse";
import TicketPage from "../pages/TicketPage";
import { planningRoutes } from "./planningRoutes";
import { signUpRoutes } from "./signUpRoutes";
import StudentVerifyEmailPage from "../pages/profiles/studentProfilePages/StudentVerifyEmailPage";
import ProtectedRoute from "../layouts/ProtectedRoute";
import NotificationsPage from "../pages/notificationsPage/NotificationsPage";
import AboutUs from "../pages/AboutUs";

export const router = createBrowserRouter(createRoutesFromElements(
    <Route element={<AppLayout />}>
        <Route element={<BreadcrumbsProvider />}>
            <Route element={<SocketProvider />}>
                <Route element={<DefaultLayout />}>
                    <Route element={<HomePage />} path="/" />

                    <Route
                        element={<HomePage />}
                        path="/recovery-password/:restoreToken"
                    />

                    <Route
                        element={<AboutUs />}
                        path="/about-us"
                    />

                    <Route
                        element={<ContactUs />}
                        path="/contact-us"
                    />

                    <Route
                        element={<Instructors />}
                        path="/instructors"
                    />
                </Route>

                <Route
                    element={(<DefaultLayout
                        footerHide={true}
                        headerClass="header--white"
                        mainClass="flex flex-col"
                        wrapperClass=" header-fixed"
                    />)}
                >
                    <Route
                        element={<AcademiesPage />}
                        path="/academies"
                    />

                    <Route element={<ProtectedRoute />}>
                        <Route
                            element={<Chat />}
                            path="/chat"
                        />

                        <Route
                            element={<Chat />}
                            path="/chat/:chatId"
                        />

                        <Route
                            element={<Chat />}
                            path="/chat/:chatId/:seminarId"
                        />

                        <Route
                            element={<NotificationsPage />}
                            path="/notifications"
                        />
                    </Route>



                </Route>

                <Route
                    element={(<DefaultLayout
                        headerClass="header--white"
                        mainClass="flex flex-col user-page header-fixed"
                    />)}
                    path="/instructors/:id"
                >
                    <Route
                        element={<InstructorSingle />}
                        index
                    />

                    <Route
                        element={<InviteToSeminar />}
                        path="invite/:tourId?"
                    />

                    <Route
                        element={<SuccessInvite />}
                        path="invite/success"
                    />
                </Route>

                <Route element={<ProtectedRoute />}>
                    <Route
                        element={(<DefaultLayout mainClass="current-seminars" />)}
                        path="/planed-by-academy"
                    >
                        <Route
                            element={<SeminarsPendingByAcademy />}
                            index
                        />
                    </Route>

                    <Route
                        element={(<DefaultLayout mainClass="current-seminars" />)}
                        path="/planed-by-insctructor"
                    >
                        <Route
                            element={<SeminarsPendingByInstructor />}
                            index
                        />
                    </Route>
                </Route>

                <Route
                    element={(<DefaultLayout
                        headerClass="header--white"
                        mainClass="flex flex-col user-page"
                        wrapperClass=" header-fixed"
                    />)}
                >
                    <Route
                        element={<InstructorSingle />}
                        path="/instructors/:id"
                    />

                    <Route
                        element={<AcademySingle />}
                        path="/academies/:id"
                    />
                </Route>

                <Route
                    element={(<DefaultLayout
                        headerClass="header--white"
                        mainClass="flex flex-col"
                        wrapperClass=" header-fixed"
                    />)}
                >
                    <Route element={<ProtectedRoute />}>
                        <Route
                            element={<SeminarOverviewPage />}
                            path="/my-seminars/:id"
                        />
                        <Route
                            element={<CampOverviewPage />}
                            path="/my-seminars/camps/:id"
                        />
                        <Route
                            element={<TourOverviewPage />}
                            path="/my-seminars/tour-overview/:tourId"
                        />
                    </Route>

                    <Route
                        element={<TicketPage />}
                        path="/pub-tickets/:hash"
                    />
                </Route>

                <Route
                    element={(<DefaultLayout
                        headerClass="header--white"
                        mainClass="flex flex-col my-seminars-page"
                        wrapperClass=" header-fixed"
                    />)}
                >
                    <Route
                        element={<RequestsPage />}
                        path="/requests"
                    />
                    <Route element={<ProtectedRoute />}>
                        <Route element={<MySeminarsLayout />}>
                            <Route
                                element={<MySeminars />}
                                path="/my-seminars"
                            />

                            <Route
                                element={<MyTickets />}
                                path="/my-tickets"
                            />
                            <Route
                                element={<RequestedPtivateLessons />}
                                path="/requested-lessons"
                            />
                        </Route>
                    </Route>
                </Route>

                <Route element={(<DefaultLayout mainClass="current-seminars" />)}>
                    <Route
                        element={<SeminarMain />}
                        path="/seminars"
                    />

                    <Route
                        element={<CampsMain />}
                        path="/camps"
                    />
                    <Route
                        element={<ToursMain />}
                        path="/tours"
                    />

                    <Route element={<ProtectedRoute />}>
                        <Route
                            element={<CampsPendingByAcademy />}
                            path="/planed-by-academy/camps"
                        />

                        <Route
                            element={<SeminarsPendingByAcademy />}
                            path="/planed-by-academy"
                        />

                        <Route
                            element={<SeminarsPendingByInstructor />}
                            path="/planed-by-insctructor"
                        />

                        <Route
                            element={<CampsPendingByInstructor />}
                            path="/planed-by-insctructor/camps"
                        />
                        <Route
                            element={<ToursPendingByInstructor />}
                            path="/planed-by-insctructor/tours"
                        />
                    </Route>
                </Route>

                <Route
                    element={(<DefaultLayout
                        headerClass="header--white"
                        mainClass="current-seminars"
                        wrapperClass=" header-fixed"
                    />)}
                >
                    <Route
                        element={<SeminarSingle />}
                        path="/seminars/:id"
                    />

                    <Route
                        element={<CampsSingle />}
                        path="/camps/:id"
                    />
                    <Route
                        element={<CampsSingle />}
                        path="/tours/:id"
                    />

                    <Route element={<ProtectedRoute />}>
                        <Route
                            element={(<SeminarSingle pageTyleDefault="pending" />)}
                            path="/planed-by-insctructor/:id"
                        />

                        <Route
                            element={(<CampsSingle pageTyleDefault="pending" />)}
                            path="/planed-by-insctructor/camps/:id"
                        />

                        <Route
                            element={(<TourSingle pageTyleDefault="pending" />)}
                            path="/planed-by-insctructor/tours/:id"
                        />

                        <Route
                            element={<SeminarSingle pageTyleDefault="pending" />}
                            path="/planed-by-academy/:id"
                        />

                        <Route
                            element={<CampsSingle pageTyleDefault="pending" />}
                            path="/planed-by-academy/camps/:id"
                        />
                    </Route>
                </Route>

                {/* sign up */}
                <Route element={<DefaultLayout />}>
                    <Route
                        element={<OAuthSignUp />}
                        path="/sign-in"
                    />
                </Route>

                {signUpRoutes}

                <Route element={<ProtectedRoute />}>
                    <Route element={<ProfileLayout />}>
                        <Route
                            element={<MyProfile />}
                            path="/settings/my-profile"
                        />

                        <Route
                            element={<Security />}
                            path="/settings/security"
                        />

                        <Route
                            element={<GeneralInfo />}
                            path="/settings/general-information"
                        />

                        <Route
                            element={<PayuotMethods />}
                            path="/settings/payout-methods"
                        />

                        <Route
                            element={<LocationInstructr />}
                            path="/settings/location-availability"
                        />

                        <Route
                            element={<PrivateSessionInstructor />}
                            path="/settings/private-session"
                        />

                        <Route
                            element={<BillingInfo />}
                            path="/settings/billing"
                        />

                        <Route
                            element={<StudentVerifyEmailPage />}
                            path="/verification"
                        />
                    </Route>
                </Route>


                {/* planning */}
                <Route element={<ProtectedRoute />}>
                    {planningRoutes}
                </Route>

                <Route element={<SecondaryLayout />}>
                    <Route
                        element={<RequestPrivateSession.RequestPrivateSessionWrapper />}
                        path="/request-private-session/:instructorId"
                    >
                        <Route
                            element={<RequestPrivateSession.TimeAndDate />}
                            index
                        />
                    </Route>

                    <Route
                        element={<RequestPrivateSession.Summary />}
                        path="/request-private-session/:instructorId/:chatId/summary"
                    />

                    <Route
                        element={<BuySeminarPage type={EVENT_TYPE.seminar} />}
                        path="/book/:id"
                    />
                    <Route
                        element={<BuySeminarPage type={EVENT_TYPE.camp} />}
                        path="/book/camp/:id"
                    />
                    <Route
                        element={<BuyPrivateLessonPage />}
                        path="/book-lesson/:eventType/:eventId"
                    />

                    <Route
                        element={<TermsAndConditions />}
                        path="terms-of-service"
                    />

                    <Route
                        element={<TermsOfUse />}
                        path="terms-of-use"
                    />

                    <Route
                        element={<PrivacyPolicy />}
                        path="privacy-policy"
                    />

                    <Route
                        element={<RefundPolicy />}
                        path="refund-policy"
                    />

                    <Route
                        element={<CookiesSettings />}
                        path="cookies-settings"
                    />

                    <Route
                        element={<NotFoundPage />}
                        path="*"
                    />
                </Route>
            </Route>
        </Route>
    </Route>
), { basename: "/" });
