import React from "react";
import { Link } from "react-router-dom";
import EditIcon from "../../../../assets/img/ico-pen.svg";
import { NOT_SPECIFIED } from "../../../../constants";
import { CardDetails } from "../components/CardDetails";
import { Step } from "../components/Step";

const TermsStep = ({ data, link = '/', isEditable }) => {
    return (
        <Step label="Booking Specific Terms">
            <CardDetails
                extraLabel={
                    data && isEditable && (
                        <Link
                            to={link}
                            className="btn btn--xs color-secondary text-normal text-14 fw-500"
                        >
                            <span className="ico ico-14 gap-4">
                                <img
                                    src={EditIcon}
                                    alt="EditIcon"
                                />
                            </span>

                            <span className="info">
                                Edit
                            </span>
                        </Link>
                    )}
                isEdited={!data}
                title="Check terms"
            >
                {data ? data : isEditable ? (
                    <Link
                        to={link}
                        className="fw-500 text-14 color-secondary"
                    >
                        Add Booking Specific Terms
                    </Link>
                ) : NOT_SPECIFIED}
            </CardDetails>
        </Step>
    );
};

export default TermsStep;
