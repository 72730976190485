import classNames from "classnames";
import { useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import AccordeonTypeArrow from "../../../components/ui/accordeons/AccordeonTypeArrow";
import Loader from "../../../components/ui/Loader";

const AcademyList = ({ academiesData, isLoading, activeAcademy, setActiveAcademy }) => {
    const itemHeights = useRef({}); // Store dynamic heights for each item
    const listRef = useRef(); // Ref for the list to trigger updates

    // Function to set the height of an item dynamically
    const setItemSize = useCallback((index, size) => {
        if (itemHeights.current[index] !== size) {
            itemHeights.current[index] = size;
        }
    }, []);

    // Get the height of an item
    const getItemSize = (index) => itemHeights.current[index] || 51; // Default height

    // Handle item click to increase its height
    const handleHeightChange = useCallback((index, height) => {
        setItemSize(index, height);
        // Trigger a re-render of the list from the clicked item onward
        listRef.current.resetAfterIndex(index, true);
    }, [setItemSize]);

    const renderRow = ({ index, style }) => {
        const academy = academiesData[index];

        return (
            <div
                style={style}
                ref={(el) => {
                    if (el) {
                        const height = el.getBoundingClientRect().height;
                        setItemSize(index, height);
                    }
                }}
            >
                <AccordeonTypeArrow
                    isActive={activeAcademy?.id === academy.id}
                    objData={academy}
                    onHeightCahange={(height) => handleHeightChange(index, height)}
                    onClick={() => {
                        if (activeAcademy?.id === academy.id) {
                            setActiveAcademy(null);
                        } else {
                            setActiveAcademy(academy);
                        }
                    }}
                    title={academy?.title}
                >
                    <p className="color-grey">{academy.addressLine}</p>
                    {!academy?.isTemplate && (
                        <Link
                            to={`${academy?.id}`}
                            className={classNames("btn btn--md btn--primary", {
                                disabled: !academy?.verify,
                            })}
                        >
                            <span>Go to Profile</span>
                        </Link>
                    )}
                </AccordeonTypeArrow>
            </div>
        );
    };

    if (isLoading) {
        return <Loader />;
    }

    if (!academiesData.length) {
        return <h1>No found</h1>;
    }

    return (
        <AutoSizer
            disableWidth
        >
            {({ height }) => (
                <List
                    ref={listRef}
                    height={height}
                    width={'100%'}
                    itemCount={academiesData.length}
                    itemSize={getItemSize}
                >
                    {renderRow}
                </List>
            )}
        </AutoSizer>
    )
};

export default AcademyList;
