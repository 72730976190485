import React, { useEffect, useState } from "react";
import MapBox from "../../../components/parts/mapBox/MapBox";
import { getCoordinates } from "../../../components/parts/mapBox/mapBoxFunctions";

const SinglePageMapBlock = ({ data }) => {
    const [coordinates, setCoordinates] = useState([]);

    useEffect(() => {
        const country = data?.country;
        const addressLine = data?.address;
        const city = data?.city;
        const location = [country, city, addressLine].filter(Boolean).join(",");
        if (location?.length) {
            fetchCoordinates(location);
        }
    }, [data]);

    const fetchCoordinates = async (address) => {
        try {
            const response = await getCoordinates(address);
            const { lat, lon } = response.coordinates;
            setCoordinates([lon, lat]);

        } catch (error) {
            console.error("Error fetching coordinates:", error);
        }
    };
    return (
        <div className="border-bottom mb-42 pb-42">
            <h4 className="heading mb-32">
                Location on Map
            </h4>

            <div className="map-section">
                <div
                    style={{
                        height: "250px",
                        width: "100%"
                    }}
                >
                    <MapBox
                        mainPoints={coordinates.length ? [{ coordinates }] : []}
                        newCenter={coordinates.length ? coordinates : undefined}
                    />
                </div>
            </div>
        </div>
    );
};

export default SinglePageMapBlock;
