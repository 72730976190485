import classNames from 'classnames'
import React, { useState } from 'react'
import ModalTemplate from './ModalTemplate'
import { ReactComponent as DeleteIcon } from "../../assets/img/ico-trash.svg";
import { getImageSrc } from '../../helpers/utils';

const VerifyPassportModal = ({ onClose, open, onSubmit }) => {
    const [files, setFiles] = useState([]);

    const addFiles = (files) => {
        setFiles(prev => ([...prev, ...files]));
    }
    const handleFileChange = (e) => {
        addFiles(e.target.files)
    };

    const handleDrop = (e) => {
        e.preventDefault();
        addFiles(e.dataTransfer.files)
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleRemove = (e, index) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (files?.length) {
            onSubmit && onSubmit(files[0]);
        }
    }

    return (
        <ModalTemplate
            onClick={onClose}
            open={open}
            modalSize='modal__dialog--lg'
        >
            <div className="modal__body">
                <form
                    className="form form--lg"
                    method="post"
                >
                    <div className="form-header">
                        <div className="heading-block text-center">
                            <h3 className="heading">
                                Verify your account with a valid ID
                            </h3>

                            <p className="text--md desk-heading">
                                Please upload a valid government-issued ID (such as a passport, driver's license or state ID) in one of the following formats: .jpg, .jpeg, .png, .pdf, .gif, .bmp, .tiff
                            </p>
                        </div>
                    </div>

                    <div className="form-body">
                        {!files?.length && (
                            <label
                                className="drop-area"
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                            >
                                <input
                                    type="file"
                                    className='hidden'
                                    onChange={handleFileChange}
                                    accept=".jpg,.jpeg,.png,.pdf,.gif,.bmp,.tiff"
                                />
                                <p>Drag & Drop your ID here or click to select a file</p>
                            </label>
                        )}
                        <div className="row g-8 mb-42">
                            {files.map((image, index) => (
                                <div
                                    key={index}
                                    className="col-12"
                                >
                                    <div
                                        className="seminar-gallery-item"
                                        style={{
                                            cursor: "pointer",
                                            aspectRatio: "16/9",
                                            position: "relative",
                                        }}
                                    >
                                        <img style={{ position: 'absolute', inset: 0 }} src={getImageSrc(image)} alt={'document'} />
                                        <div className="seminar-gallery-item__actions">
                                            <button
                                                onClick={(e) => handleRemove(e, index)}
                                                type="button"
                                                className="btn btn--secondary-2 btn--square btn--xxs rounded-full"
                                            >
                                                <span className="ico">
                                                    <DeleteIcon />
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='row-group justify-between gap--sm'>
                            <button
                                className='btn btn--default btn--md gap-10'
                                onClick={onClose}
                                type="button"
                            >
                                <span className="info">
                                    Cancel
                                </span>
                            </button>
                            <button
                                className={classNames('btn btn--primary btn--md gap-10', { disabled: !files?.length })}
                                onClick={handleSubmit}
                                type="button"
                            >
                                <span className="info">
                                    Verify
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalTemplate>
    )
}

export default VerifyPassportModal


