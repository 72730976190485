import React from 'react'

const HeadingBlock = ({ title, description }) => {
    return (
        <div className="heading-block text-center mb-12">
            <h3 className="heading">
                {title}
            </h3>

            <p className="desk-heading">
                {description}
            </p>
        </div>
    )
}

export default HeadingBlock
