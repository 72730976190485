import React, { useState } from "react";
import { SeminarTemplate } from "../parts/SeminarTemplate";
import { useSelector } from "react-redux";
import { getAllCampsFetch } from "../../../redux/thunks/planCampThuncks";
import { Country } from "country-state-city";
import {
    belts, languages, trainingStyle
} from "../../../constants";
import { useTranslation } from "react-i18next";

export const CampsPendingByInstructor = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
    const { camps, isLoading, pagination } = useSelector((state) => state.planCamps);
    const [filters] = useState({
        sort_by: "",
        sort_as: "desc",
        gender: "",
        location: "",
        price: "",
        trainingStyle: "",
        belt: "",
        language: "",
        search: "",
        page: "",
        limit: ""
    });
    const allCountries = Country.getAllCountries();
    const locationOptions = allCountries?.map((item) => ({
        value: item.name.toLowerCase(),
        label: item.name
    })) || [];
    const priceOptions = [
        "$$$$$",
        "$$$$",
        "$$$",
        "$$",
        "$"
    ].map((item) => ({
        label: item,
        value: item
    }));
    const trainingStyleOptions = trainingStyle.map(({ title, key }) => ({
        label: title,
        value: title
    }));
    const beltOptions = belts.map(({ title, key }) => ({
        label: title,
        value: title
    }));
    const languageOptions = languages.map(({ title, key }) => ({
        label: title,
        value: title
    }));
    // const sortOptions = [
    //     {
    //         label: t('date'),
    //         value: "id"
    //     }, {
    //         label: t('price'),
    //         value: "seminarMinPrice"
    //     }
    // ];
    const sortOptions = [
        { label: "Price High to Low", value: "seminarMinPrice&desc" },
        { label: "Price Low to High", value: "seminarMinPrice&asc" },
        { label: "Popularity", value: "camp_views&desc" },
    ]
    const filtersData = [
        {
            label: t('gi'),
            query: "trainingStyle",
            options: trainingStyleOptions,
            all: true
        }, {
            label: t('belt'),
            query: "belt",
            options: beltOptions
        }
    ];

    return (
        <SeminarTemplate
            baseQuery="status=pending&role=instructor"
            fethFuntion={getAllCampsFetch}
            filterLinks={[
                {
                    label: t('seminars'),
                    link: "/planed-by-insctructor"
                }, {
                    label: t('camps'),
                    link: "/planed-by-insctructor/camps"
                }
            ]}
            filters={filters}
            filtersData={filtersData}

            firsScreenOptions={{
                title: t('seminars_camps'),
                description: t('seminars_descr'),
                placeholder: t('seminars_placeholder'),
                imageBg: require("../../../assets/img/current-seminars.png")
            }}
            isLoading={isLoading}

            pagination={pagination}
            seminars={camps}
            sotrBy={sortOptions}
            name={t('camps')}
        />
    );
};
