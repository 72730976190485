import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import DropDown from "../../../../../components/ui/DropDown";
import DatePicker from "../../../../../components/ui/formElements/DatePicker";
import TimePicker from "../../../../../components/ui/formElements/TimePicker";
import { updateSeminarFetch } from "../../../../../redux/thunks/seminarsThunks";
import { covertDateFormat } from "../../../../planning/components/EventDatesSelect";
import { CardDetails } from "../../components/CardDetails";
import { Step } from "../../components/Step";

import { format, isValid } from "date-fns";
import { ReactComponent as DoneIcon } from "../../../../../assets/img/done.svg";
import { ReactComponent as PencilIcon } from "../../../../../assets/img/ico-pencil.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/img/ico-trash.svg";
import { EventHelper } from "../../../../../helpers/EventHelper";
import { convertTimeToDate } from "../../../../../helpers/utils";
import { PLANNING_STATUS } from "../../../../planning/constants/emuns";
import { commonSeminarDatesSchema } from "../../../../planning/validation/seminarSchema";
import { checkIsEdited } from "../../utils";



export const LocationStep = ({ data, isEditable }) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (availableDates) => {
        setIsLoading(true)
        const schema = commonSeminarDatesSchema(PLANNING_STATUS.negotiation);
        try {
            await schema.availableDates.validate(availableDates);
            await dispatch(updateSeminarFetch({
                seminarId: data.id,
                body: {
                    "availableDates": availableDates
                }
            })).unwrap();
        } catch (e) {
            console.log(e);
        }
        setIsLoading(false)
    }

    return (
        <>
            <Step
                label="Location & Date"
            >
                <CardDetails
                    isEdited={checkIsEdited(data?.lastEdited, ["address", "location"])}
                    title="Location"
                >
                    <p className="color-text capitalize">
                        {EventHelper.location(data)}
                    </p>
                </CardDetails>

                {data.startAt && data.endAt && (isValid(new Date(data.startAt)) && isValid(new Date(data.endAt))) && (
                    <CardDetails
                        title="Date Range"
                        isEdited={checkIsEdited(data?.lastEdited, ["startAt", "endAt"])}
                    >
                        <div className="row-group justify-between">
                            <p className="fw-500">
                                {format(new Date(data.startAt), "dd MMM, yyyy")}
                            </p>
                            <span> - </span>
                            <p className="fw-500">
                                {format(new Date(data.endAt), "dd MMM, yyyy")}
                            </p>
                        </div>
                    </CardDetails>
                )}
                <CardDetails
                    isEdited={checkIsEdited(data?.lastEdited, ['availableDates'])}
                    title="Set Date and Time"
                >

                    <DatesList
                        data={data?.seminarDates}
                        onSubmit={handleSubmit}
                        isLoading={isLoading}
                        isEditable={isEditable}
                    />
                </CardDetails>
            </Step>
        </>
    );
};
const DatesList = ({ data, onSubmit, isLoading, isEditable }) => {
    const formatDates = data => data.map((item) => ({
        ...item,
        date: isValid(new Date(item.date)) ? new Date(item.date) : null,
        startTime: convertTimeToDate(item.date, item.startTime),
        endTime: convertTimeToDate(item.date, item.endTime)
    }))

    const [formData, setFormData] = useState(formatDates(data));

    const [newDate, setNewDate] = useState({
        date: "",
        startTime: null,
        endTime: null
    });

    const handleChange = (name, index, value) => {
        setFormData(prev =>
            prev.map((item, idx) =>
                idx === index ? { ...item, [name]: value } : item
            )
        );
    };

    const handleCheck = async () => {
        onSubmit(formData.map(date => covertDateFormat(date)));
    };

    const handleAddDate = () => {
        const updatedData = [...formData, newDate];
        setFormData(updatedData);
        onSubmit(updatedData.map(date => covertDateFormat(date)));
    };

    const handleDeleteDate = (index) => {
        const updatedData = formData.filter((_, idx) => idx !== index);
        setFormData(updatedData);
        onSubmit(updatedData.map(date => covertDateFormat(date)));
    };

    return (
        <>
            {!!formData.length && formData.map((date, index) => (
                <Fragment key={index}>
                    <div className="row-group justify-between">
                        <div className="row-group gap--xs">
                            {isEditable && (
                                <button
                                    className="fw-500 text-14 text-normal style-normal color-secondary"
                                    onClick={() => handleDeleteDate(index)}
                                >
                                    <DeleteIcon />
                                </button>
                            )}
                            <p className="fw-500">Day {index + 1}</p>
                        </div>
                        <div className="row-group gap--xs">
                            <p className="fw-500">
                                {isValid(new Date(date?.date)) ? format(new Date(date?.date), "dd MMM, yyyy") : ''}
                            </p>
                            {isEditable && !isLoading && (
                                <DropDown
                                    dropDownListClasses="time-input-menu"
                                    labelButton={<div
                                        className="fw-500 text-14 text-normal style-normal color-secondary"
                                    >
                                        <PencilIcon />
                                    </div>}
                                    position="left"
                                    overflow="visible"
                                >
                                    <div
                                        className="schedule-group-item__time-group"
                                    >
                                        <div className="time-input-box-wrapper">
                                            <DatePicker
                                                placeholderText="0:00pm"
                                                selected={date.date ? new Date(date.date) : null}
                                                onChange={(date) => handleChange("date", index, date)}
                                            />

                                            <button
                                                className="done-button"
                                                onClick={handleCheck}
                                            >
                                                <DoneIcon />
                                            </button>
                                        </div>
                                    </div>
                                </DropDown>
                            )}
                        </div>
                    </div>
                    <div className="row-group justify-between">
                        <p className="fw-500">Exact time</p>
                        <div className="row-group gap--xs">
                            <p className="fw-500">
                                {date?.startTime && isValid(new Date(date?.startTime)) ? format(new Date(date?.startTime), "h:mm aa") : ''}
                            </p>
                            <span> - </span>
                            <p className="fw-500">
                                {date?.endTime && isValid(new Date(date?.endTime)) ? format(new Date(date?.endTime), "h:mm aa") : ''}
                            </p>
                            {isEditable && !isLoading && (
                                <DropDown
                                    dropDownListClasses="time-input-menu"
                                    labelButton={<div
                                        className="fw-500 text-14 text-normal style-normal color-secondary"
                                    >
                                        <PencilIcon />
                                    </div>}
                                    position="left"
                                    overflow="visible"
                                >
                                    <div className="schedule-group-item__time-group">
                                        <div className="time-input-box-wrapper">
                                            <TimePicker
                                                onChange={(time) => handleChange("startTime", index, time)}
                                                placeholderText="0:00pm"
                                                selected={date?.startTime && isValid(new Date(date?.startTime)) ? new Date(date.startTime) : null}
                                                wrapperClassName="w-88"
                                            />

                                            <span className="input-delimiter" />

                                            <TimePicker
                                                onChange={(time) => handleChange("endTime", index, time)}
                                                placeholderText="0:00pm"
                                                selected={date?.endTime && isValid(new Date(date?.endTime)) ? new Date(date.endTime) : null}
                                                wrapperClassName="w-88"
                                            />

                                            <button
                                                className="done-button"
                                                onClick={handleCheck}
                                            >
                                                <DoneIcon />
                                            </button>
                                        </div>
                                    </div>
                                </DropDown>
                            )}
                        </div>
                    </div>
                    {index !== formData.length - 1 && <hr className="hr-horisontal" />}
                </Fragment>
            ))}
            {isEditable && formData.length < 2 && !isLoading && (
                <DropDown
                    dropDownListClasses="time-input-menu"
                    labelButton={<div
                        className="fw-500 text-14 text-normal style-normal color-secondary"
                    >
                        Add Date
                    </div>}
                    position="left"
                    overflow="visible"
                >
                    <div
                        className="schedule-group-item__time-group"
                    >
                        <div className="time-input-box-wrapper">
                            <DatePicker
                                placeholderText="0:00pm"
                                selected={newDate.date}
                                onChange={(date) => setNewDate(prev => ({ ...prev, date }))}

                            />

                            <button
                                className="done-button"
                                onClick={handleAddDate}
                            >
                                <DoneIcon />
                            </button>
                        </div>
                    </div>
                </DropDown>
            )}
        </>
    );
};
