import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { PaginationTemplate } from "../../../components/parts/PaginationTemplate";
import PageLoader from "../../../components/ui/PageLoader";
import { getBelt, getImageSrc, getTotlaInvoicePrice } from "../../../helpers/utils";
import { getPrivateLessonsByEventIdAndEventTypeFetch } from "../../../redux/thunks/privateLessonsThunks";
import { getTicketByEventTypeAndEventIdFetch } from "../../../redux/thunks/ticketsThunks";


const BoughtsSetion = ({ eventType, eventId }) => {
    const [activeTab, setActiveTab] = useState("bought-seminar");
    const dispatch = useDispatch();
    const { tickets, isLoading: isTicketsLoading, pagination: paginationTickets } = useSelector((state) => state.tickets);
    const { privateLessons, isLoading: isLessonsLoading, pagination: paginationLessons } = useSelector((state) => state.privateLessons);

    const [params, setParams] = useSearchParams()

    const handleFiltersChange = (name, value) => {
        setParams(prev => {
            prev.set(name, value)

            return prev
        })
    }

    useEffect(() => {
        dispatch(getTicketByEventTypeAndEventIdFetch({
            eventId,
            eventType,
            query: params.toString()
        }))
        dispatch(getPrivateLessonsByEventIdAndEventTypeFetch({
            eventId,
            eventType,
            query: params.toString()
        }))
    }, [dispatch, eventId, params]);

    return (
        <div className="tabs tab-seminars">
            <div className="tabs__header flex items-center gap-24 pb-24">
                <div className="tabs__nav row-group gap-24 flex-auto">
                    <button
                        className={classNames("tab-link", {
                            "active-tab": activeTab === "bought-seminar"
                        })}
                        onClick={() => {
                            setActiveTab("bought-seminar");
                        }}
                    >
                        <span className="tab-link__text">
                            Bought Seminar
                        </span>

                        <span className="tab-link__number">
                            {tickets?.length}
                        </span>
                    </button>

                    <button
                        className={classNames("tab-link", {
                            "active-tab": activeTab === "bought-lesson"
                        })}
                        onClick={() => {
                            setActiveTab("bought-lesson");
                        }}
                    >
                        <span className="tab-link__text">
                            Bought Private Lesson
                        </span>

                        <span className="tab-link__number">
                            {privateLessons?.length}
                        </span>
                    </button>
                </div>
            </div>

            <div className="tabs__body mb-32">
                {activeTab === "bought-seminar" && (
                    <div className="tab active-tab">
                        {
                            tickets?.length && !isTicketsLoading ? (
                                <div className="wrapp-table">
                                    <div className="wrapp-table-body">
                                        <table className="table-custom">
                                            <tbody>
                                                {
                                                    tickets.map(item => (
                                                        <BuyerRow key={item.id} data={item} />
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : isTicketsLoading ?
                                <PageLoader />
                                : (
                                    <h4 className="heading">There is no items</h4>
                                )
                        }
                        {!isTicketsLoading && (
                            <PaginationTemplate
                                {...paginationTickets}
                                onPageChange={(page) => handleFiltersChange("page", page)}
                            />
                        )}
                    </div>
                )}

                {activeTab === "bought-lesson" && (
                    <div className="tab active-tab">
                        {
                            privateLessons?.length && !isLessonsLoading ? (
                                <div className="wrapp-table">
                                    <div className="wrapp-table-body">
                                        <table className="table-custom">
                                            <tbody>
                                                {
                                                    privateLessons.map(item => (
                                                        <BuyerRow key={item.id} data={item} />
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : isLessonsLoading ?
                                <PageLoader />
                                : (
                                    <h4 className="heading">There is no items</h4>
                                )
                        }
                        {!isLessonsLoading && (
                            <PaginationTemplate
                                {...paginationLessons}
                                onPageChange={(page) => handleFiltersChange("page", page)}
                            />
                        )}
                    </div>
                )}
            </div>


        </div>
    );
};

export default BoughtsSetion;


function BuyerRow({ data }) {
    const user = data?.user || data?.student?.studentProfile || data
    const belt = getBelt(data.belt || user?.bely)
    const invoice = data?.invoice

    return (
        <tr>
            <td style={{ width: "100%" }}>
                <div className="card-user-block card-user-block--sm">
                    <div className="card-user-block__icon">
                        <img
                            alt={user?.fullName}
                            src={getImageSrc(user?.photo)}
                        />
                    </div>

                    <div className="card-user-block__name">
                        {user?.fullName}
                    </div>
                </div>
            </td>

            <td>
                {belt && (
                    <div className="status-rang">
                        <p className="status-rang-text">
                            {belt?.title}
                        </p>

                        <div className="status-rang-ico">
                            {belt?.iconSecondary}
                        </div>
                    </div>
                )}
            </td>

            <td className="price-col">
                ${getTotlaInvoicePrice(invoice?.price, invoice?.fee, invoice?.quantity) || data?.priceInHour}
            </td>
        </tr>
    )
}