import React, {useEffect, useLayoutEffect, useMemo, useRef} from "react";
import Message from "./Message";
import Loader from "../../../components/ui/Loader";
import {ChatFooter} from "./ChatFooter";
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "../../../redux/slices/applicationSlice";
import {EVENT_STATUS} from "../../../constants/enums";
import {useParams} from "react-router-dom";
import {clearUnreadMessages, getUnreadMessages} from "../../../redux/slices/chatsSlice";
import {getChatSocket} from "../../../redux/slices/socketChatSlice";

export const ChatMessages = () => {
    const user = useSelector(getUser);
    const dispatch = useDispatch();
    const socket = useSelector(getChatSocket);
    const {chatId} = useParams();

    const {
        isLoading: isChatsLoading,
        isMessagesLoading,
        currentChatData,
        messages: chatMessagesAll
    } = useSelector((state) => state.chats);

    const getRecipient = (chat) => chat?.users?.find((recipient) => recipient?.id !== user?.id);

    const chatMessages = chatMessagesAll?.filter((message) => message.chatId + "" === chatId) || [];
    const getFormattedMessages = () => {
        const formattedMessages = [...chatMessages];

        if (chatMessages?.length >= 1) {
            formattedMessages.splice(1, 0, {
                isSystemMessage: true,
                chatId: currentChatData?.id,
                message: "Seminar Negotiation Started",
                createdAt: "2024-08-26T13:34:00.000Z",
                updatedAt: "2024-08-26T13:34:00.000Z",
                attachments: [],
                senderId: chatMessages[0].userId
            });
        }

        return formattedMessages;
    };
    const formattedMessages = useMemo(getFormattedMessages, [chatMessages, chatId]);
    const lastMessage = formattedMessages?.[formattedMessages?.length - 1];
    const canSendMessages = lastMessage?.senderId !== user?.id && currentChatData?.status !== EVENT_STATUS.canceled;

    const elementRef = useRef(null);

    const unread = useSelector(getUnreadMessages);
    useLayoutEffect(() => {
        elementRef.current?.scrollTo({
            top: elementRef.current.scrollHeight,
            behavior: "smooth"
        });
    }, [chatMessages]);

    useEffect(() => {
        if (chatId && chatMessages && !isMessagesLoading) {
            const arr = unread.filter((el) => el.chatId == chatId);

            if (arr.length) {
                const mess = arr.map((el) => el.messageId);

                socket.emit("chat:readMessages", {messagesIds: mess});
                dispatch(clearUnreadMessages(mess));
            }
        }
    }, [chatMessages, chatId]);


    if (isChatsLoading) {
        return <Loader/>;
    }

    if (!currentChatData) {
        return (
            <div className="no-chats-block">
                <p className="chat-item__name">
                    Choose the chat
                </p>
            </div>
        );
    }

    return (
        <div className="container">
            <div className="messages__body-wrapper" ref={elementRef}>
                <div className="messages__body">
                    {!isMessagesLoading && chatMessages?.length ? (
                        formattedMessages.map((item, index) => (
                            <Message
                                user={user}
                                recipient={getRecipient(currentChatData)}
                                data={item}
                                key={index}
                            />
                        ))
                    ) : isMessagesLoading ? (
                        <div className="m-auto">
                            <Loader/>
                        </div>
                    ) : (
                        <div className="m-auto">
                            <p>There are no messages yet</p>
                        </div>
                    )}
                </div>
            </div>
            <ChatFooter
                canSendMessages={canSendMessages}
                chatMessages={chatMessages}
                currentChatData={currentChatData}
            />
        </div>
    );
};
