const base = 'api/verifications'
export const verifications = (instance) => ({
    async getMyVerification() {
        const { data } = await instance.get(`${base}/my`);
        return data;
    },
    async instructorPassportVerification(payload) {
        const { data } = await instance.post(`${base}/instructors/passport`, payload);

        return data;
    },
    async academyPassportVerification(payload) {
        const { data } = await instance.post(`${base}/academies/passport`, payload);

        return data;
    },
    async academyCodeVerification(payload) {
        // {
        //     "code": "520410"
        // }
        const { data } = await instance.post(`${base}/academies/code`, payload);

        return data;
    },

    async academyFilesVerification(payload) {
        const { data } = await instance.post(`${base}/acedemies/verification-docs/upload`, payload);

        return data;
    },
    async studentSendVerificationCode(payload) {
        const { data } = await instance.post(`${base}/email-code`, payload);

        return data;
    },
    async studentCodeVerification(payload) {
        // {
        //     "code": "520410"
        // }
        const { data } = await instance.post(`${base}/students/email`, payload);

        return data;
    },
})