import {
    useEffect, useState
} from "react";
import { useSearchParams } from "react-router-dom";

export const useTabs = (tabs, query = "view") => {
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultTab = tabs?.[0]?.id;

    const [activeTab, setActiveTab] = useState(searchParams.get(query) || defaultTab);

    useEffect(() => {
        setSearchParams((prev) => {
            prev.set(query, activeTab);
            return prev;
        });
    }, [activeTab, query, setSearchParams]);

    return {
        activeTab,
        setActiveTab,
        activeTabObject: tabs.find(obj => obj.id === activeTab),
        activeTabIndex: tabs.findIndex(obj => obj.id === activeTab)
    };
};
