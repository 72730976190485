import Button from "../../../components/ui/buttons/Button";
import { ReactComponent as VisaIcon } from "../../../assets/img/ico-visa.svg"
import { ReactComponent as MastercardIcon } from "../../../assets/img/ico-mastercard.svg"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../../components/ui/PageLoader";
import { getPaymentsCardFetch } from "../../../redux/thunks/paymentsThunks";
import AddPaymentCardModal from "../../../components/modals/AddPaymentCardModal";
import UpdatePaymentCardModal from "../../../components/modals/UpdatePaymentCardModal";

import { ReactComponent as DeleteIcon } from "../../../assets/img/ico-trash.svg"
import DeletePaymentCardModal from "../../../components/modals/DeletePaymentCardModal";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import api from "../../../api";
import { format } from "date-fns";
import InvoiceModal from "../../../components/modals/InvoiceModal";
import { getImageSrc, getTotlaInvoicePrice } from "../../../helpers/utils";
import { paymentStatusMap, ticketTypeMap } from "../../../constants/mappings";
import { PAYMENT_STATUS } from "../../../constants/enums";

const BillingInfo = () => {
    const dispatch = useDispatch();
    const [isAddPaymentCardModalOpen, setIsAddPaymentCardModalOpen] = useState(false)
    const { cards, isLoading } = useSelector(state => state.payments)
    const [params, setParams] = useSearchParams()
    const invoiceId = params.get("showInvoice")

    const [invoices, setInvoices] = useState([])
    // const statuses = ["success", "canceled"]
    const status = params.get("status")

    useEffect(() => {
        if (!status) return

        if (status === "success") {
            toast.success("Payment success!")
        }

        if (status === "canceled") {
            toast.error("Payment canceled")
        }
    }, [status])

    useEffect(() => {
        dispatch(getPaymentsCardFetch())
        api.invoices.getMyInvoices().then(resp => {
            if (resp.success) {
                setInvoices(resp.data)
            }
        })
    }, [])

    if (isLoading) {
        return <PageLoader />
    }



    return (
        <div className="settings-body settings-gap">
            <form
                className="form form-settings"
            >
                <div className="form-body">
                    <div className="row gutters-form">
                        <div className="col-12">
                            <div className="sidebar-header heading-row">
                                <h3 className="heading">
                                    Billing Information
                                </h3>
                            </div>
                        </div>

                        <div className="col-12">
                            <ul className="list-credit-card">
                                {cards?.map(data => (
                                    <li key={data.id} className="lcc-item">
                                        <div className="wrapp-card-credit">
                                            <CreditCard data={data} />
                                        </div>
                                    </li>
                                ))}

                                <li className="lcc-item">
                                    <button
                                        onClick={() => setIsAddPaymentCardModalOpen(true)}
                                        className="wrapp-card-credit"
                                        type="button"
                                    >
                                        <div className="btn btn--sm radius">
                                            <span className="ico">
                                                <svg
                                                    fill="none"
                                                    height="28"
                                                    viewBox="0 0 29 28"
                                                    width="29"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14.4998 9.33301V18.6663"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />

                                                    <path
                                                        d="M19.1668 14.0003H9.8335"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />

                                                    <path
                                                        clipRule="evenodd"
                                                        d="M14.5 24.5V24.5C8.7005 24.5 4 19.7995 4 14V14C4 8.2005 8.7005 3.5 14.5 3.5V3.5C20.2995 3.5 25 8.2005 25 14V14C25 19.7995 20.2995 24.5 14.5 24.5Z"
                                                        fillRule="evenodd"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.2"
                                                    />
                                                </svg>
                                            </span>

                                            <span>
                                                Add Credit Card
                                            </span>
                                        </div>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="form-body">
                    <div className="form-items-list">
                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <div className="sidebar-header heading-row">
                                        <h3 className="heading">
                                            Invoices
                                        </h3>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="table-wrapper">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="text-left fw-500">Date</th>
                                                    <th className="text-left fw-500">ID</th>
                                                    <th className="text-left fw-500">Amount</th>
                                                    <th className="text-left fw-500">Vendor</th>
                                                    <th className="text-left fw-500">Type</th>
                                                    <th className="text-left fw-500">Status</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoices.map((item, index) => (
                                                    <tr key={index}>
                                                        <td style={{ width: "0px" }}>
                                                            <p className="font-500">
                                                                {format(item.createdAt, 'dd.MM.yyyy')}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p className="color-text">
                                                                Invoice #{item.id}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p className="font-500">
                                                                ${getTotlaInvoicePrice(item.price, item.fee, item.quantity)}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <div className="request-item__user row-group">
                                                                <div className="ico ico--sm rounded-full img-wrapper">
                                                                    <img
                                                                        alt={item.vendor?.fullName || item.vendor?.name}
                                                                        src={getImageSrc(item.vendor?.photo)}
                                                                    />
                                                                </div>

                                                                <div
                                                                    className="col-group gap-4 truncate"
                                                                    style={{ width: 124 }}
                                                                >
                                                                    {item.vendor.fullName}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {ticketTypeMap[item.productType]}
                                                        </td>
                                                        <td>
                                                            {paymentStatusMap[item.status]}
                                                        </td>
                                                        <td className="--sticky-end-table">
                                                            <div className="table-actions justify-end">
                                                                {item.status === PAYMENT_STATUS.verified && (
                                                                    <button
                                                                        onClick={async () => {
                                                                            const response = await api.invoices.getDownloadLink(item.id);
                                                                            if (!response.success) return

                                                                            const link = document.createElement('a');

                                                                            link.href = response.data.invoice_pdf;
                                                                            link.download = 'invoice.pdf';
                                                                            link.click();
                                                                        }}
                                                                        type="button"
                                                                        className="btn--square btn--xs"
                                                                    >
                                                                        <span className="ico">
                                                                            <img src={require("../../../assets/img/ico-download.svg").default} alt="Download Icon" />
                                                                        </span>
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {isAddPaymentCardModalOpen && (
                <AddPaymentCardModal
                    open={isAddPaymentCardModalOpen}
                    handleClose={() => setIsAddPaymentCardModalOpen(false)}
                    onSubmit={() => dispatch(getPaymentsCardFetch())}

                />
            )}
            {invoiceId && (
                <InvoiceModal
                    open={invoiceId}
                    invoiceId={invoiceId}
                    handleClose={() => setParams(prev => {
                        prev.delete('showInvoice')
                        return prev
                    })}
                />
            )}

        </div>
    );
};

function CreditCard({ data }) {
    const dispatch = useDispatch()
    const [isUpdatePaymentCardModalOpen, setIsUpdatePaymentCardModalOpen] = useState(false)
    const [isDeletePaymentCardModalOpen, setIsDeletePaymentCardModalOpen] = useState(false)

    const icons = {
        visa: <VisaIcon />,
        mastercard: <MastercardIcon />
    }

    const handleUpdateModalClose = () => {
        setIsUpdatePaymentCardModalOpen(false)
    }

    const handleDeleteModalClose = () => {
        setIsDeletePaymentCardModalOpen(false)
    }

    return (
        <>
            <div className="wrapp-card-credit">
                <div className="card-credit">
                    <div className="card-credit-header">
                        <div className="credit-ico-method">
                            <span className="ico" style={{ height: 20 }}>
                                {icons[data.brand]}
                            </span>
                        </div>
                        <div className="credit-actions">
                            <Button
                                onClick={() => setIsUpdatePaymentCardModalOpen(true)}
                            >
                                <span className="ico">
                                    <svg
                                        fill="none"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        width="18"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            clipRule="evenodd"
                                            d="M3.96975 11.7139L11.7143 3.96937C12.0068 3.67687 12.4815 3.67687 12.774 3.96937L14.031 5.22637C14.3235 5.51887 14.3235 5.99362 14.031 6.28612L6.28575 14.0299C6.1455 14.1709 5.955 14.2496 5.75625 14.2496H3.75V12.2434C3.75 12.0446 3.82875 11.8541 3.96975 11.7139Z"
                                            fillRule="evenodd"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.2"
                                        />

                                        <path
                                            d="M10.3125 5.37012L12.63 7.68762"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.2"
                                        />
                                    </svg>
                                </span>
                            </Button>
                            <Button
                                onClick={() => setIsDeletePaymentCardModalOpen(true)}
                                className="btn color-danger"
                            >
                                <span className="ico">
                                    <DeleteIcon />
                                </span>
                            </Button>
                        </div>
                    </div>
                    <div className="card-credit-body">
                        <div className="credit-secret">
                            <p className="credit-secret-text">
                                •••• •••• •••• {data.last4}
                            </p>
                        </div>
                    </div>

                    <div className="card-credit-footer">
                        <div className="credit-info">
                            <p>
                                {data.exp_month}
                                /
                                {data.exp_year}
                            </p>

                            {/* <p>
                            John Carter
                        </p> */}
                            {data.isDefault && (
                                <p className="text-13 color-primary">Default card</p>
                            )}
                        </div>

                        <div className="card-credit-chip">
                            <span className="ico">
                                <img src={require("../../../assets/img/credit-chip.svg").default} alt="chip" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {isUpdatePaymentCardModalOpen && (
                <UpdatePaymentCardModal
                    open={isUpdatePaymentCardModalOpen}
                    handleClose={handleUpdateModalClose}
                    onSubmit={() => dispatch(getPaymentsCardFetch())}
                    data={data}
                />
            )}
            {isDeletePaymentCardModalOpen && (
                <DeletePaymentCardModal
                    open={isDeletePaymentCardModalOpen}
                    handleClose={handleDeleteModalClose}
                    onSubmit={() => dispatch(getPaymentsCardFetch())}
                    data={data}
                />
            )}
        </>
    )
}

export default BillingInfo;
