import { format } from 'date-fns';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { EVENT_TYPE } from '../../../constants/enums';
import { EventHelper } from '../../../helpers/EventHelper';
import { getImageSrc, getValueOrDefault } from '../../../helpers/utils';
import { getUser } from '../../../redux/slices/applicationSlice';
import { acceptInstructorCampRequestFetch, rejectCampRequestFetch } from '../../../redux/thunks/planCampThuncks';
import { acceptInstructorRequestFetch, cancelSeminarRequestFetch } from '../../../redux/thunks/seminarsThunks';
import Button from '../../../components/ui/buttons/Button';

const OverviewRequestCard = ({ request, event, update, eventType }) => {
    const dispatch = useDispatch();
    const me = useSelector(getUser);
    const isSeminar = eventType === EVENT_TYPE.seminar;
    const isCamp = eventType === EVENT_TYPE.camp;

    const handleApprove = async () => {
        const requestInstructorId = data.id;

        if (!requestInstructorId) return;

        if (isSeminar) {
            await dispatch(acceptInstructorRequestFetch({ requestInstructorId })).unwrap();
        }

        if (isCamp) {
            await dispatch(acceptInstructorCampRequestFetch({ instructorId: requestInstructorId })).unwrap();
        }
        update && update()
    };

    const handleCancelRequest = async () => {
        const requestId = data.id;

        if (!requestId) return;

        if (isSeminar) {
            await dispatch(cancelSeminarRequestFetch({ requestId })).unwrap();
        }

        if (isCamp) {
            await dispatch(rejectCampRequestFetch({ requestId })).unwrap();
        }
        update && update()

    };

    const user = request.owner;
    const isMy = request?.owner.id === me?.id;

    if (!event) return null

    const hrefPrefix = event.userAcademy
        ? "planed-by-academy"
        : "planed-by-insctructor";

    return (
        <div className="request-item">
            <div className="request-item__user">
                <div className="ico ico--md rounded-full img-wrapper">
                    <img
                        alt={user?.fullName || user?.name}
                        src={getImageSrc(user?.photo)}
                    />
                </div>

                <div
                    className="col-group gap-4"
                    style={{ width: 300 }}
                >
                    <h6 className="heading truncate flex-auto">
                        {event?.name || event?.seminarName}
                    </h6>

                    <p className="color-text truncate flex-auto">
                        {event?.summary || event?.summary}
                    </p>
                </div>
            </div>

            <div className="request-item__details">
                <div className="col-group gap--xs">
                    <span className="label">
                        Seminar Price
                    </span>

                    <p className="fw-500 text--sm">
                        {getValueOrDefault(event?.seminarMinPrice, v => '$' + v)}
                    </p>
                </div>

                <div className="col-group gap--xs">
                    <span className="label">
                        Ticket Price
                    </span>

                    <p className="fw-500 text--sm">
                        {getValueOrDefault(EventHelper.totalTicketPrice(event), v => '$' + v)}
                    </p>
                </div>

                <div className="col-group gap--xs">
                    <span className="label">
                        teaching
                    </span>

                    <p className="fw-500 text--sm">
                        {EventHelper.style(event)?.title}
                    </p>
                </div>

                <div className="col-group gap--xs">
                    <span className="label">
                        Date
                    </span>

                    <p className="fw-500 text--sm">
                        {getValueOrDefault(EventHelper.startDate(event), v => format(v, "dd MMM, yyyy"))}
                    </p>
                </div>
            </div>

            <div className="request-item__action">
                <Link
                    className="btn btn--sm"
                    to={`/${hrefPrefix}/${event?.id}`}
                >
                    Show Details
                </Link>

                {!isMy && (
                    <Button
                        className="btn btn--sm btn--primary"
                        onClick={handleApprove}
                    >
                        Approve
                    </Button>
                )}

                <Button
                    className="btn btn--sm btn--default"
                    onClick={handleCancelRequest}
                >
                    Decline
                </Button>
            </div>
        </div>
    );
}

export default OverviewRequestCard
