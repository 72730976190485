import classNames from "classnames";
import { Country } from "country-state-city";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import api from "../../../api";
import VerifyEmailModal from "../../../components/modals/VerifyEmailModal";
import TooltipInfo from "../../../components/parts/TooltipInfo";
import Button from "../../../components/ui/buttons/Button";
import Input from "../../../components/ui/formElements/Input";
import SelectOne from "../../../components/ui/formElements/SelectOne";
import { explanations } from "../../../constants/explanations";
import { getImageSrc } from "../../../helpers/utils";
import { studentEmailVerificationFetch, studentSendVerificationCodeFetch } from "../../../redux/thunks/verificationsThunk";
import { ReactComponent as NextIcon } from '../../../assets/img/ico-chevron--next.svg';
import { ReactComponent as VerifyIcon } from '../../../assets/img/verify-icon.svg';
import { belts } from "../../../constants";
import BirthdayFormFields from "../../../components/parts/BirthdayFormFields";

const StudentMyProfile = ({ userData, isVerifyModalInitialyOpen }) => {
    const dispatch = useDispatch();
    const [verifyModalOpen, setVerifyModalOpen] = useState(isVerifyModalInitialyOpen);
    const [isVerificationCodeSending, setIsVerificationCodeSending] = useState(false);

    const birthday = userData?.profile?.birthday;

    const {
        control,
        register,
        handleSubmit,
        formState: { dirtyFields, errors },
        reset,
        watch,
        setValue,
    } = useForm({
        defaultValues: {
            email: userData?.email || '',
            phone: userData?.phone || '',
            ...userData?.profile,
            dateDay: birthday && new Date(birthday.split('T')[0]),
            dateMonth: birthday && new Date(birthday.split('T')[0]),
            dateYear: birthday && new Date(birthday.split('T')[0]),
        }
    });

    const onSubmit = async (data) => {
        const editedFieldsNames = Object.keys(dirtyFields);

        const changedFieldNames = editedFieldsNames.filter(
            (fieldName) => dirtyFields[fieldName]
        );

        const updateData = {};

        changedFieldNames.forEach((fieldName) => {
            updateData[fieldName] = data[fieldName];
        });

        if (updateData?.email || updateData?.phone) {
            const updatePayload = {};

            ["email", "phone"].forEach((item) => {
                if (updateData[item]) {
                    updatePayload[item] = updateData[item];
                }
            });
            const updateUserRes = await api.users.updateUser(updatePayload);

            if (updateUserRes.success) {
                toast.success(updateUserRes.message || "Updated");
            } else {
                toast.error(updateUserRes?.message || "Error");
            }
        }

        if (updateData?.country || updateData?.photo || updateData?.birthday || updateData?.fullName || updateData?.bely || updateData?.gym) {
            const formData = new FormData();

            if (updateData?.birthday) {
                const birhdayAsString = updateData.birthday.toISOString();
                
                formData.append("birthday", birhdayAsString);
            }

            [
                "country",
                "photo",
                "fullName",
                "bely",
                "gym"
            ].forEach((item) => {
                if (updateData[item]) {
                    formData.append(item, updateData[item]);
                }
            });

            const updateInstructorRes = await api.profiles.updateStudent(formData);

            if (updateInstructorRes.success) {
                toast.success(updateInstructorRes.message || "Updated");
            } else {
                toast.error(updateInstructorRes?.message || "Error");
            }
        }
    }

    const allCountries = Country.getAllCountries();
    const countries = allCountries?.map((item) => ({
        value: item.isoCode,
        label: item.name
    })) || [];

    const beltOptions = belts.map((item) => ({
        value: item.key,
        label: item.title
    }));


    const handleVerifyModalOpen = async () => {
        setIsVerificationCodeSending(true);
        await dispatch(studentSendVerificationCodeFetch());
        setVerifyModalOpen(true);
        setIsVerificationCodeSending(false);
    }

    const handleSumbitVerification = async (data) => {
        await dispatch(studentEmailVerificationFetch(data));
        setVerifyModalOpen(false);
    };

    return (
        <div className="settings-body settings-gap">
            <div className="sidebar-header heading-row">
                <h3 className="heading">
                    My Profile
                </h3>
            </div>
            
            <form
                className="form form-settings"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="form-header">
                    <div className="row gutters-form">
                        <div className="col-12">
                            <div className="upload-photo">
                                <div className="photo-load">
                                    <img
                                        src={getImageSrc(watch("photo"))}
                                        alt={watch('fullName')}
                                    />
                                </div>

                                <Controller
                                    control={control}
                                    name="photo"
                                    render={({ field: { onChange } }) => (
                                        <label className="upload-control">
                                            <input
                                                accept="image/*"
                                                hidden={true}
                                                onChange={(e) => onChange(e.target.files[0])}
                                                type="file"
                                            />

                                            <div className="btn btn--primary btn--sm radius">
                                                <span>
                                                    Upload New Picture
                                                </span>
                                            </div>
                                        </label>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-body">
                    <div className="form-items-list">
                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        inputClasses="input--solid"
                                        label="Full Name"
                                        {...register("fullName")}
                                        wrapperClasses="input--lg"
                                        error={errors.fullName?.message}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Controller
                                        control={control}
                                        name="country"
                                        render={({ field: { onChange, value } }) => (
                                            <SelectOne
                                                label="Country"
                                                onChange={(option) => onChange(option.value)}
                                                options={countries}
                                                value={value}
                                                wrapperClasses="select--outline select--outline-bg input--lg"
                                                error={errors.country?.message}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="col-12">
                                    <div className="form-group input--lg">
                                        <label className="label">
                                            Date of Birth
                                        </label>

                                        {/* <div className="row gutters-form-2">
                                            <div className="col-md-4 col-sm-4 col-12">
                                                <div className="day-datepicker">
                                                    <Controller
                                                        control={control}
                                                        name="birthday"
                                                        render={({ field: { onChange, value } }) => (
                                                            <DatePicker
                                                                className="input input--solid"
                                                                dateFormat="dd"
                                                                onChange={onChange}
                                                                selected={value}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-sm-4 col-12">
                                                <div className="month-datepicker">
                                                    <Controller
                                                        control={control}
                                                        name="birthday"
                                                        render={({ field: { onChange, value } }) => (
                                                            <DatePicker
                                                                className="input input--solid"
                                                                dateFormat="MM"
                                                                onChange={onChange}
                                                                selected={value}
                                                                showMonthYearPicker
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-sm-4 col-12">
                                                <div className="month-datepicker">
                                                    <Controller
                                                        control={control}
                                                        name="birthday"
                                                        render={({ field: { onChange, value } }) => (
                                                            <DatePicker
                                                                className="input input--solid"
                                                                dateFormat="yyyy"
                                                                onChange={onChange}
                                                                selected={value}
                                                                showYearPicker
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                                        <BirthdayFormFields 
                                            control={control}
                                            setValue={setValue}
                                            watch={watch}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Controller
                                        control={control}
                                        name="bely"
                                        render={({ field: { onChange, value } }) => (
                                            <SelectOne
                                                label="Belt"
                                                onChange={(option) => onChange(option.value)}
                                                options={beltOptions}
                                                value={value}
                                                wrapperClasses="select--outline  select--outline-bg input--lg"
                                            />
                                        )}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        inputClasses="input--solid"
                                        label="Gym"
                                        wrapperClasses="input--lg"
                                        {...register("gym")}
                                        error={errors.gym?.message}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        inputClasses="input--solid"
                                        wrapperClasses="input--lg"
                                        label="Email"
                                        {...register("email")}
                                        error={errors.email?.message}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        inputClasses="input--solid"
                                        wrapperClasses="input--lg"
                                        label="Phone"
                                        {...register("phone")}
                                        error={errors.phone?.message}
                                    />
                                </div>
                            </div>
                        </div>
{/* 
                        <div className="form-item">
                            <div className="row gutters-form">
                                <div className="col-12">
                                    <div className="heading-row">
                                        <div className="flex-ico-ss-text">
                                            <span className="ico ico--sm rounded-full border-ico">
                                                <VerifyIcon />
                                            </span>
                                            <div className="row-group">
                                                <h4 className="heading">
                                                    Verify your account with code from email
                                                </h4>

                                                <TooltipInfo content={explanations.verifyFighterID} />
                                            </div>
                                        </div>

                                        <div className="flex-group">
                                            <Button isLoading={isVerificationCodeSending} onClick={handleVerifyModalOpen} className="btn btn--primary btn--sm">
                                                <span className="info">
                                                    Verify
                                                </span>

                                                <span className="ico ico-14">
                                                    <NextIcon />
                                                </span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="form-footer">
                    <div className="flex-group justify-end">
                        <Button
                            className={classNames("btn btn--primary btn--sm", {
                                disabled: !Object.entries(dirtyFields).length,
                            })}
                            type="submit"
                        >
                            <span>
                                Save Changes
                            </span>
                        </Button>
                    </div>
                </div>
            </form>
            {/* {verifyModalOpen && (
                <VerifyEmailModal
                    open={verifyModalOpen}
                    onClose={() => setVerifyModalOpen(false)}
                    onSubmit={handleSumbitVerification}
                />
            )} */}
        </div>
    );
};

export default StudentMyProfile;
