import classNames from "classnames";

export const Steps = ({ steps, currentStep, setCurrentStep }) => (
    <div className="steps mb-28">
        {steps.map((item, index) => (
            <button
            className="flex flex-1 py-3"
                key={index}
                onClick={() => setCurrentStep(item.id)}
            >
                <span
                    className={classNames('flex steps__item', {
                        active: currentStep === item.id
                    })}
                />
            </button>
        ))}
    </div>
);