import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReschedulePrivateLessonModal from '../../../components/modals/ReschedulePrivateLessonModal';
import Button from '../../../components/ui/buttons/Button';
import { EventHelper } from '../../../helpers/EventHelper';
import { calcTotalHours, getImageSrc } from '../../../helpers/utils';
import {
    acceptRequestsPrivateLessonsFetch,
    rejectRequestsPrivateLessonFetch
} from "../../../redux/thunks/privateLessonsThunks";

const LessonsRequestItem = ({ data, update }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
    const [isApproving, setIsApproving] = useState(false);
    const [isRejecting, setIsRejecting] = useState(false);

    const handleApprove = async (id) => {
        setIsApproving(true);
        const res = await dispatch(acceptRequestsPrivateLessonsFetch(data.id)).unwrap();

        if (res?.success) {
            navigate(`/chat/${res.data?.chat?.id}`);
            update && update();
        }

        setIsApproving(false);
    };

    const handleReject = async (id) => {
        setIsRejecting(true);
        const res = await dispatch(rejectRequestsPrivateLessonFetch(data.id)).unwrap();

        if (res?.success) {
            update && update();
        }

        setIsRejecting(false);
    };

    const studentProfile = data.student?.studentProfile;

    const totalHours = useMemo(() => calcTotalHours(data.dates), [data.dates])
    const totalPrice = data?.priceInHour * totalHours

    return (
        <>
            <div className="request-item">
                <div className="request-item__user">
                    <div className="ico ico--md img-wrapper rounded-full">
                        <img
                            alt="student"
                            src={getImageSrc(studentProfile?.photo)}
                        />
                    </div>

                    <div
                        className="col-group gap-4"
                        style={{ width: 224 }}

                    >
                        <h6 className="heading">
                            {studentProfile?.fullName}
                        </h6>

                        <p className="color-text">
                            {EventHelper.dateRange(data)}
                        </p>
                    </div>
                    {!data?.isReaded && <span className="badge badge--primary">New</span>}
                </div>

                <div className="request-item__details">
                    <div className="col-group gap--xs">
                        <span className="label">
                            Total Price
                        </span>

                        <p className="fw-500 text--sm">
                            ${totalPrice}
                        </p>
                    </div>

                    <div className="col-group gap--xs">
                        <span className="label">
                            People
                        </span>

                        <p className="fw-500 text--sm">
                            {data?.peopleAttending}
                        </p>
                    </div>

                    <div className="col-group gap--xs">
                        <span className="label">
                            teaching
                        </span>

                        <p className="fw-500 text--sm">
                            {EventHelper.style(data)?.title}
                        </p>
                    </div>

                    <div className="col-group gap--xs">
                        <span className="label">
                            Location
                        </span>

                        <p className="fw-500 text--sm">
                            {data?.address}
                        </p>
                    </div>
                </div>

                <div className="request-item__action">
                    <button
                        className="btn btn--sm "
                        isLoading={isRejecting}
                        onClick={handleReject}
                    >
                        Decline
                    </button>

                    <Button
                        className="btn btn--sm btn--primary"
                        isLoading={isApproving}
                        onClick={handleApprove}
                    >
                        Start Discussion
                    </Button>
                </div>
            </div>

            <ReschedulePrivateLessonModal
                data={data.dates}
                handleClose={() => {
                    setOpenRescheduleModal(false);
                    update && update()
                }}
                id={data.id}
                open={openRescheduleModal}
            />
        </>
    );
}

export default LessonsRequestItem
