import {toast} from "react-toastify";
import {getImageSrc} from "../../../helpers/utils";
import Button from "../../../components/ui/buttons/Button";
import {ReactComponent as ChevronNextIcon} from "../../../assets/img/ico-chevron--next.svg";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {notificationEventGroups, notificationEvents} from "../../../constants/notificationEvents";
import {readNotificationsFetch} from "../../../redux/thunks/notificationsThunks";
import {ReactComponent as BellIcon} from "../../../assets/img/bell-icon.svg";
import {setToastData} from "../../../redux/slices/notificationsSlice";
import {memo} from "react";
import {USER_ROLE} from "../../../constants/enums";

const getButtonText = (item) => {
    if (notificationEventGroups.navigateToRequests.includes(item.type)) {
        return "Open Requests";
    }
    if (notificationEventGroups.navigateToInstructor.includes(item.type)) {
        return "Go to Profile";
    }
    if (notificationEventGroups.navigateToAcademy.includes(item.type)) {
        return "Go to Profile";
    }
    if (notificationEventGroups.navigateSeminarByAcademy.includes(item.type)) {
        return "Go to Seminar/Camp";
    }
    if (notificationEventGroups.navigateSeminarByInstructor.includes(item.type)) {
        return "Go to Seminar/Camp";
    }
    return null;
};

const NotificationsToast = memo(() => {
    const requestTypes = Object.values(notificationEvents);
    const {toastsData: data} = useSelector((state) => state.notifications);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    console.log("data !!! NotificationsToast", data)

    const handleToastClose = () => {
        setTimeout(() => {dispatch(setToastData(null))}, 2000)

    };

    if (!data) {
        return null;
    }
    // if (!requestTypes.includes(data?.type)) {
    //     return null;
    // }

    const getNotificationAction = (item, toastId) => {

        if (Object.keys(item?.payload).length === 0 || !item) return;

        const redirectId =
            item?.payload?.instructorId ||
            item?.payload?.academyId ||
            item?.payload?.seminarId ||
            item?.payload?.plancampId;

        if (item?.payload?.chatId) {
            navigate(`/chat/${item?.payload?.chatId}`);
            toast.dismiss(toastId);
            return;
        }

        switch (true) {
            case notificationEventGroups.navigateToRequests.includes(item?.type):
                navigate('/requests');
                break;

            case notificationEventGroups.navigateToInstructor.includes(item?.type) && !!redirectId:
                navigate(`/instructors/${redirectId}`);
                break;

            case notificationEventGroups.navigateToAcademy.includes(item?.type) && !!redirectId:
                navigate(`/academies/${redirectId}`);
                break;

            case notificationEventGroups.navigateSeminarByAcademy.includes(item?.type) && !!redirectId:
                navigate(`/planed-by-academy/${redirectId}`);
                break;

            case notificationEventGroups.navigateSeminarByInstructor.includes(item?.type) && !!redirectId:
                navigate(`/planed-by-insctructor/${redirectId}`);
                break;

            default:
                break;
        }

        if (toastId) {
            toast.dismiss(toastId);
        }

        return null;
    };

    const photo =
        data?.payload?.photo ||
        data?.payload?.user?.photo ||
        data?.payload?.student?.photo ||
        data?.payload?.instructor?.photo ||
        data?.privateLesson?.[user?.role === USER_ROLE.student ? "instructor" : "student"]?.photo

    if (data.length) {
        data.forEach((item) => {
            const buttonText = getButtonText(item);
            const toastId = toast(
                <div className="col-group gap--sm">
                    <div className="row-group gap--sm">
                        <div className="image-wrapper avatar flex items-center justify-center">
                            {photo && <img
                                alt="instructor"
                                src={getImageSrc(photo)}
                            />}
                            {!photo && <BellIcon path={"red"}/>}
                        </div>
                        <h6>{item?.payload?.fullName || item?.payload?.user?.fullName}</h6>
                    </div>
                    <p className="color-text">{item.message}</p>
                    {buttonText && <Button
                        className="btn--primary btn--sm"
                        onClick={() => getNotificationAction(item, toastId)}
                    >
                        <span className="info">{buttonText}</span>
                        <span className="ico ico--20">
                                <ChevronNextIcon/>
                            </span>
                    </Button>}
                </div>,
                {
                    hideProgressBar: true,
                    onClose: handleToastClose,
                }
            );
        });
    }
});

export default NotificationsToast;
