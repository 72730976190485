import { createSlice } from "@reduxjs/toolkit";
import { USER_ROLE } from "../../constants/enums";
import { getAcademyGeneralInfoFetch } from "../thunks/academyGeneralInfoThunks";
import {
    getGuestUserFetch, getInstructorGeneralInfoFetch, getUserFetch, logoutFetch
} from "../thunks/applicationThunks";

import { studentEmailVerificationFetch, instructorPassportVerificationFetch, academyCodeVerificationFetch, getMyVerificationFetch } from '../thunks/verificationsThunk'
const initialState = {
    authorized: null,
    user: null,
    token: null,
    generalInfo: {},
    verification: null,
    isGeneralInfoLoading: false,
    isUserLoading: false,
    authModalOpen: null,
    breadcrumbs: []
};
const applicationSlice = createSlice({
    name: "application",
    initialState,
    reducers: {
        setAuthorized: (state, action) => {
            state.authorized = action.payload;
        },

        setUser: (state, action) => {
            state.user = action.payload;
        },
        setBreadCrumbs: (state, action) => {
            state.breadcrumbs = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },

        setLogOut: (state, action) => {
            localStorage.removeItem("authToken");
            state.authorized = null;
            state.user = null;
            state.token = null;
        },
        setAuthModalOpen: (state, { payload }) => {
            state.authModalOpen = payload;
        }
    },

    extraReducers(builder) {
        builder.addCase(getUserFetch.fulfilled, (state, action) => {
            state.user = action.payload;
            state.authorized = true;
            state.modal = {
                show: false,
                name: "",
                payload: {}
            };
            state.isUserLoading = false;
        });
        builder.addCase(getUserFetch.pending, (state, action) => {
            state.authorized = null;
            state.isUserLoading = true;
        });
        builder.addCase(getUserFetch.rejected, (state, action) => {
            localStorage.removeItem("authToken");
            state.authorized = false;
            state.token = null;
            state.isUserLoading = false;
        });
        builder.addCase(getGuestUserFetch.fulfilled, (state, action) => {
            state.user = action.payload;
            state.authorized = true;
            state.modal = {
                show: false,
                name: "",
                payload: {}
            };
        });
        builder.addCase(getGuestUserFetch.pending, (state, action) => {
            state.authorized = null;
        });
        builder.addCase(getGuestUserFetch.rejected, (state, action) => {
            localStorage.removeItem("authToken");
            state.authorized = false;
            state.token = null;
        });
        builder.addCase(getInstructorGeneralInfoFetch.fulfilled, (state, action) => {
            state.generalInfo = action.payload;
            state.isGeneralInfoLoading = false;
        });
        builder.addCase(getInstructorGeneralInfoFetch.pending, (state, action) => {
            state.isGeneralInfoLoading = true;
        });
        builder.addCase(getInstructorGeneralInfoFetch.rejected, (state, action) => {
            state.isGeneralInfoLoading = false;
        });
        builder.addCase(getAcademyGeneralInfoFetch.fulfilled, (state, action) => {
            state.generalInfo = action.payload;
            state.isGeneralInfoLoading = false;
        });
        builder.addCase(getAcademyGeneralInfoFetch.pending, (state, action) => {
            state.isGeneralInfoLoading = true;
        });
        builder.addCase(getAcademyGeneralInfoFetch.rejected, (state, action) => {
            state.isGeneralInfoLoading = false;
        });
        // verifications

        builder.addCase(getMyVerificationFetch.fulfilled, (state, action) => {
            state.verification = action.payload?.data;
        });

        builder.addCase(instructorPassportVerificationFetch.fulfilled, (state, action) => {
            if (action.payload?.success && state.user) {
                state.user.verify = true;
            }
        });
        builder.addCase(studentEmailVerificationFetch.fulfilled, (state, action) => {
            if (action.payload?.success && state.user) {
                state.user.verify = true;
            }
        });
        builder.addCase(academyCodeVerificationFetch.fulfilled, (state, action) => {
            if (action.payload?.success && state.user) {
                state.user.verify = true;
            }
        });

        builder.addCase(logoutFetch.fulfilled, (state, action) => {
            localStorage.removeItem("authToken");
            state = initialState
        })

    }
});

export const {
    setUser, setAuthorized, setToken, setLogOut, setAuthModalOpen, setBreadCrumbs
} = applicationSlice.actions;
export const getUser = (state) => state.application.user;
export const getisUserLoading = (state) => state.application.isUserLoading;
// export const getIsVerified = (state) => false;
export const getIsVerified = (state) => state.application.user?.role === USER_ROLE.student || state.application.user?.verify;
export const getMyVerification = (state) => state.application.verification;
export const getBreadcrumbs = (state) => state.application.breadcrumbs;
export const getAuthorized = (state) => state.application.authorized;
export const getToken = (state) => state.application.token;
export const getGeneralInfo = (state) => state.application.generalInfo;
export default applicationSlice.reducer;
