import { useMemo } from "react";
import { Country, City } from "country-state-city";
import { continents } from "../../../constants";
const useLocationOptions = (
    {
        continent = null, country = null
    } = {},
    {
        includeAllCountries = true,
        includeAllContinents = true,
        includeAllStates = true,
        includeAllCities = true,
    } = {}
) => {

    const allCountries = Country.getAllCountries();

    const allCountriesOptions = useMemo(() => {
        return allCountries?.map((item) => ({
            value: item.isoCode,
            label: item.name
        })) || [];
    }, [allCountries]);

    const optionAll = useMemo(() => [
        {
            label: "All",
            value: "ALL"
        }
    ], []);

    const continentOptions = useMemo(() => {
        return continents.map(item => ({
            label: item.continent,
            value: item.continent
        }));
    }, [continents]);

    const countryOptions = useMemo(() => {
        if (continent === "all") {
            return allCountries.map(item => ({
                label: item.name,
                value: item.isoCode
            }));
        }
        const selectedContinent = continents.find(item => item.continent === continent);
        return selectedContinent?.countries?.map(({ code, name }) => ({
            label: name,
            value: code
        })) || [];
    }, [continent, continents, allCountries]);

    const cityOptions = useMemo(() => {
        if (country === "all") {
            return [];
        }
        return City.getCitiesOfCountry(country)?.map((item) => ({
            value: item.name,
            label: item.name
        })) || [];
    }, [country]);

    const getContinentByCountryCode = (country) => {
        const selectedContinent = continents.find(item => item.countries.find(({ code }) => code === country));
        return selectedContinent?.continent
    }


    return {
        continentOptions: includeAllContinents ? [...optionAll, ...continentOptions] : continentOptions,
        countryOptions: includeAllCountries ? [...optionAll, ...countryOptions] : countryOptions,
        allCountriesOptions: includeAllCountries ? [...optionAll, ...allCountriesOptions] : allCountriesOptions,
        cityOptions: includeAllCities ? [...optionAll, ...cityOptions] : cityOptions,
        getContinentByCountryCode: getContinentByCountryCode
    };
};

export default useLocationOptions;
