import { Country } from 'country-state-city';
import React from 'react'
import { Controller } from 'react-hook-form';
import Input from '../../../../components/ui/formElements/Input';
import SelectOne from '../../../../components/ui/formElements/SelectOne';
import { belts } from '../../../../constants';
import HeadingBlock from '../../components/HeadingBlock';
import UploadPhotoInput from '../../components/UploadPhotoInput';
import { useTranslation } from 'react-i18next';
import PhoneInput from '../../../../components/ui/formElements/PhoneInput';

const CommonDetailsTab = ({ useFormReturn }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'commonDetailsTab' });

    const { register, control, formState: { errors } } = useFormReturn
    const beltsOptions = belts.map((belt) => ({
        label: belt.title,
        value: belt.key
    }));

    return (
        <>
            <HeadingBlock
                title={t('title')}
                description={t('subtitle')}
            />

            <Controller
                name="photo"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <UploadPhotoInput
                        onChange={onChange}
                        value={value}
                    />
                )}
            />

            <Input
                inputClasses="input--solid"
                label="Full Name"
                {...register('fullName')}
                placeholder="e.g. Jane Smith, Wade Warren..."
                wrapperClasses="form-group input--lg"
                error={errors.fullName?.message}
            />

            <Input
                inputClasses="input--solid"
                label="Email"
                {...register('email')}
                placeholder="Enter your email..."
                wrapperClasses="form-group input--lg"
                error={errors.email?.message}
            />

            <Controller
                name="phone"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <PhoneInput
                        inputClasses="input--solid"
                        label="Phone"
                        value={value}
                        onChange={onChange}
                        placeholder="Enter your phone..."
                        wrapperClasses="form-group input--lg"
                        error={errors.phone?.message}
                    />
                )}
            />

            <Controller
                name="belt"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <SelectOne
                        error={errors.belt?.message}
                        label="Belt"
                        onChange={(option) => onChange(option.value)}
                        options={beltsOptions}
                        placeholder="Choose your belt"
                        value={value}
                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                    />
                )}
            />

            <Controller
                name="country"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <SelectOne
                        error={errors.country?.message}
                        label="Country"
                        onChange={(option) => onChange(option.value)}
                        options={Country.getAllCountries().map(country => ({
                            label: country.name,
                            value: country.name
                        }))}
                        placeholder="Choose your country"
                        value={value}
                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                    />
                )}
            />
        </>
    )
}

export default CommonDetailsTab
