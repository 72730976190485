import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import api from '../../../api'
import Loader from '../../../components/ui/Loader'
import EventTypeSwitcher from '../components/EventTypeSwitcher'
import LessonsRequestItem from '../components/LessonsRequestItem'
import RequestItem from '../components/RequestItem'

const LessonsTab = () => {
    const [searchParams] = useSearchParams();
    const eventType = searchParams.get("eventType")
    const requestType = searchParams.get("requestType")

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const getRequests = async () => {
        setIsLoading(true)
        const response = await api.requests.getRequestsPrivateLessons()
        if (response?.success) {
            const data = response.data || [];
            setData(data)
        }
        setIsLoading(false)
    };

    useEffect(() => {
        getRequests()
    }, [eventType, requestType])

    return (
        <div className="tab active-tab">
            <div className="request-items mb-24">
                {isLoading ? <Loader /> : data?.length ? data.map((item) => (
                    <LessonsRequestItem key={item.id} data={item} update={getRequests} />
                )) : (
                    <div className="no-requests text-center pt-28">
                        <h4 className="heading">No requests</h4>
                    </div>
                )}
            </div>
        </div>
    )
}

export default LessonsTab