import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { USER_ROLE } from "../../constants/enums";
import { getUser } from "../../redux/slices/applicationSlice.js";
import MyAcademy from "./academyProfilePages/MyAcademy";
import MyProfileInstructor from "./instructorProfilePages/MyProfileInstructor";
import StudentMyProfile from "./studentProfilePages/StudentMyProfile";

const MyProfile = () => {
    const user = useSelector(getUser);
    const [searchParams] = useSearchParams()
    if (!user) {
        return null
    }
    const isVerifyModalInitialyOpen = searchParams.get('modal') === 'verification'
    return (
        <>

            {user && user.role === USER_ROLE.academy && <MyAcademy userData={user} isVerifyModalInitialyOpen={isVerifyModalInitialyOpen} />}

            {user && user.role === USER_ROLE.student && <StudentMyProfile userData={user} isVerifyModalInitialyOpen={isVerifyModalInitialyOpen} />}

            {user && user.role === USER_ROLE.instructor && <MyProfileInstructor userData={user} isVerifyModalInitialyOpen={isVerifyModalInitialyOpen} />}

        </>
    );
};

export default MyProfile;
