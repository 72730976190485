import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import api from '../../../api'
import Loader from '../../../components/ui/Loader'
import EventTypeSwitcher from '../components/EventTypeSwitcher'
import OwnerTypeSwitcher from '../components/OwnerTypeSwitcher'
import RequestItem from '../components/RequestItem'

const RequestsTab = () => {
    const [searchParams] = useSearchParams();
    const eventType = searchParams.get("eventType")
    const requestType = searchParams.get("requestType")

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const getRequests = async () => {
        setIsLoading(true)
        const isMyOwn = requestType === 'my' ? 'true' : '';

        const [requestsResponse, hostingResponse] = await Promise.all([
            api.requests.getRequests(`no_limit=true&event=${eventType}${isMyOwn ? `&is_my_own=${isMyOwn}` : ''}`),
            api.requests.getHostingRequests(`no_limit=true&type=${eventType}${isMyOwn ? `&is_my_own=${isMyOwn}` : ''}`)
        ]);

        if (requestsResponse?.success && hostingResponse?.success) {
            const requestsData = requestsResponse.data || [];
            const hostingData = hostingResponse.data?.map(item => ({ ...item, isHosting: true })) || [];
            setData([...requestsData, ...hostingData])
        }
        setIsLoading(false)
    };

    useEffect(() => {
        getRequests()
    }, [eventType, requestType])

    return (
        <div className="tab active-tab">
            <OwnerTypeSwitcher />
            <EventTypeSwitcher />
            <div className="request-items mb-24">
                {isLoading ? <Loader /> : data?.length ? data.map((item) => (
                   <RequestItem key={item.id} data={item} isHosting={item.isHosting} update={getRequests} />
                )) : (
                    <div className="no-requests text-center pt-28">
                        <h4 className="heading">No requests</h4>
                    </div>
                )}
            </div>
        </div>
    )
}

export default RequestsTab