import classNames from "classnames";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneInput = ({
    label, placeholder, afterLabel, defaultValue, wrapperClasses, value, error, inputClasses, onChange, ...props
}) => {

    const handleChange = (value) => {
        const formattedValue = value.startsWith("+") ? value : `+${value}`;
        onChange(formattedValue);
    }
    return (
        <div className={`form-group ${wrapperClasses}`}>
            {label && (
                <div className="row-group gap--sm">
                    <label className="label">
                        {label}
                    </label>
                    {afterLabel}
                </div>
            )}

            <div className="input-wrapper">
                <ReactPhoneInput
                    value={value}
                    country={"us"}
                    onChange={handleChange}
                    placeholder={placeholder || "Enter phone number"}
                    inputClass={classNames("input", {
                        "input--error": error
                    }, inputClasses)}
                    containerStyle={{ width: "100%" }}
                    inputStyle={{ width: "100%" }}
                    {...props}
                />
            </div>

            {error && typeof error === "string" && (
                <p className="error-input">
                    {error}
                </p>
            )}
        </div>
    );
};

export default PhoneInput;
