import { object, string } from "yup";

export const birthdaySchema = object().shape({
    birthday: string()
        .required("Date of birth is required")
        .transform((value) => {
            const date = new Date(value);
            if (isNaN(date.getTime())) {
                return value;
            }
            return date.toISOString();
        })
        .matches(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/, "Date of birth must be in ISO format (yyyy-mm-ddThh:mm:ss.sssZ)"),
});

