import { yupResolver } from "@hookform/resolvers/yup";
import {
    useEffect, useState
} from "react";
import { Controller, useForm } from "react-hook-form";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useParams
} from "react-router-dom";
import { object, string } from "yup";
import { ReactComponent as LocationIcon } from "../../assets/img/icon-decor-city.svg";
import SelectionImg from "../../assets/img/section.png";
import LoginForm from "../../components/parts/LoginForm";
import Button from "../../components/ui/buttons/Button";
import CheckboxSwitch from "../../components/ui/formElements/CheckboxSwitch";
import Input from "../../components/ui/formElements/Input";
import SelectOne from "../../components/ui/formElements/SelectOne";
import PageLoader from "../../components/ui/PageLoader";
import { belts, trainingStyle as trainingStyles } from "../../constants";
import { EVENT_TYPE } from "../../constants/enums";
import { EventHelper } from "../../helpers/EventHelper";
import { getImageSrc, transformToNumber } from "../../helpers/utils";
import { getUser } from "../../redux/slices/applicationSlice";
import { getSingleCampFetch } from "../../redux/thunks/planCampThuncks";
import { getSeminarsById } from "../../redux/thunks/seminarsThunks";
import { buyTicketFetch } from "../../redux/thunks/ticketsThunks";

const BuySeminarPage = ({ type = EVENT_TYPE.seminar }) => {
    const dispatch = useDispatch();
    const [agree, setAgree] = useState(true);
    const [isBuying, setIsBuying] = useState(false)

    const { id } = useParams();
    const user = useSelector(getUser);
    const { seminarDetails, isLoading: isSeminarsLoading } = useSelector((state) => state.seminars);
    const { campDetails, isLoading: isPlancampsLoading } = useSelector((state) => state.planCamps);
    const data = type === EVENT_TYPE.camp ? campDetails : seminarDetails;
    const isLoading = isPlancampsLoading || isSeminarsLoading;

    const { register, watch, control, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(object().shape({
            fullName: string().required("Full Name is required").matches(/^[A-Za-z]+(\s[A-Za-z]+)+$/, "Full Name must contain Name and Surname").trim(""),
            email: string().email("Incorrect email format").required("Email is required"),
            belt: string().required("Belt is required"),
            quantity: string().transform(transformToNumber).min(1).default(1),
        })),
        defaultValues: {
            fullName: user?.fullName || user?.profile?.fullName || "",
            email: user?.email || "",
            belt: user?.belt || "",
            quantity: 1,
        }
    })

    const quantity = watch("quantity");

    const onSubmit = async (data) => {
        setIsBuying(true)
        const res = await dispatch(buyTicketFetch({
            eventId: id,
            eventType: type,
            ...data
        })).unwrap()
        const paymentUrl = res?.data?.session?.url

        if (paymentUrl) {
            window.location.href = paymentUrl
        }

        setTimeout(() => setIsBuying(false), 100)
    }

    const specificBeltOptions = belts.map(({ key, title }) => ({
        value: key,
        label: title
    }));


    useEffect(() => {
        if (!id) return;
        if (type === EVENT_TYPE.camp) {
            dispatch(getSingleCampFetch(id));
        } else {
            dispatch(getSeminarsById(id));
        }
    }, [dispatch, id, type]);


    if (isLoading) {
        return <PageLoader />;
    }

    if (!data) {
        return (
            <section className="section-42">
                <div className="container">
                    <h2 className="heading">
                        {type} Not found
                    </h2>
                </div>
            </section>
        );
    }

    return (
        <div className="section-with-image">
            <img src={SelectionImg} className="section-with-image__img" alt="section" />
            <div className="container">
                <div className="double-section full-height no-border flex">
                    <div className="double-section__content withBg pt-42">
                        {user
                            ? (
                                <form className="col-group gap-30" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="col-group gap--sm">
                                        <div className="pill pill--secondary">
                                            Limited tickets left
                                        </div>

                                        <h6 className="heading">
                                            {data.seminarName || data.name}
                                        </h6>

                                        <p className="text--xs color-text">
                                            {EventHelper.dateRange(data)}
                                        </p>
                                    </div>

                                    <hr className="hr-horisontal" />

                                    <div className="col-group gap-30">
                                        <h6 className="heading">
                                            {data.seminarName || data.name}
                                        </h6>

                                        <p className="color-text">
                                            {data.summary}
                                        </p>

                                        <div className="row-group gap-30">
                                            <div className="price-group fw-700 ttu">
                                                <span className="price-group__price">
                                                    $

                                                    {data.minimalPriceTicket || 0}
                                                </span>

                                                {" "}

                                                / ticket
                                            </div>
                                        </div>
                                    </div>

                                    <hr className="hr-horisontal" />

                                    <div className="col-group gap-30">
                                        <p className="color-text text-sm">
                                            Ticket and receipt are sent to the info below:
                                        </p>

                                        <div className="row gx-16 gutters-form">
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <Input
                                                    inputClasses="input--solid"
                                                    label="Full Name"
                                                    placeholder="Type your full name..."
                                                    wrapperClasses="form-group flex-auto input--lg"
                                                    {...register("fullName")}
                                                    error={errors.fullName?.message}
                                                />
                                            </div>

                                            <div className="col-md-6 col-sm-6 col-12">
                                                <Input
                                                    inputClasses="input--solid"
                                                    label="Email"
                                                    placeholder="Type your email..."
                                                    wrapperClasses="form-group flex-auto input--lg"
                                                    {...register("email")}
                                                    error={errors.email?.message}
                                                />
                                            </div>

                                            <div className="col-md-6 col-sm-6 col-12">
                                                <Input
                                                    inputClasses="input--solid"
                                                    label="Quantity"
                                                    type="number"
                                                    min={1}
                                                    wrapperClasses="form-group flex-auto input--lg"
                                                    {...register("quantity")}
                                                    error={errors.quantity?.message}
                                                />
                                            </div>

                                            <div className="col-md-6 col-sm-6 col-12">
                                                <Controller
                                                    control={control}
                                                    name="belt"
                                                    render={({ field: { onChange, value } }) => (
                                                        <SelectOne
                                                            label="Belt Type"
                                                            onChange={(option) => onChange(option.value)}
                                                            options={specificBeltOptions}
                                                            placeholder="Select your belt"
                                                            value={value}
                                                            wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                                            error={errors.belt?.message}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <hr className="hr-horisontal" />

                                    <div className="option-group">
                                        <CheckboxSwitch
                                            checked={agree}
                                            onChange={() => setAgree(!agree)}
                                            text="I want to receive newsletters"
                                        />
                                    </div>

                                    <div className="w-full text-center">
                                        <Button
                                            isLoading={isBuying}
                                            disabled={!agree}
                                            className="btn btn--primary btn--lg mb-20 w-full"
                                            type="submit"
                                        >
                                            <span className="info">
                                                Continue
                                            </span>
                                        </Button>

                                        <p className="color-text text-14">
                                            When you click “Continue” you accept our

                                            {" "}

                                            <Link
                                                className="color-secondary"
                                                to="/terms"
                                            >
                                                terms
                                            </Link>

                                            {" "}

                                            and

                                            {" "}

                                            <Link
                                                className="color-secondary"
                                                to="/privacy"
                                            >
                                                privacy policy
                                            </Link>
                                        </p>
                                    </div>
                                </form>
                            ) : (
                                <LoginForm
                                    isPreBooking
                                    wrapperClasses="form-login"
                                    description="Login or sign up to purchase on Global Seminars"
                                />
                            )}
                    </div>

                    <div className="double-section__aside withBackground">
                        <OrderSummary data={data} seminar={true} quantity={quantity} />
                    </div>
                </div>
            </div>
        </div>
    );
};

function OrderSummary({ data, seminar = false, quantity = 1 }) {
    const traningStyle = trainingStyles.find(({ title }) => title?.toLowerCase() === data?.traningStyle?.toLowerCase())?.title;
    const cover = data?.photos?.find((photo) => photo.isCover || photo.id === data.coverId)?.url;
    const name = data?.seminarName || data?.name;

    return (
        <div className="card-shadow px-36 py-32 mb-16">
            <h6 className="heading mb-32">
                Order Summary
            </h6>

            <div className="row-group gap--xxl pb-24 border-bottom mb-24 flex-lg-nowrap flex-wrap">
                <div className="image-wrapper --medium-thumb">
                    <img
                        alt={name}
                        src={getImageSrc(cover)}
                    />
                </div>

                <div className="col-group gap--xxl justify-between">
                    {data.adress}

                    <div className="row-group color-secondary fw-500 text-13 gap-4">
                        <div className="ico">
                            <LocationIcon />
                        </div>

                        <span className="truncate">
                            {EventHelper.location(data)}
                        </span>
                    </div>

                    <div className="seminar-label-info">
                        <h6 className="heading">
                            {name}
                        </h6>
                    </div>
                </div>
            </div>

            <div className="ticket-info-body ticket-info-body--no-border mb-32">
                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Date:
                    </span>

                    <span className="fw-500">
                        {EventHelper.dateRange(data)}
                    </span>
                </div>

                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Style:
                    </span>

                    <span className="fw-500">
                        {traningStyle}
                    </span>
                </div>

                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Academy:
                    </span>

                    <span className="fw-500">
                        {data.userAcademy?.academyProfile?.name}
                    </span>
                </div>

                <div className="ticket-info-body__item justify-between text--lg">
                    <span className="color-text">
                        Instructors:
                    </span>

                    <span className="fw-500">
                        {data.instructors?.map((item) => item?.user?.instructorProfile?.fullName).join(", ")}
                    </span>
                </div>
            </div>

            {false && (
                <Button className="btn--default btn--md w-full mb-32">
                    Apply Coupon
                </Button>
            )}

            <hr className="hr-horisontal mb-32" />
            <div className="col-group gap--xs">
                <div className="row-group justify-between gap--md">
                    <div className="price-group">
                        <span className="color-grey mr-8">
                            Subtotal
                        </span>
                    </div>

                    <span className="price-group__price fs-20">
                        ${data.minimalPriceTicket * quantity}
                    </span>
                </div>
                {seminar &&
                    <div className="row-group justify-between gap--md">
                        <div className="price-group">
                            <span className="color-grey mr-8">
                                Service Fee
                            </span>
                        </div>

                        <span className="price-group__price fs-20">
                            ${EventHelper.fee(data) * quantity}
                        </span>
                    </div>
                }
                <div className="row-group justify-between gap--md">
                    <div className="price-group">
                        <span className="color-grey mr-8 fs-18">
                            Total Price
                        </span>

                        <span className="price-group__amount">
                            {EventHelper.ticketsLeft(data)} left
                        </span>
                    </div>

                    <span className="price-group__price fs-24">
                        ${EventHelper.totalTicketPrice(data) * quantity}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default BuySeminarPage;
