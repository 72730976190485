import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import api from '../../../api';
import { PaginationTemplate } from '../../../components/parts/PaginationTemplate';
import PageLoader from '../../../components/ui/PageLoader';
import OverviewRequestCard from './OverviewRequestCard';

const RequestsSection = ({ eventId, eventType, event }) => {
    const dispatch = useDispatch();
    const [requests, setRequests] = useState([])
    const [isLoading, setIsLoading] = useState([])

    const getRequests = async () => {
        try {
            setIsLoading(true)
            const res = await api.requests.getRequestsByEventIdAndEventType({
                eventId,
                eventType
            })

            if (res?.success) {
                setRequests(res.data)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getRequests()
    }, [dispatch]);

    if (isLoading) {
        return <PageLoader />
    }

    if (!requests?.length) return;

    return (
        <div className="form-item">
            <div className="row gutters-form">
                <div className="col-12">
                    <div className="tabs tab-seminars">
                        <div className="tabs__header flex items-center gap-24 pb-24">
                            <h6 className="heading">
                                Requests:
                            </h6>
                        </div>

                        <div className="tabs__body">

                            <div className="wrapp-table">
                                <div className="request-items">
                                    {requests.map(item => (
                                        <OverviewRequestCard
                                            key={item.id}
                                            update={getRequests}
                                            request={item}
                                            event={event}
                                            eventType={item.type}
                                        />
                                    ))}
                                </div>

                                <PaginationTemplate />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequestsSection