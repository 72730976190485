import { Country, State } from 'country-state-city';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import MapBox from '../../../../components/parts/mapBox/MapBox';
import { getAddress, getCoordinates } from '../../../../components/parts/mapBox/mapBoxFunctions';
import Input from '../../../../components/ui/formElements/Input';
import SelectOne from '../../../../components/ui/formElements/SelectOne';
import HeadingBlock from '../../components/HeadingBlock';

const LocationTab = ({ useFormReturn }) => {
    const { register, control, watch, setValue, formState: { errors } } = useFormReturn
    const countries = Country.getAllCountries().map((item) => ({
        value: item.isoCode,
        label: item.name
    }));

    const country = watch('generalAcademyInfo.country');
    const addressLine = watch('generalAcademyInfo.addressLine');
    const city = watch('generalAcademyInfo.city');
    const state = watch('generalAcademyInfo.state');
    const zip = watch('generalAcademyInfo.zip');
    const latitude = watch('generalAcademyInfo.latitude');
    const longitude = watch('generalAcademyInfo.longitude');

    const allStates = State.getStatesOfCountry(country);
    const states = allStates?.map((item) => ({
        value: item.isoCode,
        label: item.name
    })) || [];


    useEffect(() => {
        if (country && addressLine && city && state && zip) {
            const address = `${addressLine} ${city} ${state} ${zip}`;
            fetchCoordinates(address);
        }
    }, [country, addressLine, city, state, zip]);

    const fetchCoordinates = async (address) => {
        try {
            const response = await getCoordinates(address);
            const { lat, lon } = response.coordinates;

            if (latitude !== lat || longitude !== lon) {
                setValue('generalAcademyInfo.latitude', lat);
                setValue('generalAcademyInfo.longitude', lon);
            }
        } catch (error) {
            console.error("Error fetching coordinates:", error);
        }
    };

    const handleMapClick = async (obj) => {
        const { lat, lon } = obj;
        const address = await getAddress({ lat, lon });
        setValue('generalAcademyInfo.latitude', lat);
        setValue('generalAcademyInfo.longitude', lon);
        setValue('generalAcademyInfo.addressLine', address.addressLine);
        setValue('generalAcademyInfo.city', address.city);
        setValue('generalAcademyInfo.country', address.country);
        setValue('generalAcademyInfo.state', address.state);
        setValue('generalAcademyInfo.zip', address.zip);
    };

    return (
        <div>
            <HeadingBlock
                title="ACADEMY LOCATION"
                description="Tell us a bit about your academy"
            />
            <div className="row gutters-form-2">
                <div className="col-12">
                    <Controller
                        name="generalAcademyInfo.country"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <SelectOne
                                label="Country"
                                onChange={(option) => onChange(option.value)}
                                options={countries}
                                placeholder="Country..."
                                value={value}
                                wrapperClasses="select--outline  select--outline-bg input--lg"
                                error={errors.generalAcademyInfo?.country?.message}
                            />
                        )}
                    />
                </div>

                <div className="col-md-4 col-sm-4 col-12">
                    <Input
                        inputClasses="input--solid"
                        label="Town / City"
                        placeholder="Milford"
                        wrapperClasses="input--lg"
                        {...register('generalAcademyInfo.city')}
                    />
                </div>

                <div className="col-md-4 col-sm-4 col-12">
                    <Controller
                        name="generalAcademyInfo.state"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <SelectOne
                                label="State"
                                onChange={(option) => onChange(option.value)}
                                options={states}
                                placeholder="State..."
                                value={value}
                                wrapperClasses="select--outline  select--outline-bg input--lg"
                            />
                        )}
                    />
                </div>

                <div className="col-md-4 col-sm-4 col-12">
                    <Input
                        inputClasses="input--solid"
                        label="ZIP"
                        {...register('generalAcademyInfo.zip')}
                        placeholder="10299"
                        wrapperClasses="input--lg"
                    />
                </div>

                <div className="col-12">
                    <Input
                        inputClasses="input--solid"
                        label="Address Line"
                        {...register('generalAcademyInfo.addressLine')}
                        placeholder="6391 Elgin St. "
                        wrapperClasses="input--lg"
                    />
                </div>
                <div className="col-12">
                    <div
                        className="academy-profile-settings map"
                        style={{ height: "200px" }}
                    >
                        <Controller
                            name="generalAcademyInfo"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <MapBox
                                    getCoordinatesOnclick={(obj) => handleMapClick(obj, onChange)}
                                    mainPoints={value.latitude ? [{ coordinates: [value.longitude, value.latitude] }] : []}
                                    newCenter={value.latitude ? [value.longitude, value.latitude] : undefined}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LocationTab