import { Country } from "country-state-city";
import { useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import { ReactComponent as CompassIcon } from "../../assets/img/Compass.svg";
import { getImageSrc } from "../../helpers/utils";
import Button from "../ui/buttons/Button";
import Textarea from "../ui/formElements/Textarea";
import ModalTemplate from "./ModalTemplate";

const MIN_LENGTH = 15

const CancelEventModal = ({ open, handleClose, data }) => {
    const [isConfirmationStep, setIsConfirmationStep] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isErrorsShown, setIsErrorsShown] = useState(false)
    const [formData, setFormData] = useState({
        reasonCancellation: "",
    })

    const handleChange = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSubmit = async () => {
        try {
            setIsErrorsShown(true)

            if (formData.reasonCancellation.length < MIN_LENGTH) {
                return
            }

            setIsLoading(true)
            // const response = await api.events.cancelEvent({
            //     eventId: data.id,
            //     eventType: data._type,
            //     body: formData
            // });
            let response;
            if (data.eventStartStatus === "waiting_confirm_by_profit") {
                response = await api.events.cancelOrActivateEventRequest({
                    eventId: data.id,
                    eventType: data._type,
                    mode: "shared_profit_cancel"
                })
            } else {
                response = await api.events.cancelEventRequest({
                    eventId: data.id,
                    eventType: data._type,
                    body: formData
                });
            }


            if (response?.success) {
                toast.success(response?.message || "Event Canceled")
                handleClose && handleClose()
            } else {
                toast.error(response?.message || "Something went wrong")
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <ModalTemplate
            modalSize="modal__dialog--xs"
            onClick={handleClose}
            open={open}
        >
            {
                !isConfirmationStep ? (
                    <div className="refund_body">
                        <div>
                            <h2 className="refund_heading">Cancel Event</h2>
                            <div className="refund_body_container">
                                <img
                                    className="refund_body_img"
                                    alt={data?.seminarName || data?.name}
                                    src={getImageSrc(data?.cover || data?.cover)}
                                />
                                <div>
                                    <div className="refund_body_address mb-8">
                                        <CompassIcon />
                                        <p className="capitalize">
                                            {[
                                                data?.location,
                                                data?.address,
                                                data?.continent,
                                                Country.getCountryByCode(data?.country)?.name,
                                                data?.city
                                            ].filter(Boolean).join(", ") || "Empty"}
                                        </p>
                                    </div>
                                    <p className="refund_body_seminar_name">{data?.seminarName || data?.name}</p>
                                </div>
                            </div>
                            <hr className="hr-horisontal mb-32" />
                            <Textarea
                                label="Reason Cancellation"
                                onChange={(e) => handleChange("reasonCancellation", e.target.value)}
                                placeholder="Type the reason why you need the cancel event..."
                                style={{ minHeight: 140 }}
                                textareaClasses="input--solid"
                                value={formData.reasonCancellation}
                                error={isErrorsShown && !formData.reasonCancellation.length < MIN_LENGTH && `Reason is required, minimum length is ${MIN_LENGTH} symbols`}
                                wrapperClasses="input--lg"
                            />
                        </div>
                        <div className="ml-auto mt-28">
                            <Button
                                className=" btn--md"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="btn--primary btn--md"
                                onClick={() => {
                                    if (formData.reasonCancellation.length >= MIN_LENGTH) {
                                        setIsConfirmationStep(true)
                                    } else {
                                        setIsErrorsShown(true)
                                    }
                                }}
                            >
                                Cancel Event
                            </Button>


                        </div>
                    </div>
                ) : (
                    <div className="refund_confirm_body">
                        <h2 className="refund_confirm_heading">Cancel Event Confirmation</h2>
                        <p className="refund_confirm_text">Are you sure you want to cancel event?
                            This cannot be undone.</p>
                        <div className="m-auto">
                            <Button
                                className=" btn--md"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="btn--primary btn--md"
                                onClick={handleSubmit}
                                isLoading={isLoading}
                            >
                                Yes, Proceed
                            </Button>


                        </div>
                    </div>
                )
            }
        </ModalTemplate>
    );
};

export default CancelEventModal;
