import DotIcon from "../../../../assets/img/ico-dot.svg";

import PropTypes from 'prop-types';
import { NOT_SPECIFIED } from "../../../../constants";

/**
 * CardDetails component displaying a form group with a label and content.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.isEdited - Indicates if the content has been edited.
 * @param {string} props.title - The title to display.
 * @param {React.ReactNode} props.extraLabel - Additional label content.
 * @param {React.ReactNode} props.children - The main content to display.
 * @returns {JSX.Element} A JSX element representing the card details.
 */
export const CardDetails = ({ isEdited, title, extraLabel, children }) => (
    <div className="form-group input--lg">
        {(title || isEdited || extraLabel) && (
            <label className="label row-group justify-between gap--xs">
                <div className="row-group gap--xs text--sm">
                    {title}
                    {isEdited && (
                        <span className="flex">
                            <img src={DotIcon} alt="DotIcon" />
                        </span>
                    )}
                </div>
                {extraLabel}
            </label>
        )}
        <div className={`card-details col-group ${isEdited ? "--edited" : ""}`}>
            {children ? children : NOT_SPECIFIED}
        </div>
    </div>
);

CardDetails.propTypes = {
    isEdited: PropTypes.bool.isRequired,
    title: PropTypes.string,
    extraLabel: PropTypes.node,
    children: PropTypes.node
};
