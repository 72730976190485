import { object, ref, string } from "yup";

export const passwordSchema = object().shape({
    password:
        string()
            .min(8, "Password must contain at least 8 characters")
            .required("Password is required"),
    confirmPassword:
        string()
            .min(8, "Password must contain at least 8 characters")
            .oneOf([ref("password")], "Passwords must match")
            .required("Password is required"),
});
