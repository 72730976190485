import React from 'react'
import InputPass from '../../../components/ui/formElements/InputPass';
import HeadingBlock from '../components/HeadingBlock';

const PasswordTab = ({ useFormReturn }) => {
    const { register, control, formState: { errors } } = useFormReturn;
    return (
        <>
            <HeadingBlock
                title={"Set your password"}
                description={"Create password for your account"}
            />
            <InputPass
                forgotPass={false}
                formGroupClass="input--lg input--icon-right"
                inputClass="input--solid w-full"
                label="Password"
                {...register('password', { required: true })}
                error={errors.password?.message}
                placeholder="Password"
            />

            <InputPass
                forgotPass={false}
                formGroupClass="input--lg input--icon-right"
                inputClass="input--solid w-full"
                label="Confirm Password"
                {...register('confirmPassword', { required: true })}
                error={errors.confirmPassword?.message}
                placeholder="Confirm Password"
            />
        </>
    )
}

export default PasswordTab