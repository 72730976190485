import React from 'react';
import { Controller } from 'react-hook-form';
import DatePicker from "react-datepicker";
import { useBirthday } from '../../hooks/useBirthday';

const BirthdayFormFields = ({ control, setValue, watch }) => {
    useBirthday(setValue, watch);

    return (
        <div className="row gutters-form-2">
            <div className="col-md-4 col-sm-4 col-12">
                <div className="day-datepicker">
                    <Controller
                        control={control}
                        name="dateDay"
                        render={({ field: { onChange, value } }) => (
                            <DatePicker
                                className="input input--solid"
                                dateFormat="dd"
                                onChange={onChange}
                                selected={value}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="col-md-4 col-sm-4 col-12">
                <div className="month-datepicker">
                    <Controller
                        control={control}
                        name="dateMonth"
                        render={({ field: { onChange, value } }) => (
                            <DatePicker
                                className="input input--solid"
                                dateFormat="MM"
                                onChange={onChange}
                                selected={value}
                                showMonthYearPicker
                            />
                        )}
                    />
                </div>
            </div>

            <div className="col-md-4 col-sm-4 col-12">
                <div className="month-datepicker">
                    <Controller
                        control={control}
                        name="dateYear"
                        render={({ field: { onChange, value } }) => (
                            <DatePicker
                                className="input input--solid"
                                dateFormat="yyyy"
                                onChange={onChange}
                                selected={value}
                                showYearPicker
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    )
}

export default BirthdayFormFields;
