import { yupResolver } from '@hookform/resolvers/yup'
import { Country } from 'country-state-city'
import { format, isValid } from 'date-fns'
import { pick } from 'lodash'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import api from '../../../api'
import Congratulations from '../../../components/parts/Congratulations'
import PageLoader from '../../../components/ui/PageLoader'
import { EVENT_STATUS, EVENT_STEP, USER_ROLE } from '../../../constants/enums'
import { convertObjectToFormData, getFullLocation } from '../../../helpers/utils'
import { useTabs } from '../../../hooks/useTabs'
import { createSeminar, updateSeminarFetch } from '../../../redux/thunks/seminarsThunks'
import SeminarCardPreview from '../components/SeminarCardPreview'
import TermsTab from '../common/TermsTab'
import FooterActions from '../components/FooterActions'
import TabsNavigation from '../components/TabsNavigation'
import withCommonData from '../components/withCommonData'
import { PLANNING_STATUS } from '../constants/emuns'
import { seminarDatesSchema, seminarInformationSchema, seminarSchema, termsSchema } from '../validation/seminarSchema'
import SeminarDateTab from './tabs/SeminarDateTab'
import SeminarDesignTab from './tabs/SeminarDesignTab'
import SeminarInformationTab from './tabs/SeminarInformationTab'
import { planningMessages } from '../constants/messages'


const PlanSeminarPage = ({
    chatId,
    user,
    generalInfo,
    isInCountry,
    currentChatData,
    seminarDetails,
    defaultValues,
    requestId,
    isVerified
}) => {
    const { seminarId, tourId } = useParams();
    const [searchParams] = useSearchParams();
    const editStep = searchParams.get("edit");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isCreated, setIsCreated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const planningStatus = (() => {
        if (seminarDetails?.status === EVENT_STATUS.active) {
            return PLANNING_STATUS.active;
        }
        return chatId ? PLANNING_STATUS.negotiation : PLANNING_STATUS.draft;
    })();
    const currentStep = seminarDetails?.status === EVENT_STATUS.active ? EVENT_STEP.design : currentChatData?.academyAccept?.step

    const tabItems = [
        {
            label: "Time & Date",
            id: EVENT_STEP.date,
            editableStatus: [PLANNING_STATUS.draft, PLANNING_STATUS.negotiation],
            schema: seminarDatesSchema(user.role, planningStatus)
        },
        {
            label: `Seminar Information`,
            id: EVENT_STEP.details,
            editableStatus: [PLANNING_STATUS.draft, PLANNING_STATUS.negotiation],
            schema: seminarInformationSchema(user.role, planningStatus)
        },
        {
            label: "Design & Text",
            id: EVENT_STEP.design,
            editableStatus: [PLANNING_STATUS.draft, PLANNING_STATUS.negotiation, PLANNING_STATUS.active],
            schema: seminarSchema(user.role, planningStatus)
        },
        {
            label: "Booking Specific Terms",
            id: EVENT_STEP.terms,
            editableStatus: [PLANNING_STATUS.negotiation],
            schema: termsSchema
        },
    ];

    const filteredTabs = tabItems.filter(({ editableStatus }) => editableStatus.includes(planningStatus))
    const { activeTab, setActiveTab, activeTabIndex } = useTabs(filteredTabs, 't');
    const [previewData, setPreviewData] = useState({});

    // Set active tab based on chat data if it exists
    useEffect(() => {
        if (editStep) {
            setActiveTab(editStep)
            return;
        }
        if (currentStep) {
            setActiveTab(currentStep)
        } else {
            setActiveTab(EVENT_STEP.date)
        }
    }, [currentChatData, setActiveTab])

    const currentSchema = planningStatus === PLANNING_STATUS.draft && activeTabIndex === filteredTabs.length - 1 ?
        seminarSchema(user.role, planningStatus) : filteredTabs[activeTabIndex]?.schema;

    const resolver = yupResolver(currentSchema);
    const useFormReturn = useForm({
        resolver,
        defaultValues: {
            isPrivate: false,
            isAvailablePrivateLesson: false,
            ...defaultValues,
            terms: currentChatData?.academyAccept?.terms
        }
    })
    const { handleSubmit, watch, formState: { errors, touchedFields, dirtyFields } } = useFormReturn
    const editedFields = Object.keys(dirtyFields).filter(key => dirtyFields[key] && errors[key] === undefined)

    watch((values, options) => {
        if (['continent', 'country', 'city', 'address', 'state',].includes(options.name)) {
            const location = getFullLocation([values.continent, Country.getCountryByCode(values.country)?.name, values.city, values.address])

            setPreviewData((prev) => ({
                ...prev,
                location
            }))
        }
        if (['seminarName', 'traningStyle', 'availableDates'].includes(options.name)) {
            setPreviewData((prev) => ({
                ...prev,
                [options.name]: values[options.name],
            }))
        }
    })
    const isPrivate = watch('isPrivate');

    useEffect(() => {
        const location = seminarDetails && getFullLocation([Country.getCountryByCode(seminarDetails.country)?.name, seminarDetails.city])
        const academyLocation = generalInfo && getFullLocation([Country.getCountryByCode(generalInfo.country)?.name, generalInfo.city, generalInfo.addressLine])
        const instructors = seminarDetails?.instructors?.length
            ? seminarDetails.instructors.map((item) => ({
                id: item.user?.id,
                fullName: item.user?.instructorProfile?.fullName,
            }))
            : [];

        setPreviewData((prev) => ({
            ...prev,
            fullName: user?.profile?.name || user?.profile?.fullName || '',
            seminarName: seminarDetails?.seminarName || '',
            photo: user?.profile?.photo || '',
            location: user?.role === USER_ROLE.academy ? academyLocation : location,
            instructors: instructors,
            availableDates: seminarDetails?.seminarDates,
            cover: seminarDetails?.cover || null,

        }));
    }, [generalInfo, seminarDetails, user])

    useEffect(() => {
        console.log(errors, 'errors');
    }, [errors])

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const errorMessages = Object.keys(errors).map(key => errors[key].message)
            errorMessages.map(message => toast.warn(message))
        }
    }, [errors])



    const tabComponents = {
        [EVENT_STEP.date]: (
            <SeminarDateTab
                useFormReturn={useFormReturn}
                role={user?.role}
                generalInfo={generalInfo}
                planningStatus={planningStatus}
            />
        ),
        [EVENT_STEP.details]: (
            <SeminarInformationTab
                useFormReturn={useFormReturn}
                role={user?.role}
                planningStatus={planningStatus}
                preview={previewData}
                setPreview={setPreviewData}
            />
        ),
        [EVENT_STEP.design]: (
            <SeminarDesignTab
                planningStatus={planningStatus}
                useFormReturn={useFormReturn}
                role={user?.role}
                setPreview={setPreviewData}
                isInCountry={isInCountry}

            />
        ),
        [EVENT_STEP.terms]: (
            <TermsTab
                planningStatus={planningStatus}
                useFormReturn={useFormReturn}
                role={user?.role}
            />
        ),
    }

    const renderTab = (activeTab) => {
        return tabComponents[activeTab]
    }

    const transformData = (data) => {
        const result = { ...data }
        const startAt = new Date(result.startAt)
        const endAt = new Date(result.endAt)
        if (isValid(startAt) && isValid(endAt)) {
            result.startAt = format(startAt, "yyyy-MM-dd")
            result.endAt = format(endAt, "yyyy-MM-dd")
        }
        if (result.availableDates?.length) {
            result.availableDates = result.availableDates.map(item => ({
                ...item,
                date: (item?.date && isValid(new Date(item.date))) ? format(item.date, 'yyyy-MM-dd') : null
            }))
        }
        if (tourId) {
            result.tourId = tourId
        }
        return result
    }

    const update = async (data) => {
        const dataToUpdate = pick(data, editedFields);
        const body = convertObjectToFormData(dataToUpdate, new FormData(), {}, false);

        const res = await dispatch(updateSeminarFetch({
            seminarId,
            body
        })).unwrap();
        if (res) {
            if (chatId) {
                navigate(`/chat/${chatId}`);
            } else {
                navigate(`/my-seminars/${seminarId}`);
                toast.success("Successfully updated")
            }
        }
    }

    const create = async (data) => {
        if (!isVerified) {
            toast.error(planningMessages.notVerified);
            return
        }
        const body = convertObjectToFormData(transformData(data));
        const res = await dispatch(createSeminar(body)).unwrap();
        if (res) {
            setIsCreated(true);
        }
    }

    const updateTerms = async (terms) => {
        const res = await api.requests.createUpdateTerms({
            requestId,
            body: { terms }
        });

        if (res?.success) {
            navigate(`/chat/${chatId}`);
        } else {
            toast.error(res?.message || "Something went wrong");
        }
    }

    const onSubmit = async (data) => {
      
        try {
            console.log("Form data:", data);
            setIsLoading(true);

            if (activeTab === EVENT_STEP.terms) {
                updateTerms(data.terms);
                return;
            }

            if (activeTabIndex < filteredTabs.length - 1) {
                if (planningStatus === PLANNING_STATUS.negotiation) {
                    await update(data);
                } else {
                    setActiveTab(filteredTabs[activeTabIndex + 1].id);
                }
            } else {
                console.log("Final Submit:", data);
                if (seminarId) {
                    await update(data);
                } else {
                    await create(data);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        if (chatId) {
            navigate(`/chat/${chatId}`);
            return;
        }
        if (activeTab === EVENT_STEP.date) {
            navigate("/");
        } else {
            const currentStepIndex = filteredTabs.findIndex((tab) => tab.id === activeTab);
            if (currentStepIndex > 0) {
                const previousStep = filteredTabs[currentStepIndex - 1];
                setActiveTab(previousStep.id);
            }
        }
    };

    if (isLoading) {
        return <PageLoader />
    }
    return (
        <>
            <TabsNavigation
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tabItems={filteredTabs}
                currentStep={currentStep}
                planningStatus={planningStatus}
            />
            <section className="section-42">
                <div className="container">
                    {!isCreated && (
                        <div className="section-row-block plan-block">
                            <form onSubmit={handleSubmit(onSubmit)} className="section-row-block__body">
                                {renderTab(activeTab)}
                                <FooterActions
                                    onCancel={handleCancel}
                                    submitButtonText={(activeTabIndex === filteredTabs.length - 1 && planningStatus === PLANNING_STATUS.draft) ? "Publish" : (planningStatus !== PLANNING_STATUS.draft) || (activeTabIndex === filteredTabs.length - 1) ? "Apply Changes" : "Continue"}
                                    showSubmitIcon={(activeTabIndex !== filteredTabs.length - 1) || (planningStatus !== PLANNING_STATUS.draft)}
                                    chatId={chatId}
                                    isTerms={activeTab === EVENT_STEP.terms}
                                />
                            </form>
                            <SeminarCardPreview {...previewData} />
                        </div>
                    )}
                    {isCreated && <CongratulationsSection user={user} isPrivate={isPrivate} />}

                </div>
            </section>
        </>
    )
}

const CongratulationsSection = ({ user, isPrivate }) => {
    return (
        <>
            {user?.role === USER_ROLE.academy && (
                <Congratulations
                    text={
                        <div>
                            Your pending seminar has been created, and published <br /> in the
                            {" "}
                            {!isPrivate ?
                                <Link
                                    className="color-secondary underline"
                                    to="/planed-by-academy"
                                >
                                    Match And Apply
                                </Link>
                                :
                                <Link
                                    className="color-secondary underline"
                                    to="/my-seminars"
                                >
                                    My seminars
                                </Link>
                            }
                            {" "}
                            tab.
                        </div>
                    }
                    additionalLink={{
                        to: "/instructors",
                        title: "Invite Instructor(s)"
                    }}
                />
            )}

            {user?.role === USER_ROLE.instructor && (
                <Congratulations
                    text={
                        <>
                            Your pending seminar has been created, and published <br /> in the
                            {" "}
                            {!isPrivate ?
                                <Link
                                    className="color-secondary underline"
                                    to="/planed-by-insctructor"
                                >
                                    Match And Apply
                                </Link>
                                :
                                <Link
                                    className="color-secondary underline"
                                    to="/my-seminars"
                                >
                                    My seminars
                                </Link>
                            }
                            {" "}
                            tab.
                        </>
                    }
                />
            )}
        </>
    );
};

export default withCommonData(PlanSeminarPage)