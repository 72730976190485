import * as yup from "yup";
import { USER_ROLE } from "../../../constants/enums";
import { transformToNumber } from "../../../helpers/utils";
import { PLANNING_STATUS } from "../constants/emuns";
import { traningStyleOptions } from "../constants/options";

export const commonSeminarDatesSchema = (planningStatus) => {
    return {
        //common
        country: yup.string().optional(),
        startAt: yup.date().optional().nullable(),
        endAt: yup.date().optional().nullable(),
        availableDates: planningStatus !== PLANNING_STATUS.draft
            ? yup.array().of(
                yup.object().shape({
                    date: yup.string().required('Date is required'),
                    startTime: yup.string().required('Start time is required'),
                    endTime: yup.string().required('End time is required')
                    // date: yup.string().required().matches(/^\d{4}-\d{2}-\d{2}$/, "Date must be in this format: YYYY-MM-DD"),
                    // startTime: yup.string().required().matches(/^\d{2}:\d{2}$/, "Time must be in this format: HH:MM"),
                    // endTime: yup.string().required().matches(/^\d{2}:\d{2}$/, "Time must be in this format: HH:MM")
                })
            ).required('Available dates is required')
            : yup.array().of(
                yup.object().shape({
                    date: yup.string().required('Date is required'),
                    startTime: yup.string().nullable(),
                    endTime: yup.string().nullable(),
                    // date: yup.string().required().matches(/^\d{4}-\d{2}-\d{2}$/, "Date must be in this format: YYYY-MM-DD"),
                    // startTime: yup.string().nullable().matches(/^\d{2}:\d{2}$/, "Time must be in this format: HH:MM"),
                    // endTime: yup.string().nullable().matches(/^\d{2}:\d{2}$/, "Time must be in this format: HH:MM")
                })
            ).optional(),
    };
};

export const instructorSeminarDatesSchema = {
    continent: yup.string().nullable(),
    city: yup.string().optional(),
    privateSessionDates: yup.array().of(
        yup.object().shape({
            // date: yup.string().required().matches(/^\d{4}-\d{2}-\d{2}$/, "Date must be in this format: YYYY-MM-DD"),
            // startTime: yup.string().required().matches(/^\d{2}:\d{2}$/, "Time must be in this format: HH:MM"),
            // endTime: yup.string().required().matches(/^\d{2}:\d{2}$/, "Time must be in this format: HH:MM")
            date: yup.string().required('Date is required'),
            startTime: yup.string().required('Start time is required'),
            endTime: yup.string().required('End time is required')
        })
    ).optional().nullable().default([]),
};

export const academySeminarDatesSchema = {
    isAvailablePrivateLesson: yup.boolean().default(false),
    address: yup.string().required(),
};

export const seminarDatesSchema = (role, planningStatus = PLANNING_STATUS.draft) => {
    if (role === USER_ROLE.instructor) {
        return yup.object().shape({
            ...commonSeminarDatesSchema(planningStatus),
            ...instructorSeminarDatesSchema,
        });
    } else {
        return yup.object().shape({
            ...commonSeminarDatesSchema(planningStatus),
            ...academySeminarDatesSchema,
        });
    }
};

const seminarDraftPriceRangeSchema = yup.object().shape({
    seminarMinPrice: yup.number().nullable().transform(transformToNumber)
        .test(
            'price-or-profit',
            'Either price range or shared profit must be provided',
            function (value) {
                const { sharedProfit } = this.parent;
                return sharedProfit || !!value;
            }
        ),
    seminarMaxPrice: yup.number().nullable().transform(transformToNumber)
        .test(
            'price-or-profit',
            'Either price range or shared profit must be provided',
            function (value) {
                const { sharedProfit } = this.parent;
                return sharedProfit || !!value;
            }
        ),
    sharedProfit: yup.number().nullable()
        .test(
            'price-or-profit',
            'Either price range or shared profit must be provided',
            function (value) {
                const { seminarMinPrice, seminarMaxPrice } = this.parent;
                return seminarMinPrice || seminarMaxPrice || !!value;
            }).default(null),
});
const seminarPriceRangeSchema = yup.object().shape({
    instructorPrice: yup.number().nullable().transform(transformToNumber)
        .test(
            'price-or-profit',
            'Either instructor price or shared profit must be provided',
            function (value) {
                const { sharedProfit } = this.parent;
                return sharedProfit || !!value;
            }
        ),
    sharedProfit: yup.number().nullable()
        .test(
            'price-or-profit',
            'Either instructor price or shared profit must be provided',
            function (value) {
                const { instructorPrice } = this.parent;
                return instructorPrice || !!value;
            }).default(null),
});


export const commonSeminarInformationSchema = (planningStatus) =>
    yup.object().shape({
        traningStyle: yup
            .string()
            .required('Training style is required')
            .oneOf(traningStyleOptions.map(({ value }) => value)),
        targetAudience: yup
            .string()
            .required('Target audience is required')
            .oneOf(["male", "female", "any"])
            .default("any"),
        allowUnder18: yup.boolean().required().default(false),
        instructors: yup.array().optional().nullable(),
        teachingStyles: yup.array().optional(),
        languages: yup.array().optional(),
        ...(planningStatus !== PLANNING_STATUS.draft ? {
            ...seminarPriceRangeSchema.fields,
            minimalPriceTicket: yup.number().required('Ticket price is required').min(1, 'Ticket price must be at more than 0').transform(transformToNumber),
            minAttendance: yup.number().required('Minimum attendance is required').min(1, 'Min attendance must be at more than 0').nullable().optional(),
            // minAttendanceDeadline: yup.date().nullable().optional(),
        } : {
            ...seminarDraftPriceRangeSchema.fields
        }),
    });

export const seminarInformationSchema = (role, planningStatus = PLANNING_STATUS.draft) => {
    if (role === USER_ROLE.instructor) {
        return yup.object().shape({
            ...commonSeminarInformationSchema(planningStatus).fields,
        });
    } else {
        return yup.object().shape({
            ...commonSeminarInformationSchema(planningStatus).fields,
            maxGymCapacity: yup.number().required('Gym capacity is required').min(1, 'Gym capacity must be at more than 0'),
            specificBelt: yup.string().optional(),
        });
    }
};


export const termsSchema = yup.object().shape({
    terms: yup.string().optional().nullable(),
});

export const seminarSchema = (role, planningStatus) => {
    const baseSchema = {
        // dates
        ...seminarDatesSchema(role, planningStatus).fields,
        // information
        ...seminarInformationSchema(role, planningStatus).fields,
        //design
        seminarName: yup.string().required('Seminar name is required'),
        summary: yup.string().required('Summary is required'),
        photos: yup.array().required('Photos are required'),
        coverFilename: yup.string().optional().nullable(),
        coverId: yup.string().optional().nullable(),
        faq: yup.array().optional(),
        isPrivate: yup.boolean().default(false),
        //other
        tourId: yup.number().optional().nullable(),
    };

    return yup.object().shape(baseSchema);
};

