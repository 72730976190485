import { Country } from "country-state-city"
import { getLanguagesByKeys, NOT_SPECIFIED } from "../constants"
import { EVENT_TYPE } from "../constants/enums"
import { getDateRange, getDateRangeBetween, getDateRangeFromSchedule, getFullLocation, getTeachStyle, getTrainingStyle } from "./utils"

export class EventHelper {
    static startDate(eventDetails) {
        const { seminarDates, schedules, startAt, endAt, _type } = eventDetails
        if (_type === EVENT_TYPE.camp) {
            const dates = schedules?.length && schedules.flat()

            if (!dates?.length && !startAt) return
            if (!dates?.length && !startAt) {
                return startAt
            }
            if (!dates?.length) {
                return null
            }
            const copy = [...dates]
            copy.sort((a, b) => (new Date(a.date) - new Date(b.date)))
            return copy?.[0]?.date
        }
        if (!seminarDates?.length && !startAt && !endAt) return null
        const dates = [...seminarDates]
        if(!dates?.length) {
            return startAt || null
        }
        dates?.sort((a, b) => new Date(a.date) - new Date(b.date))
        return dates[0]?.date

    }
    static getEventRequest(eventDetails, id, field = "chatId") {
        return eventDetails?._eventRequest?.academyRequests?.find(req => +req[field] === +id)
    }
    static eventTotalIncome(eventDetails) {
        return eventDetails?.minimalPriceTicket * eventDetails?.maxGymCapacity
    }
    static instructorCut(eventDetails) {
        if (eventDetails?.sharedProfit) {
            return this.eventTotalIncome(eventDetails) * (eventDetails?.sharedProfit / 100)
        } else {
            return eventDetails?.instructorPrice
        }
    }
    static academyCut(eventDetails) {
        const instructorCut = this.instructorCut(eventDetails)
        const total = this.eventTotalIncome(eventDetails)
        if (!instructorCut || !total) return
        return total - instructorCut
    }
    static ticketsLeft(eventDetails) {
        const { maxGymCapacity = 0, ticketsPurchased = 0 } = eventDetails
        return maxGymCapacity - ticketsPurchased
    }
    static fee(eventDetails) {
        const { minimalPriceTicket = 0, _ticketFee = 0 } = eventDetails
        return minimalPriceTicket * _ticketFee
    }
    static totalTicketPrice(eventDetails) {
        return eventDetails?.minimalPriceTicket + this.fee(eventDetails)
    }
    static location(eventDetails) {
        const countries = eventDetails?.countries?.map(c => Country.getCountryByCode(c)?.name) || []
        return getFullLocation([
            ...((!countries?.length && (!eventDetails?.country || eventDetails?.country === "ALL")) ? [eventDetails?.continent] : []),
            ...countries,
            Country.getCountryByCode(eventDetails?.country)?.name,
            eventDetails?.city,
            eventDetails?.address
        ])
    }
    static priceRange(eventDetails) {
        const range = [eventDetails?.seminarMinPrice, eventDetails?.seminarMaxPrice].filter(Boolean);
        if (!range?.length && !eventDetails?.sharedProfit) return NOT_SPECIFIED;

        return eventDetails?.sharedProfit
            ? `Academy: ${100 - eventDetails?.sharedProfit}%, Instructor: ${eventDetails?.sharedProfit}%`
            : "$" + range.join(" - $")
    }
    static dateRange(eventDetails) {
        const { seminarDates, schedules, startAt, endAt, _type } = eventDetails
        if (_type === EVENT_TYPE.camp) {
            if(!schedules?.length && !startAt && !endAt) return 'Dates not specified'
            return schedules?.length ? getDateRangeFromSchedule(schedules) : getDateRangeBetween(startAt, endAt)
        }
        
        if (!seminarDates?.length && !startAt && !endAt) return 'Dates not specified'
        return seminarDates?.length ? getDateRange(eventDetails?.seminarDates) : getDateRangeBetween(startAt, endAt)
    }
    static style(eventDetails) {
        return getTrainingStyle(eventDetails?.traningStyle)?.[0]
    }
    static teaching(eventDetails) {
        return getTeachStyle(eventDetails?.teachingStyles)?.filter(Boolean).join(", ")
    }
    static languages(eventDetails) {
        const eventLanguages = eventDetails?.languages || []
        if (!eventLanguages?.length) return
        return getLanguagesByKeys(eventLanguages)
    }
    static targetAudience(eventDetails) {
        const { targetAudience } = eventDetails || {};
        return targetAudience && targetAudience !== "any"
            ? targetAudience.charAt(0).toUpperCase() + targetAudience.slice(1)
            : "Everyone";
    }
}