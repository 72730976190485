import { useDebounce } from "@uidotdev/usehooks";
import { Country } from "country-state-city";
import {
    useEffect, useMemo, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import { Link } from "react-router-dom";
import { ReactComponent as ListIcon } from "../../assets/img/list.svg";
import { ReactComponent as MapIcon } from "../../assets/img/map.svg";
import { ReactComponent as SearchIcon } from "../../assets/img/ico-search-loupe.svg";
import MapBox from "../../components/parts/mapBox/MapBox";
import AccordeonTypeArrow from "../../components/ui/accordeons/AccordeonTypeArrow";
import SelectOne from "../../components/ui/formElements/SelectOne";
import Loader from "../../components/ui/Loader";
import { getAllAcademiesFetch } from "../../redux/thunks/academyGeneralInfoThunks";
import classNames from "classnames";
import AcademyList from "./components/AcademyList";

const AcademiesPage = () => {
    const dispatch = useDispatch();
    const { academies, isLoading } = useSelector((state) => state.academyGeneralInfoApp);
    const [country, setCountry] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 300)
    const allCountries = Country.getAllCountries();
    const countries = allCountries?.map((item) => ({
        value: item.isoCode,
        label: item.name
    })) || [];
    const [activeAcademy, setActiveAcademy] = useState(null);
    const [academiesData, setAcademiesData] = useState([]);

    useEffect(() => {
        const data = academies.map((academy) => ({
            ...academy,
            id: academy.id,
            addressLine: academy?.info?.addressLine,
            title: academy?.name,
            coordinates: [academy?.info?.longitude, academy?.info?.latitude]

        })) || [];
        setAcademiesData(data);
    }, [academies]);

    useEffect(() => {
        dispatch(getAllAcademiesFetch(`template=true&no_limit=true&append_info=true&search=${searchTerm}&country=${country}`));
        setActiveAcademy(null)
    }, [debouncedSearchTerm, country]);

    const [isMap, setIsMap] = useState(true);

    const mainPoints = activeAcademy
        ? [{ coordinates: activeAcademy.coordinates }]
        : [];

    const secondaryPoints = useMemo(() => academiesData.map((el) => {
        if (el.id === activeAcademy?.id) {
            return false;
        } else {
            return {
                coordinates: [...el.coordinates],
                data: el
            };
        }
    }).filter((el) => el), [activeAcademy, academiesData]);

    const newCenter = activeAcademy
        ? { coordinates: activeAcademy.coordinates }
        : null;

    const handleSearch = (e) => {
        const q = e.target.value;
        setSearchTerm(q);
    };

    const handleMarkerClick = (data) => {
        setActiveAcademy(data);
        setAcademiesData(prev => {
            const updated = [...prev];
            const activeAcademyIndex = updated.findIndex(el => el.id === data.id);
            if (activeAcademyIndex > -1) {
                const activeAcademyItem = updated.splice(activeAcademyIndex, 1)[0];
                updated.unshift(activeAcademyItem);
            }
            return updated
        });
        if (window.innerWidth < 992) {
            setIsMap(false);
        }
    };

    const handleMapClick = () => {
        setActiveAcademy(null);

    };

    return (
        <div className="academies-page">
            <aside className="academies-page__sidebar academies-sidebar">
                <div className="academies-sidebar__header">
                    <div className="academies-sidebar__header__box">
                        <h3 className="heading mb-36">
                            Explore Academies
                        </h3>

                        <button
                            className="lg-hidden"
                            onClick={() => setIsMap(!isMap)}
                        >
                            {isMap
                                ? <ListIcon />
                                : <MapIcon />}
                        </button>
                    </div>

                    <div className="col-group gap--xl">

                        <SelectOne
                            onChange={(option) => setCountry(option.value)}
                            options={countries}
                            placeholder="All countries"
                            showAllLabel={true}
                            value={country}
                            wrapperClasses="select--outline input--md"
                        />

                        <div className="form-group input--md input--icon-left">
                            <div className="input-wrapper">
                                <span className="ico color-text">
                                    <SearchIcon />
                                </span>

                                <input
                                    className="input input--solid"
                                    onChange={handleSearch}
                                    placeholder="Search academies..."
                                    type="text"
                                    value={searchTerm}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="academies-sidebar__body mobile flex-auto">
                    <AcademyList
                        academiesData={academiesData}
                        activeAcademy={activeAcademy}
                        setActiveAcademy={setActiveAcademy}
                        isLoading={isLoading}
                    />
                </div>
            </aside>

            <div
                className="academies-page__map map"
                style={{
                    display: isMap
                        ? "block"
                        : "none"
                }}
            >
                {!isLoading ? (
                    <MapBox
                        mainPoints={mainPoints}
                        newCenter={newCenter?.coordinates}
                        onMarkerClick={handleMarkerClick}
                        secondaryPoints={secondaryPoints}
                        onMapClick={handleMapClick}
                    />
                ) : (
                    <Loader />
                )}
            </div>

            <div
                className="academies-sidebar__body lg-hidden flex-auto"
                style={{
                    display: isMap
                        ? "none"
                        : "block"
                }}
            >
                <AcademyList
                    academiesData={academiesData}
                    activeAcademy={activeAcademy}
                    setActiveAcademy={setActiveAcademy}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
};

export default AcademiesPage;
